import React from 'react';
import { makeStyles, List, ListItem, ListItemText, IconButton, Tooltip } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';


// Styles for overall page
const useStyles = makeStyles(theme => ({
    list_all: {
      alignItems: 'center',
      // padding: theme.spacing(6, 1, 0, 0),
      border: 'solid 2px',
      minWidth: '25rem',
      maxWidth: '25rem',
      maxHeight: '30rem',
      minHeight: '30rem',
      overflow: 'auto',
    },
    list_item: {
      paddingLeft: '5px',
      display: 'flex',
      minHeight: '2.5rem',
      borderBottom: 'solid 1px',
    }
}));
 
//Function that pulls the data and sends it to the Table Renderer
const ListAll = props => {
    let {loading, allList, add} = props;
    const classes = useStyles();
    return (
        <>
          {loading ? (
            <>
            </>
          ) : (
            <List className={classes.list_all}>
            {allList.map(elem => {
              return(
                <>
                  <ListItem key={elem.assignment_resource} class={classes.list_item}>
                    <ListItemText primary={elem.full_name} />
                    <Tooltip title="Add to List">
                      <IconButton color="none"><AddCircleIcon onClick={() => add(elem.assignment_resource, elem.full_name)}/></IconButton>
                    </Tooltip>
                  </ListItem>
                </>
              )
            })}
          </List>
      )}
        </>
    );
};


export default ListAll;