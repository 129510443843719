import { getOverlappingDaysInIntervals } from 'date-fns'; 
import {
  START_YEAR,
  NUM_OF_YEARS,
  MONTH_NAMES,
  MONTHS_PER_YEAR,
  QUARTERS_PER_YEAR,
  MONTHS_PER_QUARTER,
  MONTH_START_END_DATES,
} from './constants'

import {
  START_UP_QTY,
  CLOSE_OUT_QTY,
  REQUIREMENT_TYPE
} from '../../project_view/project_detail_view/project_detail_gantt/constants'

import { hexToRgb, colourIsLight, addMonthsToYear, addMonthsToYearAsDate, randomColorTrack1 } from './utils'
import { over } from 'lodash';

export const buildQuarterCells = () => {
  const v = []
  for (let i = 0; i < QUARTERS_PER_YEAR * NUM_OF_YEARS; i += 1) {
    const quarter = (i % 4) + 1
    const startMonth = i * MONTHS_PER_QUARTER
    const s = addMonthsToYear(START_YEAR, startMonth)
    const e = addMonthsToYear(START_YEAR, startMonth + MONTHS_PER_QUARTER)
    v.push({
      id: `${s.year}-q${quarter}`,
      title: `Q${quarter} ${s.year}`,
      start: new Date(`${s.year}-${s.month}-01`),
      end: new Date(`${e.year}-${e.month}-01`),
    })
  }
  return v
}

export const buildMonthCells = () => {
  const v = []
  for (let i = 0; i < MONTHS_PER_YEAR * NUM_OF_YEARS; i += 1) {
    const startMonth = i
    const start = addMonthsToYearAsDate(START_YEAR, startMonth)
    const end = addMonthsToYearAsDate(START_YEAR, startMonth + 1)
    v.push({
      id: `m${startMonth}`,
      title: MONTH_NAMES[i % 12],
      start,
      end,
    })
  }
  return v
}

// export const buildWeekCells = () => {
//   const v = []
//   for (let i = 0; i < 52*MONTHS_PER_YEAR * NUM_OF_YEARS; i += 1) {
//     const week = moment().startOf('year').weekday(1).add(i, 'week')

//     v.push({
//       id: `w${i}`,
//       // title: `W${i}`,
//       title: `W`,
//       start: week.toDate(),
//       end: week.add(1, 'week').subtract(2, 'day').toDate(),
//     })
//   }

//   return v
// }

export const buildTimebar = () => [
  {
    id: 'quarters',
    title: 'Quarters',
    cells: buildQuarterCells(),
    style: {},
  },
  {
    id: 'months',
    title: 'Months',
    cells: buildMonthCells(),
    useAsGrid: true,
    style: {},
  },
  // {
  //   id: 'weeks',
  //   title: 'Weeks',
  //   cells: buildWeekCells(),
  //   useAsGrid: true,
  //   style: {},
  // },
]

export const buildTrack1Element = ({ trackId, j, elementData }) => {
  const start = new Date(elementData.start);
  const end = new Date(elementData.end);
  const bgColor = randomColorTrack1(elementData.amount);
  const color = colourIsLight(...hexToRgb(bgColor)) ? '#000000' : '#ffffff'
  return {
    id: `t-${trackId}-el-${j}`,
    title: elementData.amount.toFixed(2),
    start,
    end,
    tooltip: (`${elementData.amount.toFixed(2)}`),
    style: {
      backgroundColor: `#${bgColor}`,
      color,
      borderRadius: '2px',
      // boxShadow: '1px 1px 0px rgba(0, 0, 0, 0.25)',
      textTransform: 'capitalize',
    },
  }
}

export const buildElement = ({ trackId, j, elementData }) => {
    const bgColor = (elementData.type==='outer') ? '#093D61' : (elementData.type==='Construction') ? '#093D61' : (elementData.type==='Start-up') ? '#093D61' : '#093D61';
    const inner_bgColor = (elementData.stage === 'Targeting') ? 'FAA31B' : ((elementData.stage === 'Prospecting')||(elementData.stage === 'Proposing')) ? 'FAA31B' : (elementData.stage === 'Potential Work - 10%') ? 'FAA31B' : (elementData.stage === 'Potential Work - 50%') ? '00AEEF' : (elementData.stage === 'Potential Work - 75%') ? '00AEEF' : (elementData.stage === 'Potential Work - 95%') ? '0B7CB0' : (elementData.stage === 'Work in Progress') ? '0B7CB0' : 'E66025';
    const color = colourIsLight(...hexToRgb(bgColor)) ? '#000000' : '#ffffff';
    if (elementData.type === 'outer') {
        return {
            id: `t-${trackId}-el-${j}`,
            title: elementData.project_name,
            start: elementData.start,
            end: elementData.end,
            job_id: elementData.job_id,
            tooltip: (`${elementData.project_name}\nStage: ${elementData.stage}\nStart: ${new Date(elementData.start).toISOString().slice(0, 10)}\nEnd: ${new Date(elementData.end).toISOString().slice(0, 10)}\nCalculated %FTE: ${elementData.ht}`),
            style: {
              // backgroundColor: `${bgColor}`,
              backgroundColor: 'transparent',
              color: 'black',
              border: 'solid black 1px',
              borderRadius: '4px',
              textTransform: 'capitalize',
            },
          }
    }

    return {
        id: `t-${trackId}-el-${j}`,
        title: '',
        start: elementData.start,
        end: elementData.end,
        job_id: elementData.job_id,
        tooltip: (`${elementData.project_name}\nPhase: ${elementData.type}\nStage: ${elementData.stage}\nStart: ${new Date(elementData.start).toISOString().slice(0, 10)}\nEnd: ${new Date(elementData.end).toISOString().slice(0, 10)}\nCalculated %FTE: ${elementData.height}`),
        style: {
            backgroundColor: `#${inner_bgColor}`,
            color,
            borderRadius: '4px',
            textTransform: 'capitalize',
            height: elementData.height,
            position: 'relative',
            top: '100%',
            transform: 'translateY(-100%)'
        },
    }
}

export const buildElements = (id, trackId, trackData) => {
  const v = []
  
  if (trackData.length > 0) {
    for (let i = 0; i< trackData.length; i++) {
      let j = i+1;
      const elementData = trackData[i];
      if ((id.match(/-/g)||[]).length === 2) {
        v.push(
          buildElement({
            trackId,
            j,
            elementData,
          })
        )  
      }
      else {
        v.push(
          buildTrack1Element({
            trackId,
            j,
            elementData,
          })
        )  
      }
    }
  }  
  return v
}

export const buildSubtrack = (trackId, subtrackId, subTrackData) => ({
  id: `track-${trackId}-${subtrackId}`,
  // job_id: subTrackData.job_id,
  title: '...',
  elements: buildElements(`track-${trackId}-${subtrackId}`, subtrackId, subTrackData),
})

export const buildTrack = (trackId, trackData, multiply_by_sfdc_probability) => {

  
    let data = trackData.requirements_assigned;
    let multiTrackData1 = [];
    let track1_data = MONTH_START_END_DATES();
    // Case where no projects assigned
    if (data.length === 0) {
      return {
        id: `track-${trackId}`,
        title: trackData.first_name+" "+trackData.last_name+ " (0)",
        elements: buildElements(`track-${trackId}-${trackId}`, trackId, data),
        // hasButton: true,
        // link: 'www.google.com',
      }
    }

    // This loop goes over all projects and creates track elements for them
    data.forEach(project_data => {
        let projectTrackData = [];

        // If conditions removes any project without start and end dates
        if ((project_data.sfdc_groundbreaking) && ((new Date(project_data.sfdc_project_completion_date)).getFullYear() > 2020)) {
  
            let groundbreaking = new Date(project_data.sfdc_groundbreaking);
            let start_up = new Date(groundbreaking.getTime() - project_data.sfdc_startup_duration*24*60*60*1000);
            let end = new Date(project_data.sfdc_project_completion_date);
            let close_out = new Date(end.getTime() + project_data.sfdc_close_out_duration*24*60*60*1000);
    
            let dates_overridden = (project_data.requirement_start_date !== null)||(project_data.requirement_end_date !== null) ? true : false;
            let requirement_start_date = new Date(project_data.requirement_start_date);
            let requirement_end_date = new Date(project_data.requirement_end_date);
            let project_probability = multiply_by_sfdc_probability ? project_data.probability : 100;

            
            // Start-up Phase
            if (['pm_leader', 'additional_pm'].includes(project_data.requirement_type)) {
              let start, end_loc;
              let push = false;

              if (!dates_overridden) {
                start= start_up;
                end_loc=groundbreaking;  
                push= true;
              } else if (requirement_start_date < groundbreaking) {
                  start = requirement_start_date;
                  end_loc = (requirement_end_date < groundbreaking) ? requirement_end_date : groundbreaking;
                  push= true;
                }
              if (push) {
                projectTrackData.push({
                  type: 'Start-up',
                  start: start,
                  end: end_loc,
                  height: `${project_data.requirement_qty*START_UP_QTY*project_probability}%`,
                  stage: project_data.stage,
                  job_id: project_data.job_id,
              });  
              }
            }


            if (['pm_design', 'pm_proposal', 'pm_precon', 'pm_permitting', 'pm_buyout'].includes(project_data.requirement_type)) {
                projectTrackData.push({
                  type: REQUIREMENT_TYPE(project_data.requirement_type),
                  start: requirement_start_date,
                  end: requirement_end_date,
                  height: `${project_data.requirement_qty*100}%`,
                  project_name: project_data.name,
                  stage: project_data.stage,
                  job_id: project_data.job_id
                });
            } else {
                projectTrackData.push({
                    type: REQUIREMENT_TYPE(project_data.requirement_type),
                    start: dates_overridden ? requirement_start_date : groundbreaking,
                    end: dates_overridden ? requirement_end_date : end,
                    height: `${project_data.requirement_qty*project_probability}%`,
                    project_name: project_data.name,
                    stage: project_data.stage,
                    job_id: project_data.job_id,
                });  
            } 

            // Close-out Phase
            if (['pm_leader', 'additional_pm'].includes(project_data.requirement_type)) {
              let start, end_loc;
              let push = false;
            
              if (!dates_overridden) {
                start = end;
                end_loc = close_out;
                push = true;
              } else if (requirement_end_date >= end) {
                start = (requirement_start_date <= end) ? end: requirement_start_date;
                end_loc = requirement_end_date;
                push = true;
              }
              if (push) {
                projectTrackData.push({
                  type: 'Close-out',
                  start: start,
                  end: end_loc,
                  height: `${project_data.requirement_qty*CLOSE_OUT_QTY*project_probability}%`,
                  stage: project_data.stage,
                  job_id: project_data.job_id,
              });  
              }
            }
            
            // Creating Elements for every Project Track. 3 outer elements = project dates, 3 inner = requirement phases
            projectTrackData.push({
              type: 'outer',
              start: start_up ? start_up : groundbreaking,
              end: close_out ? close_out : end,
              project_name: project_data.name,
              stage: project_data.stage,
              job_id: project_data.job_id,
              ht: `${project_data.requirement_qty*project_probability}%`
            });

            // If a track for that project exists, merge them
            if (multiTrackData1.length > 0) {
              if ((multiTrackData1.findIndex(el => el[0].job_id === project_data.job_id))>=0) {
                projectTrackData = projectTrackData.filter(elem => elem.type !== 'outer')
                multiTrackData1[multiTrackData1.findIndex(el => el[0].job_id === project_data.job_id)] = multiTrackData1[multiTrackData1.findIndex(el => el[0].job_id === project_data.job_id)].concat(projectTrackData);
              } else {
              multiTrackData1.push(projectTrackData);
              }
            } else {
              multiTrackData1.push(projectTrackData);
            }

        }
    });

    // Loop to calculate track1 calcs
    track1_data.forEach(month => {
        let days_in_month = (new Date (month.end) - new Date(month.start))/24/60/60/1000 + 1;

        // Loop over every project
        multiTrackData1.forEach(project_data => {

          project_data.forEach(phase_data => {
            if (phase_data.type !== 'outer') {
              try {
                let overlap = getOverlappingDaysInIntervals(
                  { start: new Date(month.start), end: new Date(month.end) },
                  { start: new Date(phase_data.start), end: new Date(phase_data.end) },
                  true
                );
                if (overlap>0) {
                  overlap += 1
                }
                month.amount += parseInt(phase_data.height)/100*(overlap/days_in_month);  
              } catch (error) {
              }
          }
        })
      });
    });

    // This is for Subtracks
    const tracks = multiTrackData1.map((i, index) => buildSubtrack(trackId, index + 1, i));
    let projects_total = multiTrackData1.length;

    return {
      id: `track-${trackId}`,
      title: trackData.first_name+" "+trackData.last_name+" ("+(projects_total)+")",
      elements: buildElements(`track-${trackId}`, trackId,track1_data),
      arconnect_dp: trackData.arconnect_dp,
      tracks,
      // hasButton: true,
      // link: 'www.google.com',
      isOpen: false,
      style: {
          backgroundColor: '#FFC0CB',
      }
    }
}
