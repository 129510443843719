import React, { Component } from 'react';
import Axios from 'axios';
import _, { filter } from 'lodash';

import { API_URL } from '../../utils/config/config';
import { SFDC_STAGE_OPTIONS, SFDC_DIVISIONS, SFDC_COMPANIES } from '../../components/post-security/common/constants';

import Header from '../../components/post-security/common/header';
import Sidebar from '../../components/post-security/common/sidebar';
import PMs from '../../components/post-security/pm_view/pms.js';
import PMSidebar from '../../components/post-security/pm_view/pmSidebar.js';
import Footer from '../../components/post-security/common/footer';
import ProjectDetailedViewContainer from './ProjectDetailedViewContainer';

import {Typography, Button, Paper, Dialog, withWidth} from '@material-ui/core';
import { Replay } from '@material-ui/icons';

import { withStyles } from '@material-ui/core';

const Styles = theme => ({
    root_grid: {
        display: 'grid',
        height: '100vh',
        'grid-template-rows': '4em auto 2em',
        overflowY: 'visible',
    },
    main: {
        'padding-left': '70px',
        'padding-top': '20px',
        'padding-right': '70px',
    },
    pm_view: {
        'maxWidth': 'calc(100vw - 70px - 60px - 30px)',
        overflowX: 'auto',
    },
    header: {
        zIndex: 999999,
    }
});

class SupersContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pm_view_data: {},
            filtered_pm_view_data: {},
            loading: true,
            error: false,
            offline: false,
            filters: {'divisions_pm': [], 'supervisors_pm': [], 'locations_pm': [], 'companies_job':[], 'stages_job':[], 'divisions_job':[], 'accounts_job':[], 'multiply_by_sfdc_probability': true, 'use_custom_list': false, 'custom_list': []},
            sort_by: 'sort_by_number_of_jobs',
            profile_data: [],
            divisions_pm_list: [],
            supervisors_pm_list: [],
            locations_pm_list: [],
            divisions_job_list: [],
            accounts_job_list: [],
            companies_job_list: [],
            stages_job_list: [],
            showDetailedView: false,
            detail_view_project_info: null,
            pmResourceNames: [{id: '0', name: 'Loading....'}],
        };
        this.updateFilters = this.updateFilters.bind(this);
        this.applyFilters = this.applyFilters.bind(this);
        this.toggleDetailView = this.toggleDetailView.bind(this)
        this.updateDetailView = this.updateDetailView.bind(this);
        this.fetchPMViewData = this.fetchPMViewData.bind(this);
        this.handleSortByChange = this.handleSortByChange.bind(this);
    }

    toggleDetailView = (boo) => {
        this.setState({ showDetailedView: boo, detail_view_project_info: null});
    }

    updateDetailView = async (value) => {
        if (value) {
            try {
                const { getAccessTokenSilently } = this.props.auth;
                const token = await getAccessTokenSilently();
                const responseFetchProjectData = await Axios.get(`${API_URL}project_view/${value}/`,            
                {
                    withCredentials: true,
                    headers: {
                        'content-type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }).then((response) => {
                    this.setState({
                        ...this.state,
                        detail_view_project_info: response.data,
                        showDetailedView: true,
                    })
                });
            } catch (error) {
                console.log(error);
            }    
        }
    }

    // Update the filters in state as well as in DB
    updateFilters = async (filter_type, new_values) => {
        var obj = {};
        obj[filter_type] = new_values;
        let new_filters = Object.assign({}, this.state.filters, obj);
        this.setState({ filters: new_filters});

        const { getAccessTokenSilently } = this.props.auth;
        const token = await getAccessTokenSilently();

        let data = {
            "user_id": localStorage.getItem('id'),
            "filters": new_filters
        };

        Axios.patch(
            `${API_URL}pm_view_filters/${localStorage.getItem('id')}/`,
            data,
            {
                withCredentials: true,
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }            
        )

        this.applyFilters(new_filters);
    }

    // Apply the filters
    applyFilters = (filters) => {

        let filtered_data = _.cloneDeep(this.state.pm_view_data);

        // Custom List Filtering
        if (filters.use_custom_list) {
            let arr_id = filters.custom_list.map(e => parseInt(e));
            let filt = [];
            arr_id.forEach(e => {
                filt = filt.concat(filtered_data.filter(o => o.id === e))
            })
            filtered_data = filt;    
        }

        for (const [key, value] of Object.entries(filters)) {
            let key_db = '';
            if (key === 'divisions_pm') key_db="division";
            if (key === 'supervisors_pm') key_db="supervisor";
            if (key === 'locations_pm') key_db="location_short";
            if (key === 'companies_job') key_db=["sfdc_company", 'sfdc_second_fa_company', 'sfdc_third_fa_company', 'sfdc_fourth_fa_company'];
            if (key === 'divisions_job') key_db=["sfdc_division", 'sfdc_second_fa_division', 'sfdc_third_fa_division', 'sfdc_fourth_fa_division'];
            if (key === 'accounts_job') key_db="sfdc_account_name";
            if (key === 'stages_job') key_db="stage";
    
            if (value.length > 0) {
                // PM Based Filters
                if ((key === 'divisions_pm')||(key === 'supervisors_pm')||(key === 'locations_pm')) {
                    filtered_data = filtered_data.filter(e=> value.includes(e[key_db]))
                }
                // Job based Filters
                if ((key === 'stages_job')||(key === 'accounts_job')) {
                    filtered_data.forEach(e=> {
                        e.requirements_assigned = e.requirements_assigned.filter(el => value.includes(el[key_db]))
                    })
                    filtered_data = filtered_data.filter(e => e.requirements_assigned.length>0)
                }

                if ((key === 'divisions_job')||(key === 'companies_job')) {
                    filtered_data.forEach(e=> {
                        e.requirements_assigned = e.requirements_assigned.filter(function (el) {
                            return ((value.includes(el[key_db[0]]))||(value.includes(el[key_db[1]]))||(value.includes(el[key_db[2]]))||(value.includes(el[key_db[3]])));
                        })    
                    })
                    filtered_data = filtered_data.filter(e => e.requirements_assigned.length>0)
                }

            }
        };

        // Removing all projects without start or end dates
        filtered_data.forEach(e => {
            e.requirements_assigned = e.requirements_assigned.filter(el => el['sfdc_groundbreaking']&&el['sfdc_project_completion_date'])
        })

        // Sorting the list, so people with highest assignments are on top of the list
        if (!filters.use_custom_list) {
            filtered_data.sort((a, b) => (a.requirements_assigned.length > b.requirements_assigned.length) ? -1 : 1);
        }

        // Assigning the new value
        this.setState({ filtered_pm_view_data: filtered_data});
    }

    handleSortByChange = () => {
        let filtered_data = _.cloneDeep(this.state.filtered_pm_view_data);
 
        if (this.state.sort_by === 'sort_by_name') {
            // Sorting the list, so people with highest assignments are on top of the list
            filtered_data.sort((a, b) => (a.requirements_assigned.length > b.requirements_assigned.length) ? -1 : 1);

            this.setState({ sort_by: 'sort_by_number_of_jobs'});
        } else {
            filtered_data.sort((a, b) => (a.sfdc_resource_name > b.sfdc_resource_name) ? -1 : 1);
            this.setState({ sort_by: 'sort_by_name'});
        }

        // Assigning the new value
        this.setState({ filtered_pm_view_data: filtered_data});
    }

    fetchPMViewData = async () => {
        try {
            const { getAccessTokenSilently } = this.props.auth;
            const token = await getAccessTokenSilently();

            const responseFetchPMView = await Axios.get(`${API_URL}super_view/`, {
                withCredentials: true,
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            // Removing all projects without start or end dates
            responseFetchPMView.data.forEach(e => {
                e.requirements_assigned = e.requirements_assigned.filter(el => ((el['stage'] !== 'Dead')&&(el['stage'] !== 'Prospecting Dead')&&(el['stage'] !== 'Completed Jobs')&&(el['stage'] !== 'Closed Lost')))
            })

            responseFetchPMView.data.sort((a, b) => (a.requirements_assigned.length > b.requirements_assigned.length) ? -1 : 1)
            this.setState({
                ...this.state,
                loading: false,
                pm_view_data: responseFetchPMView.data,
                filtered_pm_view_data: responseFetchPMView.data,
                supervisors_pm_list: ([...new Set(responseFetchPMView.data.map(a => a.supervisor))]).sort().filter(Boolean).map(function(x) {return{'title': x}}),
                locations_pm_list: ([...new Set(responseFetchPMView.data.map(a => a.location_short))]).sort().filter(Boolean).map(function(x) {return{'title': x}}),
                divisions_pm_list: ([...new Set(responseFetchPMView.data.map(a => a.division))]).sort().filter(Boolean).map(function(x) {return{'title': x}}),
                divisions_job_list: SFDC_DIVISIONS,
                accounts_job_list: ([...new Set(responseFetchPMView.data.map(a => a.requirements_assigned.map(j => j.sfdc_account_name)).flat())]).sort().filter(Boolean).map(function(x) {return{'title': x}}),
                stages_job_list: SFDC_STAGE_OPTIONS,
                companies_job_list: SFDC_COMPANIES,
            })

            this.applyFilters(this.state.filters);
        }
        catch (error) {
            console.log(error);
            this.setState({ ...this.state, error: error, loading: false });
        }
    }

    fetchPMResourceNames = async () => {
        try {
            const { getAccessTokenSilently } = this.props.auth;
            const token = await getAccessTokenSilently();

            const responseFetchPMResourceNames = await Axios.get(`${API_URL}pm_resources_names/`,            
            {
                withCredentials: true,
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            responseFetchPMResourceNames.data.sort((a, b) => (a.name > b.name) ? 1 : -1)
            
            this.setState({
                ...this.state,
                pmResourceNames: responseFetchPMResourceNames.data,
            })
        }
        catch (error) {
            console.log(error);
        }
    }

    fetchFilterData = async () => {
        if (localStorage.getItem('id')) {
            try {
                const { getAccessTokenSilently } = this.props.auth;
                const token = await getAccessTokenSilently();
    
                // This will cause an issue if a row doesnt exist in DB for that user. Should fix that someday.
                const responseFetchFilterData = await Axios.get(`${API_URL}pm_view_filters/${localStorage.getItem('id')}/`,
                {
                    withCredentials: true,
                    headers: {
                        'content-type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }).then((response) => {
                    if (response.status === 200) {
                        let filters = response.data.filters;
                        this.setState({
                            ...this.state,
                            filters: filters,
                        })
                        if (!this.state.loading) {
                            this.applyFilters(this.state.filters);
                        }    
                    }
                }
                );

                // Fetching Profile Data
                const responseFetchProfileData = await Axios.get(`${API_URL}profile/`,
                {
                    withCredentials: true,
                    headers: {
                        'content-type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }).then((response) => {
                    if (response.status === 200) {
                        this.setState({
                            ...this.state,
                            profile_data: response.data,
                        })
                    }
                });               
            }
            catch (error) {
                this.setState({ ...this.state, error: error });
            }    
        }
    }
    
    componentDidMount() {
        document.title = "Super View";
        this.fetchPMViewData();
        this.fetchPMResourceNames();
        this.fetchFilterData();
    }

    render() {  

        const { classes } = this.props;        
        return (
            <>
                <div className={classes.root_grid}>
                    <div className={classes.header}>
                        {/* ***** Header Area ***** */}
                        <Header />
                    </div>
                    <div>
                        {this.state.offline ? (
                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                You are offline!
                            </div>
                        ) : this.state.error ? (
                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                <Typography variant="h5" style={{ fontFamily: 'inherit' }} gutterBottom>
                                    <strong>An error occured!</strong>
                                </Typography>
                                <center>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={() => window.location.reload()}
                                    >
                                        <Replay /> Try again
                                    </Button>
                                </center>
                            </div>
                        ) : (      
                            <>
                            <Sidebar />  
                            <div  className={classes.main}>
                                {/* ***** Main Banner Area ***** */}
                                <Paper className={classes.pm_view}>
                                    <PMs 
                                        pm_view_data={this.state.filtered_pm_view_data}
                                        loading={this.state.loading}
                                        sort_by={this.state.sort_by}
                                        handleSortByChange={this.handleSortByChange}     
                                        showDetailedView = {this.state.showDetailedView}
                                        toggleDetailView = {this.toggleDetailView}   
                                        updateDetailView = {this.updateDetailView}
                                        filters={this.state.filters}   
                                    />
                                </Paper>
                                {this.state.showDetailedView ?  
                                <>
                                    <Dialog
                                        open={this.state.showDetailedView}
                                        fullWidth={true}
                                        maxWidth={'lg'}
                                    >
                                        <ProjectDetailedViewContainer
                                            loading={this.state.loading}
                                            detail_view_project_info={this.state.detail_view_project_info}
                                            toggleDetailView={this.toggleDetailView}   
                                            pmResourceNames={this.state.pmResourceNames}  
                                            updateDetailView = {this.updateDetailView}  
                                            fetchPMViewData = {this.fetchPMViewData}
                                            multiply_by_sfdc_probability = {this.state.filters.multiply_by_sfdc_probability}
                                            profile_data={this.state.profile_data.find(e => e.id === this.state.detail_view_project_info.profile_type)}
                                            auth = {this.props.auth}
                                        />
                                    </Dialog>
                                </>
                                : (<></>)}
                            </div>
                                <PMSidebar
                                    loading={this.state.loading}           
                                    filters={this.state.filters} 
                                    divisions_pm_list={this.state.divisions_pm_list} 
                                    supervisors_pm_list={this.state.supervisors_pm_list} 
                                    locations_pm_list={this.state.locations_pm_list} 
                                    companies_job_list={this.state.companies_job_list}
                                    divisions_job_list={this.state.divisions_job_list}
                                    accounts_job_list={this.state.accounts_job_list}
                                    stages_job_list={this.state.stages_job_list}
                                    updateFilters={this.updateFilters} 
                                />
                            </>
                            )}
                        </div>
                    <div>
                        {/* ***** Footer ***** */}
                        <Footer />
                    </div>                
                </div>

            </>
        );
    }
}

export default withStyles(Styles)(SupersContainer);
