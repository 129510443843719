// BackEnd Details
const HUB_API_DOMAIN_NAME = process.env.REACT_APP_HUB_API_DOMAIN_NAME

// Development Mode

// export const API_HOST = process.env.APP_HOST || 'localhost';
// export const API_PORT = process.env.APP_PORT || 8000;
// export const HOST = `${API_HOST}:${API_PORT}/`;
// export const apiPath = 'api/v1/';
// export const API_URL = `http://${HOST}${apiPath}`;
export const DASHBOARD_API_URL = `https://dashboard.arcomurray.com/`;
// export const DASHBOARD_API_URL = `http://localhost:8000/`;

export const COMMUNIFIRE_API_KEY = process.env.COMMUNIFIRE_API_KEY || 'ZGphc2FuaTozMDMzNjI0Ny1mYjdkLTQ2ZDYtYWFkYi01M2EyOWFlZWRiMjY='

//Production Mode

const __ON__LOCALHOST__ = window.location.hostname.includes('localhost');
export const API_HOST = __ON__LOCALHOST__ ? 'localhost:8001/' : "admin.roster.arcomurray.com/";
export const HOST = `${API_HOST}`;
export const apiPath = 'api/v1/';
export const API_URL = __ON__LOCALHOST__ ? `http://${HOST}${apiPath}` : `https://${HOST}${apiPath}`;


export const HUB_URL = HUB_API_DOMAIN_NAME