import React from 'react';
import { NavLink } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';

import { makeStyles, Drawer, Toolbar, List, ClickAwayListener, ListItem } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import clsx from 'clsx';

// Icons
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// PMs view
import PeopleIcon from '@material-ui/icons/People';
// Projects view
import HomeWorkIcon from '@material-ui/icons/HomeWork';
// PAs View
import GroupAddIcon from '@material-ui/icons/GroupAdd';
// Super View
import EngineeringIcon from '@mui/icons-material/Engineering';
// Custom Settings
import SettingsIcon from '@material-ui/icons/Settings';

const useStyles = makeStyles(theme => ({
    root: {
        // display: 'flex',
        zIndex: 9,
        color: '#d8d8d8'
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        // width: 210,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        // width: 210,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(7) + 1,
        },
    },
    active: {
        backgroundColor: '#d8d8d8',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(6, 1, 0, 0),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
}));

const NavLinkMui = React.forwardRef((props, ref) => {
    const classes = useStyles();
    return <NavLink {...props} ref={ref} activeClassName={classes.active} />;
});

const Sidebar = () => {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <div className={classes.root}>
            <CssBaseline />

            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <ClickAwayListener onClickAway={handleDrawerClose}>
                    <Toolbar className={classes.toolbar}>
                        <List>
                            <br></br>
                            <ListItem
                                button
                                key={'text'}
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                className={clsx({
                                    [classes.hide]: open,
                                })}
                            >
                                <ListItemIcon>
                                    <ChevronRightIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Views'} />
                            </ListItem>
                            <ListItem
                                button
                                key={'text2'}
                                aria-label="open drawer"
                                onClick={handleDrawerClose}
                                className={clsx({
                                    [classes.hide]: !open,
                                })}
                            >
                                <ListItemIcon>
                                    <ChevronLeftIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Views'} />
                            </ListItem>
                            
                            {/* PMs View */}
                            <Tooltip title='PM View'>
                            <ListItem
                                button
                                key={'PMs View'}
                                className={classes.sublist}
                                component={NavLinkMui}
                                to="/pm-view"
                            >
                                <ListItemIcon>
                                    <PeopleIcon />
                                </ListItemIcon>
                                <ListItemText 
                                    primary={'PM View'} 
                                    classes={{ primary: classes.sublist_text }} 
                                />
                            </ListItem>
                            </Tooltip>
                            {/* Projects View */}
                            <Tooltip title='Project View'>
                            <ListItem
                                button
                                key={'Project View'}
                                className={classes.sublist}
                                component={NavLinkMui}
                                to="/project-view"
                            >
                                <ListItemIcon>
                                    <HomeWorkIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={'Project View'}
                                    classes={{ primary: classes.sublist_text }}
                                />
                            </ListItem>
                            </Tooltip>

                            {/* PAs View */}
                            {/*
                            <Tooltip title='PA View'>
                            <ListItem
                                button
                                key={'PA View'}
                                className={classes.sublist}
                                component={NavLinkMui}
                                to="/pa-view"
                            >
                                <ListItemIcon>
                                    <GroupAddIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={'PA View'}
                                    classes={{ primary: classes.sublist_text }}
                                />
                            </ListItem>
                            </Tooltip>
                            */}

                            {/* Supers View */}
                            <Tooltip title='Super View'>
                            <ListItem
                                button
                                key={'Super View'}
                                className={classes.sublist}
                                component={NavLinkMui}
                                to="/super-view"
                            >
                                <ListItemIcon>
                                    <EngineeringIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={'Super View'}
                                    classes={{ primary: classes.sublist_text }}
                                />
                            </ListItem>
                            </Tooltip>


                            {/* Custom Settings View */}
                            <Tooltip title='Custom Settings'>
                            <ListItem
                                button
                                key={'Custom Settings'}
                                className={classes.sublist}
                                component={NavLinkMui}
                                to="/custom-settings"
                            >
                                <ListItemIcon>
                                    <SettingsIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={'Custom Settings'}
                                    classes={{ primary: classes.sublist_text }}
                                />
                            </ListItem>
                            </Tooltip>

                        </List>
                    </Toolbar>
                </ClickAwayListener>
            </Drawer>
        </div>
    );
    // }
};

export default Sidebar;
