import React, { useState, useEffect } from 'react';
import { Typography, Tooltip } from '@mui/material';
import { formatDollar } from './utilityFunctions';

const PercentCompleteBars = ({ projectData, budgetData }) => {
	const [scheduleProgress, setScheduleProgress] = useState('--');
	const [costProgress, setCostProgress] = useState('--');

	useEffect(() => {
		if (!projectData || !projectData.project_data || !projectData.project_data.actual_start_date || !projectData.project_data.completion_date || !budgetData?.current_contract_summary) {
			setScheduleProgress('--');
			setCostProgress('--');
			return;
		}

		const parseDate = (dateStr) => new Date(dateStr);

		const calculateScheduleProgress = () => {
			const startDate = parseDate(projectData.project_data.actual_start_date);
			const completionDate = parseDate(projectData.project_data.completion_date);
			const currentDate = new Date();
			const totalDuration = completionDate - startDate;
			const elapsedDuration = currentDate - startDate;

			return Math.max(0, Math.min(100, (elapsedDuration / totalDuration) * 100));
		};

		const calculateCostProgress = () => {
			const costPercentComplete = budgetData?.current_contract_summary?.percent_complete || 0;
			console.log(costPercentComplete);
			return Math.max(0, Math.min(100, costPercentComplete));
		};

		setScheduleProgress(calculateScheduleProgress());
		setCostProgress(calculateCostProgress());
	}, [projectData, budgetData]);

	const getBarStyle = (progress) => ({
		width: `${Math.round(progress)}%`,
		height: '50px',
		backgroundColor: '#4caf50',
		borderRadius: '5px 5px 5px 5px', 
	});

	return (
		<div style={{ display: 'flex', flexDirection: 'column', gap: '30px', width: '100%', alignItems: 'center', marginTop: '10px' }}>
			{projectData && projectData.project_data && projectData.project_data.actual_start_date && projectData.project_data.completion_date && budgetData && budgetData.current_contract_summary && budgetData.current_contract_summary.percent_complete !== undefined ? (
				<>
					{/* Schedule Progress Bar (by Date) */}
					<div style={{ display: 'flex', gap: '2px', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
						<Tooltip
							title={
								<>
									Start Date: {projectData.project_data.actual_start_date}
									<br />
									Substantial Completion Date: {projectData.project_data.completion_date}
								</>
							}
							arrow
							componentsProps={{
								tooltip: {
									sx: {
										fontSize: '1rem',
										padding: '10px',
									},
								},
							}}
						>
							<div style={{ width: '65%', position: 'relative', backgroundColor: '#bab8b8', borderRadius: '5px' }}>
								<div className="progress-bar" style={getBarStyle(scheduleProgress)}></div>
								{/* Label positioned in the center */}
								<Typography 
									style={{ 
										position: 'absolute', 
										width: '100%', 
										textAlign: 'center', 
										top: '50%', 
										transform: 'translateY(-50%)', 
										pointerEvents: 'none',
										color: 'white'
									}}
								>
									Schedule: {typeof scheduleProgress === 'number' ? Math.round(scheduleProgress) : '--'}%
								</Typography>
							</div>
						</Tooltip>
					</div>

					{/* Cost Progress Bar (Tie in Costs) */}
					<div style={{ display: 'flex', gap: '2px', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
						<Tooltip
							title={
								<>
									JTD Costs: {formatDollar(budgetData.current_contract_summary.jtd_costs)}
									<br />
									Total Estimated Costs: {formatDollar(budgetData.current_contract_summary.total_estimated_costs)}
								</>
							}
							arrow
							componentsProps={{
								tooltip: {
									sx: {
										fontSize: '1rem',
										padding: '10px',
									},
								},
							}}
						>
							<div style={{ width: '65%', position: 'relative', backgroundColor: '#bab8b8', borderRadius: '5px' }}>
								<div className="progress-bar" style={getBarStyle(costProgress)}></div>
								{/* Label positioned in the center */}
								<Typography 
									style={{ 
										position: 'absolute', 
										width: '100%', 
										textAlign: 'center', 
										top: '50%', 
										transform: 'translateY(-50%)', 
										pointerEvents: 'none',
										color: 'white'
									}}
								>
									Costs: {typeof costProgress === 'number' ? Math.round(costProgress) : '--'}%
								</Typography>
							</div>
						</Tooltip>
					</div>
				</>
			) : (
				<Typography style={{ textAlign: 'center' }} className="main-key-project-info-body">--</Typography>
			)}
		</div>
	);
};

export default PercentCompleteBars;
