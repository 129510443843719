import React from 'react';

import {Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { CardContent } from '@mui/material';

const useStyles = makeStyles({
    // table: {
    //     minWidth: 500,
    // }
});

const ConcurExpenseTracker = ({concurUrl, isModal}) => {
    const classes = useStyles();
    console.log('YO>>',concurUrl)

    return (
        <CardContent >
            {concurUrl ? (
            <div style={{ width: '100%', height: '90vh' }}>
                <iframe
                    src={concurUrl}
                    style={{ width: '100%', height: '100%', border: 'none' }}
                    title="Embedded Content"
                    allowFullScreen
                />
            </div>                        
            ): (
                <Typography variant="h6" color="textSecondary" align="center">
                    The Concur Expense Tracker URL is broken. Please reach out to innovation@arcomurray.com.
                </Typography>
            )}
        </CardContent>
    );
};

export default ConcurExpenseTracker;
