import React, { Component } from 'react';
import Axios from 'axios';
import { API_URL } from '../../utils/config/config';

import DashboardScheduleViewerComponent from '../../components/pre-security/dashboardScheduleViewer/dashboardScheduleViewer';

class DashboardScheduleViewer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            company_slug: null,
            loading: true,
            error: false,
        };
    }

    fetchData = async () => {
        try {
            const responseFetchData = await Axios.get(`${API_URL}get_schedule/${this.state.company_slug}/`,            
            {
                withCredentials: true,
                headers: {
                    'content-type': 'application/json',
                },
            });
            
            let raw_data = JSON.parse(responseFetchData.data.data);
            let data = [];
            Object.keys(raw_data).forEach(function(key){
                data.push(raw_data[key]);
            });


            this.setState({
                ...this.state,
                loading: false,
                data: data,
            })
        } catch (error) {
            this.setState({
                loading: false,
                error: true,
            })
            console.log(error);
        }
    }

    componentDidMount() {
        let { company_slug } = this.props.match.params;
        this.setState({company_slug: company_slug}, function() {
            this.fetchData();
        });
    }

    render() {
        return (
            <>
                <DashboardScheduleViewerComponent 
                loading={this.state.loading}
                data={this.state.data}
                />
            </>
        );
    }
}

export default DashboardScheduleViewer;
