import React, { Component } from 'react';
import Axios from 'axios';
import { API_URL } from '../../utils/config/config';

import ProjectDetailView from '../../components/post-security/project_view/project_detail_view/project_detail_view';

import {Snackbar} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import { withStyles } from '@material-ui/core';
import { tr } from 'date-fns/locale';

const Styles = theme => ({
});

class ProjectDetailedViewContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            snackbar_show: false,
            snackbar_message: '',
            snackbar_type: 'info',
            projectEdits: {},
            assignmentEdits: [],
        }
        this.updateProjectEdits = this.updateProjectEdits.bind(this);
        this.updateAssignmentEdits = this.updateAssignmentEdits.bind(this);
        this.saveDetailData = this.saveDetailData.bind(this);
    }

    updateProjectEdits = (new_values) => {this.setState({ projectEdits: new_values});}
    updateAssignmentEdits = (new_values) => {this.setState({ assignmentEdits: new_values});}

    saveDetailData = async (job_id, data, pm_leader, additional_pms, current_pa_requirement_id) => {
        const { getAccessTokenSilently } = this.props.auth;
        const token = await getAccessTokenSilently();
        //Snackbar
        this.setState({snackbar_show: true});
        this.setState({snackbar_message: 'Saving...'});
        this.setState({snackbar_type: 'info'});

        // Updating the Project Record
        let waitross = await Axios.patch(
            `${API_URL}projects/update/${job_id}/`,
            {...data, ...{'sfdc_pm_leader': pm_leader ? pm_leader : '', 'sfdc_additional_pms':additional_pms ? additional_pms : ''}},
            {
                withCredentials: true,
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }            
        )
        .then((response) => {
            // Following code updates the state - data that feeds into the table to show new date.
            if (response.status === 200) {
                //Snackbar
                this.setState({snackbar_show: true});
                this.setState({snackbar_message: 'Change Saved Successfully'});
                this.setState({snackbar_type: 'success'});
            }
        })
        .catch(error => {
            console.log(error);
            //Snackbar
            this.setState({snackbar_show: true});
            this.setState({snackbar_message: 'Error Saving Change'});
            this.setState({snackbar_type: 'error'});
        })


        // Updating the Requirements
        let requirement_update_confirmation = await this.state.assignmentEdits.forEach((edit, id) => {
            // Changing the dates to API friendly dates
            edit.start = new Date(new Date(edit.start).toLocaleDateString()).toISOString().slice(0,10);
            edit.end = new Date(new Date(edit.end).toLocaleDateString()).toISOString().slice(0,10);

            // Updating Requirement Objects
            if (edit.delete === true) {
                // Case 1 DELETE
                Axios.delete(
                    `${API_URL}requirement/${edit.requirement_id}/`,
                    {
                        withCredentials: true,
                        headers: {
                            'content-type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }            
                ).catch(error => console.log(error))        
            } else if (edit.requirement_id) {
                // Case 2 EDIT

                let data_for_edit = {
                    assignment_resource: edit.assignment_resource,
                    requirement_type: edit.type,
                    requirement_start_date: edit.dates_overridden ? edit.start : null,
                    requirement_end_date: edit.dates_overridden ? edit.end : null,
                    requirement_qty: edit.qty,
                };
                Axios.patch(
                    `${API_URL}requirement/${edit.requirement_id}/`,data_for_edit,
                    {
                        withCredentials: true,
                        headers: {
                            'content-type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }            
                ).catch(error => console.log(error))        
            } else {
                // Case 3 ADD
                let data_for_add = {
                    job_id: this.props.detail_view_project_info.id,
                    assignment_resource: edit.assignment_resource,
                    requirement_type: edit.type,
                    requirement_start_date: edit.dates_overridden ? edit.start : null,
                    requirement_end_date: edit.dates_overridden ? edit.end : null,
                    requirement_qty: edit.qty,
                };
                Axios.post(
                    `${API_URL}requirement/`,data_for_add,
                    {
                        withCredentials: true,
                        headers: {
                            'content-type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }            
                ).catch(error => console.log(error));
            }
        })

        // Updating the existing PA Assignment with the latest PM Leader assignment's PA
        let new_pa_id = pm_leader ? this.props.pmResourceNames[this.props.pmResourceNames.findIndex(p => p.name === pm_leader)].pa_id : null;

        // Logic: 
        // If existing PA requirement: if new_pa_id -> patch existing to new assignment (even if it's same PM Leader) | if no new_pa_id -> delete
        // It not existing PA requirement: if new_pa_id -> create

        if (current_pa_requirement_id) {
            if (new_pa_id > 0) {
                let pa_update_confirmation = await Axios.patch(
                    `${API_URL}requirement/${current_pa_requirement_id}/`,{assignment_resource: new_pa_id},
                    {
                        withCredentials: true,
                        headers: {
                            'content-type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }            
                ).catch(error => console.log(error));        
            } else {
                let pa_delete_confirmation = await Axios.delete(
                    `${API_URL}requirement/${current_pa_requirement_id}/`,
                    {
                        withCredentials: true,
                        headers: {
                            'content-type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }            
                ).catch(error => console.log(error));            
            }
        } else {
            if (new_pa_id > 0) {
                let pa_create_confirmation = await Axios.post(
                    `${API_URL}requirement/`,{
                        job_id: this.props.detail_view_project_info.id,
                        assignment_resource: new_pa_id, 
                        requirement_type: 'project_assistant',
                        requirement_qty: 1,
                    },
                    {
                        withCredentials: true,
                        headers: {
                            'content-type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }            
                ).catch(error => console.log(error));                        
            }
        }

        if (document.title === 'PM View') {
            let waiter = await this.props.updateDetailView(this.props.detail_view_project_info.id);
            let waiter2 = await this.props.fetchPMViewData();    
        } else if (document.title === 'Project View') {
            let waiter = await this.props.fetchData();    
        } 
    }

    render() {        
        const { classes } = this.props;

        return (
            <>
                <Snackbar 
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={this.state.snackbar_show}
                    autoHideDuration={15000}
                    onClose={() => this.setState({snackbar_show: false})}
                >
                    <MuiAlert elevation={6} variant="filled" severity={this.state.snackbar_type}>
                        {this.state.snackbar_message}
                    </MuiAlert>
                </Snackbar>
                <ProjectDetailView
                    loading={this.props.loading}
                    detail_view_project_info={this.props.detail_view_project_info}
                    toggleDetailView={this.props.toggleDetailView}
                    pmResourceNames={this.props.pmResourceNames}
                    profile_data={this.props.profile_data}
                    // Local Storing Project Edits
                    projectEdits={this.state.projectEdits}
                    updateProjectEdits={this.updateProjectEdits}
                    // Local Storing Assignment Edits
                    assignmentEdits={this.state.assignmentEdits}
                    updateAssignmentEdits={this.updateAssignmentEdits}
                    // Functions saving Project and Assignment Edits
                    saveDetailData={this.saveDetailData}
                    // Multiply %FTE by SFDC Probability or not
                    multiply_by_sfdc_probability = {this.props.multiply_by_sfdc_probability}
                />
            </>
        );
    }
}

export default withStyles(Styles)(ProjectDetailedViewContainer)
