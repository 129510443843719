import React, { Component } from 'react';
import Axios from 'axios';
import { API_URL } from '../../../../utils/config/config';
import { getFileService } from '../../../../utils/services/httpService';

import LessonDetailView from '../../../components/post-security/activity-view/lesson_detail_view';
import { withStyles } from '@material-ui/core';

const Styles = theme => ({
});

class LessonDetailedViewContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            lesson_data: {},
            upvotes_by_users: 0,
            upvotes: 0,
            detail_view_lesson_id: null,
        }
        this.updateUpvotes = this.updateUpvotes.bind(this);
        this.updatePageView = this.updatePageView.bind(this);
    }

    fetchLessonContent = async () => {
        try {
            const { getAccessTokenSilently } = this.props.auth;
            const token = await getAccessTokenSilently();

            if (this.state.detail_view_lesson_id) {
                const responseFetchLessonContent = await Axios.get(`${API_URL}lessons/${this.state.detail_view_lesson_id}/?user_id=${localStorage.getItem('id')}`,            
                {
                    withCredentials: true,
                    headers: {
                        'content-type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                },
                );
                
                this.setState({
                    ...this.state,
                    loading: false,
                    lesson_data: responseFetchLessonContent.data,
                    upvotes_by_users: responseFetchLessonContent.data.upvotes_by_users ? responseFetchLessonContent.data.upvotes_by_users : 0,
                    upvotes: responseFetchLessonContent.data.upvotes_by_users ? (responseFetchLessonContent.data.upvotes-responseFetchLessonContent.data.upvotes_by_user) : responseFetchLessonContent.data.upvotes,
                })
            }
        }
        catch (error) {
            console.log(error)
            this.setState({ ...this.state, error: error, loading: false });
        }
    }

    updateUpvotes = async (value) => {
        try {
            const { getAccessTokenSilently } = this.props.auth;
            const token = await getAccessTokenSilently();

            const responseUpdateUpvotes = await Axios.post(`${API_URL}upvotes/`,            
            {
                lesson: this.state.detail_view_lesson_id,
                user: parseInt(localStorage.getItem('id')),
                upvote: value
            },{
                withCredentials: true,
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            },
            );
            this.setState({
                ...this.state,
                lesson_data: {...this.state.lesson_data},
                upvotes_by_users: value,
                upvotes: (this.state.upvotes-this.state.upvotes_by_users+value),
                loading: false,
            })
        }
        catch (error) {
            this.setState({ ...this.state, error: error, loading: false });
        }
    }

    updatePageView = async (value) => {
        if (this.state.detail_view_lesson_id) {
            try {
                const { getAccessTokenSilently } = this.props.auth;
                const token = await getAccessTokenSilently();
    
                const responseUpdateUpvotes = await Axios.patch(`${API_URL}lessons_increase_pageview/${this.state.detail_view_lesson_id}/`,            
                    {
                        page_view: 0,
                        user_id: localStorage.getItem('id') 
                    },{
                        withCredentials: true,
                        headers: {
                            'content-type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    },
                );
            }
            catch (error) {
                this.setState({ ...this.state, error: error, loading: false });
            }    
        }
    }


    componentDidMount() {

        if (!this.props.detail_view_lesson_id) {
            this.setState({loading: false})
            this.fetchLessonContent();
        } else {
            this.setState({detail_view_lesson_id: this.props.detail_view_lesson_id }, function() {
                this.fetchLessonContent();
            })    
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.detail_view_lesson_id) {
            if ((!this.state.detail_view_lesson_id)||((this.props.detail_view_lesson_id !== prevProps.detail_view_lesson_id))) {
                    this.setState( {
                        ...this.state,
                        detail_view_lesson_id: this.props.detail_view_lesson_id,
                    }, function() {
                        this.fetchLessonContent();
                    })  
                }
        }
    }


    render() {        
        const { classes } = this.props;

        return (
            <>
                <LessonDetailView
                    loading={this.state.loading}
                    lesson_data={{...this.state.lesson_data}}
                    updateUpvotes={this.updateUpvotes}
                    updateLLViewSettings={this.props.updateLLViewSettings}
                    updatePageView={this.updatePageView}
                    updateMobileDisplayTab={this.props.updateMobileDisplayTab}
                    upvotes_by_user={this.state.upvotes_by_users}
                    upvotes={this.state.upvotes}
                    auth={this.props.auth}
                    getFileService={getFileService}
                />
            </>
        );
    }
}

export default withStyles(Styles)(LessonDetailedViewContainer)
