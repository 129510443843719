import React, { useRef, useState, useEffect } from 'react';

import { Link } from "react-router-dom";
import _ from 'lodash';

import styled from 'styled-components';

import Table from './table';
import {makeStyles, Paper,  TextField, FormControl, Button } from '@material-ui/core';
import moment from 'moment';
import LessonsListFilters from '../activity-view/lessons_list_filters';

import FilterListIcon from '@mui/icons-material/FilterList';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import EditIcon from '@mui/icons-material/Edit';
import BouncingPreloader from '../common/bouncyLoader';

// Styles for overall page
const useStyles = makeStyles(theme => ({
    paper: {
        maxHeight:  Math.round(window.innerWidth * 0.9),
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        overflowX: 'hidden',
        width: 'calc(100vw - 40px)',
    },
    topbar: {
        display: 'flex',
        padding: '1rem',
        paddingBottom: '0rem',
    },
    searchbar: {
        display: 'inline-flex',
        alignItems: 'flex-end'
    },
    multicheckboxFilter: {
      width: '200px',
      paddingBottom: '1rem',
      display: 'inline-block',
    },
    filter_button: {
        backgroundColor: '#0B7CB0',
        color: 'white',
        minWidth: '20vw',
        height: '2.5rem',
        padding: '0.5rem',
        minWidth: '5vw',
    },
    formControl: {
      minWidth: '17.5vw',
      maxWidth: 400
    },
    filterSummary: {
      maxWidth: '60vw'  
      },
    stage_options: {
      '&.MuiInputBase': {
        margin: '0px',
      },
      '&.MuiSelect-icon': {
        display: 'none',
      }
    }
}));

// Styles for the React Table
const Styles = styled.div`
    padding: 1rem;
    padding-bottom: 0rem;

    .table {
        display: flow-root;
        border-spacing: 0;
        border: 1px solid black;
        border-left: 0;
        font-family: Roboto;

        overflow-x: auto;
        // overflow-y: auto;

        .tr {
            :last-child {
              .td {
                border-bottom: 1px solid black;
              }
            }
        }

        // Styling for the Header Row
        .th {
            text-align: left;
            align-items: middle;
            background: #093d61;
            color: white;
            height: 2.5rem;
            padding: 0.5rem;
            border-bottom: 1px solid black;
            border-right: 1px solid black;
        },
        .td {
            // For the column resizer
            position: relative;

            // For the column resizer
            .resizer {
                display: inline-block;
                // background: blue;
                width: 7px;
                height: 100%;
                position: absolute;
                right: 0;
                top: 0;
                transform: translateX(50%);
                z-index: 1;
                ${'' /* prevents from scrolling while dragging on touch devices */}
                touch-action:none;
            }

        }

        // Styling for the all cells
        .td {
            margin: 0;
            padding: 0.4rem;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            border-left: 1px solid black;
            border-bottom: 1px solid #d1d5da;
            white-space: nowrap;
            overflow: hidden;


            :last-child {
                // border-right: 0;
            }
        }
    }
`;

//Function that pulls the data and sends it to the Table Renderer
const Lessons = props => {
    let { lessons_view_data, loading, ll_settings, updateLLViewSettings } = props;
    const classes = useStyles();
    const tableInstance = useRef(null);
    var hiddenColumns = ['id'];
    let width = window.innerWidth-75;
 
    // Data being fed to the table
    const [lessonsViewData, setLessonsViewData] = useState(lessons_view_data) 

    const [showFilters, setShowFilters] = useState(false);
    const toggleShowFilters = (value=showFilters) => {
        setShowFilters(!value)
    }

    const [anchorEl, setAnchorEl] = React.useState(null);


    useEffect(() => {
        setLessonsViewData(lessons_view_data)
    },[lessons_view_data])

    const columns = React.useMemo(() => [
        {
            Header: 'Id',
            accessor: 'id',
            width: 5*width,
        },
        {
            Header: 'Lesson',
            accessor: 'title',
            width: 0.28*width,
            Cell: (props) => {
                if (localStorage.getItem('ll_admin')!=='true') {
                    return(props.value)    
                }
                let address = `/ll/new-lesson/${props.row.values.id}`

                return(
                    <div>
                        <Link
                            to={address}
                            target='_blank'
                            style={{textDecoration: 'none', color: 'black'}}
                        >
                            <div style={{display: 'flex'}}>{props.value}&nbsp;&nbsp;<EditIcon fontSize='small'/></div>
                        </Link>
                    </div>
                )
            },
        },
        {
            Header: 'Reviewed',
            accessor: 'reviewed',
            width: 0.07*width,
            Cell: (props) => {
                if (props.value) {
                    return(<CheckBoxIcon />)
                } else {
                    return(<CheckBoxOutlineBlankIcon />)
                }
            },
            // disableSortBy: true
            sortType: (rowA, rowB) => {
                if (rowA.original.reviewed) return 1; 
                return -1;
            },
        },
        {
            Header: 'Safety',
            accessor: 'safety',
            width: 0.07*width,
            Cell: (props) => {
                if (props.value) {
                    return(<CheckBoxIcon />)
                } else {
                    return(<CheckBoxOutlineBlankIcon />)
                }
            },
            // disableSortBy: true
            sortType: (rowA, rowB) => {
                if (rowA.original.safety) return 1; 
                return -1;
            },
        },
        {
            Header: 'Divsion',
            accessor: 'division',
            width: 0.1*width,
        },
        {
            Header: 'Author',
            accessor: 'author',
            width: 0.1*width,
            Cell: (props) => {
                let author = props.value ? props.value : '';
                author = author.includes('@arcomurray.com') ? author.split('@')[0] : author;

                return (<>{author}</>)
            }
        },
        {
            Header: 'Source',
            accessor: 'source',
            width: 0.08*width,
            Cell: (props) => {
                if (props.value) {
                    if (props.value.includes('arconnect')) {
                        return(<>ARCOnnect</>)
                    }    
                }
                return(<>{props.value}</>)
            }
        },
        {
            Header: 'Tagged Activities',
            accessor: 'selectedActivities',
            width: 0.1*width,
            Cell: (props) => {
                let value = (props.value==='...') ? '' : props.value;
                return (<>{value}</>)
            }
        },
        {
            Header: 'Date Created',
            accessor: 'date_created',
            width: 0.1*width,
            Cell: (props) => {
                return(
                    <>
                    {moment(props.value).format('MMM-DD-YYYY')}
                    </>
                )
            }
        },
        {
            Header: 'Date Updated',
            accessor: 'date_updated',
            width: 0.1*width,
            Cell: (props) => {
                return(
                    <>
                    {moment(props.value).format('MMM-DD-YYYY')}
                    </>
                )
            }
        },
    ]);

    return (
        <>
            {loading ? (
                <>
                <div style={{ position: 'absolute', top: '55%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                    <BouncingPreloader />
                </div>
                </>
            ) : (
                <Paper elevation={6} className={classes.paper} id='lessons_view'>
                    {/* Search bar */}
                    <div className={classes.topbar}>
                        <div className={classes.searchbar}>
                            <FormControl className={classes.formControl}>
                                <TextField 
                                    onChange={e => {
                                        tableInstance.current.setGlobalFilter(e.target.value);
                                    }}
                                    placeholder="Search..."
                                />
                            </FormControl>
                        </div> 
                        &emsp;
                        {/* Filter Button */}
                        <Button 
                            variant="contained"
                            className={classes.filter_button} 
                            onClick={(event) => {
                                toggleShowFilters()
                                setAnchorEl(event.currentTarget)
                            }}
                            size='small'
                        >
                            <FilterListIcon />
                            Filter
                        </Button>
                        {showFilters && (
                            <LessonsListFilters 
                                showFilters={showFilters}
                                toggleShowFilters={toggleShowFilters}
                                ll_settings={ll_settings} 
                                updateLLViewSettings={updateLLViewSettings}
                                anchorEl={anchorEl}                        
                            />
                        )}

                    </div>

                    <Styles>
                        <Table 
                            columns={columns} 
                            data={lessonsViewData} 
                            ref={tableInstance} 
                            hiddenColumns={hiddenColumns}
                            getCellProps={cellInfo => {

                                let columncheck = cellInfo.column.Header === "Tagged Activities" ? true : false;
                                let bgcolor = ''
                                if (columncheck) {
                                    bgcolor = cellInfo.row.values.selectedActivities ? ((cellInfo.row.values.selectedActivities !=='')&&(cellInfo.row.values.selectedActivities !=='...')) ? '#FFFFFF' : '#FFCCCB': '#FFCCCB';
                                    // fontcolor = '#FFFFFF'
                                }
                                return(
                                    {
                                        style: {
                                          backgroundColor: bgcolor,
                                        },
                                      }
                                )
                                return({})
                            }}                      
                        />
                    </Styles>
                </Paper>
            )}
        </>
    );
};

export default Lessons;