import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import _ from 'lodash';
import 'react-timelines/lib/css/style.css'

import { START_YEAR, NUM_OF_YEARS, MIN_ZOOM, MAX_ZOOM, DEFAULT_ZOOM } from './constants'

import { buildTimebar, buildTrack } from './builders'
import { fill } from './utils'

import Timeline from './src/index.jsx';

// Styles for overall page
const Styles = (theme) => ({
  app: {
  },
  filterSummary: {
    },
});

const now = new Date()

const timebar = buildTimebar()

class DashboardScheduleGantt extends Component {
  constructor(props) {
    super(props)

    const tracksById = fill(this.props.data.length).reduce((acc, i) => {
      const track = buildTrack(i + 1, this.props.data[i])
      acc[track.id] = track
      return acc
    }, {})

    this.state = {
      open: false,
      zoom: DEFAULT_ZOOM,
      tracksById,
      tracks: Object.values(tracksById),
    }
  }

  handleToggleOpen = () => {
    this.setState(({ open }) => ({ open: !open }))
  }

  handleZoomIn = () => {
    this.setState(({ zoom }) => ({ zoom: Math.min(zoom + 1, MAX_ZOOM) }))
  }

  handleZoomOut = () => {
    this.setState(({ zoom }) => ({ zoom: Math.max(zoom - 1, MIN_ZOOM) }))
  }

  handleToggleTrackOpen = track => {
    this.setState(state => {
      const tracksById = {
        ...state.tracksById,
        [track.id]: {
          ...track,
          isOpen: !track.isOpen,
        },
      }

      return {
        tracksById,
        tracks: Object.values(tracksById),
      }
    })
  }

  render() {  
    const { classes } = this.props;
    const { open, zoom, tracks } = this.state
    const start = new Date(`${START_YEAR}`)
    const end = new Date(`${START_YEAR + NUM_OF_YEARS}`)
    return (
      <div className={classes.app}>
        <Timeline
          scale={{
            start,
            end,
            zoom,
            zoomMin: MIN_ZOOM,
            zoomMax: MAX_ZOOM,
          }}
          isOpen={open}
          toggleOpen={this.handleToggleOpen}
          zoomIn={this.handleZoomIn}
          zoomOut={this.handleZoomOut}
          // clickElement={(element) => {
          //   this.props.updateDetailView(element.job_id)
          // }}
          // clickTrackButton={track => {
          //   // eslint-disable-next-line no-alert
          //   alert(JSON.stringify(track))
          // }}
          timebar={timebar}
          tracks={tracks}
          now={now}
          toggleTrackOpen={this.handleToggleTrackOpen}
          enableSticky
          scrollToNow
        />
      </div>
    )
  }
}

export default withStyles(Styles)(DashboardScheduleGantt)
