import React, { useRef, useState, useImperativeHandle } from 'react';
import { COLUMN_OPTIONS } from './constants';
import { SFDC_STAGE_OPTIONS } from '../common/constants';
import _ from 'lodash';


// import IconSettings from '@salesforce/design-system-react/components/icon-settings';
// import Icon from '@salesforce/design-system-react/components/icon'; 
import salesforce_logo from "./salesforce_logo.png";

import styled from 'styled-components';
import DateFnsUtils from '@date-io/date-fns'; 
import currency from 'currency.js';

import Table from '../table';
import {makeStyles, Paper, TextField, FormControl, Tooltip, Button, Avatar } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Autocomplete } from '@material-ui/lab';

// import BouncingPreloader from 'react-native-bouncing-preloaders';
import BouncingPreloader from '../common/bouncyLoader';
import People from '@material-ui/icons/People';
import { Block } from '@material-ui/icons';



// Styles for overall page
const useStyles = makeStyles(theme => ({
    paper: {
        // maxWidth: '95vw',
        // minHeight: '70vh',
        maxHeight: '90%',
        // paddingBottom: '1rem',
        display: 'flex',
        flexDirection: 'column',
    },
    topbar: {
        padding: '1rem',
        paddingBottom: '0rem',
    },
    searchbar: {
        display: 'inline-flex',
    },
    multicheckboxFilter: {
      width: '200px',
      paddingBottom: '1rem',
      display: 'inline-block',
    },
    formControl: {
      minWidth: '17.5vw',
      maxWidth: 400
    },
    filterSummary: {
      maxWidth: '60vw'  
      },
    pmtbdFilter: {
        float: 'right',
        color: '#093d61',
      },
    stage_options: {
      '&.MuiInputBase': {
        margin: '0px',
      },
      '&.MuiSelect-icon': {
        display: 'none',
      }
    }
}));

// Styles for the React Table
const Styles = styled.div`
    padding: 1rem;

    .table {
        display: flow-root;
        border-spacing: 0;
        border: 1px solid black;
        border-left: 0;
        font-family: Roboto;

        // overflow-x: auto;
        overflow-y: auto;

        .tr {
            :last-child {
              .td {
                border-bottom: 1px solid black;
              }
            }
        }

        // Styling for the Header Row
        .th {
            text-align: left;
            align-items: middle;
            background: #093d61;
            color: white;
            height: 2.5rem;
            padding: 0.5rem;
            border-bottom: 1px solid black;
            border-right: 1px solid black;
        },
        .td {
            // For the column resizer
            position: relative;

            // For the column resizer
            .resizer {
                display: inline-block;
                // background: blue;
                width: 7px;
                height: 100%;
                position: absolute;
                right: 0;
                top: 0;
                transform: translateX(50%);
                z-index: 1;
                ${'' /* prevents from scrolling while dragging on touch devices */}
                touch-action:none;
            }

        }

        // Styling for the all cells
        .td {
            margin: 0;
            padding: 0.4rem;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            border-left: 1px solid black;
            border-bottom: 1px solid #d1d5da;
            white-space: nowrap;
            overflow: hidden;


            :last-child {
                // border-right: 0;
            }
        }
    }
`;

//Function that pulls the data and sends it to the Table Renderer
const Projects = props => {
    let { pmResourceNames, project_view_data, loading, updateJobData, filters, showColumns, showDetailedView, detail_view_project_info, updateDetailView, toggleDetailView} = props;
    const classes = useStyles();
    const tableInstance = useRef(null);
    const rem_value = Math.round(window.innerWidth)/120;
    
    // State Variables
    var hiddenColumns = [...(COLUMN_OPTIONS.filter(col => !showColumns.includes(col.column_id))).map(a => a.column_id), 'assigned_pm_hidden', 'id', 'sfdc_job_number'];
    var [pmtbdFilter, setPMTBDFilter] = useState(false);

    //DetailedView
    if (showDetailedView) {
      showColumns = ['sfdc_opportunity_name'];
      var hiddenColumns = [...(COLUMN_OPTIONS.filter(col => !showColumns.includes(col.column_id))).map(a => a.column_id), 'assigned_pm_hidden', 'id', 'sfdc_job_number', 'sfdc_groundbreaking', 'sfdc_project_completion_date', 'pm_leader', 'additional_pms'];  
    }
    
    const columns = React.useMemo(() => [
        {
          Header: 'id',
          accessor: 'id',
          width: 10*rem_value,
        },
        {
          Header: 'Job Number',
          accessor: 'sfdc_job_number',
          width: 10*rem_value,
        },
        {
            Header: 'Project',
            accessor: 'sfdc_opportunity_name',
            width: 20   *rem_value,
            Cell: (props) => {
              let selected_job_no = detail_view_project_info ? detail_view_project_info.id: null;

              return(
                <div 
                    style={{
                        cursor: 'pointer',
                        display: 'inline-flex',
                    }} 
                >
                    <div
                        id={'Opportunity_Detail_View'}
                        style={{
                            backgroundColor: selected_job_no ? (selected_job_no === props.row.values.id ? '#093d61' : 'white'): 'white',
                            color: selected_job_no ? (selected_job_no === props.row.values.id ? 'white' : 'black'): 'black',
                            whiteSpace: 'pre-wrap',                            
                        }}         
                        onClick={() => {
                            if (!showDetailedView) {
                                toggleDetailView(true);
                            }
                            updateDetailView(props.row.original);
                        }}
                    >
                        {props.value}
                    </div>
                    <Tooltip title='Open Opportunity in Salesforce'>
                        <Button 
                            style={{padding: '0px', minWidth: '50px'}}
                            aria-label="salesforce"
                            startIcon={
                                <Avatar
                                    style={{height:'25px', width: '25px'}} 
                                    variant="square"
                                    src={salesforce_logo} 
                                />}
                            onClick={() => {window.open(`https://arcomurray.lightning.force.com/lightning/r/Opportunity/${props.row.original.sfdc_id}/view`, "_blank")}}
                        />
                    </Tooltip>
                </div>)
            },
        },
        {
          Header: 'Account',
          accessor: 'sfdc_account_name',
          width: 12*rem_value,
        },
        {
          Header: 'Amount',
          accessor: 'sfdc_amount',
          width: 8*rem_value,
          Cell: (props) => {
            const [value, setValue] = useState(currency(props.value, {precision: 0}).format());
            const handleChange = (event) => {
              setValue(event.target.value);
              updateJobData(props.row.values.id, {'sfdc_amount': Number(event.target.value.replace(/[^0-9.-]+/g,""))});
            };

            return(
                <TextField
                    id={'Amount_Updated_'+(props.row.values.id+4999999).toString()}
                    value={value}
                    onChange={e => setValue(e.target.value)}
                    onBlur={e => handleChange(e)}
                >
                    {props.value}
                </TextField>
            )
          },
        },
        {
            Header: 'Cost Status Profit',
            accessor: d => `${currency(d.sfdc_cost_status_profit, {precision: 0}).format()}`,
            width: 8*rem_value,
            id: 'cost_status_profit',
            Cell: (props) => {
                const [value, setValue] = useState(currency(props.value, {precision: 0}).format());
                const handleChange = (event) => {
                  setValue(event.target.value);
                  updateJobData(props.row.values.id, {'sfdc_cost_status_profit': Number(event.target.value.replace(/[^0-9.-]+/g,""))});
                };
    
                return(
                    <TextField
                        id={(props.row.values.id+8999999).toString()}
                        value={value}
                        onChange={e => setValue(e.target.value)}
                        onBlur={e => handleChange(e)}
                    >
                        {props.value}
                    </TextField>
                )
              },
            },
        {
            Header: 'Est. Margin',
            accessor: d => `${d.sfdc_estimated_margin}%`,
            width: 6*rem_value,
            id: 'est_margin',
        },
        {
            Header: 'Revenue Last Year',
            accessor: d => `${d.sfdc_revenue_last_year ? currency(d.sfdc_revenue_last_year, {precision: 0}).format() : 0.00}`,
            width: 8*rem_value,
            id: 'sfdc_revenue_last_year',
        },
        {
            Header: 'Revenue This Year',
            accessor: d => `${d.sfdc_revenue_this_year ? currency(d.sfdc_revenue_this_year, {precision: 0}).format() : 0.00}`,
            width: 8*rem_value,
            id: 'sfdc_revenue_this_year',
        },
        {
            Header: 'Revenue Next Year',
            accessor: d => `${d.sfdc_revenue_next_year ? currency(d.sfdc_revenue_next_year, {precision: 0}).format() : 0.00}`,
            width: 8*rem_value,
            id: 'sfdc_revenue_next_year',
        },
        {
            Header: 'Revenue Two+ Years',
            accessor: d => `${d.sfdc_revenue_two_years ? currency(d.sfdc_revenue_two_years, {precision: 0}).format() : 0.00}`,
            width: 8*rem_value,
            id: 'sfdc_revenue_two_years',
        },
        {
            Header: 'Location',
            accessor: d => (d.sfdc_city) ? (d.sfdc_state ? `${d.sfdc_city}, ${d.sfdc_state}` : `${d.sfdc_city}`) : (d.sfdc_state ? `${d.sfdc_state}` : ''),
            minWidth: 8*rem_value,
            id: 'location',
        },
        {
            Header: 'Ops Leader',
            accessor: 'sfdc_ops_leader',
            width: 8*rem_value,
            filter: "includesSome",
            show: false,
        },
        {
            Header: 'Probability',
            accessor: d => `${d.sfdc_probability}%`,
            width: 5*rem_value,
            id: 'sfdc_probability',
        },
        {
            Header: 'SFDC Stage',
            accessor: 'sfdc_stage',
            width: 15*rem_value,
            sortType: (rowA, rowB) => {
                if (SFDC_STAGE_OPTIONS.find(e => e.title === rowA.original.sfdc_stage).sorting_order > SFDC_STAGE_OPTIONS.find(e => e.title === rowB.original.sfdc_stage).sorting_order) return 1; 
                if (SFDC_STAGE_OPTIONS.find(e => e.title === rowA.original.sfdc_stage).sorting_order < SFDC_STAGE_OPTIONS.find(e => e.title === rowB.original.sfdc_stage).sorting_order) return -1;
                 return 0;
            },
            Cell: (props) => {
                return(
                    <Autocomplete
                        id={'SFDC_Stage_'+(props.row.values.id+1999999).toString()}
                        disableClearable
                        options={SFDC_STAGE_OPTIONS}
                        getOptionLabel={(option) => option.title}
                        defaultValue={SFDC_STAGE_OPTIONS.find(o => o.title == props.value)}
                        getOptionSelected={(option, value) => {
                        return (option.title === props.value)
                        }}
                        filterSelectedOptions
                        onChange={(e, value) => {
                        updateJobData(props.row.values.id, {'sfdc_stage': value.title, 'sfdc_probability': value.probability});
                        }}
                        renderInput={(params) => (
                        <TextField
                            {...params}
                        />
                        )}
                    />
                )
            }
        },
        {
            Header: 'Groundbreak Date',
            accessor: 'sfdc_groundbreaking',
            width: 10*rem_value,
            Cell: (props) => {
                    let temp = props.value ? props.value : null;
                    let temp_date = new Date(new Date(temp));
                    if (temp) {
                    temp_date = new Date(new Date(temp));
                    temp_date.setHours(temp_date.getHours()+21);
                }
                const [value, setValue] = React.useState(props.value ? temp_date : null);
                const handleChange = e => {
                    setValue(new Date(e).toISOString().slice(0, 10));
                    let a = new Date(e).toLocaleDateString();
                    props.row.values.sfdc_groundbreaking = new Date(e).toISOString().slice(0, 10);
                    updateJobData(props.row.values.id, {'sfdc_groundbreaking': new Date(a).toISOString().slice(0, 10)}); 
                }

                return (
                    <>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                id={'Groundbreak_Date_Updated'}
                                autoOk={true}
                                disableToolbar
                                variant="inline"
                                emptyLabel=""
                                value={value}
                                onChange={(e) => handleChange(e)}
                                format="MMM-dd-yyyy"
                            />
                        </MuiPickersUtilsProvider>
                    </>
                )
            }
        },
        {
            Header: 'Completion Date',
            accessor: 'sfdc_project_completion_date',
            width: 10*rem_value,
            Cell: (props) => {
                let temp = props.value ? props.value : null;
                let temp_date = new Date(new Date(temp));
                if (temp) {
                    temp_date = new Date(new Date(temp));
                    temp_date.setHours(temp_date.getHours()+21);
                }
                const [value, setValue] = React.useState(props.value ? temp_date : null);
                const handleChange = e => {
                    props.row.values.sfdc_project_completion_date = new Date(e).toISOString().slice(0, 10);
                    setValue(new Date(e).toISOString().slice(0, 10));
                    let a = new Date(e).toLocaleDateString()
                    updateJobData(props.row.values.id, {'sfdc_project_completion_date': new Date(a).toISOString().slice(0, 10)}); 
                }

                return (
                <>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                        id={'Project_Completion_Date_Updated'}
                        autoOk={true}
                        disableToolbar
                        variant="inline"
                        emptyLabel=""
                        value={value}
                        onChange={(e) => handleChange(e)}
                        format="MMM-dd-yyyy"
                    />
                    </MuiPickersUtilsProvider>
                </>
                )
            }
        },
        {
            Header: 'Division',
            accessor: 'sfdc_division',
            width: 10*rem_value,
        },
        {
            Header: 'Company',
            accessor: 'sfdc_company',
            width: 10*rem_value,
        },
        {
            Header: 'PM Leader',
            accessor: d => {
                let pm_leader = 'PM TBD';
                d.requirements_jobs.forEach((e) => {
                    if (e.requirement_type === 'pm_leader') {
                        pm_leader = e.name;
                    }
                })
                return (pm_leader)},
            id: 'pm_leader',
            width: 12*rem_value,
            Cell: (props) => {
                return(
                    <>
                        <Tooltip title='Edit PM Assignment'>
                            <div  
                                style={{cursor:'pointer'}}
                                onClick={() => {
                                    if (!showDetailedView) {
                                    toggleDetailView(true);
                                    }
                                    updateDetailView(props.row.original)
                                    }}
                            >
                                {props.value}
                            </div>
                        </Tooltip>
                    </>
                )
            }
        },
        {
            Header: 'Additional PMs',
            accessor: 'requirements_jobs',
            id: 'additional_pms',
            width: 12*rem_value,
            accessor: d => {
                let additional_pms = null;
                d.requirements_jobs.forEach((e) => {
                    if (e.requirement_type === 'additional_pm') {
                        additional_pms = additional_pms ? (additional_pms + '; '+ e.name) : e.name;
                    }
                })

                return (additional_pms)},

            Cell: (props) => {

                return(
                    <>
                        <Tooltip title='Edit Additional PM Assignment'>
                            <div  
                                style={{cursor:'pointer'}}
                                onClick={() => {
                                    if (!showDetailedView) {
                                    toggleDetailView(true);
                                    }
                                    updateDetailView(props.row.original)
                                    }
                                }
                            >
                                {props.value}
                            </div>
                        </Tooltip>
                    </>
                )
            }
        },
        {
            // Column for searching on Assigned PMs but no editing
            Header: 'Assigned PM_Hidden',
            accessor: d => `${d.requirements_jobs.map(a => a.name).toString()}`,
            width: 8*rem_value,
            id: 'assigned_pm_hidden',
        }
    ]);

    let filters_to_display = {...filters}
    delete filters_to_display.multiply_by_sfdc_probability

    return (
        <>
            {loading ? (
                <>
                <div style={{ position: 'absolute', top: '55%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                    <BouncingPreloader />
                </div>
                </>
            ) : (
                <Paper elevation={7} className={classes.paper} id='project_view'
                    style={{ maxWidth: showDetailedView ? '20vw' : 'calc(100vw - 70px - 40px - 30px)'}}
                >
                    {/* Search bar */}
                    <div className={classes.topbar}>
                    <div className={classes.searchbar}>
                        <FormControl className={classes.formControl}>
                            <TextField 
                                onChange={e => {
                                    tableInstance.current.setGlobalFilter(e.target.value);
                                }}
                                placeholder="Search..."
                            />
                        </FormControl>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        {!showDetailedView && (
                            <div>
                                <div className={classes.filterSummary}>
                                    <div>
                                        <><b>Applied Filters:</b></>
                                        &nbsp;
                                        <>{JSON.stringify(_.pickBy(filters_to_display, (value, key) => value.length !== 0)).slice(1,-1).replace(/"/g, '').replace(/,/g, ', ').replace('divisions:', '  Divisions: ').replace('ops_leaders:', '  Ops Leaders: ').replace('companies:', '  Companies: ').replace('stages:', '  SFDC Stages: ').replace('probabilities', '  Probabilities: ')}</>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={classes.pmtbdFilter}>
                        {(!pmtbdFilter && !showDetailedView) && (
                            <Button variant='outlined'
                                onClick={() => {
                                    tableInstance.current.setGlobalFilter('PM TBD');
                                    setPMTBDFilter(true);
                                }}                        
                            >
                                Filter for PM TBD
                            </Button>
                        )}
                        {(pmtbdFilter && !showDetailedView) && (
                            <Button variant='outlined'
                                onClick={() => {
                                    tableInstance.current.setGlobalFilter(null);
                                    setPMTBDFilter(false);
                                }}                        
                            >
                                Remove Filter for PM TBD
                            </Button>
                        )}
                    </div>

                    </div>

                    <Styles>
                        <Table columns={columns} data={project_view_data} ref={tableInstance} hiddenColumns={hiddenColumns}/>
                    </Styles>
                </Paper>
            )}
        </>
    );
};

export default Projects;