import React, { Component } from 'react';

import { getService, getFileService,postService,patchService } from '../../../../utils/services/httpService';

import Header from '../../../components/post-security/common/header';
import NewLesson from '../../../components/post-security/new-lesson/new_lesson';
import {Typography, Button} from '@material-ui/core';
import { Replay } from '@material-ui/icons';
import { withStyles } from '@material-ui/core';
import Cookies from 'js-cookie';
import { 
    AUTH_COOKIE_NAME
} from "../../../../auth/constant";

const Styles = theme => ({
    root_grid: {
        display: 'grid',
        height: '100vh',
        'grid-template-rows': '48px auto',
        overflowY: 'visible',
    },
    main: {
        'display': 'inline-flex',
        [theme.breakpoints.up('sm')]: {
            'padding-left': '70px',
            'padding-top': '20px',
            'padding-right': '70px',
            'padding-bottom': '20px',
        },
        'overflow': 'hidden',
    },
    ll_view: {
        'maxWidth': 'calc(100vw - 70px - 60px - 30px)',
        // overflowX: 'auto',
    },
    header: {
        zIndex: 999999,
    }
});

class LLView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: false,
            offline: false,
            lesson_id: null,
            lesson_data: {}
        };
        this.fetchNewLessonData = this.fetchNewLessonData.bind(this);
        this.formSubmitContainer = this.formSubmitContainer.bind(this); 
    }
    

    fetchNewLessonData = async (lesson_id) => {
        try {
            const responseFetchLessonView = await getService(this.props.auth, `lessons/${lesson_id}/?user_id=${localStorage.getItem('id')}`);
            
            if ((localStorage.getItem('ll_admin')==='true')||(localStorage.getItem('email')===responseFetchLessonView.data.author_email)) {
                this.setState({
                    ...this.state,
                    loading: false,
                    lesson_data: responseFetchLessonView.data
                })    
            } else {
                this.setState(
                    {
                        loading: false, 
                        lesson_data: {
                            attachments: '',
                            author: JSON.parse(Cookies.get(AUTH_COOKIE_NAME)).profile.email,
                            description: '',
                            division: 'general',
                            tags: [],
                            reviewed: false,
                            safety: false,
                            selectedActivities: '',
                            title: '',
                        }
                    }
                )    
            }
        }
        catch (error) {
            this.setState({ ...this.state, error: error, loading: false });
        }
    }
    
    formSubmitContainer = async (lessonFormData, request_type) => {
        try {
            if (request_type === 'post') {
                const saveLesson = await postService(this.props.auth, `lessons/`, lessonFormData, `multipart/form-data`);                
                this.props.history.push(`/ll/view/${saveLesson.data.id}`);            
            } else {
                // Attachments: Only can add more attachments rn. FormData only has info on new attachments.
                lessonFormData.append('author', JSON.parse(Cookies.get(AUTH_COOKIE_NAME)).profile.email);
                const saveLesson = await patchService(this.props.auth, `lessons/${this.state.lesson_id}/?user_id=${localStorage.getItem('id')}`, lessonFormData, `multipart/form-data`);
                this.props.history.push(`/ll/new-lesson/${saveLesson.data.id}`);            
            }
        } catch (error) {
            this.setState({ ...this.state, error: error, loading: false });
        }
    }

    componentDidMount() {
        document.title = "Log a Lesson";

        let { lesson_id } = this.props.match.params;
        this.setState({lesson_id: lesson_id})
 
        if (lesson_id)  {
            this.fetchNewLessonData(lesson_id);
        } else {
            this.setState(
                {
                    loading: false, 
                    lesson_data: {
                        attachments: '',
                        author: JSON.parse(Cookies.get(AUTH_COOKIE_NAME)).profile.email,
                        description: '',
                        division: 'general',
                        tags: [],
                        reviewed: false,
                        safety: false,
                        selectedActivities: '',
                        title: '',
                    }
                }
            )    
        }
    }

    render() {  
        const { classes } = this.props;        
        return (
            <>
                <div className={classes.root_grid}>
                    <div className={classes.header}>
                        {/* ***** Header Area ***** */}
                        <Header />
                    </div>
                    <div>
                        {this.state.offline ? (
                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                You are offline!
                            </div>
                        ) : this.state.error ? (
                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                <Typography variant="h5" style={{ fontFamily: 'inherit' }} gutterBottom>
                                    <strong>An error occured!</strong>
                                </Typography>
                                <center>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={() => window.location.reload()}
                                    >
                                        <Replay /> Try again
                                    </Button>
                                </center>
                            </div>
                        ) : this.state.loading ? (
                            <>Loading...</>                          
                        ) : (      
                            <>
                                <div className={classes.main}>
                                    <NewLesson 
                                        loading={this.state.loading}
                                        formSubmitContainer={this.formSubmitContainer}
                                        lesson_id={this.state.lesson_id}
                                        lesson_data={this.state.lesson_data}
                                        auth = {this.props.auth}                                        
                                        getFileService={getFileService}
                                    />
                                </div>
                            </>
                            )}
                        </div>
                    <div>
                    </div>                
                </div>
            </>
        );
    }
}

export default withStyles(Styles)(LLView)