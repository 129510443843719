import {useState, useEffect} from 'react';
import { Grid, Card, CardContent, Typography } from '@material-ui/core';

import PercentComplete from './PercentCompleteComponent';
import ProfitPercentage from './CurrentProfitComponent';

import CurrentContractAmount from './CurrentContractAmountComponent';
import OGContractAmount from './OriginalContractAmountComponent';
import TotalEstimatedCosts from './TotalEstimatedCostsComponent';
import JTDCosts from './JTDCostsComponent';
import ContractChangePercentage from './ContractChangePercentageComponent';
import ProfitChangePercentage from './ProfitChangePercentageComponent';


const MasterContractInfoCard = ({ budgetData }) => {

    // useEffect to trigger re-render when delayed budgetData is received
        // API currently lagging projectData endpoint by ~20sec
    const [updatedBudgetData, setUpdatedBudgetData] = useState(null);

    useEffect(() => {
        if (budgetData) {
            setUpdatedBudgetData(budgetData);
        }
    }, [budgetData]);

    return (
        <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center' }}>Current Contract</Typography>
                        <span className='ppsf-container'>
                            <CurrentContractAmount budgetData={budgetData} />
                            <ContractChangePercentage budgetData={budgetData} />
                        </span>
                        
                    </Grid>
                <Grid item xs={4}>
                    <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center' }}>Current Profit %</Typography>
                    <span className='ppsf-container'>
                        <ProfitPercentage budgetData={budgetData} />
                        <ProfitChangePercentage budgetData={budgetData} />
                    </span>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center' }}>Percent Complete</Typography>
                    <PercentComplete budgetData={budgetData} />
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center' }}>Original Contract</Typography>
                    <OGContractAmount budgetData={budgetData} />
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center' }}>Estimated Costs</Typography>
                    <TotalEstimatedCosts budgetData={budgetData} />
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center' }}>JTD Costs</Typography>
                    <JTDCosts budgetData={budgetData} />
                </Grid>
            
         </Grid>
        </CardContent>
    )
}


export default MasterContractInfoCard;