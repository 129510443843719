import React, {useState, useEffect} from 'react';

import { makeStyles, Paper, Typography, Button, Switch } from '@material-ui/core';
import BouncingPreloader from '../common/bouncyLoader';

import List1 from './list_current';
import ListAll from './list_all';

// Styles for overall page
const useStyles = makeStyles(theme => ({
    paper: {
        maxWidth: '95vw',
        minHeight: '85vh',
        padding: '10px'
    },
    paper_custom_list: {
      // maxWidth: '95vw',
      // minHeight: '85vh',
      padding: '10px',
      border: 'solid 1px'
  },
}));
 
//Function that pulls the data and sends it to the Table Renderer
const Settings = props => {
    let {loading, filters, pmResourceNames, updateFilters} = props;
    const classes = useStyles();
    const [customList, setCustomList] = useState([]);
    const [allList, setAllList] = useState([]);

    const moveUp = (id) => {  
      if (customList.length > 1) {
        let current_index = customList.findIndex(element => element.id === id);

        if (current_index !== 0) {
          let elem_current = customList[current_index];
          let elem_prev = customList[current_index - 1];
          let temp = customList;
    
          temp[current_index -1] = elem_current
          temp[current_index] = elem_prev

          setCustomList([...temp]);   
        }
      }
    }

    const moveDown = (id) => {  
      if (customList.length > 1) {
        let current_index = customList.findIndex(element => element.id === id);

        if (current_index !== customList.length-1) {
          let elem_current = customList[current_index];
          let elem_next = customList[current_index + 1];
          let temp = customList;
    
          temp[current_index + 1] = elem_current
          temp[current_index] = elem_next

          setCustomList([...temp]);   
        }
      }
    }

    const remove = (id) => {  
      let current_index = customList.findIndex(element => element.id === id);
      let temp = customList;
      temp.splice(current_index,1);
      setCustomList([...temp]);   
    }

    const add = (id, name) => {
      let ids_current = customList.map(a => a.id);
      if (!ids_current.includes(id)) {
        setCustomList([...customList,{'id': id, 'name': name}]);
        // setAllList(allList.filter(e => e.assignment_resource !== id));  
      }
    }

    useEffect(() => {
      // let ids_current = customList.map(a => a.id);
      // setAllList(pmResourceNames.filter(e => !ids_current.includes(e.assignment_resource)))
      setAllList(pmResourceNames)

      if (!loading) {
        let arr_id = filters.custom_list.map(e => parseInt(e));
        let temp_list = [];
        arr_id.forEach(e => {
          if (pmResourceNames.filter(o => o.assignment_resource === e).length > 0){
            temp_list.push({'id': e, 'name': pmResourceNames.find(o => o.assignment_resource === e).full_name})
          }
        })
        setCustomList(temp_list);        
      }
    },[pmResourceNames, loading])

    return (
        <>
          {loading ? (
            <>
              <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <BouncingPreloader />
              </div>
            </>
          ) : (
            <>
            <Paper elevation={7} className={classes.paper} key={'settings'}>
              <Typography variant="h4">Roster Settings</Typography>
              <br />
              <Paper elevation={5} className={classes.paper_custom_list} key={'settings-multiply-sfdc'}>
              <Typography variant="h5">Include SFDC Probability in %FTE Calcs?</Typography>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <Typography>
                    Do Not Multiply
                </Typography>
                <Switch
                    checked={filters.multiply_by_sfdc_probability}
                    onChange={() => updateFilters('multiply_by_sfdc_probability',!filters.multiply_by_sfdc_probability)}
                    >
                </Switch>
                <Typography>
                    Multiply
                </Typography>
              </div>
              </Paper>
              <br />
              <Typography variant="h5">PM View Settings</Typography>
              <br />
              <Paper elevation={5} className={classes.paper_custom_list} key={'settings-custom-list'}>
              <Typography variant="h5">Edit Your Custom List</Typography>
              <div style={{display:'flex'}}>
                <div>
                    <Typography variant="h6">Current List:</Typography>
                    <List1 
                      loading={loading}
                      customList={[...customList]}
                      moveUp={moveUp}
                      moveDown={moveDown}
                      remove={remove}
                  />
                </div>
                <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                <div>
                  <Typography variant="h6">Add to List:</Typography>
                  <ListAll 
                    loading={loading}
                    allList={[...allList]}
                    add={add}
                  />
                </div>
              </div>
              <br />
              <Button variant="contained" color="primary" onClick={() => updateFilters('custom_list',customList.map(e => e.id.toString()))}>Save</Button>              
              </Paper>
            </Paper>
            </>
          )}
        </>
    );
};


export default Settings;