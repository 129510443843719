import React, { useImperativeHandle } from 'react';
import { useTable, useBlockLayout, useResizeColumns, useFilters, useGlobalFilter, useSortBy } from 'react-table';


// Virtualisation of the table
import { FixedSizeList } from 'react-window';

// A great library for fuzzy filtering/sorting items
import { matchSorter } from "match-sorter";
const scrollbarWidth = () => {
    // thanks too https://davidwalsh.name/detect-scrollbar-width
    const scrollDiv = document.createElement('div')
    scrollDiv.setAttribute('style', 'width: 100px; height: 100px; overflow: scroll; position:absolute; top:-9999px;')
    document.body.appendChild(scrollDiv)
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth
    document.body.removeChild(scrollDiv)
    return scrollbarWidth
}

// Filters

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

const Table = React.forwardRef(({ columns, data, hiddenColumns }, ref) => {
    // Use the state and functions returned from useTable to build your UI
    const scrollBarSize = React.useMemo(() => scrollbarWidth(), []);

    const filterTypes = React.useMemo(
      () => ({
        // Add a new fuzzyTextFilterFn filter type.
        fuzzyText: fuzzyTextFilterFn,
        
        // Default
        text: (rows, id, filterValue) => {
          return rows.filter((row) => {
            const rowValue = row.values[id];
            return rowValue !== undefined
              ? String(rowValue)
                  .toLowerCase()
                  .startsWith(String(filterValue).toLowerCase())
              : true;
          });
        }
      }),
      []
    );
  
    // Compulsory to pass for React Table filtering
    const defaultColumn = React.useMemo(
      () => ({
        // Let's set up our default Filter UI
        // Filter: DefaultColumnFilter
      }),
      []
    );
    
    const instance = useTable(
    {
        columns,
        data,
        defaultColumn,
        filterTypes,
        initialState: { 
          hiddenColumns: hiddenColumns ? hiddenColumns : null, 
          sortBy: [
            {
                id: 'sfdc_opportunity_name',
                desc: false
            }
          ]},
    },
    useBlockLayout,
    useResizeColumns,
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    );

    const { 
      getTableProps, 
      getTableBodyProps, 
      headerGroups, 
      prepareRow, 
      rows,
      totalColumnsWidth,
      // preGlobalFilteredRows,
      // setGlobalFilter,
      // visibleColumns,
      // state, 
  } = instance;

  // return table instance
  useImperativeHandle(ref, () => instance);
    // Code for preparing each row to be rendered.
    const RenderRow = React.useCallback(
        ({ index, style }) => {
          const row = rows[index]
          prepareRow(row)
          return (
            <div
              {...row.getRowProps({
                style,
              })}
              className="tr"
            >
              {row.cells.map(cell => {
                return (
                  <div {...cell.getCellProps()} className="td">
                    {cell.render('Cell')}
                  </div>
                )
              })}
            </div>
          )
        },
        [prepareRow, rows]
    );

    // Render the UI for your table
    return (
        <>
            {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
            <div className="table">
              {/* ~~~~~Table Head~~~~~ */}

                <div {...getTableProps()}>
                    <div>
                        {headerGroups.map(headerGroup => (
                            <div className='tr' {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <div className="th" {...column.getHeaderProps()}>
                                      <div {...column.getSortByToggleProps()}>
                                        {column.render('Header')}
                                        <span>
                                          {column.isSorted
                                            ? column.isSortedDesc
                                              ? '⬇️'
                                              : '⬆️'
                                            : ''}
                                        </span>
                                      </div>
                                      <div className="resizer" {...column.getResizerProps()} />
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div> 
                </div>
                {/* ~~~~~Table Body~~~~~ */}
                <div {...getTableBodyProps()}>
                    <FixedSizeList
                        height={Math.round(window.innerHeight)-16.8*parseInt(getComputedStyle(document.documentElement).fontSize)} // total height
                        // basically height = 100vh - 16.5rem
                        itemCount={rows.length}
                        itemSize={50} //individual row height
                        width={totalColumnsWidth+scrollBarSize}
                    >
                        {RenderRow}
                    </FixedSizeList>
                </div>
            </div>
        </>
    );
});

export default Table;