import React, { Component } from 'react';
import Timeline from './src/index.jsx';
import { withStyles, Tooltip, Dialog } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import _ from 'lodash';

import 'react-timelines/lib/css/style.css'

import { START_YEAR, NUM_OF_YEARS, MIN_ZOOM, MAX_ZOOM, DEFAULT_ZOOM } from './constants'

import { buildTimebar, buildTrack } from './builders'
import ColorLegend from './color_legend';

import { fill } from './utils'


// Styles for overall page
const Styles = (theme) => ({
  app: {
  },
  filterSummary: {
    },
});

const now = new Date()

const timebar = buildTimebar()

// eslint-disable-next-line no-alert
// const clickElement = element => alert(`Clicked element\n${JSON.stringify(element, null, 2)}`)

class PMGantt extends Component {
  constructor(props) {
    super(props)

    const tracksById = fill(this.props.data.length).reduce((acc, i) => {
      const track = buildTrack(i + 1, this.props.data[i], this.props.filters.multiply_by_sfdc_probability)
      acc[track.id] = track
      return acc
    }, {})

    this.state = {
      open: false,
      zoom: DEFAULT_ZOOM,
      // eslint-disable-next-line react/no-unused-state
      tracksById,
      tracks: Object.values(tracksById),
      show_color_legend: false,
    }
  }

  handleToggleOpen = () => {
    this.setState(({ open }) => ({ open: !open }))
  }

  handleZoomIn = () => {
    this.setState(({ zoom }) => ({ zoom: Math.min(zoom + 1, MAX_ZOOM) }))
  }

  handleZoomOut = () => {
    this.setState(({ zoom }) => ({ zoom: Math.max(zoom - 1, MIN_ZOOM) }))
  }

  handleToggleTrackOpen = track => {
    this.setState(state => {
      const tracksById = {
        ...state.tracksById,
        [track.id]: {
          ...track,
          isOpen: !track.isOpen,
        },
      }

      return {
        tracksById,
        tracks: Object.values(tracksById),
      }
    })
  }

  handleToggleColorLegend = () => {
    this.setState(({ show_color_legend }) => ({ show_color_legend: !show_color_legend }))
  }

  render() {  
    const { classes } = this.props;
    const { open, zoom, tracks } = this.state
    const start = new Date(`${START_YEAR}`)
    const end = new Date(`${START_YEAR + NUM_OF_YEARS}`)
    let filters_to_display = {...this.props.filters}
    delete filters_to_display.use_custom_list
    delete filters_to_display.multiply_by_sfdc_probability
    delete filters_to_display.custom_list


    return (
      <div className={classes.app}>
        <div style={{float: 'right'}}>
          <Tooltip title="Color Legend">
            <InfoIcon onClick={this.handleToggleColorLegend} />
          </Tooltip>
        </div>
        <div>
          <Dialog
            open={this.state.show_color_legend}
            fullWidth={true}
            maxWidth={'lg'}
          >
            <ColorLegend
              handleToggleColorLegend={this.handleToggleColorLegend}            
            />
          </Dialog>
        </div>
        {/* <div>
          <ToggleButtonGroup
            exclusive
            value={this.props.sort_by}
            onChange={this.props.handleSortByChange}
            style= {{height: '25px', float: 'right', padding: '5px'}}
          >
            <ToggleButton value='sort_by_name'>Sort by Name</ToggleButton>
            <ToggleButton value='sort_by_number_of_jobs'>Sort by No. of Jobs</ToggleButton>
          </ToggleButtonGroup>
        </div> */}
          <span>Note: Projects with missing Start or End dates are hidden from the view and not accounted in the calculations.&nbsp;</span><br />
        {this.props.filters.multiply_by_sfdc_probability && (
          <span>Calculations: Sum for all assigned Projects: % Probability of the Project (based on SFDC Stage) x %FTE Assignment&nbsp;</span>
        )}
        {!this.props.filters.multiply_by_sfdc_probability && (
          <span>Calculations: Sum for all assigned Projects: %FTE Assignment&nbsp;</span>
        )}
        <div>
          <div className={classes.filterSummary}>
              <div>
                  <><b>Applied Filters:</b></>
                  &nbsp;
                  <>{JSON.stringify(_.pickBy(filters_to_display, (value, key) => value.length !== 0)).slice(1,-1).replace(/"/g, '').replace(/,/g, ', ').replace('divisions_pm:', '  Divisions (PMs): ').replace('supervisors_pm:', '  Ops Leaders (PMs): ').replace('locations_pm:', '  Offices (PMs): ').replace('divisions_job:', '  Divisions (Jobs): ').replace('stages_job:', '  SFDC Stages (Jobs): ').replace('companies_job', '  Companies (Jobs): ')}</>
              </div>
          </div>
        </div>
        {this.props.filters.use_custom_list && (
          <b>Displaying a custom list of PMs&nbsp;</b>
        )}
        <Timeline
          scale={{
            start,
            end,
            zoom,
            zoomMin: MIN_ZOOM,
            zoomMax: MAX_ZOOM,
          }}
          isOpen={open}
          toggleOpen={this.handleToggleOpen}
          zoomIn={this.handleZoomIn}
          zoomOut={this.handleZoomOut}
          clickElement={(element) => {
            this.props.updateDetailView(element.job_id)
          }}
          // clickTrackButton={track => {
          //   // eslint-disable-next-line no-alert
          //   alert(JSON.stringify(track))
          // }}
          timebar={timebar}
          tracks={tracks}
          now={now}
          toggleTrackOpen={this.handleToggleTrackOpen}
          enableSticky
          scrollToNow
        />
      </div>
    )
  }
}

export default withStyles(Styles)(PMGantt)
