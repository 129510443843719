import React from 'react';

export default function AuthCallback(props) {    
    React.useEffect(() => {
        if (window.location.hostname.includes('roster')) {
            window.location.href = "https://roster.arcomurray.com/project-view";
        } else if (window.location.hostname.includes('localhost')) {
            window.location.href = "http://localhost:3000/project-view";
        } else if (window.location.hostname.includes('lessons')) {
            window.location.href = "https://lessons.arcomurray.com/";
        } else {
            window.location.href = "/"
        }
    }, []);
    return (
        <div>
            Redirecting...
        </div>
    );
}
