import Axios from 'axios';
import { API_URL } from '../config/config';

const get_header = (token,content_type=null) => {
    return ({
        withCredentials: true,
        headers: {
            'content-type': content_type ? content_type : 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getService = async (auth, end_point) => {
    const { getAccessTokenSilently } = auth;
    const token = await getAccessTokenSilently();
    const response = await Axios.get(
        `${API_URL}${end_point}`,            
        get_header(token),
    );

    return response;
}

export const getFileService = async (auth, params) => {
    const { getAccessTokenSilently } = auth;
    const token = await getAccessTokenSilently();
    const response = await Axios.get(
        `${API_URL}get_s3_link/`,
        { ...get_header(token), params },   
    );

    return response.data.url;
}

export const patchService = async (auth, end_point, body, content_type=null) => {
    const { getAccessTokenSilently } = auth;
    const token = await getAccessTokenSilently();
    const response = await Axios.patch(
        `${API_URL}${end_point}`,            
        body,
        get_header(token, content_type),
    );

    return response;
}

export const postService = async (auth,end_point, body, content_type=null) => {
    const { getAccessTokenSilently } = auth;
    const token = await getAccessTokenSilently();
    const response = await Axios.post(
        `${API_URL}${end_point}`,            
        body,
        get_header(token, content_type),
    );

    return response;
}