import React from 'react';
import { makeStyles, Typography, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
    root: {
        height: '100vh',
        display: 'flex',
        flexFlow: 'column wrap',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        fontFamily: 'Roboto',
        textAlign: 'center',
    },
});

export default function NotFound() {
    const classes = useStyles();

    return (
        <>
            {/* <Header /> */}
            <div className={classes.root}>
                <Typography variant="h1" style={{ fontWeight: 'bolder' }}>
                    404
                </Typography>
                <Typography variant="h5" style={{ fontWeight: '500' }} gutterBottom>
                    Oops! page not found
                </Typography>
                <Typography variant="body1">The page you were looking for doesn&apos;t exist.</Typography>
                <Typography variant="body1">The page has been moved</Typography>
                <br />
                <Button variant="outlined" component={Link} to="/">
                    Take me home
                </Button>
                <br />
                <Button variant="outlined" component={Link} to="/dashboard">
                    Take me to the Dashboard
                </Button>
            </div>
        </>
    );
}
