import React, { useState } from 'react';
import { IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, TableContainer } from '@mui/material';
import TableChartIcon from '@mui/icons-material/TableChart';
import OverUnderSummaryChart from './OverUnderComponent';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { generateBVTPDF, generateInvoicePDF, generateExcel, formatDate } from './utilityFunctions';
import CloseIcon from '@mui/icons-material/Close';


const useStyles = makeStyles({
    copyButton: {
        margin: '10px 0',
    },
    excelButton: {
        backgroundColor: '#1e613b',
        color: 'white',
        margin: '10px 0px 10px 10px',
        '&:hover': {
            backgroundColor: '#1e613b',
        },
    }
});

const TablePopoutButton = ({ tableComponent: TableComponent, budgetData, invoiceData, title, source, selectedProject, selectedSnapshots, concurUrl, HUB_API_DOMAIN_NAME, apiHeader, apiKey, csrfToken }) => {
    const classes = useStyles();
    const [openDialog, setOpenDialog] = useState(false);


    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    let tableDescription = '';
    let showButtons = null;
    let viewWidth = 'lg';
    let tableHeight = '60vh';
    let baselineSnapshot = null;
    let currentSnapshot = null;
    let penultimateSnapshot = null;

    switch (source) {
        case 'trade over/under':
            tableDescription = 'This table displays JTD by default. The values below represent TOTAL variance by cost code. Select two snapshots to view the variance between them.'
            viewWidth = 'xl'
            tableHeight = '60vh'
            break;
        case 'payment-invoicing':
            showButtons = 'payment-invoicing'
            tableDescription = "This table displays the project's invoicing history from Veiwpoint."
            viewWidth = 'xl'
            tableHeight = '60vh'
            break;
        case 'concur-table':
            viewWidth = 'xxl'
            tableHeight = '65vh'
            break;
        case 'bvt-table':
            // tableDescription = 'The tables below reflect the tables utilized in the Budget Varaince Tool (BVT).'
            viewWidth = 'xl'
            tableHeight = '65vh'
            if (selectedSnapshots && selectedSnapshots.length > 0) {
                baselineSnapshot = selectedSnapshots[0];
                currentSnapshot = selectedSnapshots[selectedSnapshots.length - 1]
                penultimateSnapshot = selectedSnapshots[selectedSnapshots.length - 1]
            } else {
                baselineSnapshot = null;
                currentSnapshot = null;
                penultimateSnapshot = null;
            }
            // showButtons = 'bvt-table'
            
        default:
            tableDescription = ''; 
            break;
    }
    // Control pdf generation functions
    const handleGeneratePDF = () => {
        if (source === 'bvt-table') {
            generateBVTPDF(budgetData, selectedProject);
        } else if (source === 'payment-invoicing') {
            generateInvoicePDF(invoiceData, selectedProject);
        }
    };

    return (
        <>
            <IconButton onClick={handleClickOpen} style={{ textAlign: 'right' }}>
                <TableChartIcon />
            </IconButton>
            <Dialog open={openDialog} onClose={handleClose} maxWidth={viewWidth} fullWidth>
                <DialogTitle className='titillium-web-base' style={{fontSize: '30px', fontWeight: 'bolder', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    {title}
                    <IconButton onClick={handleClose} style={{ color: '#000'}}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent className='titillium-web-base'>{tableDescription}</DialogContent>

            {/* Conditional Dialog for selectedSnapshots */}
                {selectedSnapshots && selectedSnapshots.length > 0 && source === 'bvt-table' && (
                <DialogContent className="titillium-web-base" style={{marginTop: '-40px', marginBottom: '-30px', display: 'flex', flexDirection: 'column'}}>
                    <ul>
                        <li>Baseline Snapshot: {baselineSnapshot.name}, snapshot taken on {formatDate(baselineSnapshot.created_at)}</li>
                        <li>Current Month Snapshot: {currentSnapshot.name}, snapshot taken on {formatDate(currentSnapshot.created_at)}</li>
                        <li>Last Month's Snapshot: {penultimateSnapshot.name}, snapshot taken on {formatDate(penultimateSnapshot.created_at)}</li>
                    </ul>
                </DialogContent>
            )}
                
                <DialogContent style={{ height: tableHeight, display: 'flex', flexDirection: 'column' }}>
                {showButtons && (
                        <DialogActions className='payment-table-buttons-container'>
                            {showButtons === 'bvt-table' && (
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    className="pdf-button"
                                    onClick={handleGeneratePDF} 
                                >
                                    Download PDF
                                </Button>
                            )}
                            {showButtons === 'payment-invoicing' && (
                                <>
                                    <Button 
                                        variant="contained" 
                                        color="primary" 
                                        className="pdf-button"
                                        onClick={handleGeneratePDF} 
                                    >
                                        Download PDF
                                    </Button>
                                    <Button 
                                        variant="contained" 
                                        className="excel-button"
                                        onClick={() => generateExcel(invoiceData)} 
                                    >
                                        Download Excel
                                    </Button>
                                </>
                            )}
                        </DialogActions>
                    )}
                   <TableContainer style={{ maxHeight: '75vh', overflow: 'auto' }}>
                    <TableComponent
                        budgetData={budgetData}
                        invoiceData={invoiceData}
                        selectedSnapshots={selectedSnapshots}
                        concurUrl={concurUrl}
                        isModal={true}
                        selectedProject={selectedProject}
                        apiKey={apiKey}
                        apiHeader={apiHeader}
                        csrfToken={csrfToken}
                        HUB_API_DOMAIN_NAME={HUB_API_DOMAIN_NAME}
                    />
                </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default TablePopoutButton;
