import React from 'react';

import {Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { CardContent } from '@mui/material';

const useStyles = makeStyles({
    table: {
        minWidth: 500,
    }
});

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#093d61',
        color: theme.palette.common.white,
        fontWeight: 'bold',
        fontSize: 16,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const ProgressTrackerTable = ({budgetData, isModal}) => {
    const classes = useStyles();

    const rows = [
        { description: 'Design Review', percent_complete: 100},
        { description: 'Buyout', percent_complete: 90},
        { description: 'Submittal Review', percent_complete: 50},
        { description: 'Punchlist', percent_complete: 100},
        { description: 'Precon Meetings', percent_complete: 100},
        { description: 'Wrap Up', percent_complete: 1},
        { description: 'Design Review', percent_complete: 100},
       
    ];

    return (
        <CardContent >
        <TableContainer className={isModal ? classes.tableContainer : ''} component={Paper} style={{ maxHeight: isModal ? '100%' : '250px' }}>
            <Table className={classes.table} aria-label="payment history table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Description</StyledTableCell>
                        <StyledTableCell>% Complete</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, index) => (
                        <TableRow key={index}>
                            <StyledTableCell component="th" scope="row">
                                {row.description}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                                {row.percent_complete}%
                            </StyledTableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        </CardContent>
    );
};

export default ProgressTrackerTable;
