import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import {makeStyles, Paper,  List, ListItem, ListItemText, Divider, Typography, Button, ClickAwayListener, useTheme, useMediaQuery } from '@material-ui/core';
import {LESSON_DIVISION_OPTIONS, LESSON_TAGS} from '../constants/constants'
import { useSwipeable } from "react-swipeable";


// Styles for overall page
const useStyles = makeStyles(theme => ({
    paper: {
        // minHeight: '70vh',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'visible',
        overflowX: 'hidden',
        height : Math.round(window.innerHeight) - 7*parseInt(getComputedStyle(document.documentElement).fontSize),
        width: '100vw',
        [theme.breakpoints.up('sm')]: {
            minWidth: '28vw',
            maxHeight : Math.round(window.innerHeight) - 7*parseInt(getComputedStyle(document.documentElement).fontSize),
            minHeight: document.getElementById('activity_view') ? 'calc(document.getElementById("activity_view").clientHeight - 48rem)' : 'calc(100vh - 57.6px - 48px)',
        },
        '*::-webkit-scrollbar': {
            width: '0px'
        }
    },    
    new_lesson: {
        padding: '0.5rem',
        display: 'flex'
    },
    filter_button: {
        backgroundColor: '#0B7CB0',
        color: 'white',
        minWidth: '20vw',
        height: '2.5rem',
        padding: '0.5rem',
        minWidth: '5vw',
        margin: 'auto',
    },
    new_lesson_button: {
        backgroundColor: '#0B7CB0',
        color: 'white',
        minWidth: '20vw',
        height: '2.5rem',
        // justifyContent: "flex-start"
    },
}))

const LessonsList = props => {
    let { loading, lessons_list_data, updateDetailView, detail_view_lesson_id, toggleLessonSearch, updateMobileDisplayTab } = props;
    const classes = useStyles();
    const theme = useTheme();
    let mode = useMediaQuery(theme.breakpoints.up('sm')) ? 'desktop' : 'mobile'

    const [local_lessons_list_data, setlocal_lessons_list_data] = useState(lessons_list_data)

    useEffect(() => {
        setlocal_lessons_list_data(lessons_list_data)
        applyLessonsListFilters()
    }, [lessons_list_data])

    const handlers = useSwipeable({
        onSwiped: (eventData) => {
            if (eventData.dir==='Right') {
                updateMobileDisplayTab(0)                
            } else if (eventData.dir==='Left') {
                updateMobileDisplayTab(2)                
            }
        },
    });


    const [ll_settings, setll_settings] = useState({
        safety: false,
        reviewed: false,
        divisions: ['general'],
        tags: [],
    });

    const applyLessonsListFilters = () => {
        let temp = _.cloneDeep(lessons_list_data);

        // Filtering LESSONS for reviewed, safety, divisions
        // Divisions filtering
        if (JSON.stringify(ll_settings.divisions) !== JSON.stringify(['general'])) {
            temp = temp.filter(e => ll_settings.divisions.includes(e.lesson_division))
        }
        // Reviewed filtering
        if (ll_settings.reviewed) {
            temp = temp.filter(e => e.lesson_reviewed === true)
        }

        // Safety filtering
        if (ll_settings.safety) {
            temp = temp.filter(e => e.lesson_safety === true)
        }
        setlocal_lessons_list_data(temp);
    }

    useEffect(() => {
        applyLessonsListFilters()
    }, [ll_settings])

    return (
        <>
            <Paper className={classes.paper} {...handlers}>
                <Divider variant="middle"/>
                {mode==='mobile' && (
                    <>
                        <i><Typography variant='subtitle1' style={{paddingLeft: '15px'}}>Lessons for: {props.selected_activity_name}</Typography></i>
                    </>    
                )}
                {(local_lessons_list_data.length === 0) && (<>
                    <br />
                    <div style={{alignSelf: 'center', textAlign: 'center'}}>
                        <Typography variant='body1'>No Lessons tagged to this Activity</Typography>
                    </div>
                </>)}
                {(local_lessons_list_data.length > 0) && (
                    <List style={{padding: '0px'}}>
                    {local_lessons_list_data.map(lesson => (
                        <div key={lesson.lesson_id}>
                            <ListItem 
                                style={{'cursor':'pointer'}}
                                selected={lesson.lesson_id === detail_view_lesson_id}
                                onClick ={() => {
                                    updateDetailView(lesson.lesson_id)}
                                }
                            >
                                <ListItemText 
                                    primary={lesson.lesson_title} 
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                sx={{ display: 'inline' }}
                                                component="span"
                                                variant="body2"
                                                color="textPrimary"
                                            >
                                                {LESSON_DIVISION_OPTIONS.filter(e => e.value===lesson.lesson_division)[0].title}
                                            </Typography>  
                                            &nbsp;&nbsp;
                                            {lesson.lesson_reviewed && (
                                                <Typography
                                                    sx={{ display: 'inline' }}
                                                    component="span"
                                                    variant="body2"
                                                    style={{color: 'green'}}
                                                    title='This lesson has been reviewed by the ARCO Lessons Learned Committee'
                                                >
                                                    Reviewed
                                                </Typography>
                                            )}
                                            {!lesson.lesson_reviewed && (
                                                <Typography
                                                    sx={{ display: 'inline' }}
                                                    component="span"
                                                    variant="body2"
                                                    style={{color: '#FF5733'}}
                                                    title='This lesson is pending a review by the ARCO Lessons Learned Committee'
                                                >
                                                    Unreviewed
                                                </Typography>
                                            )}
                                        </React.Fragment> 
                                    }
                                />
                            </ListItem>
                            <Divider variant="middle"/>
                        </div>
                        ))}
                    </List>
                )}
            </Paper>
        </>
    )
}


export default LessonsList;