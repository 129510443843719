import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Link } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'rgb(250, 250, 250)',
        color: '#fff',
        padding: '0px 0px',
        fontFamily: 'Roboto',    },
    caption1: {
        fontWeight: '800',
        color: 'rgb(110, 110, 110)',
        padding: '0px 16px',
        float: 'left',
        display: 'inline-block'
    },
    caption: {
        fontWeight: '800',
        color: 'rgb(110, 110, 110)',
        padding: '0px 16px',
        float: 'right',
        display: 'inline-block'
    },
}));

const Footer = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Container maxWidth="lg">
                        <Typography variant="caption" gutterBottom className={classes.caption1} component={Link} href="https://arconnect.arco1.com/spaces/166/ami-innovation-team/home">
                            ARCO/Murray Innovation
                        </Typography>
                        <Typography variant="caption" gutterBottom className={classes.caption} component={Link} href="mailto:innovation@arcomurray.com">
                            innovation@arcomurray.com
                        </Typography>                    
            </Container>
        </div>
    );
};

export default Footer;
