import React, { useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import moment from 'moment';
import ReactToPrint from "react-to-print";

import {makeStyles, Paper, Typography, Button, TextField, Chip, Tooltip, IconButton, Breadcrumbs, Snackbar,useTheme, useMediaQuery } from '@material-ui/core';
import VerifiedIcon from '@mui/icons-material/Verified';
import BlurOffIcon from '@mui/icons-material/BlurOff';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import EngineeringIcon from '@mui/icons-material/Engineering';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import ArticleIcon from '@mui/icons-material/Article';
import EditIcon from '@mui/icons-material/Edit';
import { useSwipeable } from "react-swipeable";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import IosShareIcon from '@mui/icons-material/IosShare';
import PrintIcon from '@mui/icons-material/Print';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

import {LESSON_DIVISION_OPTIONS, LESSON_TAGS} from '../constants/constants'

// Styles for overall page
const useStyles = makeStyles(theme => ({
    lesson_detail_view: {
        padding: '10px',
        overflowY: 'auto',
        height: 'calc(100vh - 48px - 60px - 10px)',
        maxWidth: 'calc(100vw - 20px)',
        [theme.breakpoints.up('sm')]: {
            maxHeight: 'calc(100vh - 48px - 10px)',
        },
        '@media print': {
            maxHeight: 'max-content',
        },

    },
    paper: {
        overflowY: 'auto',
        // minHeight: document.getElementById('activity_view').clientHeight,
        maxHeight: 'calc(100vh - 48px - 10px)',
        minWidth: '50vw',
        pageBreakBefore: 'auto',
        '@media print': {
            display: 'block',
            pageBreakBefore: 'always'
        }

    },
    upvotedownvote: {
        border: 'none'
    },
    description: {
        maxWidth: '90%',
        // maxHeight: '70vh',
        border: '1px solid',
        padding: '10px',
        lineHeight: 'calc(1ex / 0.4)',
        // maxHeight: '80%',
        fontFamily: 'Roboto, serif',
        overflowY: 'auto',
    },
    mainBox: {
        // paddingRight: '5px',
        paddingTop: '5px', 
        maxWidth: '90%',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    voteButton: {
        color:"black", 
        padding: '0px', 
        minWidth: '0px',
    },
    voteButtonActive: {
        color:"#0B7CB0", 
        padding: '0px', 
        minWidth: '0px',
    },
    breadcrumbs: {
        cursor:'pointer',
        '&:hover':{
            textDecoration: 'underline'
        }
    }
}));

const LessonDetailView = props => {
    let { loading, lesson_data, updateUpvotes, updateLLViewSettings, updatePageView,updateMobileDisplayTab, upvotes_by_user, upvotes, auth, getFileService} = props;
    const classes = useStyles();
    const theme = useTheme();
    const [local_lesson_data, setLocal_lesson_data]= useState(lesson_data)

    const [ticker, setTicker] = useState(true)

    const handlers = useSwipeable({
        onSwiped: (eventData) => {
            if (eventData.dir==='Right') {
                updateMobileDisplayTab(1)                
            }
        },
    });


    useEffect(() => {
        setTicker(true)
    },[local_lesson_data])

    useEffect(() => {
        let seconds = 7
        if (ticker) {
            const timer = setTimeout(() => {
                updatePageView()
                setTicker(false)
                }, seconds*1000)
    
            return () => {
                clearTimeout(timer)
            }    
        }
    })

    const componentRef = React.useRef(null);

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
      }, [componentRef.current]);

    const [openSnack,setOpenSnack] = useState(false);

    const reactToPrintTrigger = React.useCallback(() => {
        return(
            <Button shape='square' title='Download/Print'
            key={lesson_data.upvotes+31}
            style={{marginLeft: 'auto'}}
            className={classes.voteButton}
            onClick={() => {
                }
            }
            >
                <PrintIcon  />
            </Button>    
        )
      }, []);
    
    useEffect(() => {
        setLocal_lesson_data(lesson_data)
    }, [lesson_data])

    let mode = useMediaQuery(theme.breakpoints.up('sm')) ? 'desktop':'mobile'

    return(
        <div className={classes.lesson_detail_view} {...handlers}>
            {(loading) && (
                <div style={{alignSelf: 'center', textAlign: 'center'}}>
                    <br />                        
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <Typography variant='h6'>Loading...</Typography>
                </div>
            )}
            { ((!loading)&&(Object.keys(lesson_data).length === 0)) ? (
                <div style={{alignSelf: 'center', textAlign: 'center'}}>
                    <br />                        
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <Typography variant='h6'>Select a Lesson from the Lessons List</Typography>
                </div>
            ) : (!local_lesson_data.title) ? (<></>) : (
                <div ref={componentRef}>
                    {/* Title */}
                    <Typography variant={'h5'}>{local_lesson_data.title}</Typography>
                    <div className={classes.mainBox}>
                        <Button shape='square' title='Helpful?'
                            key={upvotes+11}
                            className={(upvotes_by_user===1) ? classes.voteButtonActive : classes.voteButton} 
                            onClick={() => updateUpvotes((upvotes_by_user===1) ? 0 : 1)}
                        >
                            <ThumbUpIcon />
                        </Button>
                        &nbsp;
                        <Typography><b>{upvotes}</b></Typography>
                        &nbsp;
                        <Button shape='square' title='Not Helpful?'
                            key={upvotes+21}
                            className={(upvotes_by_user===-1) ? classes.voteButtonActive : classes.voteButton}
                            onClick={() => updateUpvotes((upvotes_by_user===-1) ? 0 : -1)}
                        >
                            <ThumbDownIcon />
                        </Button>
                        &nbsp;&nbsp;
                        {/* Tags: Safety, Reviewed, Division */}
                        {lesson_data.reviewed && (
                            <>
                                <Tooltip title="Reviewed by ARCO Lessons Core Group" >
                                    <Chip icon={<VerifiedIcon fontSize='small'/>} label="Reviewed" style={{backgroundColor: '#76BA1B'}} color="primary" size='small'/>
                                </Tooltip>
                            </>
                        )}
                        {!lesson_data.reviewed && (
                            <>
                                <Tooltip title="Review by ARCO Lessons Core Group pending">
                                    <Chip icon={<BlurOffIcon fontSize='small'/>} label="Review Pending" size='small'/>
                                </Tooltip>
                            </>
                        )}
                        &nbsp;&nbsp;
                        <Chip icon={<FolderSpecialIcon fontSize='small'/>} label={LESSON_DIVISION_OPTIONS.filter(e => e.value === lesson_data.division)[0].title } color="primary" size='small'/>
                        &nbsp;&nbsp;
                        {lesson_data.tags.length>0 && lesson_data.tags.map(tag => (
                            <>
                                <Chip icon={<LocalOfferIcon fontSize='small'/>} label={LESSON_TAGS.filter(e => e.value === tag)[0].title } color="secondary" size='small'/>
                                &nbsp;&nbsp;
                            </>
                        ))}
                        &nbsp;&nbsp;
                        {lesson_data.safety && (<Chip size='small' icon={<EngineeringIcon fontSize='small'/>} label="Safety Lesson"   style={{backgroundColor: '#ff6600'}} color="primary"/>)}
                        &nbsp;&nbsp;

                        <div style={{marginLeft: 'auto'}} >
                            {mode==='desktop' && (
                                <>
                                    {((localStorage.getItem('ll_admin')==='true')||(localStorage.getItem('email')===lesson_data.author_email)) && (
                                        <>
                                            <Link to={`/ll/new-lesson/${lesson_data.id}`}  target="_blank" style={{textDecoration: 'none'}}>
                                            <Button shape='square' title='Edit Lesson'
                                                key={lesson_data.upvotes+91}
                                                className={classes.voteButton}
                                                onClick={() => {
                                                    }
                                                }
                                            >
                                                <EditIcon  />
                                            </Button>
                                            </Link>
                                            &nbsp;&nbsp;                                    
                                        </>
                                    )}
                                    <ReactToPrint
                                        content={reactToPrintContent}
                                        documentTitle={`ARCO/Murray Lessons Learned - ${lesson_data.title}`}
                                        removeAfterPrint
                                        trigger={reactToPrintTrigger}
                                    />
                                    &nbsp;&nbsp;
                                </>
                            )}
                            <Button shape='square' title='Share'
                                key={lesson_data.upvotes+41}
                                className={classes.voteButton}
                                onClick={() => {
                                    if (navigator.share) {
                                        navigator.share({
                                            title: 'Select "Copy link" below to Share this Lesson',
                                            url: `${document.URL.split('ll')[0]}ll/view/${lesson_data.id.toString()}`,
                                        }).then(() => {
                                            console.log('Shared!')
                                        }).catch(console.error)
                                    } else {
                                        // For browsers that don't support navigator
                                        navigator.clipboard.writeText(`${document.URL.split('ll')[0]}ll/view/${lesson_data.id.toString()}`)
                                        setOpenSnack(true)
                                    }
                                    }
                                }
                            >
                                <IosShareIcon  />
                            </Button>
                        </div>
                        <Snackbar
                            open={openSnack}
                            autoHideDuration={2000}
                            onClose={() => setOpenSnack(false)}
                            message="Link copied!"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        />

                    </div>
                    <br />
                    {/* Description */}
                    <div
                        className={classes.description} 
                        dangerouslySetInnerHTML={{__html: lesson_data.description ? lesson_data.description.replace('<img', '<img style="max-width:50%;max-height:50%"') : ''}}
                    >
                    </div>
                    <br />
                    {/* Attachments */}
                    {lesson_data.attachments && lesson_data.attachments !== "" && (
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        <Typography variant={'body1'}>Attachments:&nbsp;</Typography>
                            {lesson_data.attachments.split('<<>>').map(file => {
                                let file_name = file.includes('arconnect') ? 'Attachment' : file.split('_attachment_')[1].substring(2,)
                                let file_ext = file.includes('arconnect') ? file : file_name.split('.')[file_name.split('.').length - 1]

                                return(
                                    <>
                                        <Chip 
                                            icon={file_ext=== 'pdf' ? <PictureAsPdfIcon fontSize='small'/> : ['png','jpg','jpeg'].includes(file_ext) ? <ImageIcon fontSize='small' /> : file_ext=== 'zip' ? <FolderZipIcon fontSize='small'/> : <ArticleIcon fontSize='small'/>} 
                                            label={file_name.substring(0,50)} 
                                            onClick={async() => {
                                                if (!file.includes('arconnect')) {
                                                    let file_name = file.split('https://arcolessonslearned.s3.amazonaws.com/')[1]
                                                    const url = await getFileService(auth,{
                                                        lesson_id: lesson_data.id,
                                                        attachment_name: file_name
                                                    })
                                                    window.open(url, '_blank')
                                                } else {
                                                    const url = file
                                                    window.open(url, '_blank')
                                                }
                                            }}
                                            color='primary'
                                            size='small'
                                        />
                                        &nbsp;&nbsp;
                                    </>)
                            })}
                        </div>
                    )}
                    <br />
                    {/* Author */}
                    <div style={{display: 'flex'}}>
                        <Typography variant={'body1'}>Created on: {moment(lesson_data.date_created).format('MMM-DD-YYYY')}</Typography>
                        {lesson_data.author && (<Typography variant={'body1'}>&nbsp;by {lesson_data.author.split('@')[0]}</Typography>)}
                    </div>
                    <br />
                    <Typography variant={'body1'}>This lesson is tagged to:</Typography>
                    {lesson_data.breadcrumbs_data.map(breadcrumbs_row => (
                        <>
                            <Breadcrumbs maxItems={2}>
                                {breadcrumbs_row.map(e => (
                                    <div style={{display: 'inline-flex'}}>
                                    <Typography 
                                        variant='subtitle2'
                                        className={classes.breadcrumbs}
                                        onClick={() => updateLLViewSettings('from_breadcrumbs', String(e[0])+'<<>>'+e[1])}>{e[1]}
                                    </Typography>
                                    </div>
                                ))}
                            </Breadcrumbs>
                        </>
                    ))}
                </div>
            )}
        </div>
    )
}

export default LessonDetailView;