import React, {useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router';
import moment from 'moment';

import DashboardScheduleGantt from './gantt/gantt';


const DashboardScheduleViewerComponent = props => {
    const { loading, data } = props;

    // data.sort((a,b) => {
    //     try {
    //         return (moment(a["Opening<<>>Grand Opening"], "YYYY-MM-DD").toDate() > moment(b["Opening<<>>Grand Opening"], "YYYY-MM-DD").toDate() ? 1 : -1)
    //     } catch {
    //         return 0
    //     }
    // })

    return (
        <div>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <div>
                    <DashboardScheduleGantt 
                        data={[...data.reverse()]} 
                    />
                </div>
            )}
        </div>
    )
};

export default DashboardScheduleViewerComponent;