import React, { Component } from 'react';
import slide2 from './tv_screen_assets/Slide2.jpeg'
import slide3 from './tv_screen_assets/Slide3.jpeg'
import slide4 from './tv_screen_assets/Slide4.jpeg'

class TvScreenContainer extends Component {
    constructor(props) {
        super(props);

        const images = [
        slide2, slide3, slide4
        ];

        this.state = {
        images,
        currentImg: 0
        }
    }

    componentDidMount() {
        this.interval = setInterval(() => this.changeBackgroundImage(), 20000);
    }

    componentWillUnmount() {
        if (this.interval) {
          clearInterval(this.interval);
        }
    }

    changeBackgroundImage() {
        let newCurrentImg = 0;
        const {images, currentImg} = this.state;
        const noOfImages = images.length;
  
        if (currentImg !== noOfImages - 1) {
          newCurrentImg = currentImg + 1;
        }
  
        this.setState({currentImg: newCurrentImg});
    }
  

    render() {
        const {images, currentImg} = this.state;
        return (
                <img src={images[currentImg]} style={{height: '100vh', display: 'block', margin: 'auto'}}/>                
            );    
        }
}

export default TvScreenContainer;
