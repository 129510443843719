import React from 'react'
import PropTypes from 'prop-types'

import Header from './Header'
import Body from './Body'

const Sidebar = ({ timebar, tracks, toggleTrackOpen, sticky, clickTrackButton, editMode, assignmentEdits, setAssignmentEdits, pmResourceNames, profile_data }) => (
  <div className="rt-sidebar">
    <Header timebar={timebar} sticky={sticky} editMode={editMode} />
    <Body 
      tracks={tracks} 
      toggleTrackOpen={toggleTrackOpen} 
      clickTrackButton={clickTrackButton} 
      editMode={editMode} 
      assignmentEdits={assignmentEdits} 
      setAssignmentEdits={setAssignmentEdits} 
      pmResourceNames={pmResourceNames}
      profile_data={profile_data}
    />
  </div>
)

Sidebar.propTypes = {
  timebar: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
    }).isRequired
  ).isRequired,
  tracks: PropTypes.arrayOf(PropTypes.shape({})),
  toggleTrackOpen: PropTypes.func,
  sticky: PropTypes.shape({}),
  clickTrackButton: PropTypes.func,
}

export default Sidebar
