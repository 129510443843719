import React, { Component } from 'react';
import Axios from 'axios';
import _ from 'lodash';

import { API_URL } from '../../../../utils/config/config';

import Lessons from '../../../components/post-security/lessons-view/lessons';

import { LESSON_DIVISION_OPTIONS, LESSON_TAGS } from '../../../components/post-security/constants/constants';

import {Typography, Button} from '@material-ui/core';
import { Replay } from '@material-ui/icons';
import { withStyles } from '@material-ui/core';

const Styles = theme => ({
    main: {
        'display': 'inline-flex',
        'padding-left': '10px',
        'padding-right': '10px',
        'padding-top': document.title === 'Lessons Learned' ? '20px' : '10px',
        'overflow': 'hidden',
    },
});

class LessonsViewContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: false,
            offline: false,
            lessons_view_data_og: [],
            lessons_view_data: [],
            ll_settings: {
                safety: false,
                reviewed: false,
                unreviewed: false,
                divisions: localStorage.getItem('cea_mode') === 'true' ? ['cea'] : LESSON_DIVISION_OPTIONS.map(e => e.value) ,
                tags: localStorage.getItem('cea_mode') === 'true' ? LESSON_TAGS.map(e => e.value): [],
            }
        };
        this.fetchLessonsViewData = this.fetchLessonsViewData.bind(this);
        this.updateLessonsViewData = this.updateLessonsViewData.bind(this);
        this.updateLessonsViewSettings = this.updateLessonsViewSettings.bind(this);
    }

    // Fetches the LessonsView Data
    fetchLessonsViewData = async () => {
        try {
            const { getAccessTokenSilently } = this.props.getAccessTokenSilently;
            const token = await getAccessTokenSilently();

            const responseFetchLessonsView = await Axios.get(`${API_URL}lessons_all/`, {
                withCredentials: true,
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            this.setState({
                ...this.state,
                loading: false,
                lessons_view_data: [...responseFetchLessonsView.data].reverse(),
                lessons_view_data_og: [...responseFetchLessonsView.data].reverse()
            }, () => {
                this.updateLessonsViewData();
            })
        }
        catch (error) {
            console.log('error',error)
            this.setState({ ...this.state, error: error, loading: false });
        }
    }

    updateLessonsViewSettings = (type, new_value) => {
        if (['safety', 'reviewed', 'divisions','unreviewed','tags'].includes(type)) {
            this.setState({ ll_settings: {...this.state.ll_settings, [type]: new_value}}, () => {
                this.updateLessonsViewData()
            });
        }
    }

    updateLessonsViewData = () => {
        let temp = _.cloneDeep(this.state.lessons_view_data_og);

        // Divisions filtering
        if (JSON.stringify(this.state.ll_settings.divisions) !== JSON.stringify(['general'])) {
            temp = temp.filter(e => this.state.ll_settings.divisions.includes(e.division))
        }
        // Reviewed filtering
        if (this.state.ll_settings.reviewed) {
            temp = temp.filter(e => e.reviewed === true)
        }
        // UnReviewed filtering
        if (this.state.ll_settings.unreviewed) {
            temp = temp.filter(e => e.reviewed !== true)
        }
        // Safety filtering
        if (this.state.ll_settings.safety) {
            temp = temp.filter(e => e.safety === true)
        }

        this.setState({ ...this.state, lessons_view_data: temp});
    }

    componentDidMount() {
        this.fetchLessonsViewData();
    }

    render() {
        const { classes } = this.props;   

        return (
            <>
                {this.state.offline ? (
                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                        You are offline!
                    </div>
                ) : this.state.error ? (
                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                        <Typography variant="h5" style={{ fontFamily: 'inherit' }} gutterBottom>
                            <strong>An error occured!</strong>
                        </Typography>
                        <center>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() => window.location.reload()}
                            >
                                <Replay /> Try again
                            </Button>
                        </center>
                    </div>
                ) : (      
                    <>
                        <div className={classes.main}>
                            <Lessons 
                                loading={this.state.loading}
                                lessons_view_data={this.state.lessons_view_data}
                                ll_settings={this.state.ll_settings}
                                updateLLViewSettings={this.updateLessonsViewSettings}
                            />                                
                        </div>
                    </>
                )}
            </>
        );
    }
}

export default withStyles(Styles)(LessonsViewContent);