import {useEffect} from "react";

import { CardContent, Typography } from "@material-ui/core";

const ProjectStageCardSmall = ({ projectData, onStageColorChange }) => {

    const stageColors = {
        'Warranty': 'rgba(0, 128, 0, 0.5)', // green
        'Stopped Work': 'rgba(255, 0, 0, 0.5)', // red
        'Pre-Construction': 'rgba(42, 172, 226, 0.5)', // lightblue
        'Lost': 'rgba(128, 128, 128, 0.5)', // grey
        'Design': 'rgba(0, 0, 255, 0.5)', // dark blue
        'Course of Construction': 'rgba(244, 126, 66, 0.80)', // orange
        'Bidding': 'rgba(42, 172, 226, 0.5)' // lightblue
    };

    const stage = projectData?.project_data?.stage || projectData?.stage || '--'

    const stageColor = stageColors[stage] || "rgba(0, 0, 0, 0.10)";

    // Send Color to Container
    useEffect(() => {
        if (onStageColorChange) {
            onStageColorChange(stageColor);
        }
    }, [stageColor, onStageColorChange]);

return (
        <div>
            {projectData && Object.keys(projectData).length > 0 ? (
                // <Typography style={{ textAlign:'center', textDecoration: `underline ${stageColor}`, textDecorationSkipInk: 'none', textDecorationThickness: '3px', textUnderlineOffset: '5px'}} className='main-key-project-info-body'>{projectData.project_data?.stage || '--'}</Typography>
                <Typography style={{ textAlign:'center'}} className='main-key-project-info-body'>{stage}</Typography>
            ) : (
                <Typography style={{textAlign: 'center'}}className="main-key-project-info-body">--</Typography>
            )}
        </div>
    );
}

export default ProjectStageCardSmall;