import React, {useState, useEffect } from 'react';
import { Autocomplete, TextField, FormControlLabel, Checkbox} from '@mui/material';


const ProjectStageSelect = ({ options, selectedValues, onChange }) => {
    const handleSelect = (event, newValue) => {
        onChange(newValue);
      };

    return (
        <div className="select-dropdown-container-portfolio">
            <Autocomplete
                multiple
                value={selectedValues || []}
                onChange={handleSelect}
                options={options || []}
                getOptionLabel={(option) => option || ''}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Select Project Stage"
                        variant="outlined"
                        fullWidth
                        sx={{
                            '& .MuiInputBase-input': {
                                color: 'white',
                            },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'white',
                                },
                                '&:hover fieldset': {
                                    borderColor: 'white',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'white',
                                },
                            },
                            '& .MuiInputLabel-root': {
                                color: 'white',
                                '&.Mui-focused': {
                                    color: 'white',
                                },
                            },
                            '& .MuiAutocomplete-endAdornment .MuiSvgIcon-root': {
                                color: 'white',
                            },
                             // Selected Values AKA "Chips" Styling 
                            '& .MuiChip-root': {
                                color: 'white', 
                                backgroundColor: 'transparent',
                                border: '1px solid lightblue',
                            },
                            '& .MuiChip-deleteIcon': {
                                color: 'white', 
                            },
                        }}
                    />
                )}
                isOptionEqualToValue={(option, value) => option === value}
            />
        </div>
    );
}

export default ProjectStageSelect;