// Functions that deal with LocalStorage management

function getCookieValue(cookieName) {
    // Given a cookie name, it parses the value and returns the value
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const [name, value] = cookie.trim().split('=');
      if (name === cookieName) {
        return decodeURIComponent(value);
      }
    }
    return null; // Cookie not found
}

export function checkLocalStorage() {
    if (!(localStorage.getItem('id') && localStorage.getItem('ll_admin') && localStorage.getItem('email'))) {
        const specificCookieName = 'ms-identity-web';
        const specificCookieValue = getCookieValue(specificCookieName);
        if (specificCookieValue !== null) {
            let profile = JSON.parse(specificCookieValue)["profile"]
            localStorage.setItem('id', profile["id"]);
            localStorage.setItem('email', profile["email"]);
            localStorage.setItem('ll_admin', profile["ll_admin"]);   
        }
    }
}