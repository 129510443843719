import React, { useState } from 'react';
import { IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import QuizIcon from '@mui/icons-material/Quiz';
import CloseIcon from '@mui/icons-material/Close';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core';
import { fontFamily } from '@mui/system';


const useStyles = makeStyles((theme) => ({
    root: {
      width: '95%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    heading: {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: theme.typography.fontWeightBold,
    },
    accordion: {
        margin: theme.spacing(1, 0),
      },
  }));
   
const FAQModal = ({ title }) => {
    const classes = useStyles();
    const [openDialog, setOpenDialog] = useState(false);

   const handleClickOpen = () => {
    setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    return (
        <>
        <QuizIcon style={{color: 'white', marginLeft: '8px', marginRight: '8px'}} onClick={handleClickOpen}/>
  
            <Dialog open={openDialog} onClose={handleDialogClose} maxWidth="xl" fullWidth>
                <DialogTitle className='titillium-web-base' style={{fontSize: '30px', fontWeight: 'bolder', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    {title}
                    <IconButton onClick={handleDialogClose} style={{ color: '#000'}}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                {/* <DialogContent className='titillium-web-base'>Add some dialogue and accordians for all of the needed FAQ's here.</DialogContent> */}

                <div className={classes.root}>
                    <Accordion className={classes.accordion}>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{backgroundColor: 'whitesmoke'}}
                        >
                        <Typography className={classes.heading}>Why isn't my project showing up?</Typography>
                        </AccordionSummary>
                        <AccordionDetails className='titillium-web-base' style={{display: 'flex', flexDirection: 'column'}}>
                            <Typography>
                                This application only pulls in projects that meet the following criteria:
                            </Typography>
                            <ul>
                                <li>The project has both Procore and Salesforce records.</li>
                                <li>The project has a Salesforce probability of 95% or higher.</li>
                                <li>The project has not been archived in Procore.</li>
                            </ul>
                            <Typography>
                            If your project meets this criteria and is still not showing up, please email our team at <a href='mailto:innovation@arcomurray.com' target='_blank'>innovation@arcomurray.com</a>.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className={classes.accordion}>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                        style={{backgroundColor: 'whitesmoke'}}
                        >
                        <Typography className={classes.heading}>My project's data is inaccurate. What's causing this?</Typography>
                        </AccordionSummary>
                        <AccordionDetails className='titillium-web-base' style={{display: 'flex', flexDirection: 'column'}}>
                            <Typography>Click on the info icons in the upper right corner of each tile to learn where the data is sourced from. Please note that the time it takes for changes to be reflected in this dashboard will vary as follows:</Typography>
                            <ul>
                                <li>Changes in Salesforce and Procore will be reflected in this dashboard momentarily.</li>
                                <li>Budget snapshot data is updated here every 24 hours.</li>
                                <li> Viewpoint data is updated every other day.</li>
                            </ul>
                        </AccordionDetails>
                    </Accordion>
                    {/* <Accordion className={classes.accordion}>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                        style={{backgroundColor: 'whitesmoke'}}
                        >
                        <Typography className={classes.heading}>Accordian 3</Typography>
                        </AccordionSummary>
                    </Accordion> */}
                    </div>
               
                <DialogActions>
                    <Button onClick={handleDialogClose} color="secondary" className="standard-secondary-button">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default FAQModal;
