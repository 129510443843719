import {useState, useEffect} from 'react';
// import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie'
import { Card, CardContent, Typography } from '@mui/material';

const formatDollar = (value) => {
    return new Intl.NumberFormat('en-US', { 
    style: 'currency', 
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0 
    }).format(value);
};

// const PercentComplete = ({ projectData }) => {

// 	if (!projectData || !projectData.project_data || !projectData.project_data.actual_start_date || !projectData.project_data.completion_date) {
// 		return (
// 			<CardContent style={{ height: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
// 				<Typography className="key-project-info-body">--</Typography>
// 			</CardContent>
// 		);
// 	}

// 	const parseDate = (dateStr) => {
// 		return new Date(dateStr);
// 	  };

// 	const calculateProgress = () => {
// 		const startDate = parseDate(projectData.project_data.start_date);
// 		const completionDate = parseDate(projectData.project_data.completion_date);
// 		if (!startDate || !completionDate) return null;

// 		const currentDate = new Date();
// 		const totalDuration = (completionDate - startDate);
// 		const elapsedDuration = (currentDate - startDate);

// 		const progress = (elapsedDuration / totalDuration) * 100

// 		if (progress > 100) {
// 			return 100
// 		} else if (progress < 0) {
// 			return 0
// 		} else {
// 			return progress
// 		}
// 	};

// 	const progress = calculateProgress();

// 	// const data = [
// 	// 	{
// 	// 		title: 'Project Progress',
// 	// 		progress: progress.toFixed(2),
// 	// 		full: (100 - progress).toFixed(2)
// 	// 	}
// 	// ];

// 	const pieData = [
// 		{
// 			id: 'Completed',
// 			label: 'Completed',
// 			value: progress,
// 			color: '#4CAF50'
// 		},
// 		{
// 			id: 'Remaining',
// 			label: 'Remaining',
// 			value: 100 - progress,
// 			color: 'transparent'
// 		}
// 	];

// // ==== OG Barchart Component ==== 
// // 	return (
		
// // 		<CardContent style={{ height: '50px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: '20px'}}>
// //             <div style={{ height: '150px', width: '70%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
// //                 <ResponsiveBar
// //                     data={data}
// //                     keys={['progress', 'full']}
// //                     margin={{ top: 20, left: 0, right: 20 }}
// //                     padding={0.5}
// //                     layout="horizontal"
// //                     colors={['#4CAF50', '#ccc']}
// //                     borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
// //                     axisTop={null}
// //                     axisRight={null}
// //                     axisBottom={null}
// //                     axisLeft={null}
// //                     enableGridX={false}
// //                     enableGridY={true}
// //                     animate={true}
// //                     motionStiffness={90}
// //                     motionDamping={15}
// //                     borderRadius={2}
// //                     labelSkipWidth={100} 
// //                     labelSkipHeight={100}
// //                     labelTextColor={{ from: 'color', modifiers: [['brighter', 5.0]] }}
// //                     theme={{
// //                         labels: {
// //                             fontSize: 14,
// //                             fontFamily: 'Titillium Web, sans-serif',
// //                             fill: '#ffffff'
// //                         }
// //                     }}
// // 					tooltip={(d) => {
// // 						return (
// // 						<div style={{ padding: '10px', background: '#d3d3d3', border: '1px solid white', borderRadius: '10px' }}>
// // 							<strong>Calculated by Date</strong>
// // 							<br />
// // 							Completion Status: {`${d.value}%`}
// // 						</div>
// // 						)
// // 					}}
// //                 />
// //             </div>
// // 			<Typography style={{ textAlign: 'center', marginTop: '-10px', }} variant="h5">
// // 				{progress.toFixed(2)}%
// // 			</Typography>
// // 		</CardContent>
// // 	);
// // };

// return (
// 	<CardContent style={{ height: '250px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: '20px' }}>
// 			<div style={{ height: '200px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
// 				<ResponsivePie
// 					data={pieData}
// 					margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
// 					innerRadius={0.6}
// 					colors={{ datum: 'data.color' }}
// 					borderWidth={1}
// 					borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
// 					enableRadialLabels={false}
// 					sliceLabel={(d) => d.id === 'Progress' ? `${d.value.toFixed(2)}%` : ''}
// 					slicesLabelsSkipAngle={10}
// 					slicesLabelsTextColor="#ffffff"
// 					animate={true}
// 					motionStiffness={90}
// 					motionDamping={15}
// 					tooltip={({ datum }) => (
// 						<div style={{ padding: '10px', background: '#d3d3d3', border: '1px solid white', borderRadius: '10px' }}>
// 							<strong>{datum.label}</strong>
// 							<br />
// 							{datum.id === 'Progress' && `${datum.value.toFixed(2)}%`}
// 						</div>
// 					)}
// 				/>
// 			</div>
// 			{/* <Typography style={{ textAlign: 'center', marginTop: '-10px' }} variant="h5">
// 				{progress.toFixed(2)}%
// 			</Typography> */}
// 		</CardContent>
// );
// };

const PercentComplete = ({ budgetData }) => {

    const getPercentComplete = () => {
        if (budgetData && budgetData.current_contract_summary && budgetData?.current_contract_summary?.percent_complete !== undefined) {
            return `${Math.round(budgetData.current_contract_summary.percent_complete)}%`;
        }
        return '--';
    };

    return (
        <div>
            <Typography style={{ textAlign: 'center' }} variant="body1" className="main-key-project-info-body">
                {getPercentComplete()}
            </Typography>
        </div>
    );
}

export default PercentComplete;
