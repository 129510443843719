import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import { TextField, Tooltip } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Autocomplete } from '@material-ui/lab';
import DateFnsUtils from '@date-io/date-fns'; 
import moment from 'moment';

// Icons
import CloseIcon from '@material-ui/icons/Close';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { TrackChangesOutlined } from '@material-ui/icons';

import {REQUIREMENT_TYPE, requirement_types} from '../../../../../constants'

const TrackKey = ({ track, toggleOpen, clickTrackButton, editMode, assignmentEdits, setAssignmentEdits, pmResourceNames, profile_data }) => {
    const { id, title, type, qty, tracks, isOpen, hasButton, sideComponent, start_up, close_out, dates_overridden, requirement_start_date, requirement_end_date, requirement_id, assignment_resource } = track
    const dropdown_options = requirement_types.map(e => { return {name: e.name, value: e.db_name}}).filter(e => !['PA', 'Super'].includes(e.name))

    const [localData, setLocalData] = useState({
        id: id,
        assignment_resource: assignment_resource,
        requirement_id: requirement_id, 
        title: title,  
        type: type, // Database language
        qty: qty, // Decimal
        start: dates_overridden ? requirement_start_date : start_up, 
        end: dates_overridden ? requirement_end_date : close_out, 
        dates_overridden: dates_overridden, 
        delete: false
    });
    
    // Updated the local state when project is switched
    useEffect(() => {
        setLocalData({
            id: id,
            assignment_resource: assignment_resource,
            requirement_id: requirement_id, 
            title: title, 
            type: type, 
            qty: qty, 
            start: dates_overridden ? requirement_start_date : start_up, 
            end: dates_overridden ? requirement_end_date : close_out, 
            dates_overridden: dates_overridden, 
            delete: false    
        });
    }, [requirement_id]);
    

    useEffect(() => {
        if (editMode) {
            let temp = assignmentEdits;
            // Find index of element in assignmentEdits if already existing, will be -1 if not existing
            var index = temp.map(function(e) { return e.id; }).indexOf(id);
           
            if (index === -1) {
                temp.push(...[localData])
            } else {
                temp[index] = localData;
            }
    
            setAssignmentEdits(temp);    
        }
    }, [localData]);

    return (
    <li className="rt-track-key">
        {!editMode && (
            <div className="rt-track-key__entry">
            <div style={{display:'float', minWidth:'50%'}}>
                <Tooltip title={dates_overridden ? "* next to Resource's name represents that their assignment dates are overrriden and not synced with the project dates" : ''}>
                <span className="rt-track-key__title" >{localData.title}{dates_overridden ? '*' :''}</span>
                </Tooltip>
            </div>
            <div style={{display:'float', minWidth:'30%'}}>
                <span className="rt-track-key__title">
                    {
                        REQUIREMENT_TYPE(localData.type)
                    }
                </span>
            </div>
            <div style={{display:'float', minWidth:'20%'}}>
                <span className="rt-track-key__title">{localData.qty*100}%</span>
            </div>
            </div>
        )}
        {editMode && (
            <div className="rt-track-key__entry" style={{textDecoration: localData.delete? 'line-through' : ''}}>
                {/* Resource */}
                <div style={{display:'float', minWidth:'25%'}}>
                    {((type !== 'project_assistant')&&(type !== 'superintendent')) && (
                        <Autocomplete
                            style={{maxWidth: '85%', display:'flex'}}
                            id={'Assignment_Selection_'+title}
                            disableClearable
                            options={pmResourceNames}
                            getOptionLabel={(option) => option.full_name}
                            defaultValue={() => {
                                return (pmResourceNames.find(o => o.full_name === localData.title))
                            }}
                            getOptionSelected={(option, value) => {
                                let temp = value ? value.full_name : null;
                                return (option.full_name === temp)
                            }}
                            onChange={(e, value) => {
                                setLocalData({...localData, ...{title: value.full_name, assignment_resource: value.assignment_resource}});
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                />
                            )}
                        />
                    )}
                    {((type === 'project_assistant')||(type === 'superintendent')) && (
                        <span className="rt-track-key__title" >{title}</span>
                    )}
                </div>
                {/* Resource Type */}
                <div style={{display:'float', minWidth:'20%'}}>
                    {((type !== 'project_assistant')&&(type !== 'superintendent')) && (
                        <Autocomplete
                        style={{maxWidth: '85%', display:'flex'}}
                        id={title}
                        disableClearable
                        options={dropdown_options}
                        getOptionLabel={(option) => option.name}
                        defaultValue={() => {
                            return (dropdown_options.find(o => o.value === localData.type))
                        }}
                        getOptionSelected={(option, value) => {
                            let temp = value ? value.value : null;
                            return (option.value === temp)
                        }}
                        onChange={(e, value) => {
                            if (['pm_design','pm_precon','pm_proposal','pm_buyout','pm_permitting'].includes(value.value)) {
                                setLocalData({...localData, ...{type: value.value, dates_overridden: true, qty: profile_data[value.value]}});
                            } else
                                setLocalData({...localData, ...{type: value.value, qty: profile_data[value.value]}});
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                            />
                        )}
                        />
                    )}
                    {(type === 'project_assistant') && (
                        <span className="rt-track-key__title">PA</span>
                    )}
                    {(type === 'superintendent') && (
                        <span className="rt-track-key__title">Super</span>
                    )}


                </div>
                {/* %FTE */}
                {(type !== 'superintendent') ? (
                    <div style={{display:'float', minWidth:'10%'}}>
                        <TextField
                            style={{display: 'block'}}
                            className="rt-track-key__title"
                            inputProps={{
                                style: {
                                maxWidth: '2.5rem',
                                padding: 1
                                }
                            }}
                            // defaultValue={`${localData.qty*100}%`}
                            value={(localData.qty*100).toFixed(0)}
                            onChange={e=>{
                                if (e.target.value.length == 0) {
                                    setLocalData({...localData, ...{qty: 0}})
                                } else {
                                    setLocalData({...localData, ...{qty: parseInt(e.target.value.replace(/[^0-9]/g, ''))/100}})
                                }
                                } 
                            }
                        />
                    </div>
                ) : (
                    <span className="rt-track-key__title">100</span>
                )}
                {/* Start Date */}
                {(type !== 'superintendent') ? (
                <div style={{display:'float', minWidth:'20%'}}>
                {!localData.dates_overridden && (
                    <Tooltip title="Locked to match as Project Dates">
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <LockIcon style={{height: '1.1rem'}} onClick={() => {
                                setLocalData({...localData, ...{dates_overridden: !localData.dates_overridden}});
                            }}/>
                            &nbsp;
                            <span>{moment(start_up).format('MMM-DD-YYYY')}</span>
                        </div>
                    </Tooltip>
                )}
                {localData.dates_overridden && (
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <Tooltip title="Overriding Project Dates">
                            <LockOpenIcon style={{height: '1.1rem'}} onClick={() => {                                    
                                setLocalData({...localData, ...{dates_overridden: !localData.dates_overridden, start: start_up, end: close_out}});
                            }}/>
                        </Tooltip>
                        &nbsp;
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                style={{display: 'block'}}
                                autoOk={true}
                                disableToolbar
                                variant="inline"
                                emptyLabel=""
                                value={localData.start}
                                // minDate={start_up}
                                // maxDate={close_out}
                                onChange={(e) => {
                                    setLocalData({...localData, ...{start: e}});                
                                }}
                                format="MMM-dd-yyyy"
                                inputProps={{
                                    style: {
                                    maxWidth: '6.5rem',
                                    padding: 1
                                    }
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                )}
                </div>
                ) : (
                <div style={{display:'float', minWidth:'20%'}}>
                        {localData.dates_overridden ? (
                            <span className="rt-track-key__title">{moment(localData.start).format('MMM-DD-YYYY')}</span>
                        ) : (
                            <span className="rt-track-key__title">{moment(start_up).format('MMM-DD-YYYY')}</span>
                        )}
                    </div>
                )}

                {/* End Date */}
                {(type !== 'superintendent') ? (
                <div style={{display:'float', minWidth:'20%'}}>
                {!localData.dates_overridden && (
                    <Tooltip title="Locked to match as Project Dates">
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <LockIcon style={{height: '1.1rem'}} onClick={() => {
                                setLocalData({...localData, ...{dates_overridden: !localData.dates_overridden}});
                            }}/>
                            &nbsp;
                            <span>{moment(close_out).format('MMM-DD-YYYY')}</span>
                        </div>
                    </Tooltip>
                    )}
                {localData.dates_overridden && (
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <Tooltip title="Overriding Project Dates">
                            <LockOpenIcon style={{height: '1.1rem'}} onClick={() => {
                                setLocalData({...localData, ...{dates_overridden: !localData.dates_overridden, start: start_up, end: close_out}});
                            }}/>
                        </Tooltip>
                        &nbsp;
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                style={{display: 'block'}}
                                autoOk={true}
                                disableToolbar
                                variant="inline"
                                emptyLabel=""
                                value={localData.end}
                                // minDate={start_up}
                                // maxDate={close_out}
                                onChange={(e) => {
                                    setLocalData({...localData, ...{end: e}});                
                                }}
                                format="MMM-dd-yyyy"
                                inputProps={{
                                    style: {
                                    maxWidth: '6.5rem',
                                    padding: 1
                                    }
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                )}

                </div>
                ) : (
                    <div style={{display:'float', minWidth:'20%'}}>
                            {localData.dates_overridden ? (
                                <span className="rt-track-key__title">{moment(localData.end).format('MMM-DD-YYYY')}</span>
                            ) : (
                                <span className="rt-track-key__title">{moment(close_out).format('MMM-DD-YYYY')}</span>
                            )}
                        </div>
                    )}
    
                {/* Delete Button */}
                <div style={{display:'float', minWidth:'5%'}}>
                    {(!localData.delete && ((type !== 'project_assistant')&&(type !== 'superintendent'))) && (
                    <CloseIcon 
                    onClick={() => setLocalData({...localData, ...{delete: true}})}
                    />
                    )}
                </div>
            </div>
        )}
    </li>
  )
}

TrackKey.propTypes = {
    track: PropTypes.shape({
        title: PropTypes.string.isRequired,
        tracks: PropTypes.arrayOf(PropTypes.shape({})),
        isOpen: PropTypes.bool,
        hasButton: PropTypes.bool,
        sideComponent: PropTypes.element,
    }),
    toggleOpen: PropTypes.func,
    clickTrackButton: PropTypes.func,
}

TrackKey.defaultProps = {
      clickTrackButton: undefined,
}

export default TrackKey
