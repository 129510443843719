import React from "react";

import { Card, CardContent, Typography } from "@material-ui/core";

const ProjectTypeCard = ({ projectData }) => {

    let projectType = projectData?.project_data?.project_type || projectData?.project_type || '--'

    // Reformating certain values for layout purposes (adding space to break on whitespace)
    if (projectType === 'Warehouse/Distribution') {
        projectType = 'Warehouse & Distribution';
      }

return (
    <div>
        {projectData ? (
            <Typography variant="body1" className='main-key-project-info-body' style={{textAlign: 'center', wordBreak: 'break-word', whiteSpace: 'normal'}}>{projectType}</Typography>
        ) : (
            <Typography variant="body1">N/A - Select a Project</Typography>
        )}
    </div>
    );
}

export default ProjectTypeCard;