import React, { Component } from 'react';
import Axios from 'axios';
import _ from 'lodash';

import { API_URL } from '../../../utils/config/config';

import { withStyles } from '@material-ui/core';
import LessonSearch from '../../components/post-security/activity-view/lesson_search';

const Styles = theme => ({
    main: {
        'display': 'flex',
        'maxWidth': '100vw',
    },
});

class LessonSearchContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            search_data: [],
            search_data_og: [],
            search_query: ''
        };
        this.fetchSearchViewData = this.fetchSearchViewData.bind(this);
    }

    // Fetches the LLView Data
    fetchSearchViewData = async (search_query=this.state.search_query) => {
        console.log('HERE at fetchSearchViewData');
        try {
            if (search_query==='') {
                this.setState({
                    ...this.state,
                    loading: false,
                    search_data: [],
                    search_data_og: [],
                    search_query: search_query
                })    
            }
            else {
                const { getAccessTokenSilently } = this.props.auth;
                const token = await getAccessTokenSilently();
                let coded_search_query = encodeURIComponent(search_query);
    
                const responseFetchSearchView = await Axios.get(`${API_URL}lesson_search/?search_query=${coded_search_query}&safety=${this.props.ll_settings.safety.toString()}&reviewed=${this.props.ll_settings.reviewed.toString()}&divisions=${this.props.ll_settings.divisions.join('<<>>')}&user_id=${localStorage.getItem('id')}`, {
                    withCredentials: true,
                    headers: {
                        'content-type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                });
                this.setState({
                    ...this.state,
                    loading: false,
                    search_data: [...responseFetchSearchView.data],
                    search_data_og: [...responseFetchSearchView.data],
                    search_query: search_query
                })    
            }
        }
        catch (error) {
            console.log('error at fetchSearchViewData: ', error);
            this.setState({ ...this.state, error: error, loading: false });
        }
    }

    componentDidMount() {
        // this.fetchSearchViewData('')
    }

    render() {
        const { classes } = this.props;        
        return (
            <>
                <div className={classes.main}>
                    {/* ***** Main Banner Area ***** */}
                    {/* <Paper className={classes.pm_view}> */}
                    <LessonSearch 
                        loading={this.state.loading}
                        show_lesson_search={this.props.show_lesson_search}
                        toggleLessonSearch={this.props.toggleLessonSearch}
                        search_data={this.state.search_data}

                        ll_settings={this.props.ll_settings}

                        updateLLViewSettings={this.props.updateLLViewSettings}

                        updateDetailView={this.props.updateDetailView}
                        fetchSearchViewData={this.fetchSearchViewData}
                            
                        />  
                </div>
            </>
        );
    }
}

export default withStyles(Styles)(LessonSearchContainer);