import React, { useRef, useState, useEffect } from 'react';
import _ from 'lodash';

import styled from 'styled-components';

import Table from './table';
import {makeStyles, Paper,  Button, useTheme,useMediaQuery } from '@material-ui/core';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useSwipeable } from "react-swipeable";


// Styles for overall page
const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',
        overflowX: 'hidden',
        height: '100%',
    },
    topbar: {
        display: 'flex',
        padding: '1rem',
        paddingBottom: '0rem',
    },
    new_lesson: {
        padding: '0.5rem',
    },
    new_lesson_button: {
        backgroundColor: '#0B7CB0',
        color: 'white',
        minWidth: '20vw',
        height: '2.5rem',
    },
    searchbar: {
        display: 'inline-flex',
        alignItems: 'flex-end'
    },
    multicheckboxFilter: {
      width: '200px',
      paddingBottom: '1rem',
      display: 'inline-block',
    },
    filterSummary: {
      maxWidth: '60vw'  
      },
    stage_options: {
      '&.MuiInputBase': {
        margin: '0px',
      },
      '&.MuiSelect-icon': {
        display: 'none',
      }
    }
}));

// Styles for the React Table
const Styles = styled.div`
    // padding: 1rem;
    // padding-bottom: 0rem;

    .table {
        display: flow-root;
        border-spacing: 0;
        // border: 1px solid gray;
        border-left: 0;
        font-family: Roboto;
        overflow-x: auto;
        overflow-y: auto;

        .tr {
            :last-child {
              .td {
                border-bottom: 1px solid black;
              }
            }
        }

        // Styling for the Header Row
        .th {
            text-align: left;
            align-items: middle;
            background: #093d61;
            color: white;
            height: 2.5rem;
            padding: 0.5rem;
            border-bottom: 1px solid black;
            border-right: 1px solid black;
        },
        .td {
            // For the column resizer
            position: relative;

            // For the column resizer
            .resizer {
                display: inline-block;
                // background: blue;
                width: 7px;
                height: 100%;
                position: absolute;
                right: 0;
                top: 0;
                transform: translateX(50%);
                z-index: 1;
                ${'' /* prevents from scrolling while dragging on touch devices */}
                touch-action:none;
            }

        }

        // Styling for the all cells
        .td {
            margin: 0;
            padding: 0.4rem;
            padding-left: 0rem;
            padding-right: 0rem;
            border-left: 1px solid black;
            border-bottom: 1px solid #d1d5da;
            white-space: nowrap;
            overflow: hidden;

            :last-child {
                // border-right: 0;
            }
        }
    }
`;

//Function that pulls the data and sends it to the Table Renderer
const Activities = props => {
    const theme = useTheme();
    let { activity_view_data, loading, showDetailedView, updateDetailView, toggleDetailView, wbs_level, updateLLViewSettings, collapsed_activities, selectedActivities, setSelectedActivities, selected_activity, updateMobileDisplayTab} = props;
    const classes = useStyles();
    const tableInstance = useRef(null);
    const rem_value = Math.round(window.innerWidth)/100;

    const activities_column_width = useMediaQuery(theme.breakpoints.up('sm')) ? Math.round(window.innerWidth * 0.205) : Math.round(window.innerWidth * 1)
    var hiddenColumns = ['id','has_children', 'ancestry'];

    const handlers = useSwipeable({
        onSwiped: (eventData) => {
            if (eventData.dir==='Left') {
                updateMobileDisplayTab(1)                
            }
        }
    });


    let mode = 1;
    // Acitvities View: Mode 1 | New Lesson: Mode 2
    if (document.title == 'Lessons Learned') {
        mode = 1;
        hiddenColumns.push('selector')
    } else if (document.title == 'Log a Lesson') {
        mode = 2;
    }

    //DetailedView
    // if (showDetailedView) {
    //   hiddenColumns = ['id', 'selector', 'link_activity', 'ancestry', 'has_children'];  
    // }
    hiddenColumns = ['id', 'selector', 'link_activity', 'ancestry', 'has_children'];  

    // Data being fed to the table
    const [activityViewData, setActivityViewData] = useState(activity_view_data) 


    useEffect(() => {
        setActivityViewData(activity_view_data)
    },[activity_view_data])

    const columns = React.useMemo(() => [
        {
            Header: 'id',
            accessor: 'id',
            width: 5*rem_value,
        },
        {
            Header: 'Selector',
            accessor: 'selector',
            width: 6*rem_value,
            Cell: (props) => {
            const [value, setValue] = useState(selectedActivities.includes(parseInt(props.row.values.id)) ? 'selected' : 'select');
            
            return(
                <div 
                    onClick={() => {
                        let temp = [...activityViewData]

                        if (value !== 'selected') {
                            setSelectedActivities([...selectedActivities, parseInt(props.row.values.id)])
                            temp[props.row.index].selector = 'selected'
                        } else {
                            let temp2 = [...selectedActivities]
                            setSelectedActivities(temp2.filter(e => e !==parseInt(props.row.values.id)))                            
                            temp[props.row.index].selector = 'select'
                        }
                        setActivityViewData(temp);
                    }}
                >
                    <Button
                        variant={value === 'selected' ? 'contained' : 'outlined'}
                        style={{backgroundColor: value==='selected' ? '#22ba43' : '#FFFFFF', outline: '#2f2f2f solid 1px'}}
                    >
                        {value}
                    </Button>
                </div>
            )
            },
        },
      {
            Header: 'Ancestry',
            accessor: 'ancestry',
            width: 10*rem_value,
            Cell: (props) => {
              return(props.value)
            },
        },
        {
            Header: 'Has Children',
            accessor: 'has_children',
            width: 10*rem_value,
            Cell: (props) => {
              return(props.value)
            },
        },
        {
            Header: 'Activity',
            accessor: 'title',
            // width: Math.round(window.innerWidth * 0.22),
            width: activities_column_width,
            Cell: (props) => {
                let space = [];
                if (props.row.values.ancestry) {
                    space = props.row.values.ancestry.split(',');
                }
                return(
                    <div 
                        style={{display: 'flex', cursor: 'pointer'}}
                    >
                        {props.row.values.has_children ? 
                            collapsed_activities.includes(props.row.values.id) ? 
                                (<ArrowRightIcon onClick={() => updateLLViewSettings('activity_collapse', props.row.values.id)} />) 
                                : 
                                (<ArrowDropDownIcon onClick={() => updateLLViewSettings('activity_collapse', props.row.values.id)}/>) 
                            : 
                            <>&emsp;&ensp;</>
                        }
                        <div 
                            onClick={() => updateLLViewSettings('activity_select', String(props.row.values.id)+'<<>>'+props.value)}
                            style={{display: 'flex', alignItems: 'center', minWidth: '100%'}}
                        >
                        {space.map(e => 
                            <>&ensp;</>
                        )}
                        {props.value}
                        <span style={{color: 'gray', fontSize: 'smaller'}}><i>&nbsp;{props.row.original.link_activity.length}</i></span>
                        {(props.row.values.id===selected_activity) && (
                            <>&emsp;<DoneOutlineIcon style={{padding: '0px'}} fontSize='small'/></>
                        )}
                        </div>
                    </div>
                )
            },
        }
    ]);

    return (
        <>
            {loading ? (
                <>
                </>
            ) : (
                <Paper 
                    // className={classes.paper} id='activity_view'
                    // style={{ minWidth: showDetailedView ? Math.round(window.innerWidth * 0.23) : Math.round(window.innerWidth * 0.8) }}
                    {...handlers}
                >
                    {/* <div className={classes.new_lesson}>
                        <Button 
                            variant="contained" 
                            className={classes.new_lesson_button} 
                            component={Link}
                            to={'/ll/new-lesson'}
                            // target='_blank'                        
                        >
                            New Lesson
                        </Button>
                    </div> */}

                    <Styles>
                        <Table 
                            columns={columns} 
                            data={activityViewData} 
                            ref={tableInstance} 
                            hiddenColumns={hiddenColumns}
                            updateLLViewSettings={updateLLViewSettings}
                            getCellProps={cellInfo => {
                                let columncheck = cellInfo.column.Header === "Activity" ? true : false;
                                let cellAncestryCount = cellInfo.row.values.ancestry != null ? cellInfo.row.values.ancestry.split(',').length : 0;
                                // let spectrum = ['#093D61', '#0B7CB0', '#29ABE2', '#00AEEF']
                                // let spectrum = ['#B1DAE7', '#c5e4ed', '#d8edf3', '#ECF6F9', '#FFFFFF']
                                let spectrum = ['#A9D0DD','#c5e4ed', '#d8edf3', '#ECF6F9', '#FFFFFF']
                                // let spectrum = ['#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF']
                                let bgcolor = ''
                                let fontcolor = ''
                                if (columncheck) {
                                    bgcolor = spectrum[cellAncestryCount];
                                    // fontcolor = '#FFFFFF'
                                    fontcolor = '#000000'
                                }
                                return(
                                    {
                                        style: {
                                          backgroundColor: bgcolor,
                                          color: fontcolor
                                        },
                                      }
                                )
                            }}
                      
                        />
                    </Styles>
                </Paper>
            )}
        </>
    );
};

export default Activities;