import React, { useRef, useEffect, useState } from 'react';
import { CardContent, Typography } from '@material-ui/core';
import { ResponsiveBar } from '@nivo/bar';

const formatDollar = (value) => {
  return new Intl.NumberFormat('en-US', { 
      style: 'currency', 
      currency: 'USD' 
  }).format(value);
}

const formatDollarShort = (value) => {
  if (value >= 1000000) {
    return `$${(value / 1000000).toFixed(0)}M`;
} else if (value >= 1000) {
    return `$${(value / 1000).toFixed(0)}k`;
} else {
    return new Intl.NumberFormat('en-US', { 
        style: 'currency', 
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0 
    }).format(value);
  }
}

const processPcoSummary = (pco_summary) => {
  return Object.entries(pco_summary).map(([reason, total]) => ({
    reason,
    total,
  }));
};

// const CustomLabel = ({ x, y, width, height, color, data, fontSize, fontFamily, fontColor }) => {
//   // Using to resize background behind text svg
//   const [textDimensions, setTextDimensions] = useState({ width: 45, height: 17 });
//     const textRef = useRef(null);

//     useEffect(() => {
//         if (textRef.current) {
//             const bbox = textRef.current.getBBox();
//             setTextDimensions({ width: bbox.width, height: bbox.height });
//         }
//     }, [textRef]);

//   if (width <= 65) {
//       const labelX = x + width + 5;
//       const labelY = y + height / 2;
//       const lineX = x + width;
//       const lineY = y + height / 2;

//       const text = formatDollarShort(data.value);
//       const padding = 5;


//       return (
//         <>
//             <line
//                 x1={lineX}
//                 y1={lineY}
//                 x2={labelX}
//                 y2={labelY}
//                 stroke={color}
//                 strokeWidth={3}
//             />
//              <rect
//                   x={labelX}
//                   y={labelY - textDimensions.height / 2 - padding / 2}
//                   width={textDimensions.width + padding * 2}
//                   height={textDimensions.height + padding}
//                   fill="white"
//                   rx={3}
//                   ry={3}
//                   stroke={color}
//                   strokeWidth={1}
//               />
//             <text
//                 x={labelX + padding + 5}
//                 y={labelY}
//                 fill={color}
//                 dominantBaseline="central"
//                 style={{ fontSize, fontFamily, fontColor }}
//             >
//                 {text}
//             </text>
//         </>
//     );
// }
// return null;
// };

const PCOTypeSummary = ( { budgetData } ) => {

  let pco_summary_data = {};
  let totals = [];

  if (budgetData && Object.keys(budgetData).length > 0) {
    pco_summary_data = budgetData['pco_summary'] || {};
    totals = processPcoSummary(pco_summary_data);
  }

  // Defining Values for custom bar label
  const fontSize = '13.5px';
  const fontFamily = 'Titillium We, sans-serif';
  const fontColor = '#093d61'


  return (

    <CardContent style={{maxWidth: '800px', margin: 'auto'}}>
      { budgetData && Object.keys(budgetData).length === 0 ? (
       <div style={{ textAlign: 'center' }}>
          <Typography variant="body1">Please select a project</Typography>
        </div>
      ) : (
      <div style={{ height: '300px'}}>
        <ResponsiveBar
          data={totals}
          keys={['total']}
          indexBy="reason"
          margin={{ top: 10, right: 10, bottom: 65, left: 135 }}
          padding={0.05}
          layout="horizontal"
          colors={['#093d61']}
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -35,
            legend: 'Total ($)',
            legendPosition: 'middle',
            legendOffset: 55,
            format: key => formatDollarShort(key)
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
          }}
          labelSkipWidth={60}
          labelSkipHeight={12}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
          borderRadius={5}
          labelTextColor={{
            from: 'color',
            modifiers: [
                [
                    'brighter',
                    '10.0'
                ]
            ]
        }}
        height={300}
        theme={{
          labels: {
            text: {
              fontSize: 13.5,
              fill: '#000000',
            },
          },
          axis: {
            ticks: {
              text: {
                fontSize: 12, 
                fill: '#555555', 
              },
            },
            legend: {
              text: {
                fontSize: 17,
                fill: '#333333',
              },
            },
          },
        }}
        // layers={[
        //   'grid',
        //   'axes',
        //   'bars',
        //   'markers',
        //   'legends',
        //   ({ bars }) => (
        //     <g>
        //       {bars.map(bar => (
        //         <CustomLabel
        //           key={bar.key}
        //           x={bar.x}
        //           y={bar.y}
        //           width={bar.width}
        //           height={bar.height}
        //           color={fontColor}
        //           data={bar.data}
        //           fontFamily={fontFamily}
        //           fontSize={fontSize}
        //         />
        //       ))}
        //     </g>
        //   ),
        // ]}
        label={(d) => formatDollarShort(d.value)}
        tooltip={(d) => (
          <div style={{ padding: '5px', background: '#333', color: '#fff', borderRadius: '3px', fontFamily: 'Titillium Web, sans-serif' }}>
            <strong>{d.indexValue}</strong>
            <br />
            Total: {formatDollar(d.value)}
          </div>
        )}
        />
      </div>
       ) }
    </CardContent>
  );
};

export default PCOTypeSummary;
