import React, { useState, useEffect } from 'react';

import LessonsSearchFilters from './lessons_search_filters';
import _ from 'lodash';

import { LESSON_DIVISION_OPTIONS } from '../constants/constants';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import {makeStyles, Paper, List, ListItem, ListItemText, Typography, TextField, ClickAwayListener, Dialog, useTheme, useMediaQuery, FormControl } from '@material-ui/core';

// Styles for overall page
const useStyles = makeStyles(theme => ({
    paper: {
        minWidth: 'inherit',
        height: '80vh',
        display: 'flex',
        flexDirection: 'column',
        padding: '10px',
        // paddingLeft: '0px',
        backgroundColor: '#f8f8f8',
    }, 
    mainSection: {
        display: 'inline-flex',
        overflowY: 'scroll',
    },
    list: {
        minWidth: 'calc(100%)', 
        [theme.breakpoints.up('sm')]: {
            minWidth: 'calc(100% - 15rem)', 
            paddingRight: '10px',
        },
    },
    filters: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            marginLeft: 'auto'
        },
    },
    listItem: {
        cursor: 'pointer',
        margin: '5px 0',
        '&:hover': {
            backgroundColor: '#ebf0f8'
        }

    }
}))

const Lesson_Search = props => {
    let { loading, show_lesson_search, toggleLessonSearch, search_data, fetchSearchViewData, updateDetailView, ll_settings, updateLLViewSettings } = props;
    const classes = useStyles();
    const theme = useTheme();
    let mode = useMediaQuery(theme.breakpoints.up('sm')) ? 'desktop':'mobile'
    
    const [searchQuery, setSearchQuery] = useState('')


    return (
        <>
                <Dialog open={show_lesson_search} fullWidth maxWidth="lg">
                    <ClickAwayListener onClickAway={() => toggleLessonSearch(false)}>
                        <Paper className={classes.paper}>
                            <div style={{display: 'inline-flex', alignItems: 'center'}}>
                            {mode === 'mobile' && (
                                <ChevronLeftIcon fontSize='large' 
                                onClick ={() => {
                                    toggleLessonSearch(false)
                                }}
                                />
                            )}
                            <FormControl style={{width: '100%'}}>
                            <TextField 
                                value={searchQuery}
                                id="standard-basic" label="Search..." variant="outlined" onChange={(e) => {
                                    fetchSearchViewData(e.target.value)
                                    setSearchQuery(e.target.value)
                                }} style={{backgroundColor: 'white'}}
                            />
                            </FormControl>
                            </div>
                            <div className={classes.mainSection}>
                                <List className={classes.list}>
                                    {search_data.map(lesson => (
                                        <>
                                            <Paper elevation={1} >
                                            <ListItem 
                                                className={classes.listItem}
                                                onClick ={() => {
                                                    toggleLessonSearch(false)
                                                    updateDetailView(lesson.id)
                                                }}
                                            >
                                                <ListItemText 
                                                    primary={lesson.title} 
                                                    secondary={
                                                        <React.Fragment>
                                                            <div>
                                                            <Typography
                                                                sx={{ display: 'inline' }}
                                                                component="span"
                                                                variant="body2"
                                                                color="text.primary"
                                                            >
                                                                {LESSON_DIVISION_OPTIONS.filter(e => e.value===lesson.division)[0].title}
                                                            </Typography>  
                                                            &nbsp;&nbsp;
                                                            {lesson.reviewed && (
                                                                <Typography
                                                                    sx={{ display: 'inline' }}
                                                                    component="span"
                                                                    variant="body2"
                                                                    style={{color: 'green'}}
                                                                    title='This lesson has been reviewed by the ARCO Lessons Learned Committee'
                                                                >
                                                                    Reviewed
                                                                </Typography>
                                                            )}
                                                            {!lesson.reviewed && (
                                                                <Typography
                                                                    sx={{ display: 'inline' }}
                                                                    component="span"
                                                                    variant="body2"
                                                                    style={{color: '#FF5733'}}
                                                                    title='This lesson is pending a review by the ARCO Lessons Learned Committee'
                                                                >
                                                                    Unreviewed
                                                                </Typography>
                                                            )}
                                                            </div>          
                                                        </React.Fragment> 
                                                    }
                                                />
                                            </ListItem>
                                            </Paper>
                                            {/* <Divider variant="middle"/> */}
                                        </>
                                    ))}
                                </List>
                                {(mode==='desktop') && (
                                    <LessonsSearchFilters
                                        className={classes.filters}
                                        lesson_search_filters={ll_settings}
                                        updateLLViewSettings={updateLLViewSettings}
                                        fetchSearchViewData={fetchSearchViewData}
                                    />
                                )}             
                            </div>
                        </Paper>
                    </ClickAwayListener>
                </Dialog>
        </>
    )
}


export default Lesson_Search;