import React, { Component } from 'react';
// import DeliveriesComp from '../../components/pre-security/Deliveries';

class Deliveries extends Component {

    render() {

        let url = window.location.href
        let split_url = url.split('/');
        let project_keyword = split_url[split_url.length -1];

        let gcal_width = window.screen.width < 600 ? window.screen.width*0.8 : 800
        let gcal_height = window.screen.width < 600 ? window.screen.height/3 : 400
        let gcal_mode = window.screen.width < 600 ? 'AGENDA' : 'WEEK'

        let allowed_projects = ['banner', 'westseattle']

        let gcal_url = ''
        let pictime_url = ''
        if (project_keyword === 'banner') {
            gcal_url = "https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23ffffff&ctz=America%2FLos_Angeles&title=Banner%20Self%20Storage&showPrint=0&showCalendars=0&src=YXJjby54MTMwQGdtYWlsLmNvbQ&mode=" + gcal_mode + "&src=MmQ0MmM2NTJkNzU4MmUxOTlmMzk3Y2U0NGNmNGI0MjkyOTc1NGIzMDI1ZGY1Y2E1MzY2OTk1OTM0MzEyN2M4ZkBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&src=ZWIzMDgzZDViODFkYTBjYWYwMjM1NWEyNjJlOGU5NzM3ZGE2Y2Y4YTZjNTdjZWNmY2I1NDZmNTYyM2IxOWQ3MUBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&src=NTM5ZjgxMTlmMmQ4MGQ4ZWQzY2FmMGM3ZTM5ZmVjOGYwNTUxY2U5YTc5ZTQ0OWExNTM0NDkyYjk5MTk5OTU1M0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%23039BE5&color=%23E67C73"
            pictime_url = 'https://www.picktime.com/banner'
        // if (project_keyword === 'englewood') {
        //     gcal_url = "https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23ffffff&ctz=America%2FDenver&title=Englewood%20Apartments&showPrint=0&showCalendars=0&src=YXJjby54MTMwQGdtYWlsLmNvbQ&mode=" + gcal_mode + "&src=YXJjby54MTMwLjJAZ21haWwuY29t&color=%23039BE5&color=%23E67C73"
        //     pictime_url = 'https://www.picktime.com/englewood'
        } else if (project_keyword === 'westseattle') {
            gcal_url = "https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23ffffff&ctz=America%2FLos_Angeles&showPrint=0&showCalendars=0&title=West%20Seattle%20Deliveries&mode=" + gcal_mode + "&src=YXJjby5jNDA2QGdtYWlsLmNvbQ&src=OTQwODVlMGI2OWYxNzMzYjVlM2MxODJjMzBkYzBmZTBiYzJjM2FkN2JlMjBkYTVmNGVkMmE2M2RjMjBmMGY4MEBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%23039BE5&color=%234285F4"
            pictime_url = 'https://www.picktime.com/westseattle'
        // } else if (project_keyword === 'aspgren') {
        //     gcal_url = "https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23ffffff&ctz=America%2FDenver&showPrint=0&showCalendars=0&title=Aspgren%20Deliveries&mode=" + gcal_mode + "&src=Nzg2ZjgxNWZjZTgwZTAxYWJiZGZiMmE5YzNhMTUyYWM0YThlMjI3NjkzY2JkZWRjNmFmYTBjYTJhMTRmZTZiZEBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%23039BE5&color=%234285F4"
        //     pictime_url = 'https://www.picktime.com/aspgren'
        // } else if (project_keyword === 'tgburlingame') {
        //     gcal_url = "https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23ffffff&ctz=America%2FLos_Angeles&showPrint=0&showCalendars=0&title=Topgolf%20Burlingame%20Deliveries&mode=" + gcal_mode + "&src=ZTg0NmE4ZjcyZTEwMGNkNjNjYzZlODA2MGQzZjhkOGMxZDlmYjFiZjA3NmJmNWVhYjMwMDRhNWJjMjVkZDQwM0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%23039BE5&color=%234285F4"
        //     pictime_url = 'https://www.picktime.com/tgburlingame'
        // } else if (project_keyword === 'atf') {
        //     gcal_url = "https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23ffffff&ctz=America%2FDenver&showPrint=0&showCalendars=0&title=ATF%20Deliveries&mode=" + gcal_mode + "&src=MGE5MmEwNjY3ZmJhYWJhYmNkMGQ3NTE3YjdiNzExZTJlYmU1OTk4NGM4MDZiNjYzMWZkMTEwNzBjMmJjN2UyYUBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%23039BE5&color=%234285F4"
        //     pictime_url = 'https://www.picktime.com/atf'
        }  

<iframe src="https://calendar.google.com/calendar/embed?
src=0a92a0667fbaababcd0d7517b7b711e2ebe59984c806b6631fd11070c2bc7e2a
%40
group.calendar.google.com&ctz=America%2FChicago" style="border: 0" width="800" height="600" frameborder="0" scrolling="no"></iframe>

        return (
            <>
                { allowed_projects.includes(project_keyword) && (
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: '100%', overflowY: 'auto'}}>
                        <iframe
                            src={gcal_url} 
                            style={{"border":"solid 1px #777", minWidth: gcal_width, height: gcal_height}} 
                            frameborder="0" 
                            scrolling="no"
                        />
                        <br />
                        <iframe
                            src={pictime_url}
                            width={gcal_width}
                            height="600" 
                        />
                    </div>
                )}
            </>
        );
    }
}

export default Deliveries;
