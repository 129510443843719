import React, { useRef, useState, useEffect } from 'react';

import styled from 'styled-components';

import Table from './table';
import {makeStyles, Paper, Button, Typography } from '@material-ui/core';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// Styles for overall page
const useStyles = makeStyles(theme => ({
    paper: {
        width: '65vw',
        [theme.breakpoints.up('sm')]: {
            width: 'auto'
        },
        // maxWidth: '95vw',
        // minHeight: '70vh',
        maxHeight:  Math.round(window.innerWidth * 0.9),
        // paddingBottom: '1rem',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'clip',
        overflowX: 'hidden',
    },
    topbar: {
        display: 'flex',
        padding: '1rem',
        paddingBottom: '0rem',
    },
    searchbar: {
        display: 'inline-flex',
        alignItems: 'flex-end'
    },
    multicheckboxFilter: {
      width: '200px',
      paddingBottom: '1rem',
      display: 'inline-block',
    },
    formControl: {
      minWidth: '17.5vw',
      maxWidth: 400
    },
    filterSummary: {
      maxWidth: '60vw'  
      },
    stage_options: {
      '&.MuiInputBase': {
        margin: '0px',
      },
      '&.MuiSelect-icon': {
        display: 'none',
      }
    }
}));

// Styles for the React Table
const Styles = styled.div`
    padding: 1rem;
    padding-bottom: 0rem;

    .table {
        display: flow-root;
        border-spacing: 0;
        border: 1px solid black;
        border-left: 0;
        font-family: Roboto;

        // overflow-x: auto;
        overflow-y: none;

        .tr {
            :last-child {
              .td {
                border-bottom: 1px solid black;
              }
            }
        }

        // Styling for the Header Row
        .th {
            text-align: left;
            align-items: middle;
            background: #093d61;
            color: white;
            height: 2.5rem;
            padding: 0.5rem;
            border-bottom: 1px solid black;
            border-right: 1px solid black;
        },
        .td {
            // For the column resizer
            position: relative;

            // For the column resizer
            .resizer {
                display: inline-block;
                // background: blue;
                width: 7px;
                height: 100%;
                position: absolute;
                right: 0;
                top: 0;
                transform: translateX(50%);
                z-index: 1;
                ${'' /* prevents from scrolling while dragging on touch devices */}
                touch-action:none;
            }

        }

        // Styling for the all cells
        .td {
            margin: 0;
            padding: 0.4rem;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            border-left: 1px solid black;
            border-bottom: 1px solid #d1d5da;
            white-space: nowrap;
            overflow: hidden;


            :last-child {
                // border-right: 0;
            }
        }
    }
`;

//Function that pulls the data and sends it to the Table Renderer
const Activities = props => {
    let { loading, activity_view_data, updateLLViewSettings, collapsed_activities, selectedActivities, setSelectedActivities} = props;
    const classes = useStyles();
    const tableInstance = useRef(null);
    const rem_value = Math.round(window.innerWidth)/100;

    var hiddenColumns = ['id','has_children', 'ancestry'];
 
    // Data being fed to the table
    const [activityViewData, setActivityViewData] = useState(activity_view_data) 


    useEffect(() => {
        setActivityViewData(activity_view_data)
    },[activity_view_data])

    const columns = React.useMemo(() => [
        {
            Header: 'id',
            accessor: 'id',
            width: 5*rem_value,
        },
        {
            Header: 'Selector',
            accessor: 'selector',
            width: parseFloat(getComputedStyle(document.documentElement).fontSize)*4+20,
            filterable: false,
            Cell: (props) => {
            const [value, setValue] = useState(selectedActivities.includes(parseInt(props.row.values.id)) ? 'selected' : 'select');
            
            return(
                <div 
                    onClick={() => {
                        let temp = [...activityViewData]

                        if (value !== 'selected') {
                            setSelectedActivities([...selectedActivities, parseInt(props.row.values.id)])
                            temp[props.row.index].selector = 'selected'
                        } else {
                            let temp2 = [...selectedActivities]
                            setSelectedActivities(temp2.filter(e => e !==parseInt(props.row.values.id)))                            
                            temp[props.row.index].selector = 'select'
                        }
                        setActivityViewData(temp);
                    }}
                >
                    <Button
                        variant={value === 'selected' ? 'contained' : 'outlined'}
                        style={{backgroundColor: value==='selected' ? '#22ba43' : '#FFFFFF', outline: '#2f2f2f solid 1px',  maxHeight: '1rem', width: '4rem'} }
                    >
                        {value}
                    </Button>
                </div>
            )
            },
        },
      {
            Header: 'Ancestry',
            accessor: 'ancestry',
            width: 10*rem_value,
            Cell: (props) => {
              return(props.value)
            },
        },
        {
            Header: 'Has Children',
            accessor: 'has_children',
            width: 10*rem_value,
            Cell: (props) => {
              return(props.value)
            },
        },
        {
            Header: 'Activity',
            accessor: 'title',
            width: Math.round(window.innerWidth * 0.65),
            Cell: (props) => {
                let space = [];
                if (props.row.values.ancestry) {
                    space = props.row.values.ancestry.split(',')
                }
                return(
                    <div 
                        style={{display: 'flex', cursor: 'pointer'}}
                    >
                        {props.row.values.has_children ? 
                            collapsed_activities.includes(props.row.values.id) ? 
                                (<ArrowRightIcon onClick={() => updateLLViewSettings('activity_collapse', props.row.values.id)} />) 
                                : 
                                (<ArrowDropDownIcon onClick={() => updateLLViewSettings('activity_collapse', props.row.values.id)}/>) 
                            : 
                            <>&emsp;&ensp;</>
                        }
                        <div 
                            onClick={() => updateLLViewSettings('activity_collapse', props.row.values.id)}
                            style={{display: 'flex', alignItems: 'center', minWidth: '100%'}}
                        >
                        {space.map(e => 
                            <>&ensp;</>
                        )}
                        {props.value}
                        <span style={{color: 'gray', fontSize: 'smaller'}}><i>&nbsp;{props.row.original.link_activity.length}</i></span>
                        </div>
                    </div>
                )
            },
        },
    ]);

    return (
        <>
            {loading ? (
                <>
                <div style={{ position: 'absolute', top: '55%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                    <Typography variant='subtitle1'>Loading...</Typography>
                </div>
                </>
            ) : (
                <Paper elevation={1} className={classes.paper} id='activity_view'
                >
                    <div className={classes.topbar}>
                            <Typography variant='subtitle1'>Selected:&nbsp;{selectedActivities.length}</Typography>
                    </div>

                    <Styles>
                        <Table 
                            columns={columns} 
                            data={activityViewData} 
                            ref={tableInstance} 
                            hiddenColumns={hiddenColumns}
                            updateLLViewSettings={updateLLViewSettings}
                            getCellProps={cellInfo => {

                                let columncheck = cellInfo.column.Header === "Activity" ? true : false;
                                let cellAncestryCount = cellInfo.row.values.ancestry != null ? cellInfo.row.values.ancestry.split(',').length : 0;
                                let spectrum = ['#A9D0DD','#c5e4ed', '#d8edf3', '#ECF6F9', '#FFFFFF']
                                let bgcolor = ''
                                let fontcolor = ''
                                if (columncheck) {
                                    bgcolor = spectrum[cellAncestryCount];
                                    fontcolor = 'black'
                                }
                                return(
                                    {
                                        style: {
                                          backgroundColor: bgcolor,
                                          color: fontcolor
                                        },
                                      }
                                )
                            }}
                      
                        />
                    </Styles>
                </Paper>
            )}
        </>
    );
};

export default Activities;