import React from 'react';
import { Grid, CardContent, Typography } from '@material-ui/core';

import StartDateCard from './StartDateComponent';
import TCODateCard from './TCODateComponent';
import ProjectedEndDateCard from './ProjectedEndDate';
import ProjectTypeCard from './ProjectTypeComponent';
import SquareFootageCard from './SquareFootageComponent';
import PriceSFCard from './PricePerSFComponent';
import ProjectStageCardSmall from './ProjectStatusSmallComponent';
import ProjectDurationCard from './ProjectDurationComponent';

const PortfolioMasterProjectInfoCard = ({ projectData }) => {
    const project = projectData?.project_data || projectData;

  return (
    <CardContent>
      <Grid container spacing={2} style={{ width: '100%', justifyContent: 'center' }}>
        <Grid item xs={4}>
          <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center' }}>
            Project Type
          </Typography>
          <ProjectTypeCard projectData={project} />
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center' }}>
            Project Status
          </Typography>
          <ProjectStageCardSmall projectData={project} />
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center' }}>
            Square Footage
          </Typography>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
            <SquareFootageCard projectData={project} />

          </div>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center' }}>
            Start Date
          </Typography>
          <StartDateCard projectData={project} />
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center' }}>
            Projected Finish
          </Typography>
          <ProjectedEndDateCard projectData={project} />
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center' }}>
            Duration
          </Typography>
          <ProjectDurationCard projectData={project} />
        </Grid>
      </Grid>
    </CardContent>
  );
};

export default PortfolioMasterProjectInfoCard;
