import React, { Component } from 'react';
import Axios from 'axios';
import _ from 'lodash';

import { API_URL } from '../../../../utils/config/config';
import { patchService } from '../../../../utils/services/httpService';
import Header from '../../../components/post-security/common/header';
import BouncingPreloader from '../../../components/post-security/common/bouncyLoader';
import { LESSON_DIVISION_OPTIONS, LESSON_TAGS } from '../../../components/post-security/constants/constants';

import Activities from '../../../components/post-security/activity-view/activities';
import SearchAndFilter from '../../../components/post-security/activity-view/search_and_filter_buttons';
import LessonsListContent from './LessonsListContent';
import LessonDetailedViewContainer from './LessonDetailedViewContainer';
import LessonSearchContainer from '../LessonSearchContainer';
import MobileBottomNavigation from '../../../components/post-security/activity-view/mobile_bottom_navigation';
import IosShareIcon from '@mui/icons-material/IosShare';

import { DEFAULT_SHOW_COLUMNS } from '../../../components/post-security/constants/constants';

import {Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions} from '@material-ui/core';
import { Replay } from '@material-ui/icons';
import { withStyles } from '@material-ui/core';
import { local } from 'd3';

const Styles = theme => ({
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    mobilesave: {
        "& .MuiDialog-container": {
            // paddingTop: '58px',
            "& .MuiPaper-root": {
            //   minHeight: "calc(100vh - 48px - 40px)",
              margin: '0px',
              width: 'calc(100vw - 40px)',
            },
        },
    },
    root_grid: {
        display: 'grid',
        height: '100vh',
        'grid-template-rows': '48px calc(100vh - 48px - 60px) 60px', // Header: 48px comes from toolbar = dense.
        [theme.breakpoints.up('sm')]: {
            'grid-template-rows': '48px auto', // Header: 48px comes from toolbar = dense.
        },
        overflowY: 'hidden',
    },
    header: {
        zIndex: 999999,
    },
    main: {
        'display': 'grid',
        'grid-template-columns': '0.75fr 1.25fr 2fr', // Header: 48px comes from toolbar = dense.
        'maxWidth': '100vw',
        // 'padding-left': '2rem',
        // 'padding-top': document.title === 'Lessons Learned' ? '20px' : '5px',
        // 'padding-right': '2rem',
        'overflow': 'hidden',
        'borderBottom': '1px solid gray'
        },
    gridItem1: {
        'borderRight': '1px solid gray',
        'maxWidth': '100vw',
        [theme.breakpoints.up('sm')]: {
            'maxWidth': '22vw', // Header: 48px comes from toolbar = dense.
        },
    },
    gridItem2: {
        // 'borderLeft': '1px solid gray',
        'borderRight': '1px solid gray',
        'minWidth': '28vw',
        'maxWidth': '28vw',
        'overflow': 'hidden',
        // maxHeight: document.getElementById('gridItem1') ? 'document.getElementById("gridItem1").clientHeight' : 'calc(100vh - 48px)',
    },
    gridItem3: {
        'maxWidth': '49.5vw'
    },
});

class LLViewContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: false,
            offline: false,
            activitiy_view_data_og: [],
            activitiy_view_data: [],
            showSaveMobile: window.navigator.standalone ? false : true,
            showColumns: DEFAULT_SHOW_COLUMNS,
            wbs_level: 2,
            collapsed_activities: [],
            search_activities: null,
            lessons_list_data: [],
            selected_activity: null,
            selected_activity_name: '',
            detail_view_lesson_id: null,
            show_lesson_search: false,
            cea_mode: localStorage.getItem('cea_mode') === 'true' ? true : false,
            ll_settings: {
                safety: false,
                reviewed: false,
                unreviewed: false,
                divisions: localStorage.getItem('cea_mode') === 'true' ? ['cea'] : LESSON_DIVISION_OPTIONS.map(e => e.value) ,
                tags: localStorage.getItem('cea_mode') === 'true' ? LESSON_TAGS.map(e => e.value): [],
            },
            mobile_display_tab: 0
        };
        this.fetchLLViewData = this.fetchLLViewData.bind(this);
        this.toggleLessonSearch = this.toggleLessonSearch.bind(this);
        this.updateActivityViewData = this.updateActivityViewData.bind(this);
        this.updateLessonsListData = this.updateLessonsListData.bind(this);
        this.updateDetailView = this.updateDetailView.bind(this);
        this.updateLLViewSettings = this.updateLLViewSettings.bind(this);
        this.updateMobileDisplayTab = this.updateMobileDisplayTab.bind(this);
        this.toggleCEAmode = this.toggleCEAmode.bind(this);
    }
    
    componentDidMount() {
        document.title = "Lessons Learned";
        if (!localStorage.getItem('cea_mode')) {
            localStorage.setItem('cea_mode', false)
        }

        this.fetchLLViewData();

        let { lesson_id } = this.props.match.params;
        this.setState({detail_view_lesson_id: lesson_id});
        document.querySelectorAll('iframe').forEach(iframe => iframe.remove());
    }

    toggleLessonSearch = (value) => this.setState({ show_lesson_search: value})
    updateDetailView = (values) => this.setState({ detail_view_lesson_id: values, mobile_display_tab: 2});
    updateMobileDisplayTab = (value) => this.setState({ mobile_display_tab: value})
    updateSaveMobile = () => this.setState({showSaveMobile: !this.state.showSaveMobile })

    updateLLViewSettings = (type, new_value) => {
        if (['safety', 'reviewed','unreviewed', 'divisions','tags'].includes(type)) {
            this.setState({ ll_settings: {...this.state.ll_settings, [type]: new_value}}, () => {
                this.updateActivityViewData()
                this.updateLessonsListData();                
            });
        } else if (type === 'wbs_change') {
            this.setState({collapsed_activities: [], wbs_level: new_value}, () => {
                this.updateActivityViewData()
                this.updateLessonsListData();                
            });
        } else if (type === 'activity_collapse') {
            let new_collapsed_activities = this.state.collapsed_activities;
            const index = new_collapsed_activities.indexOf(new_value);
            if (index > -1) {
                // Activity already existed. Now removing from collapsed list
                new_collapsed_activities.splice(index, 1);
            } else {
                // Activity not existed in array. Now adding to collapsed list
                new_collapsed_activities.push(new_value);                
            }
            this.setState({collapsed_activities: new_collapsed_activities}, () => {
                this.updateActivityViewData()
            });
        } else if (type === 'activity_select') {
            let [id, name] = new_value.split('<<>>')
            id = parseInt(id)
            this.setState(
                {
                    selected_activity: (id!==this.state.selected_activity) ? id : null, 
                    selected_activity_name: (id!==this.state.selected_activity) ? name : '', 
                    mobile_display_tab: 1
                }, () => {
                this.updateLessonsListData();                
            })
        } else if (type === 'from_activity_search') {
            this.setState({collapsed_activities: [], search_activities: new_value}, () => {
                this.updateActivityViewData()
            })
        } else if (type === 'from_breadcrumbs') {
            let [id, name] = new_value.split('<<>>')
            id = parseInt(id)
            this.setState({collapsed_activities: [], selected_activity: id, selected_activity_name: name, mobile_display_tab: 1}, () => {
                this.updateActivityViewData()
                this.updateLessonsListData();                
            });
        }
    }

    // Fetches the LLView Data
    fetchLLViewData = async () => {
        try {
            const { getAccessTokenSilently } = this.props.auth;
            const token = await getAccessTokenSilently();
            // const responseFetchLLView = await Axios.get(`${API_URL}activities/?safety=${this.state.ll_settings.safety.toString()}&reviewed=${this.state.ll_settings.reviewed.toString()}&divisions=${this.state.ll_settings.divisions.join('<<>>')}`, {

            const responseFetchLLView = await Axios.get(`${API_URL}activities/`, {
                withCredentials: true,
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            this.setState({
                ...this.state,
                loading: false,
                activitiy_view_data: [...responseFetchLLView.data].reverse(),
                activitiy_view_data_og: [...responseFetchLLView.data].reverse()
            }, () => {
                this.updateActivityViewData()
                this.updateLessonsListData()    
            })
        }
        catch (error) {
            this.setState({ ...this.state, error: error, loading: false });
        }
    }

    // ACTIVITY VIEW :
    updateActivityViewData = () => {
        let temp = _.cloneDeep(this.state.activitiy_view_data_og);

        // filtering based on divisions
        temp = temp.filter(e => {
            if (e.general) {
                return true
            } else {
                this.state.ll_settings.divisions.forEach(division => {
                    if (e[division]) {
                        return true
                    }
                })
                return false
            }
        })

        // let new_wbs_level = this.state.wbs_level;
        // filtering based on wbs level
        // temp = temp.filter(e => {
        //     if (typeof(e.ancestry) !== "string") {
        //         return true
        //     }
        //     return (e.ancestry.split(',').length <= new_wbs_level)
        // })

        // filtering based on collapsed activities
        if (!this.state.search_activities) {
            this.state.collapsed_activities.forEach(collapsed => {
                temp = temp.filter(e => {
                    if (typeof(e.ancestry) === "string") {
                        return !e.ancestry.split(',').includes(collapsed.toString())
                    }
                    return true
                })
            })    
        }

        // filtering based on activities search
        if (this.state.search_activities) {
            // Finding the actual activities that pass the filter -> getting thier ids and ancestry (which is comma separated ids of parents+ancestors)
            let temp1 = temp.filter(e => e.title.toLowerCase().includes(this.state.search_activities.toLowerCase())).map(e => ({id: e.id, parents: e.ancestry}))

            // Converting the above found values into a flat array.
            // visible items = activities that pass filters + their ancestors
            let visible = temp1.map(e => e.id) + [...new Set(temp1.map(e => e.parents).filter(m => m).map(e => e.split(',').map(k => parseInt(k))).flat(1))]
            
            temp = temp.filter(e => visible.includes(e.id))
        }

        // // Filtering LESSONS for reviewed, safety, divisions
        temp.forEach(activity => {
            let temp_lessons = _.cloneDeep(activity.link_activity);

            // Divisions filtering
            if (JSON.stringify(this.state.ll_settings.divisions) !== JSON.stringify(['general'])) {
                temp_lessons = temp_lessons.filter(e => this.state.ll_settings.divisions.includes(e.lesson_division))
            }
            // Reviewed filtering
            if (this.state.ll_settings.reviewed) {
                temp_lessons = temp_lessons.filter(e => e.lesson_reviewed === true)
            }
            // UnReviewed filtering
            if (this.state.ll_settings.unreviewed) {
                temp_lessons = temp_lessons.filter(e => e.lesson_reviewed !== true)
            }
            // Safety filtering
            if (this.state.ll_settings.safety) {
                temp_lessons = temp_lessons.filter(e => e.lesson_safety === true)
            }
            // Tags filtering
            // Filters out all lessons that are ACE and don't have tags that are unselected
            // Keeps all other divisions selected
            if (this.state.cea_mode) {
                const set1 = new Set(this.state.ll_settings.tags);
                temp_lessons = temp_lessons.filter(e => e.lesson_division === 'cea' ? e.lesson_tags.some(item => set1.has(item)) : true)
            }

            activity.link_activity = temp_lessons;
        })
        
        this.setState({ ...this.state, activitiy_view_data: temp});
    }
    // Updating Lessons List Data
    updateLessonsListData = () => {
        let raw_data = []
        if (this.state.selected_activity) {
            // Lessons only for the selected activity
            raw_data = _.cloneDeep(this.state.activitiy_view_data_og.filter(e=> e.id===this.state.selected_activity)[0].link_activity)
        } else {
            // All Lessons
            this.state.activitiy_view_data_og.forEach(e => {
                raw_data = raw_data.concat(e.link_activity)
                // Removing Duplicates
                raw_data = raw_data.filter((value, index, self) =>
                    index === self.findIndex((t) => (t.lesson_id === value.lesson_id))
                )
            })
        }

        if (this.state.ll_settings.safety) {
            raw_data = raw_data.filter(e => e.lesson_safety === true)
        }
        if (this.state.ll_settings.reviewed) {
            raw_data = raw_data.filter(e => e.lesson_reviewed === true)
        }
        if (this.state.ll_settings.unreviewed) {
            raw_data = raw_data.filter(e => e.lesson_reviewed !== true)
        }
        if (JSON.stringify(this.state.ll_settings.divisions) !== JSON.stringify(['general'])) {
            raw_data = raw_data.filter(e => this.state.ll_settings.divisions.includes(e.lesson_division))
        }
        // Filters out all lessons that are ACE and don't have tags that are unselected
        // Keeps all other divisions selected
        if (this.state.cea_mode) {
            const set1 = new Set(this.state.ll_settings.tags);
            raw_data = raw_data.filter(e => e.lesson_division === 'cea' ? e.lesson_tags.some(item => set1.has(item)) : true)
        }

        // Descending order
        raw_data = raw_data.sort((a, b) => b.date_updated - a.date_updated)
        this.setState({lessons_list_data: raw_data})
    }

    toggleCEAmode = async () => {
        try {
            // Storing ceaview toggle in BE but it's not being used actively for filteirng etc. Everything happens on BE.
            // let r = await patchService(this.props.auth, `ll_user_toggle_ceaview/${localStorage.getItem('id')}/`,{})
            // if (r.status === 200) {
            let current_state = localStorage.getItem('cea_mode') === 'true' ? true : false
            let new_state = current_state ? false : true
            let new_state_string = new_state ? 'true' : 'false'

            localStorage.setItem('cea_mode', new_state_string)

            this.setState({ ...this.state, 
                cea_mode: new_state, 
                ll_settings: {
                    safety: false,
                    reviewed: false,
                    unreviewed: false,
                    divisions: new_state ? ['cea']:  LESSON_DIVISION_OPTIONS.map(e => e.value),
                    tags: new_state ? LESSON_TAGS.filter(e => e.division === 'cea').map(e => e.value): [],
                } 
            }, () => {
                this.updateActivityViewData()
                this.updateLessonsListData();                    
            });
            // }
        }
        catch (error) {
            console.log(error)
            this.setState({ ...this.state, error: error, loading: false });
        }    
    }

    componentDidUpdate(prevProps) {
        document.querySelectorAll('iframe').forEach(iframe => iframe.remove());
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root_grid}>
                <div className={classes.header}>
                    {/* ***** Header Area ***** */}
                    <Header
                        toggleLessonSearch={this.toggleLessonSearch}   
                        updateLLViewSettings={this.updateLLViewSettings}
                        ll_settings={this.state.ll_settings}
                        cea_mode={this.state.cea_mode}
                        toggleCEAmode={this.toggleCEAmode}
                    />
                    <LessonSearchContainer 
                        auth={this.props.auth}
                        show_lesson_search={this.state.show_lesson_search}
                        toggleLessonSearch={this.toggleLessonSearch}
                        updateDetailView={this.updateDetailView}
                        updateLLViewSettings={this.updateLLViewSettings}
                        ll_settings={this.state.ll_settings}
                    />
                </div>
                <div>
                    {this.state.offline ? (
                        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                            You are offline!
                        </div>
                    ) : this.state.error ? (
                        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                            <Typography variant="h5" style={{ fontFamily: 'inherit' }} gutterBottom>
                                <strong>An error occured!</strong>
                            </Typography>
                            <center>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={() => window.location.reload()}
                                >
                                    <Replay /> Try again
                                </Button>
                            </center>
                        </div>
                    ) : this.state.loading ? (
                        <div style={{ position: 'absolute', top: '55%', left: '50%', transform: 'translate(-55%, -50%)' }}>
                            <BouncingPreloader />
                        </div>    
                    ) : (   
                        <div>  
                        {(Math.round(window.innerHeight) > Math.round(window.innerWidth)) && (
                            <div className={classes.sectionMobile}>
                                        <Dialog open={this.state.showSaveMobile}
                                            className={classes.mobilesave}
                                        >

                                            <DialogTitle style={{display: 'flex', alignSelf: 'center', padding: '10px'}}>
                                                <Typography variant='h6'>
                                                    Add to Home Screen
                                                </Typography>
                                            </DialogTitle>
                                            <DialogContent style={{display: 'flex', alignSelf: 'center'}}>
                                                <div>
                                                    <span align='center' style={{fontFamily: 'Roboto'}}>
                                                    <p style={{margin: '0px'}}>Tap the&nbsp;<IosShareIcon fontSize='small' style={{display:'inline-block'}}/>&nbsp;button below and</p>
                                                    <p style={{margin: '0px'}}>select 'Add to Home Screen' option</p>
                                                    <p style={{margin: '0px'}}>for quick access icon</p>
                                                    </span>
                                                    <br />
                                                </div>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={() => this.updateSaveMobile()}>Close</Button>
                                            </DialogActions>
                                        </Dialog>
                                {this.state.mobile_display_tab===0 && (
                                    <Activities 
                                        loading={this.state.loading}
                                        showColumns={this.state.showColumns}

                                        updateDetailView={this.updateDetailView}
                                        toggleDetailView={this.toggleDetailView}

                                        activity_view_data={this.state.activitiy_view_data}
                                        wbs_level = {this.state.wbs_level}
                                        selected_activity={this.state.selected_activity}
                                        updateLLViewSettings = {this.updateLLViewSettings}
                                        collapsed_activities = {this.state.collapsed_activities}
                                        updateMobileDisplayTab={this.updateMobileDisplayTab}
                                    />
                                )}
                                {this.state.mobile_display_tab===1 && (
                                    <LessonsListContent 
                                        loading={this.state.loading}
                                        lessons_list_data={this.state.lessons_list_data}
                                        updateDetailView={this.updateDetailView}
                                        detail_view_lesson_id={this.state.detail_view_lesson_id}
                                        toggleLessonSearch={this.toggleLessonSearch}
                                        selected_activity={this.state.selected_activity}
                                        selected_activity_name={this.state.selected_activity_name}
                                        updateMobileDisplayTab={this.updateMobileDisplayTab}
                                    />
                                )}
                                {this.state.mobile_display_tab===2 && (
                                    <LessonDetailedViewContainer
                                        // loading={this.state.loading}
                                        auth = {this.props.auth}
                                        detail_view_lesson_id={this.state.detail_view_lesson_id}
                                        updateLLViewSettings = {this.updateLLViewSettings}
                                        updateMobileDisplayTab={this.updateMobileDisplayTab}
                                    />
                                )}
                                <MobileBottomNavigation style={{zIndex: 999}}
                                    mobile_display_tab={this.state.mobile_display_tab}
                                    updateMobileDisplayTab={this.updateMobileDisplayTab}
                                />
                            </div>

                        )}
                        {(Math.round(window.innerHeight) < Math.round(window.innerWidth)) && (
                        <div className={classes.sectionDesktop}>
                            <div className={classes.main}>
                                {/* ***** Main Banner Area ***** */}
                                {/* <Paper className={classes.pm_view}> */}
                                <div className={classes.gridItem1} id='gridItem1'>
                                    <Activities 
                                        loading={this.state.loading}
                                        showColumns={this.state.showColumns}
                                        updateDetailView={this.updateDetailView}
                                        toggleDetailView={this.toggleDetailView}
                                        activity_view_data={this.state.activitiy_view_data}
                                        wbs_level = {this.state.wbs_level}
                                        selected_activity={this.state.selected_activity}
                                        updateLLViewSettings = {this.updateLLViewSettings}
                                        collapsed_activities = {this.state.collapsed_activities}
                                    />
                                </div>
                                <div className={classes.gridItem2}
                                    // style={{ maxHeight: document.getElementById('gridItem1').clientHeight}}
                                >
                                    <SearchAndFilter                                     
                                        toggleLessonSearch={this.toggleLessonSearch}
                                        updateLLViewSettings={this.updateLLViewSettings}
                                        ll_settings={this.state.ll_settings}
                                    />
                                    {/* Show the filtered lessons here */}
                                    <LessonsListContent 
                                        loading={this.state.loading}
                                        lessons_list_data={this.state.lessons_list_data}
                                        updateDetailView={this.updateDetailView}
                                        detail_view_lesson_id={this.state.detail_view_lesson_id}
                                        toggleLessonSearch={this.toggleLessonSearch}
                                    />
                                </div>

                                {/* Show the detailed view of lesson here */}
                                <div className={classes.gridItem3} id='lesson_detail_view'>
                                    <LessonDetailedViewContainer
                                        loading={this.state.loading}
                                        auth = {this.props.auth}
                                        detail_view_lesson_id={this.state.detail_view_lesson_id}
                                        updateLLViewSettings = {this.updateLLViewSettings}
                                    />
                                </div>
                            </div>
                        </div>
                        )}
                        </div>                            
                    )}
                </div>
            </div>
        );
    }
}

export default withStyles(Styles)(LLViewContent)