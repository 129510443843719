import React from 'react';
import { NavLink } from 'react-router-dom';


import { makeStyles, Select, FormControl, Input, MenuItem, Drawer, Toolbar, List, ClickAwayListener, ListItem, Checkbox, TextField, Divider, Typography} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CssBaseline from '@material-ui/core/CssBaseline';
import clsx from 'clsx';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';


// Icons
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

// Ops_Leaders
import PersonPinIcon from '@material-ui/icons/PersonPin';
// Divisions
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
// Company
import BusinessIcon from '@material-ui/icons/Business';
// SFDC Stages
import ClearAllIcon from '@material-ui/icons/ClearAll';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';


import { COLUMN_OPTIONS } from './constants';

const checkBoxOutlineIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkBoxIcon = <CheckBoxIcon fontSize="small" />;

const NavLinkMui = React.forwardRef((props, ref) => {
    const classes = useStyles();
    return <NavLink {...props} ref={ref} activeClassName={classes.active} />;
});


const useStyles = makeStyles(theme => ({
    root: {
        // display: 'flex',
        zIndex: 9,
        color: '#d8d8d8'
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: 200,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: 400,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(7) + 1,
        },
    },
    active: {
        backgroundColor: '#d8d8d8',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(6, 1, 0, 0),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    multicheckboxFilter: {
        width: '300px',
        paddingBottom: '1rem',
        display: 'inline-block',
    },
}));

const ProjectSidebar = (props) => {
    let {loading, filters, divisions, ops_leaders, stages, companies, updateFilters, showColumns, updateShowColumns} = props;
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <>
        <div className={classes.root}>
            <CssBaseline />
            <Drawer
                anchor={'right'}
                variant="permanent"
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <ClickAwayListener onClickAway={handleDrawerClose}>
                    <Toolbar className={classes.toolbar}>
                        <List>
                            <br></br>
                            <ListItem
                                button
                                key={'text'}
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                className={clsx({
                                    [classes.hide]: open,
                                })}
                            >
                                <ListItemIcon>
                                    <ChevronLeftIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Views'} />
                            </ListItem>
                            <ListItem
                                button
                                key={'text2'}
                                aria-label="open drawer"
                                onClick={handleDrawerClose}
                                className={clsx({
                                    [classes.hide]: !open,
                                })}
                            >
                                <ListItemIcon>
                                    <ChevronRightIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Filters'} />
                            </ListItem>
                            
                            {/* Divisions */}
                            <ListItem
                                key={'divisions'}
                                className={classes.sublist}
                            >
                                <ListItemIcon>
                                    <BubbleChartIcon 
                                        onClick={handleDrawerOpen}
                                    />
                                </ListItemIcon>
                                {loading ? (<><br /><br />Loading...<br /><br /></>) : (
                                    <>
                                <div className={classes.multicheckboxFilter}>
                                    <Autocomplete 
                                    multiple 
                                    id="divisions-filter" 
                                    options={divisions} 
                                    disableCloseOnSelect 
                                    defaultValue={() => {
                                        let values = [];
                                        filters.divisions.forEach(e => {
                                            values.push({title: e})
                                        })
                                        return (values)
                                    }} 
                                    getOptionLabel={(option) => option.title}
                                    getOptionSelected={(option) => filters['divisions'].includes(option.title)}
                                    onChange={(e, value) => {
                                        // filters = (Object.assign({}, filters, {'divisions': value.map(a => a.title)}));
                                        updateFilters('divisions',value.map(a => a.title));
                                    }}
                                    renderOption={(option, { selected }) => (
                                        <React.Fragment>
                                        <Checkbox
                                            icon={checkBoxOutlineIcon}
                                            checkedIcon={checkBoxIcon}
                                            style={{marginRight: 8}}
                                            checked={selected}
                                        />
                                        {option.title}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Filter by Divisions"
                                        placeholder="Divisions"
                                        />
                                    )} 
                                    />
                                </div>

                                    </>
                                )}
                            </ListItem>                            

                            {/* OpsLeaders */}
                            <ListItem
                                key={'ops_leaders'}
                                className={classes.sublist}
                            >
                                <ListItemIcon>
                                    <PersonPinIcon onClick={handleDrawerOpen}/>
                                </ListItemIcon>
                                {loading ? (<><br /><br />Loading...<br /><br /></>) : (
                                    <>
                                <div className={classes.multicheckboxFilter}>
                                    <Autocomplete 
                                    multiple 
                                    id="ops-leaders-filter" 
                                    options={ops_leaders} 
                                    disableCloseOnSelect 
                                    defaultValue={() => {
                                        let values = [];
                                        filters.ops_leaders.forEach(e => {
                                            values.push({title: e})
                                        })
                                        return (values)
                                    }} 
                                    getOptionLabel={(option) => option.title}
                                    getOptionSelected={(option) => filters['ops_leaders'].includes(option.title)}
                                    onChange={(e, value) => {
                                        // filters = (Object.assign({}, filters, {'divisions': value.map(a => a.title)}));
                                        updateFilters('ops_leaders',value.map(a => a.title));
                                    }}
                                    renderOption={(option, { selected }) => (
                                        <React.Fragment>
                                        <Checkbox
                                            icon={checkBoxOutlineIcon}
                                            checkedIcon={checkBoxIcon}
                                            style={{marginRight: 8}}
                                            checked={selected}
                                        />
                                        {option.title}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Filter by OpsLeaders"
                                        placeholder="Ops Leader"
                                        />
                                    )} 
                                    />
                                </div>
                                    </>
                                )}
                            </ListItem>                            

                            {/* SFDC Stages */}
                            <ListItem
                                key={'stages'}
                                className={classes.sublist}
                            >
                                <ListItemIcon>
                                    <ClearAllIcon onClick={handleDrawerOpen} />
                                </ListItemIcon>
                                {loading ? (<><br /><br />Loading...<br /><br /></>) : (
                                    <>
                                <div className={classes.multicheckboxFilter}>
                                    <Autocomplete 
                                    multiple 
                                    id="stages-filter" 
                                    options={stages} 
                                    disableCloseOnSelect
                                    defaultValue={() => {
                                        let values = [];
                                        filters.stages.forEach(e => {
                                            values.push({title: e})
                                        })
                                        return (values)
                                    }} 
                                    getOptionLabel={(option) => option.title}
                                    getOptionSelected={(option) => filters['stages'].includes(option.title)}
                                    onChange={(e, value) => {
                                        // filters = (Object.assign({}, filters, {'divisions': value.map(a => a.title)}));
                                        updateFilters('stages',value.map(a => a.title));
                                    }}
                                    renderOption={(option, { selected }) => (
                                        <React.Fragment>
                                        <Checkbox
                                            icon={checkBoxOutlineIcon}
                                            checkedIcon={checkBoxIcon}
                                            style={{marginRight: 8}}
                                            checked={selected}
                                        />
                                        {option.title}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Filter by SFDC Stages"
                                        placeholder="Stage"
                                        />
                                    )} 
                                    />
                                </div>
                                    </>
                                )}
                            </ListItem>                            

                            {/* Companies */}
                            <ListItem
                                key={'companies'}
                                className={classes.sublist}
                            >
                                <ListItemIcon>
                                    <BusinessIcon onClick={handleDrawerOpen} />
                                </ListItemIcon>
                                {loading ? (<><br /><br />Loading...<br /><br /></>) : (
                                    <>
                                <div className={classes.multicheckboxFilter}>
                                    <Autocomplete 
                                    multiple 
                                    id="companies-filter" 
                                    options={companies} 
                                    disableCloseOnSelect 
                                    getOptionLabel={(option) => option.title}
                                    defaultValue={() => {
                                        let values = [];
                                        filters.companies.forEach(e => {
                                            values.push({title: e})
                                        })
                                        return (values)
                                    }} 
                                    getOptionSelected={(option) => filters['companies'].includes(option.title)}
                                    onChange={(e, value) => {
                                        // filters = (Object.assign({}, filters, {'divisions': value.map(a => a.title)}));
                                        updateFilters('companies',value.map(a => a.title));
                                    }}
                                    renderOption={(option, { selected }) => (
                                        <React.Fragment>
                                        <Checkbox
                                            icon={checkBoxOutlineIcon}
                                            checkedIcon={checkBoxIcon}
                                            style={{marginRight: 8}}
                                            checked={selected}
                                        />
                                        {option.title}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Filter by Companies"
                                        placeholder="Companies"
                                        />
                                    )} 
                                    />
                                </div>
                                    </>
                                )}
                            </ListItem>                            

                            <Divider />
                            {/* Show/Hide Columns */}
                            &nbsp;

                            <ListItem
                                key={'show/hide'}
                                className={classes.sublist}
                            >
                                <ListItemIcon>
                                    <CheckBoxIcon onClick={handleDrawerOpen} />
                                </ListItemIcon>
                                <div className={classes.multicheckboxFilter}>
                                    <FormControl className={classes.formControl}>
                                        <Select 
                                            id="show-hide-columns"
                                            multiple
                                            value={showColumns}
                                            onChange={(e) => {
                                                updateShowColumns(e.target.value);
                                                handleDrawerOpen();
                                            }}
                                            input={<Input />}
                                            renderValue={(selected) => "Show/Hide Columns"}
                                            MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                            }}
                                        >
                                            {COLUMN_OPTIONS.map((name) => (
                                            <MenuItem key={name.title} value={name.column_id}>
                                                <Checkbox checked={showColumns.indexOf(name.column_id) > -1} />
                                                <ListItemText primary={name.title} />
                                            </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </ListItem>                            
                            <br />
                        
                            {/* Link to Edit Custom List */}
                            <ListItem
                                button
                                key={'edit_custom_list'}
                                className={classes.sublist}
                                component={NavLinkMui}
                                to="/custom-settings"
                            >
                                <ListItemIcon>
                                    <BubbleChartIcon 
                                        onClick={handleDrawerOpen}
                                    />
                                </ListItemIcon>
                                <>
                                    <Typography>
                                        Edit Custom Settings
                                    </Typography>
                                    &nbsp;
                                    <OpenInNewIcon />
                                </>
                            </ListItem>                            


                        </List>
                    </Toolbar>
                </ClickAwayListener>
            </Drawer>
        </div>
        </>
    );
    // }
};

export default ProjectSidebar;
