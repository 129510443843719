import React from "react";
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../auth/useAuth';
import { checkLocalStorage } from '../auth/localStorage'
//  Lessons: Redirecting to sign-in


const PrivateRoute = ({ component: Component, ...rest }) => {
    const {
        isAuthenticated, 
        isLoading, 
    } = useAuth();
    const auth = useAuth();

    let app_name = 'Roster';
    if (window.location.href.includes('lessons')) {
        app_name = 'Lessons Learned';
    }
    let form_html = ''
    if (app_name === 'Lessons Learned') {
        form_html = `<div data-tf-popover="qyj6Y6od" data-tf-custom-icon="https://images.typeform.com/images/wiBmeQ5kFu8M" data-tf-opacity="100" data-tf-iframe-props="title=AMI Tool Feedback" data-tf-transitive-search-params data-tf-button-color="#FFFFFF" data-tf-medium="snippet" data-tf-hidden="utm_source={{`+localStorage.getItem('email')+`}},product={{product|default:'lessonslearned'}}" style="all:unset;"></div>`
    } else {
        form_html = `<div data-tf-popover="qyj6Y6od" data-tf-custom-icon="https://images.typeform.com/images/wiBmeQ5kFu8M" data-tf-opacity="100" data-tf-iframe-props="title=AMI Tool Feedback" data-tf-transitive-search-params data-tf-button-color="#FFFFFF" data-tf-medium="snippet" data-tf-hidden="utm_source={{`+localStorage.getItem('email')+`}},product={{product|default:'roster'}}" style="all:unset;"></div>`
    }

    const script = document.createElement('script');
    script.src = "//embed.typeform.com/next/embed.js";
    script.async = true;    
    document.body.appendChild(script);      

    if (isLoading) {
        return (<>Loading...</>)
    }
    checkLocalStorage();

    // Checking if a new user
    // let local_email = localStorage.getItem('email') ? localStorage.getItem('email'): null;
    // let local_id = localStorage.getItem('id') ? localStorage.getItem('id'): null;

    // Checking and adding LL Admin in Local Storage
    // let ll_admin = localStorage.getItem('ll_admin') ? localStorage.getItem('ll_admin'): null;

    // Case 1: No local credentials
    // Case 2: Local credentials don't match Auth user
    // Case 3: Pre-logged in user.

    // if (!local_email) {
    //     // Case 1
    //     const sendMetaData = async() => {
    //             const token = await getAccessTokenSilently();
    //             let coded_username = encodeURIComponent(user.sub);
    //             Axios.get(
    //                 // `${API_URL}getuser/${coded_username}`,
    //                 `${API_URL}user/`,
    //                 {
    //                     params: {
    //                         username: coded_username,
    //                     },                        
    //                     headers: {
    //                         'content-type': 'application/json',
    //                         Authorization: `Bearer ${token}`,
    //                     },
    //                 }, 
    //             ).then((response) => {
    //                 // Writing Metadata if new user
    //                 if (!response.data.email) {
    //                     Axios.patch(
    //                         `${API_URL}user/${response.data[0].id}/`,
    //                         {
    //                             'email': user.email,
    //                             'first_name': user.given_name,
    //                             'last_name': user.family_name,
    //                         },
    //                         {
    //                             headers: {
    //                                 'content-type': 'application/json',
    //                                 Authorization: `Bearer ${token}`,
    //                             },
    //                         }
    //                     ).then(() => {

    //                         localStorage.setItem('email', user.email); 
    //                         localStorage.setItem('id', response.data[0].id); 
    //                         localStorage.setItem('ll_admin', response.data[0].ll_admin); 

    //                         return (isAuthenticated) ? (
    //                             <Route
    //                                 {...rest}
    //                                 render={props => 
    //                                     <>
    //                                     <Component {...props}/>
    //                                     <div dangerouslySetInnerHTML={{__html: form_html}} />
    //                                     </>
    //                                 }
    //                             />
    //                         ) : <Redirect to="/" />
    //                     });  
    //                 }
    //             }).catch(error => {
    //                 console.log('Error sending new user MetaData ', error);
    //                 return(<AuthError app_name={app_name}/>)
    //                 // return(<>Error. Please refresh the page. Code=Case 1</>)
    //             })
    //         }
    //     sendMetaData();
    // } else {
    //     // Case: 2
    //     // If due to some reason, user logged in and user credentials in local storage don't match:
    //     if (user) {
    //         if (user.email !== local_email) {
    //             // Clean up localStorage
    //             localStorage.clear();
    //             return(<AuthError app_name={app_name}/>)
    //             // return(<>Error. Please refresh the page.</>)
    //         }        
    //     } else {
    //         return(<AuthError app_name={app_name}/>)
    //         // return(<>Error. Please refresh the page.</>)
    //     } 
    // }
    
    // // Case when id doesnt exist.
    // // if ((!local_id)||(!ll_admin)) {
    //     const getUserId = async() => {
    //         const token = await getAccessTokenSilently();
    //         let coded_username = encodeURIComponent(user.sub);
    //         Axios.get(
    //             `${API_URL}user/`,
    //             {
    //                 params: {
    //                     username: coded_username,
    //                 },                        
    //                 headers: {
    //                     'content-type': 'application/json',
    //                     Authorization: `Bearer ${token}`,
    //                 },
    //             }, 
    //         ).then((response) => {
    //             localStorage.setItem('id', response.data[0].id); 
    //             localStorage.setItem('ll_admin', response.data[0].ll_admin); 
    //         }).catch(error => {
    //             return(<AuthError app_name={app_name}/>)
    //             // return(<>Error. Please refresh the page. Code=Case 1</>)
    //         })
    //     }
    // getUserId();
    // // }

    // // Case 3: 
    // // Assign LR Identification here
    // if (isAuthenticated) {
    //     LogRocket.identify('THE_USER_ID_IN_YOUR_APP', {
    //         name: user ? user.name:'',
    //         email: user ? user.email: '',          
    //     });    
    // }

    return (isAuthenticated) ? (
        <Route
            {...rest}
            render={props => 
                <>
                    <div dangerouslySetInnerHTML={{__html: form_html}} />
                    <Component {...{...props, auth: auth}}/>
                </>
            }
        />
    ) : <Redirect to="/" />;
}

export default (PrivateRoute);
