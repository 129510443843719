import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, Tooltip, Button } from '@material-ui/core';
import { Link, NavLink, useLocation } from 'react-router-dom';

import HomeIcon from '@mui/icons-material/Home';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import BroadcastOnPersonalIcon from '@mui/icons-material/BroadcastOnPersonal';
import SearchAndFilter from '../activity-view/search_and_filter_buttons';
import { useAuth } from '../../../../auth/useAuth';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    toolbar: {
        backgroundColor: '#093d61',
        paddingLeft: '0px',
        paddingRight: '0px',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: '16px',
            paddingRight: '16px',
        },
    },
    title: {
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'inline',
        },
    },
    text: {
        color: 'white',
        textDecoration: 'none',
        padding: theme.spacing(0, 1.5),
    },
    activeButton: {
        color: '#00AEEF',
        padding: theme.spacing(0, 1.5),
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        color: '#white',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
        marginLeft: 'auto'
    },
}));

const Header = props => {
    let { toggleLessonSearch, updateLLViewSettings, ll_settings } = props
    const classes = useStyles();
    const location = useLocation();
    const { logout } = useAuth();

    return (
        <div className={classes.root}>
            <AppBar color="default" position="sticky" >
                <Toolbar variant='dense' className={classes.toolbar}>
                    <Typography variant="h6" className={classes.title}>
                        {(location.pathname==='/ll/view') && (
                        <Link 
                            onClick={() => window.location.reload()}                                 
                            to="/ll/view" 
                            className={classes.text}
                        >
                            ARCO Lessons Learned
                        </Link>
                        )}
                        {(location.pathname!=='/ll/view') && (
                        <NavLink to="/ll/view" className={classes.text}>
                            ARCO Lessons Learned
                        </NavLink>
                        )}
                    </Typography>
                    {/* MOBILE HEADER ICONS */}
                    <div className={classes.sectionMobile}>
                        {(location.pathname==='/ll/view') && (
                            <>
                                <SearchAndFilter                                     
                                    toggleLessonSearch={toggleLessonSearch}
                                    updateLLViewSettings={updateLLViewSettings}
                                    ll_settings={ll_settings}
                                />
                                <NavLink 
                                    to="/ll/new-lesson" 
                                    className={classes.text}
                                >
                                    <AddCircleOutlineIcon />
                                </NavLink>
                            </>
                        )}
                        {(location.pathname!=='/ll/view') && (
                            <Tooltip title="Home" arrow>
                                <Link 
                                    to="/ll/view" 
                                    className={classes.text}
                                    activeClassName={classes.activeButton}
                                >
                                    <HomeIcon />
                                </Link>
                            </Tooltip>
                        )}
                    </div>

                    {/* DESKTOP HEADER ICONS */}
                    <div className={classes.sectionDesktop}>
                        <Tooltip title="Controlled Environment Agriculture Mode" arrow>
                            <div 
                                className={classes.text} 
                                style={{display: 'flex', color: props.cea_mode ? '#AAFF00' : 'white',cursor: 'pointer'}}
                                onClick={() => {
                                    props.hasOwnProperty('toggleCEAmode') ? props.toggleCEAmode() : console.log('no toggleCEAmode function passed to header')
                                }}
                            >
                                <BroadcastOnPersonalIcon />&ensp;
                                <Typography 
                                    // className={classes.text}
                                >
                                    CEA Mode
                                </Typography>
                            </div>
                        </Tooltip>
                        &ensp;&ensp;&ensp;&ensp;
                        {(location.pathname==='/ll/view') && (
                            <>
                                <Tooltip title="Home" arrow>
                                    <Link 
                                        to="/ll/view" 
                                        onClick={() => window.location.reload()} 
                                        // className={classes.text}
                                        className={classes.activeButton}
                                    >
                                        <HomeIcon />
                                    </Link>
                                </Tooltip>
                            </>
                        )}
                        {(location.pathname!=='/ll/view') && (
                            <Tooltip title="Home" arrow>
                            <NavLink 
                                to="/ll/view" 
                                className={classes.text}
                                activeClassName={classes.activeButton}
                            >
                                <HomeIcon />
                            </NavLink>
                            </Tooltip>
                        )}
                        {localStorage.getItem('ll_admin')==='true' && (
                            <div>
                                <Tooltip title="Admin View" arrow>
                                    <NavLink to="/ll/lessons"
                                        className={classes.text}                                
                                        activeClassName={classes.activeButton}
                                    >
                                        <MenuBookIcon />
                                    </NavLink>
                                </Tooltip>
                            </div>                        
                        )}
                        <Tooltip title="Add a Lesson" arrow>
                            <NavLink 
                                to="/ll/new-lesson" 
                                className={classes.text}
                                activeClassName={classes.activeButton}
                            >
                                <AddCircleOutlineIcon />
                            </NavLink>
                        </Tooltip>
                        <Tooltip title="Log out" arrow>
                            <Button className={classes.text} color="default" onClick={() => logout()} >
                                Logout
                            </Button>

                            {/* <NavLink 
                                to="/ll/new-lesson" 
                                className={classes.text}
                            >
                                <AddCircleOutlineIcon />
                            </NavLink> */}
                        </Tooltip>



                    </div>
                </Toolbar>
            </AppBar>
        </div>
    );
};

export default Header;
