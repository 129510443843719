import React, {useEffect, useState} from 'react';

import { makeStyles, Checkbox, Divider, List, ListItem, Popper, Paper, ClickAwayListener} from '@material-ui/core';

import LessonsSearchFilters from './lessons_search_filters';


const useStyles = makeStyles(theme => ({
    paper: {
        fontFamily: 'Roboto'
    },
    listItem:{
        padding: '0px',
        '&:hover': {
            backgroundColor: '#ebf0f8'
        }
    }
}));

const LessonsListFilters = (props) => {
    let {showFilters,toggleShowFilters, ll_settings, updateLLViewSettings, anchorEl} = props;
    const classes = useStyles();

    return (
        <>
            <ClickAwayListener onClickAway={() => toggleShowFilters(true)}>
                <Popper
                    open={showFilters}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                        }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                        }}                
                >
                    <Paper elevation={7}>
                        <LessonsSearchFilters 
                            lesson_search_filters={ll_settings}
                            updateLLViewSettings={updateLLViewSettings}
                        />
                    </Paper>
                </Popper>
            </ClickAwayListener>
        </>
    );
    // }
};

export default LessonsListFilters;
