import React from "react";
import { CardContent, Typography } from "@mui/material";
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie'
import { borderRadius, fontWeight } from "@mui/system";


const CFASplit = ({projectData}) => {
    
const cfaStructure = projectData?.project_data?.cfa_structure || projectData?.cfa_structure;

    if (!cfaStructure) {
        return (
          <CardContent
            style={{
              height: '100px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography className="key-project-info-body">--</Typography>
          </CardContent>
        );
      }

    const data = Object.keys(cfaStructure).map((key) => ({
        id: key,
        value: cfaStructure[key],
      }));

   
// ======= Pie Chart Option ========
    const pieConfig = {
        margin: { top: 10, right: 115, bottom: 20, left: 115 },
        fit: true,
        innerRadius: 0.5,
        padAngle: 0.7,
        cornerRadius: 3,
        activeOuterRadiusOffset: 8,
        borderWidth: 1,
        borderColor: {
            from: 'color',
            modifiers: [
                ['darker', 0.2]
            ]
        },
        arcLinkLabelsSkipAngle: 10,
        arcLinkLabelsTextColor: "#333333",
        arcLinkLabelsThickness: 2,
        arcLinkLabelsColor: { from: 'color' },
        arcLabelsSkipAngle: 10,
        arcLinkLabelsDiagonalLength: 10,
        arcLinkLabelsStraightLength: 10,  
        arcLinkLabelsOffset: 0, 
        arcLabelsTextColor: '#FFFFFF',
        defs: [
            {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                size: 4,
                padding: 1,
                stagger: true
            },
            {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
            }
        ],
        colors: ['rgba(9, 61, 97, 0.8)', 'rgba(0, 174, 239, 0.8)', 'rgba(165, 165, 165, 0.8)', 'rgba(0, 0, 0, 0.6)'],
        theme: {
            labels: {
                text: {
                    fontSize: 14, 
                    fontFamily: 'Titillium Web, sans-serif', 
                    fill: '#ffffff' 
                },
            },
            tooltip: {
                container: {
                    fontSize: 14,
                    fontFamily: 'Titillium Web, sans-serif',
                },
            },
        }
    };

    return (
            <div style={{ height: '100%', width: '100%', position: 'relative'}}>
            <ResponsivePie
                data={data}
                {...pieConfig}
            />
            </div>
        );
    };
//     return (
//         <CardContent style={{ height: '150px' }}>
//             <ResponsiveBar
//                 data={data}
//                 {...barConfig}
//             />
//         </CardContent>
//     );
// };

export default CFASplit;


 // ==== Bar Chart Option ====
    // const data = projectData && projectData.project_data && projectData.project_data.cfa_structure
    //     ? Object.keys(projectData.project_data.cfa_structure).map(key => ({
    //         division: key,
    //         percentage: projectData.project_data.cfa_structure[key],
    //       }))
    //     : [];

    // const barConfig = {
    //     keys: ['percentage'],
    //     indexBy: 'division',
    //     margin: { right: 20, left: 110 },
    //     layout: 'horizontal',
    //     padding: 0.3,
    //     valueScale: { type: 'linear' },
    //     indexScale: { type: 'band', round: true },
    //     colors: bar => bar.data.percentage > 49 ? '#093d61' : '#2aace2',
    //     axisTop: null,
    //     axisRight: null,
    //     labelSkipWidth: 12,
    //     labelSkipHeight: 12,
    //     labelTextColor: { from: 'color', modifiers: [['brighter', 10.0]] },
    //     legends: [],
    //     animate: true,
    //     motionStiffness: 90,
    //     motionDamping: 15,
    //     borderRadius: '15px',
    //     theme: {
    //         labels: {
    //             text: {
    //             fontSize: 15,
    //             fill: '#000000',
    //             },
    //         },
    //         axis: {
    //             ticks: {
    //               text: {
    //                 fontSize: 15, 
    //                 fill: '#555555', 
    //                 fontWeight: '500',
    //               },
    //             }
    //           },
    //     }
    // };
