import React from "react";
import { Typography } from "@material-ui/core";

const formatDollar = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
}

const OGcontactAmount = ({ budgetData }) => {

    const getOriginalcontactAmount = () => {
        if (budgetData && budgetData?.current_contract_summary && budgetData?.current_contract_summary?.original_contract_value !== undefined) {
            const contractAmount = budgetData?.current_contract_summary?.original_contract_value
            const roundedContractAmount = Math.ceil(contractAmount)
            return `$${roundedContractAmount.toLocaleString()}`;
        }
        return '--';
    };

    return (
        <div>
            <Typography style={{ textAlign: 'center' }} variant="body1" className="main-key-project-info-body">
                {getOriginalcontactAmount()}
            </Typography>
        </div>
    );
}

export default OGcontactAmount;