import {
  START_YEAR,
  NUM_OF_YEARS,
  MONTH_NAMES,
  MONTHS_PER_YEAR,
  QUARTERS_PER_YEAR,
  MONTHS_PER_QUARTER,
  MONTH_START_END_DATES,
  START_UP_QTY,
  CLOSE_OUT_QTY,
  REQUIREMENT_TYPE,
} from './constants'

import { hexToRgb, colourIsLight, addMonthsToYear, addMonthsToYearAsDate, randomColor, randomColorTrack1 } from './utils'

export const buildQuarterCells = () => {
  const v = []
  for (let i = 0; i < QUARTERS_PER_YEAR * NUM_OF_YEARS; i += 1) {
    const quarter = (i % 4) + 1
    const startMonth = i * MONTHS_PER_QUARTER
    const s = addMonthsToYear(START_YEAR, startMonth)
    const e = addMonthsToYear(START_YEAR, startMonth + MONTHS_PER_QUARTER)
    v.push({
      id: `${s.year}-q${quarter}`,
      title: `Q${quarter} ${s.year}`,
      start: new Date(`${s.year}-${s.month}-01`),
      end: new Date(`${e.year}-${e.month}-01`),
    })
  }
  return v
}

export const buildMonthCells = () => {
  const v = []
  for (let i = 0; i < MONTHS_PER_YEAR * NUM_OF_YEARS; i += 1) {
    const startMonth = i
    const start = addMonthsToYearAsDate(START_YEAR, startMonth)
    const end = addMonthsToYearAsDate(START_YEAR, startMonth + 1)
    v.push({
      id: `m${startMonth}`,
      title: MONTH_NAMES[i % 12],
      start,
      end,
    })
  }
  return v
}

export const buildTimebar = () => [
  {
    id: 'quarters',
    title: 'Quarters',
    cells: buildQuarterCells(),
    style: {},
  },
  {
    id: 'months',
    title: 'Months',
    cells: buildMonthCells(),
    useAsGrid: true,
    style: {},
  },
]

export const buildOuterElement = ({ trackId, j, elementData }) => {
  
  return {
    id: `t-${trackId}-el-${j}`,
    title: '',
    start: elementData.start,
    end: elementData.end,
    // tooltip: (`${elementData.name}\nStage: ${elementData.stage}\nStart: ${elementData.requirement_start_date}\nEnd: ${elementData.requirement_end_date}` + pm_leader_text),
    style: {
      borderRadius: '2px',
      textTransform: 'capitalize',
      opacity: '0.25',
    },
  }
}

export const buildElement = ({ trackId, j, elementData }) => {
  const bgColor = (elementData.type==='outer') ? '#90E0EF' : (elementData.type==='Construction') ? '#0077B6' : (elementData.type==='Start-up') ? '#007FBB' : '#007FBB';
  const color = colourIsLight(...hexToRgb(bgColor)) ? '#000000' : '#ffffff'

  return {
    id: `t-${trackId}-el-${j}`,
    title: elementData.name,
    start: elementData.start,
    end: elementData.end,
    tooltip: (`Type: ${elementData.type}\nStart: ${new Date(elementData.start).toISOString().slice(0, 10)}\nEnd: ${new Date(elementData.end).toISOString().slice(0, 10)}\nCalculated %FTE: ${elementData.height}`),
    style: {
      backgroundColor: `${bgColor}`,
      color,
      borderRadius: '2px',
      textTransform: 'capitalize',
      height: elementData.height,
    },
  }
}

export const buildElements = (id, trackId, trackData) => {
  const v = [];
  
  if (trackData.length > 0) {
    for (let i = 0; i< trackData.length; i++) {
      let j = i+1;
      const elementData = trackData[i];
        v.push( (elementData.type==='outer') ? 
          buildOuterElement({
              trackId,
            j,
            elementData,
          })
          :
          buildElement({
            trackId,
          j,
          elementData,
          })
        )  
      }
    }
  return v
}

export const buildTrack = (trackId, trackData, multiply_by_sfdc_probability) => {
  let data = [];

  let dates_overridden = (trackData.requirement_start_date !== null)||(trackData.requirement_end_date !== null) ? true : false;
  let requirement_start_date = new Date(trackData.requirement_start_date);
  let requirement_end_date = new Date(trackData.requirement_end_date);
  let project_probability = multiply_by_sfdc_probability ? trackData.sfdc_probability : 100;
  // Outer Elements
  data.push({
    type: 'outer',
    start: trackData.start_up,
    end: trackData.groundbreaking
  });
  data.push({
    type: 'outer',
    start: trackData.groundbreaking,
    end: trackData.end
  });
  data.push({
    type: 'outer',
    start: trackData.end,
    end: trackData.close_out
  });

  // Start-up Phase
  if (['pm_leader', 'additional_pm'].includes(trackData.requirement_type)) {
    let start, end;
    let push = false;

    if (!dates_overridden) {
      start= trackData.start_up;
      end=trackData.groundbreaking;  
      push= true;
    } else if (requirement_start_date < trackData.groundbreaking) {
        start = requirement_start_date;
        end = (requirement_end_date < trackData.groundbreaking) ? requirement_end_date : trackData.groundbreaking;
        push= true;
      }
    if (push) {
      data.push({
        type: 'Start-up',
        start: start,
        end: end,
        height: `${trackData.requirement_qty*START_UP_QTY*project_probability}%`,
      });  
    }
  }
  
  // All Other Phases
  if (['pm_design', 'pm_proposal', 'pm_precon', 'pm_permitting', 'pm_buyout'].includes(trackData.requirement_type)) {
    data.push({
      type: REQUIREMENT_TYPE(trackData.requirement_type),
      start: requirement_start_date,
      end: requirement_end_date,
      height: `${trackData.requirement_qty*project_probability}%`,
    });
  } else {
    // Phases: pm_leader, additional_pm, buyout, project_assistant
    let start, end;
    let push = false;

    if (!dates_overridden) {
      push = true;
      start = trackData.groundbreaking;
      end = trackData.end;
    } else {
      push = true;
      start = requirement_start_date;
      end = requirement_end_date;
    }

    if (push) {
      data.push({
        type: REQUIREMENT_TYPE(trackData.requirement_type),
        start: start,
        end: end,
        height: `${trackData.requirement_qty*project_probability}%`,
      });  
    }
  }

  // Close-out Phase
  if (['pm_leader', 'additional_pm'].includes(trackData.requirement_type)) {
    let start, end;
    let push = false;
  
    if (!dates_overridden) {
      start = trackData.end;
      end = trackData.close_out;
      push = true;
    } else if (requirement_end_date >= trackData.end) {
      start = (requirement_start_date <= trackData.end) ? trackData.end: requirement_start_date;
      end = requirement_end_date;
      push = true;
    }
    if (push) {
      data.push({
        type: 'Close-out',
        start: start,
        end: end,
        height: `${trackData.requirement_qty*CLOSE_OUT_QTY*project_probability}%`,
      });  
    }
  }

  return {
    id: `track-${trackId}`,
    // job_id: trackData.job_id,
    title: `${trackData.name}`,
    requirement_id: trackData.requirement_id,
    assignment_resource: trackData.assignment_resource,
    type: trackData.requirement_type,
    qty: trackData.requirement_qty,
    dates_overridden: dates_overridden,
    start_up: trackData.start_up,
    requirement_start_date: requirement_start_date,
    close_out: trackData.close_out,
    requirement_end_date: requirement_end_date,
    elements: buildElements(`track-${trackId}`, trackId, data),
    // hasButton: true,
    // link: 'www.google.com',
  }
}