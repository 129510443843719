import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, Button } from '@material-ui/core';
import { Link, NavLink } from 'react-router-dom';
import { useAuth } from '../../../auth/useAuth';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
        display: 'inline',
    },
    text: {
        color: 'rgba(0,0,0,0.9)',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none',
        },
    },
    active: {
        // backgroundColor: '#d8d8d8',
        borderRadius: '3px',
    },
    button: {
        padding: theme.spacing(0, 2),
    },
    sectionDesktop: {
        display: 'none',
        color: '#d8d8d8',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        color: '#d8d8d8',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
}));

const Header = () => {
    const classes = useStyles();
    const { logout } = useAuth();
    return (
        <div className={classes.root}>
            <AppBar color="default" position="sticky">
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        <NavLink to="/" className={classes.text}>
                            ARCO Roster
                        </NavLink>
                    </Typography>
                    <div className={classes.sectionDesktop}>
                            <Button className={classes.button} color="default" onClick={() => window.print()} >
                                Print
                            </Button>
                    </div>
                    <div className={classes.sectionDesktop}>
                        <Link to={{ pathname:`mailto:innovation@arcomurray.com?subject=Feedback-Roster` }} target='_blank' className={classes.text} activeClassName={classes.active}>
                            <Button className={classes.button} color="default">
                                Feedback
                            </Button>
                        </Link>
                    </div>
                    <div className={classes.sectionDesktop}>
                            <Button className={classes.button} color="default" onClick={() => logout()} >
                                Logout
                            </Button>
                    </div>
                    {/* <div className={classes.sectionDesktop}>
                        <NavLink to="/" className={classes.text} activeClassName={classes.active}>
                            <Button className={classes.button} color="default">
                                Home
                            </Button>
                        </NavLink>
                    </div> */}
                </Toolbar>
            </AppBar>
        </div>
    );
};

export default Header;
