import React, { Component } from 'react';
import {Typography, Button} from '@material-ui/core';
import { Replay } from '@material-ui/icons';
import { withStyles } from '@material-ui/core';
import LessonsList from '../../../components/post-security/activity-view/lessons_list';

const Styles = theme => ({
    main: {
        'display': 'flex',
        // 'grid-template-columns': '0.75fr 1.25fr 2fr', // Header: 48px comes from toolbar = dense.
        'maxWidth': '100vw',
        // 'padding-left': '2rem',
        // 'padding-top': document.title === 'Lessons Learned' ? '20px' : '5px',
        // 'padding-right': '2rem',
        'overflow': 'hidden',
    },
});

class LessonsListContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: false,
            offline: false,
            lessons_list_data: [],    
            detail_view_lesson_id: null,        
        };
    }

    componentDidMount() {
        if (this.props.lessons_list_data) {
            this.setState( {
                ...this.state,
                lessons_list_data: this.props.lessons_list_data,
                detail_view_lesson_id: this.props.detail_view_lesson_id
            }, function() {
                // this.updateLLViewData();
            })  
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.lessons_list_data) {
            if ((this.props.lessons_list_data !== prevProps.lessons_list_data)||(this.props.detail_view_lesson_id !== prevProps.detail_view_lesson_id)) {
                    this.setState( {
                        ...this.state,
                        lessons_list_data: this.props.lessons_list_data,
                        detail_view_lesson_id: this.props.detail_view_lesson_id
                    }, function() {
                        // this.updateLLViewData();
                    })  
                }
        }
    }

    render() {
        const { classes } = this.props;        
        return (
            <>
                {this.state.offline ? (
                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                        You are offline!
                    </div>
                ) : this.state.error ? (
                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                        <Typography variant="h5" style={{ fontFamily: 'inherit' }} gutterBottom>
                            <strong>An error occured!</strong>
                        </Typography>
                        <center>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() => window.location.reload()}
                            >
                                <Replay /> Try again
                            </Button>
                        </center>
                    </div>
                ) : (      
                    <>
                        <div className={classes.main}>
                            {/* ***** Main Banner Area ***** */}
                            {/* <Paper className={classes.pm_view}> */}
                            
                            <LessonsList 
                                loading={this.state.loading}
                                lessons_list_data={this.state.lessons_list_data}
                                updateDetailView={this.props.updateDetailView}
                                detail_view_lesson_id={this.state.detail_view_lesson_id}
                                toggleLessonSearch={this.props.toggleLessonSearch}
                                selected_activity={this.props.selected_activity}
                                selected_activity_name={this.props.selected_activity_name}
                                updateMobileDisplayTab={this.props.updateMobileDisplayTab}
                                />
                        </div>
                    </>
                )}
            </>
        );
    }
}

export default withStyles(Styles)(LessonsListContent)