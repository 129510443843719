export const SFDC_STAGE_OPTIONS = ([
    {'title': 'Targeting', 'probability': 1, 'sorting_order': 1}, 
    {'title': 'Prospecting', 'probability': 10, 'sorting_order': 2}, 
    {'title': 'Proposing', 'probability': 10, 'sorting_order': 3}, 
    {'title': 'Potential Work - 10%', 'probability': 10, 'sorting_order': 4}, 
    {'title': 'Potential Work - 50%', 'probability': 50, 'sorting_order': 5}, 
    {'title': 'Potential Work - 75%', 'probability': 75, 'sorting_order': 6}, 
    {'title': 'Potential Work - 95%', 'probability': 95, 'sorting_order': 7}, 
    {'title': 'Work in Progress', 'probability': 100, 'sorting_order': 8}, 
    {'title': 'Completed, Closeout Remaining', 'probability': 100, 'sorting_order': 9}, 
    {'title': 'Completed Jobs', 'probability': 100, 'sorting_order': 10}, 
  ]);

export const SFDC_DIVISIONS = ([{"title":"AM-CON"},{"title":"General"},{"title":"Charlotte"},{"title":"Industrial"},{"title":"ACE"},{"title":"Entertainment"},{"title":"Self Storage"},{"title":"Multifamily"},{"title":"Senior_Living"},{"title":"Laundry"},{"title":"Process_Other"},{"title":"Mission_Critical"},{"title":"Tenant Solutions"},{"title":"Chicago"},{"title":"Suburban"},{"title":"National/Multi-site"},{"title":"HVAC"},{"title":"Process"},{"title":"Service"},{"title":"New Business"},{"title":"Existing Business"},{"title":"Cannabis"},{"title":"Agriculture"},{"title":"Greenhouse"},{"title":"Solar"},{"title":"Structural - GEPTO"},{"title":"Structural - Fab Modelling"},{"title":"Energy Services"},{"title":"Minneapolis"},{"title":"Toronto"}]).sort((a,b) => (a.title > b.title)? 1:-1);

export const SFDC_OPS_LEADERS = ([{"title":"Allen, Ross"},{"title":"Dannegger, Brad"},{"title":"Dormeier, Josh"},{"title":"Epperson, Colin"},{"title":"Fleps, Eric"},{"title":"Garrett, Brett"},{"title":"Gaw, Mike"},{"title":"Glover, Andrew"},{"title":"Guth, Rick"},{"title":"Heaney, Trevor"},{"title":"Hearld, Justin"},{"title":"Hidder, Patrick"},{"title":"Jones, Tyler"},{"title":"Krol, Bill"},{"title":"Ladowski, Lauren"},{"title":"Mata, Elliot"},{"title":"McLaughlin, Jason"},{"title":"Meissner, Jeff"},{"title":"Niedhammer, Chris"},{"title":"Palutsis, Eric"},{"title":"Pomerenke, Joe"},{"title":"Pratt, Daniel"},{"title":"Roche, John"},{"title":"Rook, Joe"},{"title":"Smiles, Andrew"},{"title":"Stellakis, Lee"},{"title":"Wagner, Daniel"},{"title":"Wagner, Paul"},{"title":"Zamparo, Rod"}]).sort((a,b) => (a.title > b.title)? 1:-1);

export const SFDC_COMPANIES = (
  [
    {
      "title": "AMN-IND-CHI"
    },
    {
      "title": "AMN-TS-CHI"
    },
    {
      "title": "AMN-MF-CHI"
    },
    {
      "title": "AMN-ENT"
    },
    {
      "title": "AMN-PS"
    },
    {
      "title": "AMN-ACE"
    },
    {
      "title": "AMN-AUS"
    },
    {
      "title": "AMN-IND-DAL"
    },
    {
      "title": "AMN-TS-DAL"
    },
    {
      "title": "AMN-SS"
    },
    {
      "title": "AMN-NAS"
    },
    {
      "title": "AMN-DEN"
    },
    {
      "title": "AMN-MF-DEN"
    },
    {
      "title": "AMN-NCAL"
    },
    {
      "title": "AMN-IND-MIN"
    },
    {
      "title": "AMN-MF-MIN"
    },
    {
      "title": "AMN-TS-MIN"
    },
    {
      "title": "AMN-MIA"
    },
    {
      "title": "AMN-MIL"
    },
    {
      "title": "AMN-BUF"
    },
    {
      "title": "AMN-BIR"
    },
    {
      "title": "AMN-HUN"
    },
    {
      "title": "AMN-TOR"
    },
    {
      "title": "AMN-MON"
    },
    {
      "title": "ARCOM"
    },
    {
      "title": "ADB"
    },
    {
      "title": "ADB-BTS-ATL"
    },
    {
      "title": "ADB-CLS-ATL"
    },
    {
      "title": "ADB-BAL"
    },
    {
      "title": "ADB-CBS"
    },
    {
      "title": "ADB-CHS"
    },
    {
      "title": "ADB-CLT"
    },
    {
      "title": "ADB-IND"
    },
    {
      "title": "ADB-HOU"
    },
    {
      "title": "ADB-JAX"
    },
    {
      "title": "ADB-LOU"
    },
    {
      "title": "ADB-MEM"
    },
    {
      "title": "ADB-NY"
    },
    {
      "title": "ADB-PHL"
    },
    {
      "title": "ADB-PITT"
    },
    {
      "title": "ADB-BTS-PHL"
    },
    {
      "title": "ADB-RAL"
    },
    {
      "title": "ADB-RICH"
    },
    {
      "title": "ADB-SAV"
    },
    {
      "title": "ADB-SLC"
    },
    {
      "title": "ADB-WIL"
    },
    {
      "title": "ACC"
    },
    {
      "title": "ACC-SLMF"
    },
    {
      "title": "ACC-PHX"
    },
    {
      "title": "ABG"
    },
    {
      "title": "ANC"
    },
    {
      "title": "ANCL"
    },
    {
      "title": "ANDT"
    },
    {
      "title": "ANKC"
    },
    {
      "title": "ANLG"
    },
    {
      "title": "ANNE"
    },
    {
      "title": "ANNW"
    },
    {
      "title": "ANOV"
    },
    {
      "title": "ANPC"
    },
    {
      "title": "ANH-PITT"
    },
    {
      "title": "AMCC"
    },
    {
      "title": "AMCC-O"
    },
    {
      "title": "AMCC-IA"
    },
    {
      "title": "AMCC-LG"
    },
    {
      "title": "FA Distributed"
    },
    {
      "title": "AM Mechanical"
    },
    {
      "title": "AM-Con"
    },
    {
      "title": "AME"
    },
    {
      "title": "ARCO Ventures"
    },
    {
      "title": "AMPS"
    },
    {
      "title": "BFF"
    },
    {
      "title": "BRC"
    },
    {
      "title": "BWTR"
    }
]).sort((a,b) => (a.title > b.title)? 1:-1);