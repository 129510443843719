import React, { Component } from 'react';
import { hot, setConfig } from 'react-hot-loader';

// Import custom components
import MainRouter from '../../routers/routes';

import LogRocket from 'logrocket';
LogRocket.init('xfdqze/pm-log');
LogRocket.identify('App', window.location.href.includes('lessons') ? 'Lessons Learned': 'Roster')

// Hiding an error in console log.
// https://stackoverflow.com/questions/54770535/react-hot-loader-react-dom-patch-is-not-detected
setConfig({
    showReactDomPatchNotification: false
})

class AppContainer extends Component {

    render() {
        return (<MainRouter />);
    }
}

export default hot(module)(AppContainer);
