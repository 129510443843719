import React, { Component } from 'react';
import Header from '../../components/pre-security/header';
import MainBanner from '../../components/pre-security/mainBanner';
import Footer from '../../components/pre-security/footer';


class LandingPageContainer extends Component {

    render() {
        return (
            <>
                {/* ***** Header Area ***** */}
                <Header />
                {/* ***** Main Banner Area ***** */}
                <MainBanner />
                {/* ***** Footer ***** */}
                <Footer />
            </>
        );
    }
}

export default LandingPageContainer;
