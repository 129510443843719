export const COLUMN_OPTIONS = ([
    {'title': 'SFDC Probability', 'column_id': 'sfdc_probability'}, 
    {'title': 'Account', 'column_id': 'sfdc_account_name'}, 
    {'title': 'Amount', 'column_id': 'sfdc_amount'}, 
    {'title': 'Cost Status Profit', 'column_id': 'cost_status_profit'}, 
    {'title': 'Est. Margin', 'column_id': 'est_margin'}, 
    {'title': 'SFDC Stage', 'column_id': 'sfdc_stage'}, 
    {'title': 'Ops Leaders', 'column_id': 'sfdc_ops_leader'}, 
    {'title': 'ARCO Division', 'column_id': 'sfdc_division'}, 
    {'title': 'ARCO Company', 'column_id': 'sfdc_company'}, 
    {'title': 'Project Type', 'column_id': 'sfdc_project_type'},
    {'title': 'Location', 'column_id': 'location'},
    {'title': 'Revenue Last Year', 'column_id': 'sfdc_revenue_last_year'},
    {'title': 'Revenue This Year', 'column_id': 'sfdc_revenue_this_year'},
    {'title': 'Revenue Next Year', 'column_id': 'sfdc_revenue_next_year'},
    {'title': 'Revenue 2+ Years', 'column_id': 'sfdc_revenue_two_years'},
  ]).sort((a,b) => (a.title > b.title)? 1:-1);

export const DEFAULT_SHOW_COLUMNS = ['sfdc_account_name','sfdc_amount', 'sfdc_stage','sfdc_project_type','location'];
