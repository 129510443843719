import React from 'react';
import ReactDOM from 'react-dom';
import { useAuth } from './useAuth';

export default function AuthenticationErrorModal({ error }) {
    const { logout } = useAuth();
    const Modal = (
        <div id="modal-parent" style={modalParentStyle}>
            <div id="modal" style={modalStyle}>
                <h4 id="modal-title" style={modalTitleStyle}>Oops! An authentication error has occurred.</h4>
                <p id="modal-message" style={modalMessageStyle}>Please log out then log in again.</p>
                <button id="modal-button" style={modalButtonStyle} onClick={logout}>Log out</button>
                <p id="modal-error" style={modalErrorStyle}>{error}</p>
            </div>
        </div>
    )
    return Modal
}

const modalParentStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: 21474836470
}

const modalStyle = {
    height: 'clamp(20vh, 40vh, 90vh)',
    maxWidth: '90vw',
    backgroundColor: 'white',
    position: 'relative',
    display: 'flex',
    gap: '.1em',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4em',
    borderRadius: '1em',
    boxShadow: '0 0 10px 5px rgba(0,0,0,0.1)',
}

const modalTitleStyle = {
    fontSize: '2em',
    margin: 0,
}

const modalMessageStyle = {
    fontSize: '1.2em',
    // italic
    fontStyle: 'italic',
    margin: 0,
}

const modalErrorStyle = {
    fontSize: '.9em',
    color: 'gray',
    fontStyle: 'italic',
    position: 'absolute',
    bottom: '1em',

}

const modalButtonStyle = {
    fontSize: '1.5em',
    // bold
    fontWeight: 'bold',
    borderRadius: '.5em',
    padding: '.5em 1em',
    border: 'none',
    backgroundColor: 'lightgray',
    cursor: 'pointer',
}