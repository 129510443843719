import React, { useEffect, useState, createContext, useContext } from "react";
import Cookies from 'js-cookie';
import { 
    REFRESH_URL,
    AUTH_COOKIE_NAME,
    getSignInUrl,
    __DEV__,
    getLogoutUrl
} from "./constant";
import axios from "axios";
import AuthenticationErrorBoundary from "./fallback";
import AuthenticationErrorModal from "./modal";

/* ProjectManagerLog Authentication Handler
 *
 * This file contains the authentication handler for the ProjectManagerLog
 * application. It uses an internal state to store the authentication object
 * and provides functions to login, logout, and check authentication.
 * 
 * Typical flow: 
 * 1. User clicks login button
 * 2. User is redirected to login page
 * 3. User logs in
 * 4. User is redirected to the application callback URL with the token and profile
 * 5. The callback URL parses the token and profile and calls the loginCallback function
 * 6. The loginCallback function sets the authentication object
 * 7. The authentication object is stored in the browser cookies
 * 8. The user profile is stored in the browser local storage
 * 9. User may request the access token from the authentication object
 * 10. User may request the user profile from the local storage
 * */
const HOUR_IN_MS = 1000 * 60 * 60;

function _useAuth(options = {
    refreshEvery: HOUR_IN_MS /* null (on call), -1 (never, on start), or number of milliseconds (every) */,
    refreshOnFocus: false /* true (refresh on focus), false (do not refresh on focus) */,
    handleOwnError: true /* true (handle own error), false (do not handle own error) */,
    redirectPath: "/auth/callback" /* string (redirect path) */,
    // ! could lead to a loop
    updateCookieIfServerSaysAuthenticated: false /* true (update cookie if server says authenticated), false (do not update cookie if server says authenticated) */
}) {
    // Authentication object
    const [authObject, setAuthObject] = useState(() => {

        /* If there is no cookie OR the cookie is empty, then we are not authenticated
         * If there is a cookie, and it is not empty, then we are authenticated but we should
         * check by making a call in useEffect
         * */

        // Build an authentication object
        let authObject = {
            authState: 'Unauthenticated',
            expiresOn: null,
            tokenType: null,
            profile: null
        };

        const cookieContent = Cookies.get(AUTH_COOKIE_NAME);
        if (cookieContent === undefined || cookieContent === "") {
            // No cookie, so we are not authenticated
        } else {
            // We have a cookie, so we are authenticated
            try {
                const cookieObject = JSON.parse(cookieContent);
                authObject = {
                    ...authObject,
                    ...cookieObject
                }
            } catch (e) {
                // Invalid cookie, so we are not authenticated
                console.error("Invalid cookie produced error: ", e);
            }
        }
        
        return authObject;
    });
    
    useEffect(() => {
        window.addEventListener('focus', () => {
            if (options.refreshOnFocus) {
                checkAuthenticationStatus();
            }
        });
        return () => {
            window.removeEventListener('focus', () => { 
                if (options.refreshOnFocus) {
                    checkAuthenticationStatus();
                }
            });
        }
    }, []);

    const [error, setError] = useState/*<string | null>*/(null);

    const _getDefaultRedirectUrl = () => {
        /* Get the default redirect URL
         * if we are on roster.arcomurray.com/project-view, then we want to redirect to
         * https://roster.arcomurray.com/auth/callback
         * This fails for localhost since it does not capture the port number
         * so we need to check for localhost and return http://localhost:3000/auth/callback
         * */
        if (window.location.href.includes('localhost')) {
            return "http://localhost:3000/auth/callback";
        }
        // Assume "/auth/callback" is the redirect URL if no other redirect URL is specified
        return window.location.protocol + "//" + window.location.hostname + options.redirectPath;
    }

    const checkAuthenticationStatus = async () => {
        /* Check the authentication status
         * The concept here is to deal with inconsistencies between the cookie and the server
         * If the cookie says we are authenticated, but the server says we are not, then we
         * should clear the cookie and redirect to the login page, but we do not have control
         * over the cookie, so we need to make a call to the server to update the cookie
         * */
        let response;
        try {
            response = await axios.get(REFRESH_URL, { 
                withCredentials: true
            });
        } catch (e) {
            // An error occurred
            setError("Checking user authentication - " + String(e));
            return;
        }
        const json_data = response.data;
        const { profile, authState } = json_data;

        if (authState === 'Authenticated') {
            localStorage.setItem('ll_admin', profile.ll_admin);
        }

        if (authState === 'Authenticated' && authObject.authState !== 'Authenticated') {
            // We are authenticated, so navigate to the redirect URL
            if (options.updateCookieIfServerSaysAuthenticated) {
                // Update the cookie (by asking the server to do it)
                login(_getDefaultRedirectUrl());
            }
        } else if (authState !== 'Authenticated' && authObject.authState === 'Authenticated') {
            // We are not authenticated, so clear the cookie and redirect to the login page
            setError("Your session has timed out. Please log in again.");
        } else {
            return;
        }
    }

    useEffect(() => {
        // Ask the server if we are authenticated
        if (!window.location.href.includes('/phd')) {
            checkAuthenticationStatus();
            let interval;
            if (options.refreshEvery === null) {
                // Do nothing
            } else if (options.refreshEvery === -1) {
                // Refresh on start
            } else {
                // Refresh every n milliseconds
                interval = setInterval(() => {
                    // Background daemon to refresh the authentication status
                    checkAuthenticationStatus();
                }, options.refreshEvery);
            }

            return () => {
                clearInterval(interval);
            }
        }
    }, []);

    const [loading, setLoading] = useState(false);
    const authenticated = authObject?.authState === 'Authenticated';
    const profile = authObject?.profile;  

    const login = (redirect = "") => {
        if (redirect === "") {
            redirect = _getDefaultRedirectUrl();
        }
        const url = getSignInUrl(redirect);
        window.location.href = url;
    }

    const logout = (redirect = "") => {
        setAuthObject({
            ...authObject,
            authState: 'Unauthenticated',
        });
        if (redirect === "") {
            redirect = _getDefaultRedirectUrl();
        }
        const url = getLogoutUrl(redirect);
        window.location.href = url;
    }

 
    const getAccessTokenSilently = async () => {
        options.refreshEvery === null && await checkAuthenticationStatus();
        return "NO BEARER TOKEN";
    }

    return {
        authenticated,
        isAuthenticated: authenticated, // For compatibility with react-auth-kit and react-auth0-wrapper
        profile,
        user: profile, // For compatibility with react-auth-kit and react-auth0-wrapper
        login,
        logout,
        checkAuthenticationStatus,
        getAccessTokenSilently,
        loading,
        isLoading: loading, // For compatibility with react-auth0-wrapper
        error,
        handleOwnError: options.handleOwnError
    }
}

// Create a context for the auth object
// useAuth is read-only, so we only want user to invoke useAuth when they need to
const AuthContext = createContext({
    authenticated: false,
    isAuthenticated: false,
    profile: null,
    user: null,
    login: () => {},
    logout: () => {},
    checkAuthenticationStatus: () => {},
    getAccessTokenSilently: () => {},
    loading: false,
    isLoading: false,
    error: null,
    handleOwnError: true
});

// Create a provider for auth context
export function AuthProvider({ children }) {
    const auth = _useAuth();
    return (
        <AuthenticationErrorBoundary>
            <AuthContext.Provider value={auth}>
                {auth.error && auth.handleOwnError && <AuthenticationErrorModal error={auth.error} />}
                {children}
            </AuthContext.Provider>
        </AuthenticationErrorBoundary>
    )
}

export function useAuth() {
    return useContext(AuthContext);
}
