import React from "react";

import { Card, CardContent, Typography } from "@material-ui/core";

const formatDollar = (value) => {
    const roundedValue = Math.ceil(value);
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(roundedValue);
}

const JTDCosts = ({ budgetData }) => {

return (
    <div>
        {budgetData && Object.keys(budgetData).length > 0 ? (
            <Typography style={{ textAlign:'center'}}variant="body1" className='main-key-project-info-body'>
                {budgetData?.current_contract_summary?.jtd_costs !== undefined ? formatDollar(budgetData.current_contract_summary?.jtd_costs)
                : '--'}
            </Typography>
        ) : (
            <Typography style={{textAlign: 'center'}}className="main-key-project-info-body">--</Typography>
        )}
    </div>
    );
}

export default JTDCosts;