import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, Button} from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { SIGN_IN_URL } from '../../auth/constant';
import { useAuth } from '../../auth/useAuth';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
        display: 'inline',
    },
    text: {
        color: 'rgba(0,0,0,0.9)',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none',
        },
    },
    active: {
        backgroundColor: '#d8d8d8',
        borderRadius: '3px',
    },
    button: {
        padding: theme.spacing(0, 2),
    },
    sectionDesktop: {
        display: 'none',
        color: '#d8d8d8',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        color: '#d8d8d8',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
}));

const Header = () => {
    const classes = useStyles();
    const {
        isAuthenticated, 
        user, 
        isLoading, 
        login,
        getAccessTokenSilently
    } = useAuth();

    return (
        <div className={classes.root}>
            <AppBar color="default" position="fixed">
                <Toolbar>
                    {window.location.href.includes('lessons') && (
                        <>
                            <Typography variant="h6" className={classes.title}>
                                <NavLink to="/" className={classes.text}>
                                    ARCO Lessons Learned
                                </NavLink>
                            </Typography>
                        </>
                    )}
                    {!window.location.href.includes('lessons') && (
                        <>
                            <Typography variant="h6" className={classes.title}>
                                <NavLink to="/" className={classes.text}>
                                    ARCO Roster
                                </NavLink>
                            </Typography>
                        </>
                    )}
                    <div className={classes.sectionDesktop}>
                        {!isAuthenticated && (
                            <Button className={classes.button} color="default" onClick={() => login(window.location.href.includes('lessons') ? "https://lessons.arcomurray.com/ll/view" : "")}>
                                Sign In
                            </Button>
                        )}
                        {isAuthenticated && (
                            <NavLink to="/project-view" className={classes.text}>
                                <Button className={classes.button} color="default">
                                    Go To Roster
                                </Button>
                            </NavLink>
                        )}
                    </div>
                    <div className={classes.sectionMobile}>
                        {!isAuthenticated && (
                            <Button className={classes.button} color="default" onClick={() => login(window.location.href.includes('lessons') ? "https://lessons.arcomurray.com/ll/view" : "")}>
                                Sign In
                            </Button>
                        )}
                        {isAuthenticated && (
                            <NavLink to="/project-view" className={classes.text}>
                                <Button className={classes.button} color="default">
                                    Go To Roster
                                </Button>
                            </NavLink>
                        )}
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    );
};

export default Header;
