import React, {useState} from 'react';
import {CardContent, Grid, Typography, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import IconInfo from '@mui/icons-material/Info';
import { makeStyles } from '@material-ui/core/styles';
import { formatDollar, formatDollarShort, getColorBasedOnValue, extractNumericValue } from './utilityFunctions';

// Styles for the component
const useStyles = makeStyles((theme) => ({
    fieldValue: {
      marginBottom: theme.spacing(1),
    },
    gridContainer: {
      height: '100%',
    },
    gridItem: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
    },
  }));
  
  const FinancialSummaryComponent = (projectData) => {
    const classes = useStyles();
    const project = projectData?.projectData || projectData;
    // console.log("Project Info:")
    // console.log(project)
    const baselineContractData = project?.baseline_contract_summary
    const penultimateComparisonData = project?.penultimate_contract_summary;
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleDialogOpen = () => {
      setDialogOpen(true);
    };
    const handleDialogClose = () => {
      setDialogOpen(false);
    };

    // Calculate Profit Value
    const currentProfitValue = (typeof baselineContractData?.current_profit_percentage === 'number' && typeof baselineContractData?.revised_contract_value === 'number')
    ? (baselineContractData.current_profit_percentage / 100) * baselineContractData.revised_contract_value
    : null;

    // Calculate Profit Percentage Change


    // Calc Owner Contract Value Change
    const ownerContractChangeValue = baselineContractData?.revised_contract_value - baselineContractData?.original_contract_value;

    // Contract Data
    const cleanedbaselineContractData = {
      revisedOwnerContract: formatDollar(baselineContractData?.revised_contract_value) || '--',
      profitValue: '$100,000',
      profitPercentage: typeof baselineContractData?.current_profit_percentage === 'number' 
      ? baselineContractData.current_profit_percentage.toFixed(2)
      : '--',
      ownerContractChange: typeof ownerContractChangeValue === 'number' && !isNaN(ownerContractChangeValue)
        ? formatDollarShort(ownerContractChangeValue)
        : '--',
      profitPercentageChange: '+25',
      jtdCosts: typeof baselineContractData?.jtd_costs === 'number'
      ? baselineContractData.jtd_costs : '--',
    };

    const cleanedComparisonData = {
      profitPercentageChangeByMonth: typeof penultimateComparisonData?.current_profit_percentage_change === 'number' ? penultimateComparisonData.current_profit_percentage_change.toFixed(2) : '--',
      amountChangeRevisedContract: typeof penultimateComparisonData?.revised_contract_value_change === 'number' ? penultimateComparisonData.revised_contract_value_change.toFixed(2) : '--',
      costsChangeAmount: typeof penultimateComparisonData?.total_estimated_costs_change === 'number' ? penultimateComparisonData.total_estimated_costs_change : '--',

    }

    const amountChangeColor = getColorBasedOnValue(
      formatDollar(cleanedComparisonData.amountChangeRevisedContract)
    );
    const costsChangeColor = getColorBasedOnValue(
      formatDollar(cleanedComparisonData.costsChangeAmount)
    );
    const profitPercentageChangeColor = getColorBasedOnValue(
      `${cleanedComparisonData.profitPercentageChangeByMonth}%`
    );

    const amountChangeValue = extractNumericValue(
      cleanedComparisonData.amountChangeRevisedContract
    );
    const profitPercentageChangeValue = extractNumericValue(
      cleanedComparisonData.profitPercentageChangeByMonth
    );
  
    return (
      <CardContent style={{ height: '100%', position: 'relative' }}>
            <IconButton
          style={{ position: 'absolute', top: -10, right: -10 }}
          onClick={handleDialogOpen}
        >
          < IconInfo />
        </IconButton>
        <Grid container spacing={3} className={classes.gridContainer}>
          {/* Column 1 */}
          <Grid item xs={12} md={6} className={classes.gridItem}>
            <div>
              <Typography className="card-title" style={{textAlign: 'center'}}>Owner Contract</Typography>
              <Typography className="main-key-project-info-body" style={{textAlign: 'center'}}>
              <span style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '2px'}}>
                <div>{cleanedbaselineContractData.revisedOwnerContract}</div>
                
                {amountChangeValue !== null && amountChangeValue !== 0 && (
                  <div
                    style={{
                      fontSize: 'small',
                      marginTop: '-5px',
                      color: amountChangeColor,
                    }}
                  >
                    ({formatDollar(cleanedComparisonData.amountChangeRevisedContract)})
                  </div>
                )}
                </span>
              </Typography>
            </div>
            <div>
              <Typography className="card-title" style={{textAlign: 'center'}}>JTD Costs</Typography>
              <Typography className="main-key-project-info-body" style={{textAlign: 'center'}}>
                
                <span style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '2px'}}>
                  <div>{formatDollar(cleanedbaselineContractData.jtdCosts)}</div>

                  {amountChangeValue !== null && amountChangeValue !== 0 && (
                  <div
                    style={{
                      fontSize: 'small',
                      marginTop: '-5px',
                      color: amountChangeColor,
                    }}
                  >
                    ({formatDollar(cleanedComparisonData.costsChangeAmount)})
                  </div>
                )}
                </span>
              </Typography>
            </div>
          </Grid>
  
          {/* Column 2 */}
          <Grid item xs={12} md={6} className={classes.gridItem}>
            <div>
              <Typography className="card-title" style={{textAlign: 'center'}}>Profit Value</Typography>
              <Typography className="main-key-project-info-body" style={{textAlign: 'center'}}>
                {formatDollar(currentProfitValue)}
              </Typography>
            </div>
            <div>
              <Typography className="card-title" style={{textAlign: 'center'}}>Current Profit %</Typography>
              <Typography className="main-key-project-info-body" style={{textAlign: 'center'}}>
                <span style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '2px'}}>
                  <div>{cleanedbaselineContractData.profitPercentage}%</div>
                  {profitPercentageChangeValue !== null && profitPercentageChangeValue !== 0 && (
                  <div
                    style={{
                      fontSize: 'small',
                      marginTop: '-5px',
                      color: profitPercentageChangeColor,
                    }}
                  >
                    ({cleanedComparisonData.profitPercentageChangeByMonth}%)
                  </div>
                )}
                </span>
              </Typography>
            </div>
          </Grid>
        </Grid>

        <Dialog open={dialogOpen} onClose={handleDialogClose}>
          <DialogTitle>Financial Summary Information</DialogTitle>
          <DialogContentText component="div" style={{marginLeft: '20px', marginRight: '20px'}}>
            <p>
              This financial summary provides an overview of the project's financial metrics.
            </p><p>
              <strong>Note: Changes denoted are currently configured to reflect updates from the previous month's budget snapshot.</strong>
            </p>
          </DialogContentText>
          <DialogActions>
              <Button onClick={handleDialogClose} color="primary">
                  Close
              </Button>
          </DialogActions>
        </Dialog>
      </CardContent>
    );
  };
  
  export default FinancialSummaryComponent;