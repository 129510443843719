import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        minHeight: '100vh',
        background: 'linear-gradient(90deg, #1CB5E0 0%, #000851 100%), #F18D2B',
        mixBlendMode: 'normal',
        position: 'relative',
        height: '100%',
    },
    
    textWrapper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: '#fff',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    text1: {
        fontFamily: 'Roboto',
        fontWeight: 200,
        fontStyle: 'normal',
        fontSize: '60px',
        lineHeight: '60px',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '42px',
        },
        paddingLeft: '1rem',
    },
    text2: {
        fontFamily: 'Roboto',
        fontWeight: 100,
        fontSize: '35px',
        textAlign: 'center',
        paddingBottom: '3px',
    },
    icon: {
        display: 'inline-block',
        verticalAlign: 'middle',
        fontSize: '20px',
    },
}));

const MainBanner = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.textWrapper}>
                {window.location.href.includes('lessons') && (
                    <>
                        <h3 className={classes.text1}>ARCO Lessons Learned</h3>
                        <h4 className={classes.text2}>&nbsp;
                            {/* Learn and Share Lessons from ARCO Jobsites! */}
                        </h4>
                    </>
                )}
                {!window.location.href.includes('lessons') && (
                    <>
                        <h3 className={classes.text1}>ARCO Roster</h3>
                        <h4 className={classes.text2}>
                            Manage your project teams intelligently!
                        </h4>
                    </>
                )}
            </div>
        </div>
    );
};

export default MainBanner;
