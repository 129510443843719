import React from "react";

import { CardContent, Typography } from "@material-ui/core";
import { ResponsiveRadialBar } from '@nivo/radial-bar';

const ProjectGrade = ({ selectedProject }) => {
    const projectGrade = 80; // Replace with custom algo
    const angle = (projectGrade / 100) * 90; 

    const data = [
        {
            id: 'Grade',
            data: [
                {
                    x: 'Project Grade',
                    y: projectGrade
                }
            ]
        }
    ];

    return (
        <CardContent style={{ position: 'relative' }}>
            <div className="red-datawarehouse-banner">
                Awaiting ABS Data Warehouse
            </div>
        <div style={{ height: '300px', position: 'relative' }}>
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '80%', height: '80%', borderRadius: '50%', border: '2px solid #333', backgroundColor: '#ccc', zIndex: -1 }}></div>
            <ResponsiveRadialBar
                data={data}
                keys={['y']}
                minValue={0}
                maxValue={100}
                innerRadius={0.5}
                padding={0.1}
                colors={['#4CAF50']}
                arcBackground="#EAEAEA"
                startAngle={-90}
                endAngle={90}
                margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
                radialAxisStart={null}
                circularAxisOuter={null}
                enableRadialGrid={false}
                enableCircularGrid={false}
                barAriaLabel={e => e.id + ': ' + projectGrade + '%'}
                isInteractive={false}
                enableLabels={true}
            />
            {/* <p>Project Grade = {projectGrade}%</p> */}
        </div>
    </CardContent>
    );
};

export default ProjectGrade;