import React from 'react';

import { makeStyles, Paper, ClickAwayListener, Typography, Button} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { hexToRgb, colourIsLight } from './utils'


const useStyles = makeStyles(theme => ({
    root: {
        minWidth: '60vw',
        minHeight: '50vh',
        padding: '10px',
        // zIndex: 9,
        // color: '#d8d8d8'
    },
    button: {
        minWidth: '8rem',
    },
    grid_container: {
        display: 'grid',
        'grid-template-columns': '30rem 30rem',
        gridGap: '10px',
    },
    grid_cell: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    project_button: {
        minWidth: '20rem',
        backgroundColor:"#093D61", 
        color: '#ffffff',
        borderBottomWidth: '20px',
    },
}));


const ColorLegend = (props) => {
    let {handleToggleColorLegend} = props;
    const classes = useStyles();

    return (
        <div>
            <ClickAwayListener onClickAway={handleToggleColorLegend}>
                <Paper className={classes.root}>
                    <div style={{float: 'right'}}>
                        <CloseIcon style={{position: 'relative', top:'7px'}} onClick={handleToggleColorLegend}/>
                    </div>
                    <Typography variant='h5'>PM View - Color Legend</Typography>
                    <div>
                        <br />
                        <div>
                            <Typography variant='h6'>Workload Summary:</Typography>
                            &nbsp;&nbsp;
                            <Button variant="contained" className={classes.button} style={{backgroundColor:"#00AEEF", color: colourIsLight(...hexToRgb('#00AEEF')) ? '#000000' : '#ffffff'}}>0-0.25</Button>
                            &nbsp;&nbsp;
                            <Button variant="contained" className={classes.button} style={{backgroundColor:"#29ABE2", color: colourIsLight(...hexToRgb('#29ABE2')) ? '#000000' : '#ffffff'}}>0.25-0.5</Button>
                            &nbsp;&nbsp;
                            <Button variant="contained" className={classes.button} style={{backgroundColor:"#0B7CB0", color: colourIsLight(...hexToRgb('#0B7CB0')) ? '#000000' : '#ffffff'}}>0.5-0.75</Button>
                            &nbsp;&nbsp;
                            <Button variant="contained" className={classes.button} style={{backgroundColor:"#093D61", color: colourIsLight(...hexToRgb('#093D61')) ? '#000000' : '#ffffff'}}>0.75-1</Button>
                            &nbsp;&nbsp;
                            <Button variant="contained" className={classes.button} style={{backgroundColor:"#FAA31B", color: colourIsLight(...hexToRgb('#FAA31B')) ? '#000000' : '#ffffff'}}>1-2</Button>
                            &nbsp;&nbsp;
                            <Button variant="contained" className={classes.button} style={{backgroundColor:"#E66025", color: colourIsLight(...hexToRgb('#E66025')) ? '#000000' : '#ffffff'}}>2-5</Button>
                            &nbsp;&nbsp;
                            <Button variant="contained" className={classes.button} style={{backgroundColor:"#9F1D20", color: colourIsLight(...hexToRgb('#9F1D20')) ? '#000000' : '#ffffff'}}>5+</Button>
                        </div>
                        <Typography variant='h7'>The number in the box indicates multiple of full time equivalent (FTE) workload</Typography>
                        <br />
                        <br />
                        <Typography variant='h6'>Project Bar:</Typography>
                        <div class={classes.grid_container}>
                            <div class={classes.grid_cell}>
                                <Typography variant='h7'>Project Stage: Targeting/Prospecting/Proposing/Potential Work - 10%</Typography>
                            </div>
                            <div>
                                <Button variant="outlined" className={classes.project_button} style={{
                                    borderBottomColor: "#FAA31B",    
                                }}
                                >
                                        Project Name
                                        
                                </Button>
                            </div>    
                            <div class={classes.grid_cell}>
                                <Typography variant='h7'>Project Stage: Potential Work - 50%/75%</Typography>
                            </div>
                            <div>    
                                <Button variant="outlined" className={classes.project_button} style={{
                                    borderBottomColor: "#00AEEF",    
                                }}
                                >
                                        Project Name
                                </Button>
                            </div>
                            <div class={classes.grid_cell}>
                                <Typography variant='h7'>Project Stage: Potential Work - 95%/Work in Progress/Close-out Pending</Typography>
                            </div>
                            <div>
                                <Button variant="outlined" className={classes.project_button} style={{
                                    borderBottomColor: "#0B7CB0",    
                                }}
                                >
                                        Project Name
                                </Button>
                            </div>
                        </div>
                        <br />
                        <Typography variant='h7'>The thickness of the colored bar indicates the assigned workload. Full thickness = 100% assignment</Typography>
                        <br />
                        
                    </div>
                </Paper>
            </ClickAwayListener>
        </div>
    )
};

export default ColorLegend;