export const MIN_ZOOM = 1
export const DEFAULT_ZOOM = 4
export const MAX_ZOOM = 7

export const START_YEAR = 2021
export const NUM_OF_YEARS = 4
export const MONTH_NAMES = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
export const MONTHS_PER_YEAR = 12
export const QUARTERS_PER_YEAR = 4
export const MONTHS_PER_QUARTER = 3
export const NUM_OF_MONTHS = NUM_OF_YEARS * MONTHS_PER_YEAR

export const MAX_TRACK_START_GAP = 4
export const MAX_ELEMENT_GAP = 8

export const MAX_MONTH_SPAN = 8
export const MIN_MONTH_SPAN = 2

export const MONTH_START_END_DATES = () => {
    const MONTH_START_ENDS = [
        {start: '01-01', end: '01-31'},
        {start: '02-01', end: '02-28'},
        {start: '03-01', end: '03-31'},
        {start: '04-01', end: '04-30'},
        {start: '05-01', end: '05-31'},
        {start: '06-01', end: '06-30'},
        {start: '07-01', end: '07-31'},
        {start: '08-01', end: '08-31'},
        {start: '09-01', end: '09-30'},
        {start: '10-01', end: '10-31'},
        {start: '11-01', end: '11-30'},
        {start: '12-01', end: '12-31'}
    ];
    let NEW = [];
    for (let i = START_YEAR; i<(START_YEAR+NUM_OF_YEARS); i++) {
        MONTH_START_ENDS.forEach(e => {
            let a = {start: i.toString() +'-' + e.start, end: i.toString() +'-' + e.end, title: ''}
          NEW.push(a);
        })
    }

    return(NEW)
}

// export const PHASES = [
//     {name: "Real Estate", color: '#E0B1CB', seq: 1},
//     {name: "ARCO", color: '#BE95C4', seq: 2},
//     {name: "Interior Design", color: '#AF8EC2', seq: 3},
//     {name: "Construction Documents", color: '#9F86C0', seq: 4},
//     {name: "Permitting", color: '#7F6DA7', seq: 5},
//     {name: "Construction", color: '#5E548E', seq: 6},
//     {name: "Training", color: '#413768', seq: 7},
// ]

export const PHASES = [
    {name: "Real Estate", color: '#FF671D', seq: 1},
    {name: "ARCO", color: '#FF9E18', seq: 2},
    {name: "Interior Design", color: '#E96BB0', seq: 3},
    {name: "Construction Documents", color: '#F45197', seq: 4},
    {name: "Preconstruction", color: '#93358D', seq: 5},
    {name: "Construction", color: '#592C82', seq: 6},
    {name: "Opening", color: '#IC355E', seq: 7},
]
