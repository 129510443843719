import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField } from '@mui/material';

const ProjectSelect = ({filteredProjects, selectedProject, handleProjectSelectChange, setSelectedProjectId, csrfToken, isCompletedChecked }) => {
  const [selectedOption, setSelectedOption] = useState('');
  const [displayedProjects, setDisplayedProjects] = useState(filteredProjects);

  useEffect(() => {
    if (selectedProject) {
      setSelectedOption(selectedProject.name);
    } else {
      setSelectedOption('');
    }
  }, [selectedProject]);

  useEffect(() => {
    filterProjects();
  }, [isCompletedChecked, filteredProjects]);

  const handleChange = (event, newValue) => {
    const newSelectedOption = newValue ? newValue.name : '';
    setSelectedOption(newSelectedOption);
    handleProjectSelectChange(newSelectedOption);

    if (newValue) {
      setSelectedProjectId(newValue.id);
    }
  };

  const sortAlphaNumbersLast = (a, b) => {
    const isANumber = /^\d/.test(a.name);
    const isBNumber = /^\d/.test(b.name);

    if (isANumber && !isBNumber) return 1;
    if (!isANumber && isBNumber) return -1;

    return a.name.localeCompare(b.name);
  };

  const filterProjects = () => {
    let filteredProjectList = [...filteredProjects];

    if (isCompletedChecked) {
      filteredProjectList = filteredProjects.filter(
        (project) => project.stage_group !== 'Complete'
      );
    }

    filteredProjectList.sort(sortAlphaNumbersLast);

    setDisplayedProjects(filteredProjectList);
  };

  return (
    <div className="select-dropdown-container">
      <Autocomplete
        value={
          selectedOption
            ? displayedProjects.find(
                (project) => project.name === selectedOption
              ) || null
            : null
        }
        onChange={handleChange}
        options={displayedProjects}
        getOptionLabel={(option) =>
          `${option.project_number} - ${option.name}`
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Project"
            variant="outlined"
            fullWidth
            sx={{
              '& .MuiInputBase-input': {
                color: 'white',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'white',
                },
                '&:hover fieldset': {
                  borderColor: 'white',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'white',
                },
              },
              '& .MuiInputLabel-root': {
                color: 'white',
                '&.Mui-focused': {
                  color: 'white',
                },
              },
              '& .MuiAutocomplete-endAdornment .MuiSvgIcon-root': {
                color: 'white',
              },
            }}
          />
        )}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        noOptionsText="No projects found. Only projects at a stage of 95% and above in Salesforce are included in this tool."
      />
    </div>
  );
};

export default ProjectSelect;
