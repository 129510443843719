import React from 'react';
import { NavLink } from 'react-router-dom';

import { makeStyles, Drawer, Toolbar, List, ClickAwayListener, ListItem, Checkbox, TextField, Switch, Typography} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CssBaseline from '@material-ui/core/CssBaseline';
import clsx from 'clsx';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

// Icons
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

// Location
import LocationCityIcon from '@material-ui/icons/LocationCity';
// Divisions
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
// Company
import BusinessIcon from '@material-ui/icons/Business';
// Supervisor
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
// SFDC Stages
import ClearAllIcon from '@material-ui/icons/ClearAll';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

// Account 
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';


const checkBoxOutlineIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkBoxIcon = <CheckBoxIcon fontSize="small" />;

const NavLinkMui = React.forwardRef((props, ref) => {
    const classes = useStyles();
    return <NavLink {...props} ref={ref} activeClassName={classes.active} />;
});

const useStyles = makeStyles(theme => ({
    root: {
        // display: 'flex',
        zIndex: 9,
        color: '#d8d8d8'
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: 200,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: 400,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(7) + 1,
        },
    },
    active: {
        backgroundColor: '#d8d8d8',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(6, 1, 0, 0),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    multicheckboxFilter: {
        width: '300px',
        // paddingBottom: '1rem',
        display: 'inline-block',
    },
}));

const PMSidebar = (props) => {
    let {loading, filters, divisions_pm_list, supervisors_pm_list, locations_pm_list, companies_job_list, divisions_job_list, accounts_job_list, stages_job_list, updateFilters} = props;
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <>
        <div className={classes.root}>
            <CssBaseline />
            <Drawer
                anchor={'right'}
                variant="permanent"
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <ClickAwayListener onClickAway={handleDrawerClose}>
                    <Toolbar className={classes.toolbar}>
                        <List>
                            {/* Filters By PMs */}                            
                            <br></br>
                            <ListItem
                                button
                                key={'text222'}
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                className={clsx({
                                    [classes.hide]: open,
                                })}
                            >
                                <ListItemIcon>
                                    <ChevronLeftIcon />
                                </ListItemIcon>
                            </ListItem>
                            <ListItem
                                button
                                key={'text212'}
                                aria-label="open drawer"
                                onClick={handleDrawerClose}
                                className={clsx({
                                    [classes.hide]: !open,
                                })}
                            >
                                <ListItemIcon>
                                    <ChevronRightIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Filters by PMs'} />
                            </ListItem>
                            
                            {/* Supervisors */}
                            <ListItem
                                key={'supervisors'}
                                className={classes.sublist}
                            >
                                <ListItemIcon>
                                    <SupervisorAccountIcon onClick={handleDrawerOpen} />
                                </ListItemIcon>
                                {loading ? (<><br /><br />Loading...<br /><br /></>) : (
                                    <>
                                        <div className={classes.multicheckboxFilter}>
                                            <Autocomplete 
                                            multiple 
                                            id="supervisors-filter" 
                                            options={supervisors_pm_list} 
                                            disableCloseOnSelect
                                            defaultValue={() => {
                                                let values = [];
                                                filters.supervisors_pm.forEach(e => {
                                                    values.push({title: e})
                                                })
                                                return (values)
                                            }}  
                                            getOptionLabel={(option) => option.title}
                                            getOptionSelected={(option) => filters['supervisors_pm'].includes(option.title)}
                                            onChange={(e, value) => {
                                                // filters = (Object.assign({}, filters, {'divisions': value.map(a => a.title)}));
                                                updateFilters('supervisors_pm',value.map(a => a.title));
                                            }}
                                            renderOption={(option, { selected }) => (
                                                <React.Fragment>
                                                <Checkbox
                                                    icon={checkBoxOutlineIcon}
                                                    checkedIcon={checkBoxIcon}
                                                    style={{marginRight: 8}}
                                                    checked={selected}
                                                />
                                                {option.title}
                                                </React.Fragment>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Filter by Supervisors"
                                                placeholder="Supervisors"
                                                helperText="Filters based on PM's Supervisor"
                                                />
                                            )} 
                                            />
                                        </div>
                                    </>
                                )}
                            </ListItem>                            

                            {/* Offices */}
                            <ListItem
                                key={'locations'}
                                className={classes.sublist}
                            >
                                <ListItemIcon>
                                    <LocationCityIcon onClick={handleDrawerOpen}/>
                                </ListItemIcon>
                                {loading ? (<><br /><br />Loading...<br /><br /></>) : (
                                    <>
                                        <div className={classes.multicheckboxFilter}>
                                            <Autocomplete 
                                            multiple 
                                            id="locations-filter" 
                                            options={locations_pm_list} 
                                            disableCloseOnSelect
                                            defaultValue={() => {
                                                let values = [];
                                                filters.locations_pm.forEach(e => {
                                                    values.push({title: e})
                                                })
                                                return (values)
                                            }}  
                                            getOptionLabel={(option) => option.title}
                                            getOptionSelected={(option) => filters['locations_pm'].includes(option.title)}
                                            onChange={(e, value) => {
                                                // filters = (Object.assign({}, filters, {'divisions': value.map(a => a.title)}));
                                                updateFilters('locations_pm',value.map(a => a.title));
                                            }}
                                            renderOption={(option, { selected }) => (
                                                <React.Fragment>
                                                <Checkbox
                                                    icon={checkBoxOutlineIcon}
                                                    checkedIcon={checkBoxIcon}
                                                    style={{marginRight: 8}}
                                                    checked={selected}
                                                />
                                                {option.title}
                                                </React.Fragment>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Filter by Offices"
                                                placeholder="Offices"
                                                helperText="Filters based on PM's Office"
                                                />
                                            )} 
                                            />
                                        </div>
                                    </>
                                )}
                            </ListItem>                            

                            {/* Divisions */}
                            <ListItem
                                key={'divisions_pm'}
                                className={classes.sublist}
                            >
                                <ListItemIcon>
                                    <BubbleChartIcon 
                                        onClick={handleDrawerOpen}
                                    />
                                </ListItemIcon>
                                {loading ? (<><br /><br />Loading...<br /><br /></>) : (
                                    <>
                                        <div className={classes.multicheckboxFilter}>
                                            <Autocomplete 
                                            multiple 
                                            id="divisions-filter" 
                                            options={divisions_pm_list} 
                                            disableCloseOnSelect
                                            defaultValue={() => {
                                                let values = [];
                                                filters.divisions_pm.forEach(e => {
                                                    values.push({title: e})
                                                })
                                                return (values)
                                            }}  
                                            getOptionLabel={(option) => option.title}
                                            getOptionSelected={(option) => filters['divisions_pm'].includes(option.title)}
                                            onChange={(e, value) => {
                                                // filters = (Object.assign({}, filters, {'divisions': value.map(a => a.title)}));
                                                updateFilters('divisions_pm',value.map(a => a.title));
                                            }}
                                            renderOption={(option, { selected }) => (
                                                <React.Fragment>
                                                <Checkbox
                                                    icon={checkBoxOutlineIcon}
                                                    checkedIcon={checkBoxIcon}
                                                    style={{marginRight: 8}}
                                                    checked={selected}
                                                />
                                                {option.title}
                                                </React.Fragment>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Filter by Divisions"
                                                placeholder="Divisions"
                                                helperText="Filters based on PM's assigned Division"
                                                />
                                            )} 
                                    />
                                </div>
                                    </>
                                )}
                            </ListItem>                            


                            {/* Filters By Jobs */}                            
                            <br></br>
                            <ListItem
                                button
                                key={'text111'}
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                className={clsx({
                                    [classes.hide]: open,
                                })}
                            >
                                <ListItemIcon>
                                    <ChevronLeftIcon />
                                </ListItemIcon>
                            </ListItem>

                            <ListItem
                                button
                                key={'text2111'}
                                aria-label="open drawer"
                                onClick={handleDrawerClose}
                                className={clsx({
                                    [classes.hide]: !open,
                                })}
                            >
                                <ListItemIcon>
                                    <ChevronRightIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Filters by Jobs'} />
                            </ListItem>

                            {/* SFDC Stages */}
                            <ListItem
                                key={'stages'}
                                className={classes.sublist}
                            >
                                <ListItemIcon>
                                    <ClearAllIcon onClick={handleDrawerOpen} />
                                </ListItemIcon>
                                {loading ? (<><br /><br />Loading...<br /><br /></>) : (
                                <div className={classes.multicheckboxFilter}>
                                    <Autocomplete 
                                    multiple 
                                    id="stages-filter" 
                                    options={stages_job_list} 
                                    disableCloseOnSelect 
                                    defaultValue={() => {
                                        let values = [];
                                        filters.stages_job.forEach(e => {
                                            values.push({title: e})
                                        })
                                        return (values)
                                    }}  
                                    getOptionLabel={(option) => option.title}
                                    getOptionSelected={(option) => filters['stages_job'].includes(option.title)}
                                    onChange={(e, value) => {
                                        filters = (Object.assign({}, filters, {'stages_job': value.map(a => a.title)}));
                                        updateFilters('stages_job',value.map(a => a.title));
                                    }}
                                    renderOption={(option, { selected }) => (
                                        <React.Fragment>
                                        <Checkbox
                                            icon={checkBoxOutlineIcon}
                                            checkedIcon={checkBoxIcon}
                                            style={{marginRight: 8}}
                                            checked={selected}
                                        />
                                        {option.title}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Filter by SFDC Stages"
                                        placeholder=""
                                        helperText="Filters based on Jobs"
                                        />
                                    )} 
                                    />
                                </div>
                                )}
                            </ListItem>                            

                            {/* Companies */}
                            <ListItem
                                key={'companies'}
                                className={classes.sublist}
                            >
                                <ListItemIcon>
                                    <BusinessIcon onClick={handleDrawerOpen} />
                                </ListItemIcon>
                                {loading ? (<><br /><br />Loading...<br /><br /></>) : (
                                    <>
                                        <div className={classes.multicheckboxFilter}>
                                            <Autocomplete 
                                            multiple 
                                            id="companies-filter" 
                                            options={companies_job_list} 
                                            disableCloseOnSelect 
                                            defaultValue={() => {
                                                let values = [];
                                                filters.companies_job.forEach(e => {
                                                    values.push({title: e})
                                                })
                                                return (values)
                                            }}  
                                            getOptionLabel={(option) => option.title}
                                            getOptionSelected={(option) => filters['companies_job'].includes(option.title)}
                                            onChange={(e, value) => {
                                                // filters = (Object.assign({}, filters, {'divisions': value.map(a => a.title)}));
                                                updateFilters('companies_job',value.map(a => a.title));
                                            }}
                                            renderOption={(option, { selected }) => (
                                                <React.Fragment>
                                                <Checkbox
                                                    icon={checkBoxOutlineIcon}
                                                    checkedIcon={checkBoxIcon}
                                                    style={{marginRight: 8}}
                                                    checked={selected}
                                                />
                                                {option.title}
                                                </React.Fragment>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Filter by Companies"
                                                placeholder="Companies"
                                                helperText="Filters based on Job's assigned Company"
                                                />
                                            )} 
                                            />
                                        </div>
                                    </>
                                )}
                            </ListItem>                            

                            {/* Divisions */}
                            <ListItem
                                key={'divisions'}
                                className={classes.sublist}
                            >
                                <ListItemIcon>
                                    <BubbleChartIcon 
                                        onClick={handleDrawerOpen}
                                    />
                                </ListItemIcon>
                                {loading ? (<><br /><br />Loading...<br /><br /></>) : (
                                    <>
                                        <div className={classes.multicheckboxFilter}>
                                            <Autocomplete 
                                            multiple 
                                            id="divisions-filter" 
                                            options={divisions_job_list} 
                                            disableCloseOnSelect
                                            defaultValue={() => {
                                                let values = [];
                                                filters.divisions_job.forEach(e => {
                                                    values.push({title: e})
                                                })
                                                return (values)
                                            }}  
                                            getOptionLabel={(option) => option.title}
                                            getOptionSelected={(option) => filters['divisions_job'].includes(option.title)}
                                            onChange={(e, value) => {
                                                // filters = (Object.assign({}, filters, {'divisions': value.map(a => a.title)}));
                                                updateFilters('divisions_job',value.map(a => a.title));
                                            }}
                                            renderOption={(option, { selected }) => (
                                                <React.Fragment>
                                                <Checkbox
                                                    icon={checkBoxOutlineIcon}
                                                    checkedIcon={checkBoxIcon}
                                                    style={{marginRight: 8}}
                                                    checked={selected}
                                                />
                                                {option.title}
                                                </React.Fragment>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Filter by Divisions"
                                                placeholder="Divisions"
                                                helperText="Filters based on Job's assigned Division"
                                                />
                                            )} 
                                    />
                                </div>
                                    </>
                                )}
                            </ListItem>                            

                            {/* Accounts */}
                            <ListItem
                                key={'accounts'}
                                className={classes.sublist}
                            >
                                <ListItemIcon>
                                    <AccountBalanceIcon 
                                        onClick={handleDrawerOpen}
                                    />
                                </ListItemIcon>
                                {loading ? (<><br /><br />Loading...<br /><br /></>) : (
                                    <>
                                        <div className={classes.multicheckboxFilter}>
                                            <Autocomplete 
                                            multiple 
                                            id="accounts-filter" 
                                            options={accounts_job_list} 
                                            disableCloseOnSelect
                                            defaultValue={() => {
                                                let values = [];
                                                filters.accounts_job.forEach(e => {
                                                    values.push({title: e})
                                                })
                                                return (values)
                                            }}  
                                            getOptionLabel={(option) => option.title}
                                            getOptionSelected={(option) => filters['accounts_job'].includes(option.title)}
                                            onChange={(e, value) => {
                                                updateFilters('accounts_job',value.map(a => a.title));
                                            }}
                                            renderOption={(option, { selected }) => (
                                                <React.Fragment>
                                                <Checkbox
                                                    icon={checkBoxOutlineIcon}
                                                    checkedIcon={checkBoxIcon}
                                                    style={{marginRight: 8}}
                                                    checked={selected}
                                                />
                                                    {option.title}
                                                </React.Fragment>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Filter by Accounts"
                                                placeholder="Accounts"
                                                helperText="Filters based on Job's assigned Account"
                                                />
                                            )} 
                                    />
                                </div>
                                    </>
                                )}
                            </ListItem>                            


                            {/* Additional Settings */}                            
                            <ListItem
                                button
                                key={'text2424111'}
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                className={clsx({
                                    [classes.hide]: open,
                                })}
                            >
                                <ListItemIcon>
                                    <ChevronLeftIcon />
                                </ListItemIcon>
                            </ListItem>

                            <ListItem
                                button
                                key={'text211244'}
                                aria-label="open drawer"
                                onClick={handleDrawerClose}
                                className={clsx({
                                    [classes.hide]: !open,
                                })}
                            >
                                <ListItemIcon>
                                    <ChevronRightIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Additional Settings'} />
                            </ListItem>

                            {/* Use Custom List */}
                            <ListItem
                                key={'use_custom_list'}
                                className={classes.sublist}
                            >
                                <ListItemIcon>
                                    <BubbleChartIcon 
                                        onClick={handleDrawerOpen}
                                    />
                                </ListItemIcon>
                                {loading ? (<><br /><br />Loading...<br /><br /></>) : (
                                    <>
                                        <Switch
                                            checked={filters.use_custom_list}
                                            onChange={() => updateFilters('use_custom_list',!filters.use_custom_list)}
                                            >
                                        </Switch>
                                        <Typography>
                                            Use Custom List of PMs
                                        </Typography>
                                    </>
                                )}
                            </ListItem>                            
                            <br />
                            {/* Link to Edit Custom List */}
                            <ListItem
                                button
                                key={'edit_custom_list'}
                                className={classes.sublist}
                                component={NavLinkMui}
                                to="/custom-settings"
                            >
                                <ListItemIcon>
                                    <BubbleChartIcon 
                                        onClick={handleDrawerOpen}
                                    />
                                </ListItemIcon>
                                <>
                                    <Typography>
                                        Edit Custom List
                                    </Typography>
                                    &nbsp;
                                    <OpenInNewIcon />
                                </>
                            </ListItem>                            

                        </List>
                    </Toolbar>
                </ClickAwayListener>
            </Drawer>
        </div>
        </>
    );
    // }
};

export default PMSidebar;
