import React, { Fragment } from 'react';

// Import routing components
import { BrowserRouter, Route, Switch } from 'react-router-dom';

// Import custom components
// import MainLayout from '../components/common/layout/MainLayout';
import NotFound from '../components/error/NotFound';

import PublicRoute from './PublicRoute'; // Pre auth routes
import FullyPublicRoute from './FullyPublicRoute'; // Pre auth routes
import PrivateRoute from './PrivateRoute'; // If not auth, re-route to '/'

// Pre-Security
import LandingPageCont from '../containers/pre-security/LandingPageContainer';
import Vis2dCont from '../containers/pre-security/Vis2dContainer';
import Deliveries from '../containers/pre-security/Deliveries';
import PHD from '../containers/pre-security/PHD';
import DashboardScheduleViewer from '../containers/pre-security/DashboardScheduleViewer';
import TvScreenContainer from '../containers/pre-security/TvScreenContainer';

// Post-Security
// Roster
import PMsCont from '../containers/post-security/PMsContainer';
import ProjectsViewCont from '../containers/post-security/ProjectsViewContainer';
import PAsCont from '../containers/post-security/PAsContainer';
import SupersCont from '../containers/post-security/SupersContainer';
import SettingsCont from '../containers/post-security/SettingsContainer';

// Lessons Learned
import LLViewContent from '../ll/containers/post-security/activity-view/LLViewContent';
import LessonsView from '../ll/containers/post-security/lessons-view/LessonsView';
import NewLessonView from '../ll/containers/post-security/new-lesson/NewLesson';
import AuthCallback from '../components/auth/authCallback';


const Router = () => {
    return (
        <Fragment>
                <BrowserRouter>
                    <Switch>
                        <PublicRoute path="/vis2d/:long_bay_spacing/:long_bay_spacing_details/:short_bay_spacing_details/:short_bay_spacing_details" component={Vis2dCont} />
                        <PublicRoute exact path="/" component={LandingPageCont} />
                        <PublicRoute exact path="/dashboard_schedule_viewer/:company_slug?" component={DashboardScheduleViewer} />
                        <FullyPublicRoute exact path="/banner" component={Deliveries} />
                        <FullyPublicRoute exact path="/westseattle" component={Deliveries} />
                        <FullyPublicRoute exact path="/phd" component={PHD} />
                        {/* <FullyPublicRoute exact path="/englewood" component={Deliveries} />
                        <FullyPublicRoute exact path="/aspgren" component={Deliveries} />
                        <FullyPublicRoute exact path="/tgburlingame" component={Deliveries} />
                        <FullyPublicRoute exact path="/atf" component={Deliveries} />
                        <FullyPublicRoute exact path="/tv-screen" component={TvScreenContainer} /> */}

                        <PublicRoute exact path="/auth/callback" component={AuthCallback} />       
                        
                        <PrivateRoute exact path="/pm-view" component={PMsCont} />
                        <PrivateRoute exact path="/project-view" component={ProjectsViewCont} />
                        {/* <PrivateRoute exact path="/pa-view" component={PAsCont} /> */}
                        <PrivateRoute exact path="/super-view" component={SupersCont} />
                        <PrivateRoute exact path="/custom-settings" component={SettingsCont} />

                        <PrivateRoute exact path="/ll/view/:lesson_id?" component={LLViewContent} />
                        <PrivateRoute exact path="/ll/lessons" component={LessonsView} />
                        <PrivateRoute exact path="/ll/new-lesson/:lesson_id?" component={NewLessonView} />

                        <Route component={NotFound} />

                    </Switch>
                </BrowserRouter>
        </Fragment>
    )
};

export default Router;