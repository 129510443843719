import React from 'react';

import { makeStyles, List, ListItem, ListItemText, IconButton, Tooltip } from '@material-ui/core';

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

// Styles for overall page
const useStyles = makeStyles(theme => ({
    list: {
      alignItems: 'center',
      // padding: theme.spacing(6, 1, 0, 0),
      border: 'solid 2px',
      minWidth: '25rem',
      maxWidth: '25rem',
      maxHeight: '30rem',
      minHeight: '30rem',
      overflow: 'auto',
    },
    list_item: {
      paddingLeft: '5px',
      display: 'flex',
      minHeight: '2.5rem',
      borderBottom: 'solid 1px',
    }
}));
 
//Function that pulls the data and sends it to the Table Renderer
const List1 = props => {
    let {loading, customList, moveUp, moveDown, remove} = props;
    const classes = useStyles();
    return (
        <>
          {loading ? (
            <>
            </>
          ) : (
              <List className={classes.list} key={props.customList}>
                {props.customList.map(elem => {
                  return(
                    <>
                      <ListItem key={elem.id} class={classes.list_item}>
                        <ListItemText primary={elem.name} />
                        <Tooltip title="Move Up">
                          <IconButton color="none" onClick={() => moveUp(elem.id)}><ArrowUpwardIcon /></IconButton>
                        </Tooltip>
                        <Tooltip title="Move Down">
                        <IconButton color="none" onClick={() => moveDown(elem.id)}><ArrowDownwardIcon /></IconButton>
                        </Tooltip>
                        <Tooltip title="Remove from List">
                        <IconButton color="none" onClick={() => remove(elem.id)}><RemoveCircleIcon /></IconButton>
                        </Tooltip>
                      </ListItem>
                    </>
                  )
                })}
              </List>
          )}
        </>
    );
};


export default List1;