import React, { useState } from 'react';

import LessonsListFilters from './lessons_list_filters';
import _ from 'lodash';

import {makeStyles, Paper, Button, useTheme, useMediaQuery } from '@material-ui/core';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterAltIcon from '@mui/icons-material/FilterAlt';


// Styles for overall page
const useStyles = makeStyles(theme => ({
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    paper: {
        minWidth: '28vw',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        overflowX: 'hidden',
    },    
    new_lesson: {
        padding: '0.5rem',
        display: 'flex'
    },
    filter_button: {
        backgroundColor: '#0B7CB0',
        color: 'white',
        minWidth: '20vw',
        height: '2.5rem',
        padding: '0.5rem',
        minWidth: '5vw',
        margin: 'auto',
    },
    text: {
        color: 'white',
        textDecoration: 'none',
        padding: theme.spacing(0, 1.5),
    },
    new_lesson_button: {
        backgroundColor: '#0B7CB0',
        color: 'white',
        minWidth: '20vw',
        height: '2.5rem',
        // justifyContent: "flex-start"
    },
}))

const SearchAndFilter = props => {
    let { toggleLessonSearch, updateLLViewSettings, ll_settings } = props;
    const classes = useStyles();

    const [showFilters, setShowFilters] = useState(false);
    const toggleShowFilters = (value=showFilters) => {
        setShowFilters(!value)
    }

    const [anchorEl, setAnchorEl] = React.useState(null);

    const theme = useTheme();
    let mode = useMediaQuery(theme.breakpoints.up('sm')) ? 'desktop':'mobile'


    return (
        <>
            {(mode === 'desktop') && (
                <Paper className={classes.paper}>
                    <div className={classes.new_lesson}>
                        <Button 
                            variant="contained" 
                            className={classes.new_lesson_button} onClick={() => toggleLessonSearch(true)}>
                            <SearchIcon />
                            Search
                        </Button>
                        <Button 
                            variant="contained"
                            className={classes.filter_button} 
                            onClick={(event) => {
                                toggleShowFilters()
                                setAnchorEl(event.currentTarget)
                            }}
                            size='small'
                        >
                            <FilterListIcon />
                            Filter
                        </Button>
                        {showFilters && (
                            <LessonsListFilters 
                                showFilters={showFilters}
                                toggleShowFilters={toggleShowFilters}
                                ll_settings={ll_settings} 
                                updateLLViewSettings={updateLLViewSettings}
                                anchorEl={anchorEl}                        
                            />
                        )}
                    </div>                
                </Paper>
            )}
            {(mode === 'mobile') && (
                <div style={{display: 'flex'}}>
                        <div 
                            onClick={(event) => {
                                toggleShowFilters()
                                setAnchorEl(event.currentTarget)
                            }}

                            className={classes.text}
                        >
                            <FilterAltIcon />
                        </div>
                        <div 
                            className={classes.text}
                            onClick={() => toggleLessonSearch(true)}
                        >
                            <SearchIcon />
                        </div>
                        {showFilters && (
                            <LessonsListFilters 
                                showFilters={showFilters}
                                toggleShowFilters={toggleShowFilters}
                                ll_settings={ll_settings} 
                                updateLLViewSettings={updateLLViewSettings}
                                anchorEl={anchorEl}                        
                            />
                        )}
                </div>                
            )}

        </>
    )

}


export default SearchAndFilter;