export const MIN_ZOOM = 2
export const DEFAULT_ZOOM = 1
export const MAX_ZOOM = 7

export const START_YEAR = 2024
export const NUM_OF_YEARS = 3
export const MONTH_NAMES = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
export const MONTHS_PER_YEAR = 12
export const QUARTERS_PER_YEAR = 4
export const MONTHS_PER_QUARTER = 3
export const NUM_OF_MONTHS = NUM_OF_YEARS * MONTHS_PER_YEAR

export const START_UP_QTY = 0.4
export const CLOSE_OUT_QTY = 0.3


export const MAX_TRACK_START_GAP = 4
export const MAX_ELEMENT_GAP = 8

export const MAX_MONTH_SPAN = 8
export const MIN_MONTH_SPAN = 2

export const requirement_types = [
    {'db_name': 'pm_leader', 'name': 'PM Leader'},
    {'db_name': 'additional_pm', 'name': 'Additional PM'},
    {'db_name': 'pm_design', 'name': 'PM Design'},
    {'db_name': 'pm_precon', 'name': 'PM Precon'},
    {'db_name': 'pm_permitting', 'name': 'PM Permitting'},
    {'db_name': 'pm_proposal', 'name': 'PM Proposing'},
    {'db_name': 'pm_buyout', 'name': 'PM Buyout/GMAX'},
    {'db_name': 'project_assistant', 'name': 'PA'},
    {'db_name': 'superintendent', 'name': 'Super'},
];

export const REQUIREMENT_TYPE = (requirement_type) => {
    return requirement_types.find(e => e.db_name === requirement_type).name
} 

export const MONTH_START_END_DATES = () => {
    const MONTH_START_ENDS = [
        {start: '01-01', end: '01-31'},
        {start: '02-01', end: '02-28'},
        {start: '03-01', end: '03-31'},
        {start: '04-01', end: '04-30'},
        {start: '05-01', end: '05-31'},
        {start: '06-01', end: '06-30'},
        {start: '07-01', end: '07-31'},
        {start: '08-01', end: '08-31'},
        {start: '09-01', end: '09-30'},
        {start: '10-01', end: '10-31'},
        {start: '11-01', end: '11-30'},
        {start: '12-01', end: '12-31'}
    ];
    let NEW = [];
    for (let i = START_YEAR; i<(START_YEAR+NUM_OF_YEARS); i++) {
        MONTH_START_ENDS.forEach(e => {
            let a = {start: i.toString() +'-' + e.start, end: i.toString() +'-' + e.end, amount: 0}
          NEW.push(a);
        })
    }

    return(NEW)
}
