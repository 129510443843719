import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsiveLine } from '@nivo/line';
import { Typography } from '@material-ui/core';
import { formatDollar, formatDollarShort, formatDollarFull } from './utilityFunctions';

const ARCOContingencyChart = ({ budgetData, showAllContData }) => {
  if (!budgetData || !budgetData.contingency_data || budgetData.contingency_data.length === 0 ) {
    return <Typography style={{ textAlign: 'center' }}>--</Typography>;
  }

  // const testData =
  //   [
  //     {
  //         "contingency": 1404051,
  //         "snapshot_id": 3155,
  //         "% of contract": 1.8613599932968763,
  //         "snapshot_date": "2024-07",
  //         "arco_contingency": 1404051,
  //         "trade_contingency": 1900000,
  //         "allocated_contingency": 0
  //     },
  //     {
  //         "contingency": 1404051,
  //         "snapshot_id": 3159,
  //         "% of contract": 1.8669978067517894,
  //         "snapshot_date": "2024-06",
  //         "arco_contingency": 1404051,
  //         "trade_contingency": 130000,
  //         "allocated_contingency": 0
  //     },
  //     {
  //         "contingency": 1404051,
  //         "snapshot_id": 3162,
  //         "% of contract": 1.8669978067517894,
  //         "snapshot_date": "2024-05",
  //         "arco_contingency": 1404051,
  //         "trade_contingency": 1404051,
  //         "allocated_contingency": 0
  //     },
  //     {
  //         "contingency": 1404051,
  //         "snapshot_id": 3164,
  //         "% of contract": 1.866205204626182,
  //         "snapshot_date": "2024-04",
  //         "arco_contingency": 1404051,
  //         "trade_contingency": 1404051,
  //         "allocated_contingency": 0
  //     },
  //     {
  //         "contingency": 1404051,
  //         "snapshot_id": 3166,
  //         "% of contract": 1.8873323390626406,
  //         "snapshot_date": "2024-03",
  //         "arco_contingency": 1404051,
  //         "trade_contingency": 1404051,
  //         "allocated_contingency": 0
  //     },
  //     {
  //         "contingency": 1404051,
  //         "snapshot_id": 3169,
  //         "% of contract": 1.8903543073576576,
  //         "snapshot_date": "2024-02",
  //         "arco_contingency": 1404051,
  //         "trade_contingency": 0,
  //         "allocated_contingency": 0
  //     },
  //     {
  //         "contingency": 1372881,
  //         "snapshot_id": 3171,
  //         "% of contract": 1.848388350451293,
  //         "snapshot_date": "2024-01",
  //         "arco_contingency": 1372881,
  //         "trade_contingency": 0,
  //         "allocated_contingency": 0
  //     },
  //     {
  //         "contingency": 1372881,
  //         "snapshot_id": 3172,
  //         "% of contract": 1.8433357971613662,
  //         "snapshot_date": "2023-12",
  //         "arco_contingency": 1372881,
  //         "trade_contingency": 0,
  //         "allocated_contingency": 0
  //     },
  //     {
  //         "contingency": 1404051,
  //         "snapshot_id": 3173,
  //         "% of contract": 1.8836245833209535,
  //         "snapshot_date": "2023-11",
  //         "arco_contingency": 1404051,
  //         "trade_contingency": 0,
  //         "allocated_contingency": 0
  //     },
  //     {
  //         "contingency": 1404051,
  //         "snapshot_id": 3174,
  //         "% of contract": 1.8896562850205973,
  //         "snapshot_date": "2023-10",
  //         "arco_contingency": 1404051,
  //         "trade_contingency": 700000,
  //         "allocated_contingency": 0
  //     },
  //     {
  //         "contingency": 1404051,
  //         "snapshot_id": 3175,
  //         "% of contract": 1.8896368016660374,
  //         "snapshot_date": "2023-09",
  //         "arco_contingency": 1404051,
  //         "trade_contingency": 80000,
  //         "allocated_contingency": 0
  //     },
  //     {
  //         "contingency": 1404051,
  //         "snapshot_id": 3176,
  //         "% of contract": 1.8889512447373296,
  //         "snapshot_date": "2023-08",
  //         "arco_contingency": 1404051,
  //         "trade_contingency": 1204051,
  //         "allocated_contingency": 0
  //     },
  //     {
  //         "contingency": 1404051,
  //         "snapshot_id": 3177,
  //         "% of contract": 1.89307160420773,
  //         "snapshot_date": "2023-07",
  //         "arco_contingency": 1404051,
  //         "trade_contingency":  1204051,
  //         "allocated_contingency": 0
  //     },
  //     {
  //         "contingency": 1404051,
  //         "snapshot_id": 3178,
  //         "% of contract": 1.89307160420773,
  //         "snapshot_date": "2023-06",
  //         "arco_contingency": 1404051,
  //         "trade_contingency": 1204051,
  //         "allocated_contingency": 0
  //     },
  //     {
  //         "contingency": 1404051,
  //         "snapshot_id": 3179,
  //         "% of contract": 1.89307160420773,
  //         "snapshot_date": "2023-05",
  //         "arco_contingency": 1404051,
  //         "trade_contingency": 1404051,
  //         "allocated_contingency": 0
  //     },
  //     {
  //         "contingency": 1404051,
  //         "snapshot_id": 3180,
  //         "% of contract": 1.8937759497059434,
  //         "snapshot_date": "2023-04",
  //         "arco_contingency": 1404051,
  //         "trade_contingency": 230000,
  //         "allocated_contingency": 0
  //     },
  //     {
  //         "contingency": 1404051,
  //         "snapshot_id": 9647,
  //         "% of contract": 1.8604543496280583,
  //         "snapshot_date": "2024-08",
  //         "arco_contingency": 1404051,
  //         "trade_contingency": 2000,
  //         "allocated_contingency": 0
  //     },
  //     {
  //         "contingency": 1404051,
  //         "snapshot_id": 11273,
  //         "% of contract": 1.8527900889414657,
  //         "snapshot_date": "2024-09",
  //         "arco_contingency": 1404051,
  //         "trade_contingency": 2000,
  //         "allocated_contingency": 0
  //     }
  // ]


  // Prepare data for Nivo Bar Chart
  let chartData = budgetData.contingency_data
    .map((item) => ({
      snapshot_date: item.snapshot_date,
      arco_contingency: item.arco_contingency,
      trade_contingency: item.trade_contingency,
      percentage_of_contract: item['% of contract'],
    }))
    .sort((a, b) => new Date(a.snapshot_date) - new Date(b.snapshot_date));

  // If not showing all data, limit to last 6 months
  if (!showAllContData) {
    // Calculate date 6 months ago
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

    // Filter chartData to include only entries from the last 6 months
    chartData = chartData.filter(
      (item) => new Date(item.snapshot_date) >= sixMonthsAgo
    );
  }

  const lineData = [{
      id: 'Percentage of Contract',
      data: chartData.map((item) => ({
        x: item.snapshot_date,
        y: item.percentage_of_contract,
      })),
    }];

  // Setting the min and max percentages here
    const percentages = chartData.map((item) => item.percentage_of_contract);
    const minPercentage = Math.min(...percentages);
    const maxPercentage = Math.max(...percentages);

  // Subtract 3% from Lower, and add 1% to Max
  const adjustedMinPercentage = minPercentage - 3; 
  const adjustedMaxPercentage = maxPercentage + 1;

  // Ensure adjusted min does not go below zero 
    // NOTE: Add a separate case to handle multi-fam's loss leader jobs
  const finalMinPercentage = Math.max(adjustedMinPercentage, 0);


  // Adjust Left y-axis $ amounts 
  // const contingencies = chartData.map((item) => item.contingency);
  // const minContingency = Math.min(...contingencies);
  // const maxContingency = Math.max(...contingencies);

  // // Decrease Lower by 3%
  // const adjustedMinContingency = minContingency * 0.97; 
  // // Increase Upper by 1%
  // const adjustedMaxContingency = maxContingency * 1.01; 



  // Function to calculate tick values for the x-axis
  const getTickValues = (dataLength) => {
    if (dataLength <= 10) {
      // Show all tick values if 10 or fewer data points
      return chartData.map((item) => item.snapshot_date);
    } else {
      // Otherwise, show every nth tick to prevent overcrowding
      const interval = Math.ceil(dataLength / 10);
      return chartData
        .map((item, index) =>
          index % interval === 0 ? item.snapshot_date : null
        )
        .filter(Boolean);
    }
  };

  return (
    <div style={{ height: '500px', position: 'relative' }}>
      {/* Bar Chart */}
      <div style={{ height: '100%', position: 'absolute', width: '100%' }}>
        <ResponsiveBar
          data={chartData}
          keys={['arco_contingency', 'trade_contingency']}
          indexBy="snapshot_date"
          margin={{ top: 30, right: 70, bottom: 180, left: 80 }}
          padding={0.4}
          colors={['#083d61', '#54bef3']}
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          borderRadius={2}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -45,
            legend: 'Snapshot Date',
            legendPosition: 'middle',
            legendOffset: 65,
            tickValues: getTickValues(chartData.length),
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Total Contingency ($)',
            legendPosition: 'middle',
            legendOffset: -72,
            format: (value) => formatDollarShort(value),
          }}
          labelSkipWidth={50}
          labelSkipHeight={35}
          label={(d) => formatDollarShort(d.value)}
          labelTextColor={{ from: 'color', modifiers: [['brighter', 10]] }}
          tooltip={({ indexValue, value, data }) => (
            <div style={{ padding: '5px', background: '#333', color: '#fff', borderRadius: '3px', fontFamily: 'Titillium Web, sans-serif' }}>
              <strong>Date: {indexValue}</strong>
              <br />
              Arco Contingency: {formatDollarFull(data.arco_contingency)}
              <br />
              Trade Contingency: {formatDollarFull(data.trade_contingency)}
              <br />
              % of Contract: {data.percentage_of_contract.toFixed(2)}%
            </div>
          )}
          xScale={{ type: 'point' }}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'top',
              direction: 'row',
              justify: false,
              translateX: 0,
              translateY: -35,
              itemsSpacing: 15,
              itemWidth: 140,
              itemHeight: 25,
              itemDirection: 'left-to-right',
              symbolSize: 15,
              fontSize: "20px",
              symbolShape: 'square',
              itemTextColor: '#000',
              data: [
                { id: 'arco_contingency', label: 'ARCO Contingency', color: '#083d61' },
                { id: 'trade_contingency', label: 'Subcontractor Contingency', color: '#54bef3'},
              ],
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemTextColor: '#333',
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          theme={{
            fontFamily: 'Titillium Web, sans-serif',
            legends: {
              text: {
                fontSize: 13,
                fontFamily: 'Titillium Web, sans-serif',
              }
            },
            labels: {
              text: {
                fontSize: 13.5,
                fill: '#000000',
                fontFamily: 'Titillium Web, sans-serif',
              },
            },
            axis: {
              ticks: {
                text: {
                  fontSize: 12,
                  fill: '#555555',
                  fontFamily: 'Titillium Web, sans-serif',
                },
              },
              legend: {
                text: {
                  fontSize: 17,
                  fill: '#333333',
                  fontFamily: 'Titillium Web, sans-serif',
                },
              },
            },
          }}
        />
      </div>

      {/* Line Chart Overlay */}
      <div style={{
          height: '100%',position: 'absolute', width: '100%', pointerEvents:'none',}}>
        <ResponsiveLine
          data={lineData}
          margin={{ top: 5, right: 85, bottom: 180, left: 95 }}
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear',
            min: finalMinPercentage,
            max: adjustedMaxPercentage,
            stacked: false,
            reverse: false,
          }}
          axisLeft={null}
          axisRight={{
            tickSize: 5,
            tickPadding: 5,
            legend: '% of Contract',
            legendPosition: 'middle',
            legendOffset: 55,
            format: (value) => `${value}%`,
          }}
          axisBottom={null}
          
          colors={['#54bef3']}
          pointSize={null}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          enablePoints={true}
          enableArea={false}
          enableSlices="x"
          // curve="monotoneX" -- Add back for smooth graph contour
          enableGridX={false}
          enableGridY={false}
          theme={{
            labels: {
              text: {
                fontSize: 13.5,
                fill: '#000000',
                fontFamily: 'Titillium Web, sans-serif',
              },
            },
            axis: {
              ticks: {
                text: {
                  fontSize: 12,
                  fill: '#555555',
                  fontFamily: 'Titillium Web, sans-serif',
                },
              },
              legend: {
                text: {
                  fontSize: 17,
                  fill: '#333333',
                  fontFamily: 'Titillium Web, sans-serif',
                },
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default ARCOContingencyChart;
