import React, { useImperativeHandle } from 'react';
import { useTable, useBlockLayout, useResizeColumns, useFilters, useGlobalFilter, useSortBy } from 'react-table';
import {TextField } from '@material-ui/core';

// Virtualisation of the table
import { FixedSizeList } from 'react-window';

const scrollbarWidth = () => {
    // thanks too https://davidwalsh.name/detect-scrollbar-width
    const scrollDiv = document.createElement('div')
    scrollDiv.setAttribute('style', 'width: 100px; height: 100px; overflow: scroll; position:absolute; top:-9999px;')
    document.body.appendChild(scrollDiv)
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth
    document.body.removeChild(scrollDiv)
    return scrollbarWidth
}

// Filters - THESE ARE NOT USED
// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter }
}, updateLLViewSettings) {
  // const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {

        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      style={{fontFamily: 'Roboto'}}
      // placeholder={`Search ${count} records...`}
      placeholder={`Filter...`}
    />
  );
}

// Create a default prop getter
const defaultPropGetter = () => ({})


const Table = React.forwardRef(({ columns, data, hiddenColumns, getCellProps, updateLLViewSettings }, ref) => {
    // Use the state and functions returned from useTable to build your UI
    const scrollBarSize = React.useMemo(() => scrollbarWidth(), []);

    const filterTypes = React.useMemo(
      () => ({
        
        // Default
        text: (rows, id, filterValue) => {
          return rows.filter((row) => {
            const rowValue = row.values[id];
            return rowValue !== undefined
              ? String(rowValue)
                  .toLowerCase()
                  .startsWith(String(filterValue).toLowerCase())
              : true;
          });
        }
      }),
      []
    );
  
    // Compulsory to pass for React Table filtering
    const defaultColumn = React.useMemo(
      () => ({
        // Let's set up our default Filter UI
        Filter: DefaultColumnFilter
      }),
      []
    );
    
    const instance = useTable(
    {
        columns,
        data,
        defaultColumn,
        filterTypes,
        getCellProps: defaultPropGetter,
        initialState: { 
          hiddenColumns: hiddenColumns ? hiddenColumns : null, 
        },
    },
    useBlockLayout,
    useResizeColumns,
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    );

    const { 
      getTableProps, 
      getTableBodyProps, 
      headerGroups, 
      prepareRow, 
      rows,
      totalColumnsWidth,
      // preGlobalFilteredRows,
      // setGlobalFilter,
      // visibleColumns,
      // state, 
  } = instance;

  // return table instance
  useImperativeHandle(ref, () => instance);
    // Code for preparing each row to be rendered.
    const RenderRow = React.useCallback(
        ({ index, style }) => {
          const row = rows[index]
          prepareRow(row)
          return (
            <div
              {...row.getRowProps({
                style,
              })}
              className="tr"
            >
              {row.cells.map(cell => {
                return (
                  <div 
                  // {...row.getRowProps()}
                  {...cell.getCellProps([
                    {
                      style: cell.column.style,
                    },
                    getCellProps(cell),
                  ])}    
                  className="td"
                  >
                    {cell.render('Cell')}
                  </div>
                )
              })}
            </div>
          )
        },
        [prepareRow, rows]
    );

    // Render the UI for your table
    return (
        <>
            {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
            <div className="table">
              {/* ~~~~~Table Head~~~~~ */}

                <div {...getTableProps()}>
                    <div>
                        {headerGroups.map(headerGroup => (
                            <div className='tr' {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <div className="th" {...column.getHeaderProps()}>
                                      <div
                                        style={{display: 'flex', alignItems: 'center'}}
                                      >
                                        {column.render('Header')}
                                        &nbsp;&nbsp;&nbsp;
                                        <TextField id="outlined-basic" placeholder="Filter" variant="outlined" 
                                          style={{backgroundColor: 'white'}}
                                          size='small'
                                          InputLabelProps={{
                                            style: {
                                              height: 24,
                                              ...({ top: `-6px` }),
                                            },
                                          }}
                                          inputProps={{
                                            style: {
                                              height: 24,
                                              padding: '0 0px',
                                            },
                                        }}
                                        onChange={(e) => updateLLViewSettings('from_activity_search', e.target.value || null)}                                          
                                        />
                                        {/* {column.render("Filter")} */}
                                      </div>
                                      {/* <div className="resizer" {...column.getResizerProps()} /> */}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div> 
                </div>
                {/* ~~~~~Table Body~~~~~ */}
                <div {...getTableBodyProps()}>
                    <FixedSizeList
                        // height={Math.round(window.innerHeight)-16.8*parseInt(getComputedStyle(document.documentElement).fontSize)} // total height
                        height={Math.round(window.innerHeight)- ((window.innerHeight>window.innerWidth) ? 9: 5.5)*parseInt(getComputedStyle(document.documentElement).fontSize)} // total height
                        // basically height = 100vh - 16.5rem
                        itemCount={rows.length}
                        itemSize={35} //individual row height
                        width={totalColumnsWidth+scrollBarSize}
                    >
                        {RenderRow}
                    </FixedSizeList>
                </div>
            </div>
        </>
    );
});

export default Table;