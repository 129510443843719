/* eslint-disable no-bitwise */

import { MONTHS_PER_YEAR } from './constants'
import { SFDC_STAGE_OPTIONS } from '../../common/constants'

export const fill = n => {
  const arr = []
  for (let i = 0; i < n; i += 1) {
    arr.push(i)
  }
  return arr
}

const COLORS = ['FAA31B', 'E66025', 'E66025', 'E66025', '00AEEF', '00AEEF', '0B7CB0', '093D61', '093D61', '093D61']

export const randomColor = (stage) => COLORS[COLORS.length - 1 - SFDC_STAGE_OPTIONS.findIndex(item => item.title === stage)]

const COLORS_TRACK1 = ['FFBA08', 'FAA307', 'F48C06', 'E85D04', 'DC2F02', 'D00000', '6A040F','6A040F','6A040F','6A040F','6A040F']
const COLORS_GREEN_RED2 = ["358600","4ca419","63c132","7ace4f","fba04b","fc9c4b","fc974b","fc8e4a","fd7640","fd5d35",'fd5d35','fd5d35','fd5d35'];
const COLORS_WHITE_GREEN = ["ffffff","e7f8df","cff1be","b7ea9e","abe78e","9ee37d","81d258","63c132","52b11b","358600"];

export const randomColorTrack1 = (amount) => {
  if (amount <=0.25) {
    return '00AEEF'
  } else if (amount <=0.5) {
    return '29ABE2'
  } else if (amount <=0.75) {
    return '0B7CB0'
  } else if (amount <=1) {
    return '093D61'
  } else if (amount <=2) {
    return 'FAA31B'
  } else if (amount <=5) {
    return 'E66025'
  } else return '9F1D20'
}


export const hexToRgb = hex => {
  const v = parseInt(hex, 16)
  const r = (v >> 16) & 255
  const g = (v >> 8) & 255
  const b = v & 255
  return [r, g, b]
}

export const colourIsLight = (r, g, b) => {
  const a = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255
  return a < 0.5
}

export const addMonthsToYear = (year, monthsToAdd) => {
  let y = year
  let m = monthsToAdd
  while (m >= MONTHS_PER_YEAR) {
    m -= MONTHS_PER_YEAR
    y += 1
  }
  return { year: y, month: m + 1 }
}

export const addMonthsToYearAsDate = (year, monthsToAdd) => {
  const r = addMonthsToYear(year, monthsToAdd)
  return new Date(`${r.year}-${r.month}`)
}