import React, {useEffect, useState} from 'react';

import { makeStyles, Checkbox, Divider, List, ListItem, Typography} from '@material-ui/core';

import { LESSON_DIVISION_OPTIONS, LESSON_TAGS } from '../constants/constants';

const useStyles = makeStyles(theme => ({
    root: {
        fontFamily: 'Roboto',
        width: '15rem',
        borderLeft: '1px solid f8f8f8',
        backgroundColor: 'white',

    },
    listItem:{
        padding: '0px',
        '&:hover': {
            backgroundColor: '#ebf0f8'
        }
    }
}));

const LessonsSearchFilters = (props) => {
    let {lesson_search_filters, updateLLViewSettings, fetchSearchViewData} = props;
    const classes = useStyles();
    console.log(lesson_search_filters)

    return (
        <>
            <div className={classes.root}>
                <List id='sidebar'>
                    {LESSON_DIVISION_OPTIONS.map(e => {
                        return(
                            <>
                                <ListItem
                                    className={classes.listItem} 
                                    key={111+e.value}
                                    onClick={
                                        (z) => {
                                            lesson_search_filters.divisions.includes(e.value) ? updateLLViewSettings('divisions',lesson_search_filters.divisions.filter(d => d !== e.value)) : updateLLViewSettings('divisions',[...lesson_search_filters.divisions, e.value])
                                            if (fetchSearchViewData) {
                                                fetchSearchViewData();
                                            }
                                        }
                                    }
                                >
                                    <div key={e.value}>
                                    <Checkbox 
                                        checked={lesson_search_filters.divisions.includes(e.value)? true : false} 
                                    />
                                    {e.title}
                                    </div>
                                </ListItem>    
                            </>                        
                        )

                    })}
                    <Divider />
                    <ListItem key={211} className={classes.listItem} 
                        onClick={(z) => {
                            updateLLViewSettings('safety', !lesson_search_filters.safety)
                            if (fetchSearchViewData) {
                                fetchSearchViewData();
                            }
                        }}
                    >                            
                        <Checkbox checked={lesson_search_filters.safety}/>
                        Safety Lessons Only
                    </ListItem>    
                    <Divider />
                    <ListItem key={212} className={classes.listItem}
                        onClick={(z) => {
                            updateLLViewSettings('reviewed',!lesson_search_filters.reviewed)
                            if (fetchSearchViewData) {
                                fetchSearchViewData();
                            }
                        }}  
                    >
                        <Checkbox checked={lesson_search_filters.reviewed}/>
                        Reviewed Lessons Only
                    </ListItem>
                    {(localStorage.getItem('ll_admin')==='true' && (!fetchSearchViewData)) && (
                        <>
                            <ListItem key={214} className={classes.listItem}
                                onClick={(z) => {
                                    updateLLViewSettings('unreviewed',!lesson_search_filters.unreviewed)
                                    if (fetchSearchViewData) {
                                        fetchSearchViewData();
                                    }
                                }}  
                            >
                                <Checkbox checked={lesson_search_filters.unreviewed}/>
                                Unreviewed Lessons Only
                            </ListItem>    
                        </>
                    )}
                    {(localStorage.getItem('cea_mode')==='true' && (!fetchSearchViewData)) && (
                        <>
                        <Divider />
                        <Typography variant='subtitle1' style={{paddingLeft: '15px',paddingTop: '10px'}}>CEA Categories</Typography>
                        {LESSON_TAGS.map(e => (
                            <ListItem
                                className={classes.listItem} 
                                key={311+e.sorting_order}
                                onClick={
                                    (z) => {
                                        lesson_search_filters.tags.includes(e.value) ? updateLLViewSettings('tags',lesson_search_filters.tags.filter(d => d !== e.value)) : updateLLViewSettings('tags',[...lesson_search_filters.tags, e.value])
                                        if (fetchSearchViewData) {
                                            fetchSearchViewData();
                                        }
                                    }
                                }
                            >
                                <div key={411+e.sorting_order}>
                                    <Checkbox 
                                        checked={lesson_search_filters.tags.includes(e.value)? true : false} 
                                    />
                                    {e.title}
                                </div>
                            </ListItem>    
                        ))}
                        </>
                    )}
                </List>
            </div>
        </>
    );
    // }
};

export default LessonsSearchFilters;
