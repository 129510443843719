/* eslint-disable import/no-unresolved */
import React, { Component } from 'react';
import Timeline from './source/src/index.jsx';
import { withStyles } from '@material-ui/core';

import 'react-timelines/lib/css/style.css'

import { START_YEAR, NUM_OF_YEARS, MIN_ZOOM, MAX_ZOOM, DEFAULT_ZOOM } from './constants'

import { buildTimebar, buildTrack } from './builders'

import { fill } from './utils'


// Styles for overall page
const Styles = (theme) => ({
  app: {
    border: '1px solid black',
  },
});

const now = new Date()

const timebar = buildTimebar()

// eslint-disable-next-line no-alert
// const clickElement = element => alert(`Clicked element\n${JSON.stringify(element, null, 2)}`)

class ProjectDetailGantt extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      zoom: DEFAULT_ZOOM,
      // eslint-disable-next-line react/no-unused-state
    }
  }

  render() {  
    const tracksById = fill(this.props.data.length).reduce((acc, i) => {
      const track = buildTrack(i + 1, this.props.data[i], this.props.multiply_by_sfdc_probability)
      acc[track.id] = track
      return acc
    }, {});
    let tracks = Object.values(tracksById);
  
    const { classes } = this.props;
    const { zoom } = this.state
    const start = new Date(`${START_YEAR}`)
    const end = new Date(`${START_YEAR + NUM_OF_YEARS}`)
    return (
      <div className={classes.app}>
        {/* Timeline componenet has no subtracks  */}
        <Timeline
          scale={{
            start,
            end,
            zoom,
            zoomMin: MIN_ZOOM,
            zoomMax: MAX_ZOOM,
          }}
          // clickElement={clickElement}
          // clickTrackButton={track => {
          //   // eslint-disable-next-line no-alert
          //   alert(JSON.stringify(track))
          // }}
          timebar={timebar}
          tracks={tracks}
          now={now}
          enableSticky
          scrollToNow
          editMode={this.props.editMode}
          assignmentEdits={this.props.assignmentEdits}
          setAssignmentEdits={this.props.setAssignmentEdits}
          pmResourceNames={this.props.pmResourceNames}
          profile_data={this.props.profile_data}
        />
      </div>
    )
  }
}

export default withStyles(Styles)(ProjectDetailGantt)
