import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../auth/useAuth';

const PublicRoute = ({ component: Component, ...rest }) => {
    const { isAuthenticated } = useAuth();

    function containsAnyWord(string, wordArray) {
        for (let i = 0; i < wordArray.length; i++) {
            if (string.includes(wordArray[i])) {
                return true; // If any word is found, return true
            }
        }
        return false; // If no words are found, return false
    }

    if (containsAnyWord(rest['path'],['vis2d','englewood','aspgren','westseattle','tgburlingame','atf','dashboard_schedule_viewer','tv-screen','phd'])) {
        return (<Route {...rest} render={props => <Component {...props} />} />)
    } else {    
        if (isAuthenticated) {
            if (window.location.href.includes('lessons')) {
                return (<Redirect to="/ll/view" />)
            } else {
                // Roster Route
                return (<Redirect to="/project-view" />)
            }
        } else {
            return (<Route {...rest} render={props => <Component {...props} />} />)
        }    
    }
    }
export default PublicRoute;