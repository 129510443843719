/* eslint-disable no-bitwise */

import { MONTHS_PER_YEAR } from './constants'
import { SFDC_STAGE_OPTIONS } from '../../../common/constants'

export const fill = n => {
  const arr = []
  for (let i = 0; i < n; i += 1) {
    arr.push(i)
  }
  return arr
}

const COLORS = ['023E8A', '024D95', '015BA0', '0077B6', '0096C7', '00A5D0', '00B4D8', '48CAE4', '90E0EF', 'ADE8F4', 'CAF0F8']

export const randomColor = (stage) => COLORS[COLORS.length - 1 - SFDC_STAGE_OPTIONS.findIndex(item => item.title === stage)]

const COLORS_TRACK1 = ['FFBA08', 'FAA307', 'F48C06', 'E85D04', 'DC2F02', 'D00000', '6A040F','6A040F','6A040F','6A040F','6A040F']
export const randomColorTrack1 = (amount) => COLORS_TRACK1[Math.floor(amount)]


export const hexToRgb = hex => {
  const v = parseInt(hex, 16)
  const r = (v >> 16) & 255
  const g = (v >> 8) & 255
  const b = v & 255
  return [r, g, b]
}

export const colourIsLight = (r, g, b) => {
  const a = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255
  return a < 0.5
}

export const addMonthsToYear = (year, monthsToAdd) => {
  let y = year
  let m = monthsToAdd
  while (m >= MONTHS_PER_YEAR) {
    m -= MONTHS_PER_YEAR
    y += 1
  }
  return { year: y, month: m + 1 }
}

export const addMonthsToYearAsDate = (year, monthsToAdd) => {
  const r = addMonthsToYear(year, monthsToAdd)
  return new Date(`${r.year}-${r.month}`)
}