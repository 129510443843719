import React, {useState, useEffect} from 'react';
import { Component } from 'react';

import { CardContent, Autocomplete, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const CompanySelect = ({ selectedCompany, options, handleSelectChange, portfolioView }) => {
    const [inputValue, setInputValue] = useState('');

    const sortedOptions = [...options].sort((a, b) => {
        // Check for Numericals (thanks 2OC...)
        const isANumeric = /^\d/.test(a.company);
        const isBNumeric = /^\d/.test(b.company);
    
        // Sort numericals
        if (isANumeric && isBNumeric) {
            return a.company.localeCompare(b.company, undefined, { numeric: true });
        }
    
        // Make sure Alphabeticals come before Numerical optioins
        if (isANumeric) return 1;
        if (isBNumeric) return -1;
    
        // Sort all alphabetically
        return a.company.localeCompare(b.company);
    });

    // Clear the selected company when views are switched
    useEffect(() => {
        if (portfolioView) {
            handleSelectChange(null);
        }
    }, [portfolioView]);
    
    return (
        <div className="select-dropdown-container" >
            <Autocomplete
                value={selectedCompany}
                onChange={(event, newValue) => handleSelectChange(event, newValue)}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                options={options}
                // getOptionLabel={(option) => `${option.company} (${option.project_count})`}
                getOptionLabel={(option) => `${option.company}`}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Select Company"
                        variant="outlined"
                        fullWidth
                        sx={{
                            '& .MuiInputBase-input': {
                                color: 'white',
                            },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'white',
                                },
                                '&:hover fieldset': {
                                    borderColor: 'white',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'white',
                                },
                            },
                            '& .MuiInputLabel-root': {
                                color: 'white',
                                '&.Mui-focused': {
                                    color: 'white',
                                },
                            },
                            '& .MuiAutocomplete-endAdornment .MuiSvgIcon-root': {
                                color: 'white',
                            },
                        }}
                    />
                )}
                isOptionEqualToValue={(option, value) => {
                    return value === null || option.company === value.company;
                }}
                onOpen={() => {
                    // Clear the selected company when the dropdown is opened
                    handleSelectChange(null, null);
                }}
            />
        </div>
    )

}

export default CompanySelect;