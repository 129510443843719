import { CardContent, Typography, Grid } from "@material-ui/core";

const formatDate = (dateString) => {
  if (dateString) {
      const date = new Date(dateString);
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return date.toLocaleDateString(undefined, options);
  }
};
const formatDollar = (value) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
};

const formatNumber = (number) => {
  return new Intl.NumberFormat('en-US').format(number);
};

const KeyInfoTable = ({ projectData }) => {
  const pricePerSF = () => {
    if (projectData && projectData.project_data && projectData.project_data.total_contract && projectData.project_data.procore_size) {
        let cost = projectData.project_data.total_contract;
        let sf = projectData.project_data.procore_size;

        if (sf !== 0) { 
            let ppsf = cost / sf;
            return ppsf;
        }
    }
    return null;
};

const ppsf = pricePerSF();
    return (
        <CardContent>
             {projectData ? (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body2">
                  Start Date:  {formatDate(projectData.project_data?.actual_start_date) || '--'}
                </Typography>
                <Typography variant="body2">
                  TCO Date: {formatDate(projectData.project_data?.projected_finish_date) || '--' }
                </Typography>
                <Typography variant="body2">
                  End Date: {formatDate(projectData.project_data?.completion_date) || '--'}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">
                  Project Type: {projectData.project_data?.project_type || '--'}
                </Typography>
                <Typography variant="body2">
                  Total SF: {formatNumber(projectData.project_data?.square_feet) || '--'}
                </Typography>
                <Typography variant="body2">
                  Cost/SF: {formatDollar(ppsf)}/SF
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Typography variant="body2">N/A - Select a Project</Typography>
          )}
        </CardContent>
    )
}

export default KeyInfoTable;