import React, { useState } from 'react';
import { IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';

const ProjectSettingsModal = ({ Component, projectSnapshots, budgetData, title, selectedSnapshots, onSelectionChange, handleModalClose, initialSnapshots }) => {
    const [openDialog, setOpenDialog] = useState(false);

   const handleClickOpen = () => {
    setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    // Function to close the modal AND trigger the PATCH request to update
    const handleSave = () => {
        handleModalClose(selectedSnapshots);
        setOpenDialog(false);
    };

    return (
        <>
            <IconButton onClick={handleClickOpen} style={{ textAlign: 'right' }}>
                <SettingsIcon style={{color: 'white'}} />
            </IconButton>
            <Dialog open={openDialog} onClose={handleDialogClose} maxWidth="xl" fullWidth>
                <DialogTitle className='titillium-web-base' style={{fontSize: '30px', fontWeight: 'bolder', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    {title}
                    <IconButton onClick={handleDialogClose} style={{ color: '#000'}}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className='titillium-web-base'>Changes made to these selections will update the data used by the rest of the dashboard.</DialogContent>
                <DialogContent className='titillium-web-base' style={{marginTop: "-20px"}}>The below snapshots are organized by the date the snapshot was taken. They may reflect data for the month prior.</DialogContent>
                <DialogContent style={{ height: '50vh', display: 'flex', flexDirection: 'column' }}>
                    <Component 
                        budgetData={budgetData} 
                        isModal={true}
                        projectSnapshots={projectSnapshots}
                        selectedSnapshots={selectedSnapshots}
                        onSelectionChange={onSelectionChange}
                        initialSnapshots={initialSnapshots}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="secondary" className="standard-secondary-button">
                        Close
                    </Button>
                    <Button onClick={handleSave} color="primary" className="standard-primary-button">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ProjectSettingsModal;
