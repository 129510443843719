export const __FORCE__DEV__ON__LOCALHOST__ = true;
const __DEV__ = __FORCE__DEV__ON__LOCALHOST__ && window.location.hostname === 'localhost';


let ORIGIN = 'https://admin.roster.arcomurray.com';
let REDIRECT_URL = 'https://roster.arcomurray.com/project-view';
if (__DEV__) {
    ORIGIN = 'http://localhost:8001';
    REDIRECT_URL = 'http://localhost:3000/project-view';
}
let SIGN_IN_URL = `${ORIGIN}/auth/sign_in/with_redirect`;
let getSignInUrl = (redirect /*: string*/) => /* :string */ {
    const url = new URL(SIGN_IN_URL);
    url.searchParams.append("redirect", redirect);
    return url.toString();
}
let LOGOUT_URL = `${ORIGIN}/auth/sign_out/with_redirect`;
const getLogoutUrl = (redirect /*: string*/) => /* :string */ {
    const url = new URL(LOGOUT_URL);
    url.searchParams.append("redirect", redirect);
    return url.toString();
}
let REFRESH_URL = `${ORIGIN}/auth/ajax/check_auth_status`;
let AUTH_COOKIE_NAME = "ms-identity-web";
let AUTH_REDIRECT_NAME = "pm-log.redirect";
let AUTH_LOCAL_STORAGE_NAME = "pm-log.auth";
export { 
    __DEV__,
    REDIRECT_URL,
    SIGN_IN_URL,
    getSignInUrl,
    getLogoutUrl,
    LOGOUT_URL,
    REFRESH_URL,
    AUTH_COOKIE_NAME,
    AUTH_REDIRECT_NAME,
    AUTH_LOCAL_STORAGE_NAME
}
