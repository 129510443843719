export const LESSON_DIVISION_OPTIONS = ([
    {'title': 'General', 'value': 'general', 'sorting_order': 1}, 
    {'title': 'CEA', 'value': 'cea', 'sorting_order': 2}, 
    {'title': 'Industrial', 'value': 'industrial', 'sorting_order': 3}, 
    {'title': 'Multifamily', 'value': 'multifamily', 'sorting_order': 4}, 
    {'title': 'Process', 'value': 'process', 'sorting_order': 5}, 
    {'title': 'Entertainment', 'value': 'entertainment', 'sorting_order': 6}, 
    {'title': 'Self-Storage', 'value': 'self-storage', 'sorting_order': 7}, 
    {'title': 'Tenant Solutions', 'value': 'tenant-solutions', 'sorting_order': 8}, 
  ]);

export const LESSON_TAGS = ([
  {'title': 'Cannabis', 'value': 'cannabis', 'sorting_order': 1, 'division': 'cea'}, 
  {'title': 'Leafy', 'value': 'leafy', 'sorting_order': 2, 'division': 'cea'}, 
  {'title': 'Tomato/Vine', 'value': 'tomato/vine', 'sorting_order': 3, 'division': 'cea'}, 
])

  // Activities View
export const DEFAULT_SHOW_COLUMNS = ['title'];
