import React, {useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router';
import * as THREE from 'three';

let renderer, scene, camera;

const Vis2d = () => {
    const { long_bay_spacing, long_bay_spacing_details, short_bay_spacing, short_bay_spacing_details } = useParams();
    let long_bay_spacing_option = parseInt(long_bay_spacing)    
    let short_bay_spacing_option = parseInt(short_bay_spacing)    
    const canvasRef = useRef();


    renderer = new THREE.WebGLRenderer( { antialias: true, canvas: canvasRef.current } );
    renderer.setPixelRatio( window.devicePixelRatio );
    renderer.setClearColor( 0x000000, 0.0 );
    renderer.setSize( window.innerWidth, window.innerHeight );
    // document.body.appendChild( renderer.domElement );

    scene = new THREE.Scene();


    // if ((ld <= 0)||(ltb <= 0)||(hd <= 0)||(heb <= 0)||(h <= 0)) {
    //     return(<div>Invalid Input. Revise the Input to render the geometry.</div>)
    // }    
    let x_coordinates = [];
    let lb = 0 // Long Bays

    if (long_bay_spacing_option === 5) {
        // Custom Bay Spacing
        x_coordinates = [0];
        let custom_long_bay_spacing_split = long_bay_spacing_details.replaceAll('_', ' ').replaceAll('-', '(').replaceAll('~', ')').split(',')
        custom_long_bay_spacing_split.forEach(e => {
            e = e.split('@')
            if (e.length < 2) {
                return(<div>Invalid Input. Revise the Input to render the geometry.</div>)
            }
            for (let i = 0; i < e[0]; i++) {
                x_coordinates.push(x_coordinates[x_coordinates.length-1] + parseInt(e[1]))
            }
        })
        lb = x_coordinates.length-1
    } else {
        // Standard Bay Spacing
        let details = long_bay_spacing_details.replaceAll('_', ' ').replaceAll('-', '(').replaceAll('~', ')')
        let bays = details.split(' ').filter(e => e.includes('(')).map(e => parseInt(e.slice(1,-1)));
        let bay_width = details.split(' ').filter(e => e.includes('ft')).map(e => parseInt(e.slice(0,-2)));
        x_coordinates = [0];

        let end_bays = 0
        if (bays.length > 1) {
            end_bays = bays[1]
            x_coordinates.push(bay_width[1])
        }
        
        for (let i=1; i<=bays[0]; i++) {
            x_coordinates.push(x_coordinates[x_coordinates.length-1]+bay_width[0]) 
        }
        
        if (end_bays == 2) {
            x_coordinates.push(x_coordinates[x_coordinates.length-1]+bay_width[1])
        }        
        lb = x_coordinates.length-1
    }

    let y_coordinates = [];
    let hb = 0 // Long Bays

    // Horizontal Stuff
    if (short_bay_spacing_option === 5) {
        // Custom Bay Spacing
        y_coordinates = [0];
        let custom_h_bay_spacing_split = short_bay_spacing_details.replaceAll('_', ' ').replaceAll('-', '(').replaceAll('~', ')').split(',')
        custom_h_bay_spacing_split.forEach(e => {
            e = e.split('@')
            if (e.length < 2) {
                return(<div>Invalid Input. Revise the Input to render the geometry.</div>)
            }
            for (let i = 0; i < e[0]; i++) {
                y_coordinates.push(y_coordinates[y_coordinates.length-1] + parseInt(e[1]))
            }
        })
        hb = y_coordinates.length-1
    } else {
        // Standard Bay Spacing
        let details = short_bay_spacing_details.replaceAll('_', ' ').replaceAll('-', '(').replaceAll('~', ')')
        let bays = details.split(' ').filter(e => e.includes('(')).map(e => parseInt(e.slice(1,-1)));
        let bay_width = details.split(' ').filter(e => e.includes('ft')).map(e => parseInt(e.slice(0,-2)));
        y_coordinates = [0];
        let end_bays = 0
        if (bays.length > 1) {
            end_bays = bays[1]
            y_coordinates.push(bay_width[1])
        }
        
        for (let i=1; i<=bays[0]; i++) {
            y_coordinates.push(y_coordinates[y_coordinates.length-1]+bay_width[0]) 
        }
        
        if (end_bays == 2) {
            y_coordinates.push(y_coordinates[y_coordinates.length-1]+bay_width[1])
        }    
        hb = y_coordinates.length-1
    }

    let x_shift = x_coordinates[x_coordinates.length-1]/2;
    let y_shift = y_coordinates[y_coordinates.length-1]/2;

    let scale = Math.min(0.8*window.innerWidth/x_shift, 0.8*window.innerHeight/y_shift)

    let coordinates = [];
    for (let i=0; i<=hb; i++) {
        let row_coord = [];
        for (let j=0; j<=lb; j++) {
            row_coord.push([(x_coordinates[j]-x_shift)*scale,(y_coordinates[i]-y_shift)*scale,0])
        }
        coordinates.push(row_coord)
    }

    camera = new THREE.PerspectiveCamera( 40, window.innerWidth / window.innerHeight, 1, 1000 );
    camera.position.set( 0, 0, 999 );
    // camera.position.set( 0, 0, Math.min(1000, 1.2*Math.max(x_coordinates[x_coordinates.length-1]/2, y_coordinates[y_coordinates.length-1]/2)));
    // console.log('ZOOM:',Math.min(1000, 1.2*Math.max(x_coordinates[x_coordinates.length-1]/2, y_coordinates[y_coordinates.length-1]/2)));
    const material = new THREE.LineBasicMaterial( { color: 0x0000ff } );
    const dotMaterial = new THREE.PointsMaterial( { size: 2, sizeAttenuation: false } );

    // Vertical Lines
    for (let col = 0; col < coordinates[0].length; col++) {
        for (let i = 0; i < coordinates.length-1; i++) {
            const points = [];
            points.push( new THREE.Vector3(coordinates[i][col][0],coordinates[i][col][1],coordinates[i][col][2]));
            points.push( new THREE.Vector3(coordinates[i+1][col][0],coordinates[i+1][col][1],coordinates[i+1][col][2]));
        
            const geometry = new THREE.BufferGeometry().setFromPoints( points );
            const line = new THREE.Line( geometry, material );

            scene.add( line );            
        }
    }

    // Horizontal Lines and Vertices
    coordinates.forEach(row => {
        for (let i = 0; i < row.length-1; i++) {
            const points = [];
            points.push( new THREE.Vector3(row[i][0],row[i][1],row[i][2]));
            points.push( new THREE.Vector3(row[i+1][0],row[i+1][1],row[i+1][2]));
        
            const geometry = new THREE.BufferGeometry().setFromPoints( points );
            const line = new THREE.Line( geometry, material );
            scene.add( line );            

            const dotGeometry = new THREE.BufferGeometry().setFromPoints( [new THREE.Vector3( row[i][0],row[i][1],row[i][2] )] );
            var dot = new THREE.Points( dotGeometry, dotMaterial );
            scene.add( dot );
        }
        const dotGeometry = new THREE.BufferGeometry().setFromPoints( [new THREE.Vector3( row[row.length-1][0],row[row.length-1][1],row[row.length-1][2] )] );
        var dot = new THREE.Points( dotGeometry, dotMaterial );
        scene.add( dot );
    })

    // Long Elevation
    // let y_shift_elevation = hd/2 + ltb
    // for (let i = 0; i < x_coordinates.length; i++) {
    //     const points = [];
    //     if (i+1 !== x_coordinates.length) {
    //         points.push( new THREE.Vector3(x_coordinates[i+1]-x_shift,0-y_shift_elevation,0));
    //     }
    //     points.push( new THREE.Vector3(x_coordinates[i]-x_shift,0-y_shift_elevation,0));
    //     points.push( new THREE.Vector3(x_coordinates[i]-x_shift,0-y_shift_elevation-h,0));
    //     if (i+1 !== x_coordinates.length) {
    //         points.push( new THREE.Vector3(x_coordinates[i+1]-x_shift,0-y_shift_elevation-h,0));
    //     }
    
    //     const geometry = new THREE.BufferGeometry().setFromPoints( points );
    //     const line = new THREE.Line( geometry, material );
    //     scene.add( line );            
    // }

    

    renderer.setViewport( 0, 0, window.innerWidth, window.innerHeight );
    renderer.render( scene, camera );

    let tooltip = "Bay Locations: " + x_coordinates + '\nHorizontal Bay Locations: ' + y_coordinates;

    return(<div title={tooltip} style={{cursor: "pointer"}}><canvas ref={canvasRef} /></div>)
};

export default Vis2d;