import React from "react";
import { CardContent, Typography } from "@material-ui/core";

const calculateMonthDifference = (startDateStr, endDateStr) => {
    if (startDateStr && endDateStr) {
      const startDate = new Date(startDateStr);
      const endDate = new Date(endDateStr);
  
      let months;
      months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
      months -= startDate.getMonth();
      months += endDate.getMonth();
  
      // Adjusting for dias
      if (endDate.getDate() < startDate.getDate()) {
        months--;
      }
  
      return months <= 0 ? 0 : months;
    }
    return null;
  };
    
  const ProjectDurationCard = ({ projectData }) => {
    const endDate = projectData?.project_data?.projected_finish_date || projectData?.projected_finish_date
    const startDate = projectData?.project_data?.actual_start_date || projectData?.actual_start_date

    const projectDuration = calculateMonthDifference(startDate, endDate)

    return (
        <div>
        {projectData && projectDuration ? (
            <Typography style={{ textAlign:'center'}} variant="body1" className='main-key-project-info-body'>
                {`${projectDuration} mos.` || '--' }
            </Typography>
        ) : (
            <Typography style={{ textAlign:'center'}} variant="body1" className='main-key-project-info-body'>--</Typography>
        )}
    </div>
    )
  }

  export default ProjectDurationCard;