import React from 'react'
import PropTypes from 'prop-types'

const Header = ({ timebar, sticky: { isSticky, sidebarWidth, headerHeight }, editMode = {} }) => (
  <div style={isSticky ? { paddingTop: headerHeight } : {}}>
    <div
      className={`rt-sidebar__header ${isSticky ? 'rt-is-sticky' : ''}`}
      style={isSticky ? { width: sidebarWidth } : {}}
    >
      {timebar.map(({ id, title }) => (
        <div key={id} className="rt-timebar-key">
          {((id === 'months')&&(!editMode)) && (
            <div style={{display:'flex'}}>
            <div style={{paddingLeft: '20px', minWidth:'50%'}}>
              <span className="rt-track-key__title" style={{float: 'left'}}>Resource</span>
            </div>
            <div style={{paddingLeft: '20px', minWidth:'30%'}}>
            <span className="rt-track-key__title" style={{float: 'left'}}>Type</span>
            </div>
            <div style={{paddingLeft: '20px', minWidth:'20%'}}>
              <span className="rt-track-key__title" style={{float: 'left'}}>% Assigned</span>
            </div>
            </div>
          )}
          {((id === 'months')&&(editMode)) && (
            <div style={{display:'flex'}}>
            <div style={{paddingLeft: '20px', minWidth:'25%'}}>
              <span className="rt-track-key__title" style={{float: 'left'}}>Resource</span>
            </div>
            <div style={{paddingLeft: '20px', minWidth:'20%'}}>
            <span className="rt-track-key__title" style={{float: 'left'}}>Type</span>
            </div>
            <div style={{paddingLeft: '20px', minWidth:'10%'}}>
              <span className="rt-track-key__title" style={{float: 'left'}}>%FTE</span>
            </div>
            <div style={{paddingLeft: '20px', minWidth:'20%'}}>
              <span className="rt-track-key__title" style={{float: 'left'}}>Start Date</span>
            </div>
            <div style={{paddingLeft: '20px', minWidth:'20%'}}>
              <span className="rt-track-key__title" style={{float: 'left'}}>End Date</span>
            </div>
            <div style={{paddingLeft: '20px', minWidth:'5%'}}>
              <span className="rt-track-key__title" style={{float: 'left'}}>Delete</span>
            </div>
            </div>
          )}
        </div>
      ))}
    </div>
  </div>
)

Header.propTypes = {
  sticky: PropTypes.shape({
    isSticky: PropTypes.bool.isRequired,
    headerHeight: PropTypes.number.isRequired,
    sidebarWidth: PropTypes.number.isRequired,
  }),
  timebar: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
    }).isRequired
  ).isRequired,
}

export default Header
