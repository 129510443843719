import React from "react";
import { useEffect } from "react";
import '../static/percent-change-styles.css';

import { Card, CardContent, Typography, Box } from "@material-ui/core";

const formatDollar = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
}

const CurrentContractAmount = ({ budgetData }) => {
    // Define contract values & calc difference
    const totalValue = budgetData?.current_contract_summary?.revised_contract_value
    console.log('total value = ', totalValue)
    const roundedTotalValue = Math.ceil(totalValue)
    const currentContract = `$${roundedTotalValue.toLocaleString()}`

return (
    <div>
            {budgetData && budgetData?.current_contract_summary ? (
                <div style={{ textAlign: 'center' }}>
                    <Typography className="main-key-project-info-body">
                        {currentContract || "Missing Conract Value" }
                    </Typography>
                </div>
            ) : (
                    <Typography style={{textAlign: 'center'}}className="key-project-info-body">--</Typography>
            )}
        </div>
    );
}

export default CurrentContractAmount;