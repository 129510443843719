import React, { useState } from 'react';

import _ from 'lodash';

import {makeStyles, Paper, BottomNavigation,BottomNavigationAction  } from '@material-ui/core';

import FilterListIcon from '@mui/icons-material/FilterList';
import ListIcon from '@mui/icons-material/List';
import FeedIcon from '@mui/icons-material/Feed';

// Styles for overall page
const useStyles = makeStyles(theme => ({
    paper: {
        minWidth: '28vw',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        overflowX: 'hidden',
    },    
    new_lesson: {
        padding: '0.5rem',
        display: 'flex'
    },
    filter_button: {
        backgroundColor: '#0B7CB0',
        color: 'white',
        minWidth: '20vw',
        height: '2.5rem',
        padding: '0.5rem',
        minWidth: '5vw',
        margin: 'auto',
    },
    new_lesson_button: {
        backgroundColor: '#0B7CB0',
        color: 'white',
        minWidth: '20vw',
        height: '2.5rem',
        // justifyContent: "flex-start"
    },
}))

const MobileBottomNavigation = props => {
    let { mobile_display_tab, updateMobileDisplayTab } = props;
    const classes = useStyles();
    const [value, setValue] = React.useState(mobile_display_tab);

    return (
        <>
            <Paper elevation={7}
                // style={{backgroundColor: '#093d61'}}
            >
                <BottomNavigation
                showLabels
                value={mobile_display_tab}
                onChange={(event, newValue) => {
                    updateMobileDisplayTab(newValue);
                }}
                style={{position: 'fixed', bottom: 0, left: 0, right: 0}}
                >
                    <BottomNavigationAction label="Activities" icon={<FilterListIcon />} />
                    <BottomNavigationAction label="Lessons List" icon={<ListIcon />} />
                    <BottomNavigationAction label="Lesson Details" icon={<FeedIcon />} />
                </BottomNavigation>
            </Paper>
        </>
    )

}


export default MobileBottomNavigation;