import React, { Component } from 'react';
import Axios from 'axios';
import _, { filter } from 'lodash';

import { API_URL } from '../../utils/config/config';

import Header from '../../components/post-security/common/header';
import Sidebar from '../../components/post-security/common/sidebar';
import Settings from '../../components/post-security/settings_view/settings.js';
import Footer from '../../components/post-security/common/footer';

import {Typography, Button, Paper, Snackbar} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { Replay } from '@material-ui/icons';

import { withStyles } from '@material-ui/core';

const Styles = theme => ({
    root_grid: {
        display: 'grid',
        height: '100vh',
        'grid-template-rows': '4em auto 2em',
        overflowY: 'visible',
    },
    main: {
        'padding-left': '70px',
        'padding-top': '20px',
        'padding-right': '70px',
    },
    pm_view: {
        'maxWidth': 'calc(100vw - 70px - 60px - 30px)',
        overflowX: 'auto',
    },
    header: {
        zIndex: 999999,
    }
});

class SettingsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: false,
            offline: false,
            pmResourceNames: []
        };
        this.fetchFilterData = this.fetchFilterData.bind(this);
        this.updateFilters = this.updateFilters.bind(this);
    }

    fetchFilterData = async () => {
        if (localStorage.getItem('id')) {
            try {
                const { getAccessTokenSilently } = this.props.auth;
                const token = await getAccessTokenSilently();
    
                const responseFetchPMResourceNames = await Axios.get(`${API_URL}pm_resources_names/`,            
                {
                    withCredentials: true,
                    headers: {
                        'content-type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                });
                responseFetchPMResourceNames.data.sort((a, b) => (a.name > b.name) ? 1 : -1)
                
                const responseFetchFilterData = await Axios.get(`${API_URL}pm_view_filters/${localStorage.getItem('id')}/`,
                {
                    withCredentials: true,
                    headers: {
                        'content-type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }).then((response) => {
                    if (response.status === 200) {
                        this.setState({
                            ...this.state,
                            filters: response.data.filters,
                            pmResourceNames: responseFetchPMResourceNames.data,
                            loading: false,
                        })
                    }
                }
                );
            }
            catch (error) {
                this.setState({ ...this.state, error: error });
            }    
        }
    }

    updateFilters = async (filter_type, filter_value) => {
        //Snackbar
        this.setState({snackbar_show: true});
        this.setState({snackbar_message: 'Saving...'});
        this.setState({snackbar_type: 'info'});

        var obj = {};
        obj[filter_type] = filter_value;
        let new_filters = Object.assign({}, this.state.filters, obj);
        this.setState({ filters: new_filters});

        const { getAccessTokenSilently } = this.props.auth;
        const token = await getAccessTokenSilently();

        let data = {
            "user_id": localStorage.getItem('id'),
            "filters": new_filters
        };

        Axios.patch(
            `${API_URL}pm_view_filters/${localStorage.getItem('id')}/`,
            data,
            {
                withCredentials: true,
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }            
        ).then((response) => {
            if (response.status === 200) {
                //Snackbar
                this.setState({snackbar_show: true});
                this.setState({snackbar_message: 'Change Saved Successfully'});
                this.setState({snackbar_type: 'success'});
            }
        }).catch(error => {
            console.log(error);
            //Snackbar
            this.setState({snackbar_show: true});
            this.setState({snackbar_message: 'Error Saving Change'});
            this.setState({snackbar_type: 'error'});
        })      
    }


    componentDidMount() {
        document.title = "Roster Settings";
        this.fetchFilterData();
    }

    render() {  

        const { classes } = this.props;        
        return (
            <>
                <div className={classes.root_grid}>
                    <div className={classes.header}>
                        {/* ***** Header Area ***** */}
                        <Header />
                    </div>
                    <div>
                        {this.state.offline ? (
                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                You are offline!
                            </div>
                        ) : this.state.error ? (
                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                <Typography variant="h5" style={{ fontFamily: 'inherit' }} gutterBottom>
                                    <strong>An error occured!</strong>
                                </Typography>
                                <center>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={() => window.location.reload()}
                                    >
                                        <Replay /> Try again
                                    </Button>
                                </center>
                            </div>
                        ) : (      
                            <>
                            <Sidebar />  
                            <div  className={classes.main}>
                                {/* ***** Main Banner Area ***** */}
                                <Paper className={classes.pm_view}>
                                    <Settings 
                                        loading={this.state.loading} 
                                        filters={this.state.filters}
                                        pmResourceNames={this.state.pmResourceNames}
                                        updateFilters={this.updateFilters}
                                    />
                                </Paper>
                            </div>
                            <Snackbar 
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                open={this.state.snackbar_show}
                                autoHideDuration={2000}
                                onClose={() => this.setState({snackbar_show: false})}
                            >
                                <MuiAlert elevation={6} variant="filled" severity={this.state.snackbar_type}>
                                    {this.state.snackbar_message}
                                </MuiAlert>
                            </Snackbar>                            

                            </>
                            )}
                        </div>
                    <div>
                        {/* ***** Footer ***** */}
                        <Footer />
                    </div>                
                </div>
            </>
        );
    }
}

export default withStyles(Styles)(SettingsContainer)
