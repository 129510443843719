import React, { Component } from 'react';
import Vis2d from '../../components/pre-security/vis2d';

class Vis2dCont extends Component {

    render() {
        return (
            <>
                <Vis2d />
            </>
        );
    }
}

export default Vis2dCont;
