import React, { useState } from 'react';
import {useDropzone} from 'react-dropzone';
import {makeStyles, Paper, Typography, TextField, Checkbox, Button, MenuItem, Select, Dialog, Chip, useTheme, useMediaQuery,InputLabel, FormControl } from '@material-ui/core';
import BouncingPreloader from '../common/bouncyLoader';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import ArticleIcon from '@mui/icons-material/Article';
import Cookies from 'js-cookie';
import ReactQuill from "react-quill"
import EditorToolbar, { modules, formats } from "./editor_toolbar";
import 'react-quill/dist/quill.snow.css'

import {Snackbar} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import {LESSON_DIVISION_OPTIONS, LESSON_TAGS} from '../constants/constants';
import LLViewContent from '../../../containers/post-security/new-lesson/NewLessonActivitiesSelector';
import { 
    AUTH_COOKIE_NAME
} from "../../../../auth/constant";

// Styles for overall page
const useStyles = makeStyles(theme => ({
    paper: {
        overflow: 'scroll',
        width: 'calc(100vw - 20px)',
        height: '100vh',
        [theme.breakpoints.up('sm')]: {
            width: '80vw',
            height: '90vh',
        },
        padding: '10px',
        paddingBottom: '0px',
        // paddingBottom: '1rem',
        display: 'flex',
        flexDirection: 'column',
    },
    mainGrid: {
        display: 'grid',
        gridGap: '15px',
        gridTemplateColumns: 'auto',
        [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: '1.5fr 3.5fr',
        },
    },
    standard: {
        display: 'flex',
        alignItems: 'center'
    },
    titleText: {
        width: 'calc(100vw - 20px)',
        [theme.breakpoints.up('sm')]: {
            width: '50vw'        
        },
    },
    textEditor: {
        width: 'calc(100vw - 20px)',
        "& .ql-editor": {
            minHeight: '55vh',
        },
        [theme.breakpoints.up('sm')]: {
            width: '50vw',        
            "& .ql-editor": {
                minHeight: '35vh',
            }
        },
    },
    selectorDialog: {
        height: '90vh', 
        "& .MuiDialog-container": {
            paddingTop: '58px',
            "& .MuiPaper-root": {
              minHeight: "calc(100vh - 48px - 40px)",
              margin: '0px',
              width: 'calc(100vw - 20px)',
              [theme.breakpoints.up('sm')]: {
                width: '72vw'
              }
            },
        },
        [theme.breakpoints.up('sm')]: {
        },
    },
    dropzone: {
        textAlign: "center",
        padding: "10px",
        border: "1px #bdbdbd solid",
        backgroundColor: "#fafafa",
        color: "#717171",
        [theme.breakpoints.up('sm')]: {
            padding: "20px",
        },
    },
}))

//Function that pulls the data and sends it to the Table Renderer
const NewLesson = props => {
    let { loading, formSubmitContainer, auth, getFileService } = props;
    const classes = useStyles();
    const [snackbar, setSnackbar] = useState({show: false, message: '', type: 'info'})
    const theme = useTheme();

    let mode = useMediaQuery(theme.breakpoints.up('sm')) ? 'desktop':'mobile'
    // Form inputs
    const [lessonData, setLessonData] = useState({...props.lesson_data})
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({ 
        // accept: {
        //     'image/png': ['.png','.jpg'],
        // },
        maxFiles: 5});
    const [activityTaggingDialog, setActivityTaggingDialog] = useState(false);
    const [selectedActivities, setSelectedActivities] = useState(lessonData.selectedActivities ? lessonData.selectedActivities !== '...' ? lessonData.selectedActivities.split(',').map(e => parseInt(e)) : [] : []);
    var lessonFormData = new FormData();
    const formSubmit = (reviewed, request_type) => {
        // Checking if necessary fields are filled out
        if ((lessonData.title === '')||(lessonData.description === '')||(lessonData.description === '<p><br></p>')) {
            // Show Error Snackbar
            setSnackbar({show: true, message: 'Please fill in Title and Description', type: 'error'})
        } else if ((lessonData.division === 'cea')&&(lessonData.tags.length === 0)) {
            // Show Error Snackbar
            setSnackbar({show: true, message: 'Please select a division specific tag', type: 'error'})
        } else {
            // Initiate Submit
            lessonFormData.append('title', lessonData.title);
            lessonFormData.append('description', lessonData.description);
            lessonFormData.append('safety', lessonData.safety);
            lessonFormData.append('division', lessonData.division);
            lessonFormData.append('tags', lessonData.tags.join('<<>>'))
            lessonFormData.append('selectedActivities', selectedActivities.toString()==='' ? '...' : selectedActivities.toString());
            lessonFormData.append('author', lessonData.author);
            lessonFormData.append('reviewed', reviewed);
            lessonFormData.append('source', 'll_'+mode);
            let file_counter = 1;
            acceptedFiles.map(file => {
                lessonFormData.append('attachments'+file_counter, file);
                file_counter += 1; 
            })

            formSubmitContainer(lessonFormData, request_type);
            setSnackbar({show: true, message: 'Lesson has been logged successfully!', type: 'success'})
            if (request_type === 'post') {
                // Cleaning the form
                setLessonData({
                    attachments: '',
                    description: '',
                    division: 'general',
                    tags: [],
                    reviewed: false,
                    safety: false,
                    selectedActivities: '',
                    title: '',
                    author: JSON.parse(Cookies.get(AUTH_COOKIE_NAME)).profile.email,
                })
                setSelectedActivities([]);
            }
        }
    }

    return (
        <>
            {loading ? (
                <>
                <div style={{ position: 'absolute', top: '55%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                    <BouncingPreloader />
                </div>
                </>
            ) : (
                <Paper elevation={1} className={classes.paper} id='new_lesson'>
                    <Snackbar 
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        open={snackbar.show}
                        autoHideDuration={15000}
                        onClose={() => setSnackbar({...snackbar, show: false})}
                    >
                        <MuiAlert elevation={6} variant="filled" severity={snackbar.type}>
                            {snackbar.message}
                        </MuiAlert>
                    </Snackbar>

                    <Typography variant="h5">Log a Lesson</Typography>
                    <br />
                    <div className={classes.mainGrid}>
                        <div className={classes.standard}>
                            <Typography variant="h6">Title</Typography>
                            <Typography variant="h6" style={{color: 'red'}}>*</Typography>
                        </div>
                        <div className={classes.standard}>
                            <TextField 
                                id="outlined-basic" 
                                placeholder="Enter the Title here...Atleast 5 words needed." 
                                variant="outlined"  
                                size="small" 
                                className={classes.titleText}
                                value ={lessonData.title}
                                onChange={(e) => setLessonData({...lessonData, title: e.target.value.substring(0,100)})} />
                        </div>
                        <div className={classes.standard}>
                            <Typography variant="h6">Description</Typography>
                            <Typography variant="h6" style={{color: 'red'}}>*</Typography>
                        </div>
                        <div className={classes.standard}>
                            <div className={classes.textEditor}>
                                <EditorToolbar mode={mode}/>
                                <ReactQuill
                                    theme='snow'
                                    placeholder={
                                        "Describe the Lesson here...\nPlease cover following points:\n1)Three bullet points summary.\n2) What was supposed to happen, including the context of your project\n3) What really happened?\n4) What did we learn from this?\n5) Describe the solution/mitigation measures\n\nTips for Title: Be descriptive, specific, and informative.\nE.g., “Fire Protection” doesn’t tell us much about the content, especially when it is in the Fire Protection category. “Fire Protection Seismic Bracing per ASCE 7” is much more descriptive.\nE.g., “Conduit Routing” or “Conduit in Tilt” is somewhat descriptive but “ACI requirements for Conduits in Tilt” takes it further."}
                                    value={lessonData.description}
                                    onChange={value => setLessonData({...lessonData, description: value})}
                                    modules={modules}
                                    formats={formats}
                                />

                            </div>
                        </div>
                        <div className={classes.standard}>
                            <Typography variant="h6">Upload supporting documents</Typography>
                        </div>
                        <div className={classes.standard}>
                             <section className={classes.fileUploader}>
                                <div {...getRootProps({className: classes.dropzone})}>
                                    <input {...getInputProps()} />
                                    {mode==='desktop' && <Typography variant='subtitle1'>Drag 'n' drop or click to select up to 5 files</Typography>}
                                    {mode==='mobile' && <Typography variant='subtitle1'>Select up to 5 files</Typography>}
                                </div>
                                <br />
                                {lessonData.attachments && lessonData.attachments !== "" && (
                                    <div style={{display: 'flex', alignItems: 'center', flexWrap:'wrap'}}>
                                        <Typography variant={'subtitle1'}>Current attachments: </Typography>&nbsp;
                                        {lessonData.attachments.split('<<>>').map(file => {
                                            let file_name = file.includes('arconnect') ? file : file.split('_attachment_')[1].substring(2,)
                                            let file_ext = file.includes('arconnect') ? file : file_name.split('.')[file_name.split('.').length - 1]
                                            return(
                                                <>
                                                    <Chip 
                                                        icon={file_ext=== 'pdf' ? <PictureAsPdfIcon /> : ['png','jpg','jpeg'].includes(file_ext) ? <ImageIcon /> : file_ext=== 'zip' ? <FolderZipIcon /> : <ArticleIcon />} 
                                                        label={file_name.substring(0,25)} 
                                                        onClick={async() => {
                                                            if (!file.includes('arconnect')) {
                                                                let file_name = file.split('https://arcolessonslearned.s3.amazonaws.com/')[1]
                                                                const url = await getFileService(auth,{
                                                                    lesson_id: lessonData.id,
                                                                    attachment_name: file_name
                                                                })
                                                                window.open(url, '_blank')
                                                            } else {
                                                                const url = file
                                                                window.open(url, '_blank')
                                                            }
                                                        }
                                                        }
                                                        color='primary'
                                                        size='small'
                                                    />
                                                    &nbsp;&nbsp;
                                                </>)
                                        })}
                                    </div>
                                )}
                                {acceptedFiles.length > 0 && (
                                    <div style={{display: 'flex', alignItems: 'center', flexWrap:'wrap'}}>
                                        <Typography variant='subtitle1'>New Selected Files:</Typography>&nbsp;
                                        {acceptedFiles.map(file => (
                                                <>
                                                    <Chip 
                                                        icon={<ArticleIcon />} 
                                                        label={file.path.substring(0,25)} 
                                                        color='primary'
                                                        size='small'
                                                    />&nbsp;&nbsp;
                                                </>
                                            ))}
                                    </div>
                                )}
                            </section>
                        </div>
                        <div className={classes.standard}>
                            <Typography variant="h6">Check if this is a safety lesson</Typography>
                        </div>
                        <div className={classes.standard}>
                            <Checkbox 
                                label={'Hi'} 
                                style={{paddingLeft: '0px'}} 
                                checked={lessonData.safety}
                                onChange={(e) => setLessonData({...lessonData, safety:e.target.checked})}
                            />
                        </div>
                        <div className={classes.standard}>
                            <Typography variant="h6">Applies to specific vertical?</Typography>
                        </div>
                        <div className={classes.standard}>
                            <Select
                                value={lessonData.division}
                                onChange={(e) => setLessonData({...lessonData, division: e.target.value})}
                                style={{minWidth: '15rem'}}
                            >
                                {LESSON_DIVISION_OPTIONS.map(e => {
                                    return <MenuItem key={'xfsvfd'+e.sorting_order} value={e.value}>{e.title}</MenuItem>
                                })}
                            </Select>
                        </div>
                        {(LESSON_TAGS.filter(e => e.division === lessonData.division).length>0) && (
                            <>
                                <div className={classes.standard}>
                                    <Typography variant="h6">Select Division specific tags</Typography>
                                    <Typography variant="h6" style={{color: 'red'}}>*</Typography>
                                </div>
                                <div className={classes.standard}>
                                    <FormControl sx={{ m: 1, width: 300 }}>
                                        <InputLabel id="tags-label">Tags</InputLabel>
                                        <Select
                                            value={lessonData.tags ? lessonData.tags : []}
                                            onChange={(e) => setLessonData({...lessonData, tags: e.target.value})}
                                            style={{minWidth: '15rem'}}
                                            multiple
                                        >
                                            {LESSON_TAGS.filter(e => e.division === lessonData.division).map(e => 
                                                (<MenuItem key={'xfsfsvfd'+e.sorting_order} value={e.value}>{e.title}</MenuItem>)
                                            )}
                                        </Select>
                                    </FormControl>                                
                                </div>
                            </>
                        )}

                        <div className={classes.standard}>
                            <Typography variant="h6">Tag the Lesson to Activities</Typography>
                        </div>
                        <div className={classes.standard}>
                            <Button
                                variant="outlined" 
                                style={{backgroundColor: '#093D61', color: '#FFFFFF', minWidth: '10rem'}}
                                onClick={e => setActivityTaggingDialog(!activityTaggingDialog)}
                            >
                                Activities
                            </Button>
                            &nbsp;
                            &nbsp;
                            <Typography>Selected activities: {selectedActivities.length}</Typography>
                            <Dialog
                                className={classes.selectorDialog}
                                // fullWidth={true}
                                maxWidth={'xl'}
                                open={activityTaggingDialog}
                            >
                                <div style={{display: 'flex', 'padding': '10px'}}>
                                    <Typography variant='h6'>Select the Activities</Typography>
                                    <Button 
                                        variant='contained' 
                                        style={{backgroundColor: '#093D61', color: '#FFFFFF', padding:'5px', marginLeft: 'auto'}}
                                        onClick={() => setActivityTaggingDialog(!activityTaggingDialog)}
                                    >
                                        Done
                                    </Button>
                                </div>
                                <LLViewContent
                                    auth = {auth}                                        

                                    selectedActivities={selectedActivities}
                                    setSelectedActivities={setSelectedActivities}
                                />
                            </Dialog>
                        </div>
                        {((mode==='desktop')&&(localStorage.getItem('ll_admin')==='true')) && (
                            <>
                                <div className={classes.standard}>
                                    <Typography variant="h6">Author</Typography>
                                </div>
                                <div className={classes.standard}>
                                    <TextField 
                                        id="outlined-basic" 
                                        // placeholder={localStorage.getItem('email')}
                                        variant="outlined"
                                        size="small" 
                                        className={classes.titleText}
                                        value ={lessonData.author}
                                        onChange={(e) => setLessonData({...lessonData, author: e.target.value})} 
                                    />
                                </div>
                            </>
                        )}
                        <div className={classes.standard}>
                        </div>
                        <div className={classes.standard} style={{display: 'block'}}>
                            <br />
                            {!props.lesson_id && (
                                <Button 
                                    variant="outlined" 
                                    style={{backgroundColor: '#093D61', color: '#FFFFFF', minWidth: '10rem'}}
                                    onClick={() => formSubmit(localStorage.getItem('ll_admin')==='true' ? true: false, 'post')}
                                    component='label'
                                >
                                    Submit
                                </Button>
                            )}
                            {props.lesson_id && (
                                <>
                                    <Button 
                                    variant="outlined" 
                                    component="label" 
                                    style={{backgroundColor: '#2f77bc', color: '#FFFFFF'}}
                                    onClick={() => formSubmit(false, 'patch')}
                                    >
                                        Update
                                    </Button>
                                    &nbsp;&nbsp;
                                    {(!lessonData.reviewed && (localStorage.getItem('ll_admin')==='true')) && (
                                        <Button 
                                            variant="outlined" 
                                            component="label" 
                                            style={{backgroundColor: '#093D61', color: '#FFFFFF'}}
                                            onClick={() => formSubmit(true, 'patch')}
                                        >
                                            Update and Mark Approved
                                        </Button>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </Paper>
            )}
        </>
    )
}

export default NewLesson;