import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';

// Helper function to generate a PDF for Payment & Invoicing Table
export const generateInvoicePDF = (sortedInvoiceData, selectedProject) => {
    const doc = new jsPDF({
        orientation: 'landscape'
    });
    doc.text('Payment & Invoicing History Table', 14, 10);
    doc.text(`${selectedProject.project_number} - ${selectedProject.name}`, 14, 20);

    const tableColumn = ["Pay App Description", "Invoice Date", "Date Paid", "Payment Age", "Invoiced", "Paid", "Cumulative Paid"];
    const tableRows = [];

    sortedInvoiceData.forEach(row => {
        const paymentAge = row.pay_full_date && row.invoice_date ? calculatePaymentAge(row.invoice_date, row.pay_full_date) : '--';
        const rowData = [
            row.pay_app_description || '--',
            row.invoice_date || '--',
            row.pay_full_date || '--',
            paymentAge !== null ? `${paymentAge} days` : '--',
            row.invoiced ? formatDollar(row.invoiced) : '--',
            row.paid ? formatDollar(row.paid) : '--',
            row.cumulative_paid ? formatDollar(row.cumulative_paid) : '--',
        ];
        tableRows.push(rowData);
    });

    autoTable(doc, {
        head: [tableColumn],
        body: tableRows,
        startY: 25,
    });
    doc.save('owner_billings_table.pdf');
};

//   Generate PDF For BVT Tables
export const generateBVTPDF = (budgetData, selectedProject) => {
    const doc = new jsPDF({ orientation: 'landscape' });

    doc.text('Budget Variance Tool', 14, 10);
    doc.text(`${selectedProject.project_number} - ${selectedProject.name}`, 14, 20);

    const { baseline_contract_summary: baseline, penultimate_contract_summary: penultimate, current_contract_summary: current } = budgetData;

    const createTableRows = (items, baseline, penultimate, current, type) => {
        return items.map(item => [
            item,
            baseline?.contract_structure?.[type]?.[item] ? formatDollar(baseline.contract_structure[type][item]) : '--',
            penultimate?.contract_structure?.[type]?.[item] ? formatDollar(penultimate.contract_structure[type][item]) : '--',
            current?.contract_structure?.[type]?.[item] ? formatDollar(current.contract_structure[type][item]) : '--',
            baseline?.contract_structure?.[type]?.[item] && penultimate?.contract_structure?.[type]?.[item] 
                ? formatDollar(current.contract_structure[type][item] - penultimate.contract_structure[type][item]) : '--',
            baseline?.contract_structure?.[type]?.[item] && current?.contract_structure?.[type]?.[item] 
                ? formatDollar(current.contract_structure[type][item] - baseline.contract_structure[type][item]) : '--',
        ]);
    };

    // Generate Budget Table
    const budgetItems = Object.keys(current?.contract_structure?.Budget || {});
    const budgetTableRows = createTableRows(budgetItems, baseline, penultimate, current, 'Budget');

    doc.text('Budget Summary', 14, 30);
    autoTable(doc, {
        head: [['Budget Item', 'Baseline', 'Penultimate', 'Current', 'Month Over Month Variance', 'Baseline Variance']],
        body: budgetTableRows,
        startY: 35,
    });

    // Generate Costs Table
    const costItems = Object.keys(current?.contract_structure?.Cost || {});
    const costTableRows = createTableRows(costItems, baseline, penultimate, current, 'Cost');

    doc.text('Costs Summary', 14, doc.autoTable.previous.finalY + 10);
    autoTable(doc, {
        head: [['Cost Item', 'Baseline', 'Penultimate', 'Current', 'Month Over Month Variance', 'Baseline Variance']],
        body: costTableRows,
        startY: doc.autoTable.previous.finalY + 15,
    });

    // Generate Busts/Pickups Table
    const overUnderItems = Object.keys(current?.contract_structure?.["Over/Under"] || {});
    const overUnderTableRows = createTableRows(overUnderItems, baseline, penultimate, current, 'Over/Under');

    doc.text('Busts/Pickups Summary', 14, doc.autoTable.previous.finalY + 10);
    autoTable(doc, {
        head: [['Busts/Pickups Item', 'Baseline', 'Penultimate', 'Current', 'Month Over Month Variance', 'Baseline Variance']],
        body: overUnderTableRows,
        startY: doc.autoTable.previous.finalY + 15,
    });

    // Save the PDF
    doc.save(`${selectedProject.project_number}_financial_summary.pdf`);
};

// Helper function to generate an Excel file
export const generateExcel = (sortedInvoiceData) => {
    const worksheetData = sortedInvoiceData.map(row => ({
        'Pay App Description': row.pay_app_description || '--',
        'Invoice Date': row.invoice_date || '--',
        'Date Paid': row.pay_full_date || '--',
        'Payment Age': row.pay_full_date && row.invoice_date ? `${calculatePaymentAge(row.invoice_date, row.pay_full_date)} days` : '--',
        'Invoiced': row.invoiced ? formatDollar(row.invoiced) : '--',
        'Paid': row.paid ? formatDollar(row.paid) : '--',
        'Cumulative Paid': row.cumulative_paid ? formatDollar(row.cumulative_paid) : '--',
    }));

    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Payment and Invoicing');

    XLSX.writeFile(workbook, 'owner_billings_table.xlsx');
};

// Utility function for payment age 
export const calculatePaymentAge = (invoiceDate, payFullDate) => {
    if (!invoiceDate || !payFullDate) return '--';
    const invoice = new Date(invoiceDate);
    const paid = new Date(payFullDate);
    const timeDiff = paid - invoice;
    return Math.ceil(timeDiff / (1000 * 60 * 60 * 24)); 
};


// Formatting Number
export const formatNumber = (number) => {
    return new Intl.NumberFormat('en-US').format(number);
  };

// Format Date in MM/DD/YYYY format
export const formatDate = (dateString) => {
    if (dateString) {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString(undefined, options);
    }
  };

// Formats number to nearest USD amount (no cents)
export const formatDollar = (value) => {
    const roundedValue = Math.ceil(value);
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(roundedValue);
};

// Truncates Large Numbers to B, M, 100k, k, etc...
export const formatDollarShort = (value) => {
    // Check for invalid or NaN values and return $0 if found
    if (isNaN(value) || value === null || value === undefined) {
        return '$0';
    }

    const absValue = Math.abs(value);
    const sign = value < 0 ? '-' : '';

    if (absValue >= 1000000000) { // Check for billions
        return `${sign}$${(absValue / 1000000000).toFixed(2)}B`;
    } else if (absValue >= 1000000) { // Check for millions
        return `${sign}$${(absValue / 1000000).toFixed(2)}M`;
    } else if (absValue >= 100000) { // Check for hundreds of thousands
        return `${sign}$${(absValue / 1000).toFixed(0)}k`;
    } else if (absValue >= 1000) { // Check for thousands
        return `${sign}$${(absValue / 1000).toFixed(1)}k`;
    } else { // Case for values under 1000
        return `${sign}$${absValue.toFixed(0)}`; // Round to nearest dollar for values under 1k
    }
};

// Full Dollar Val
export const formatDollarFull = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
};

// Control Color of Text Field
export const getColorBasedOnValue = (formattedValue) => {
    // Regex removed currency and percentage symbols
    const cleanedValue = formattedValue.replace(/[$%(),]/g, '');

    // Extract Numberical Value
    const match = cleanedValue.match(/-?\d+(\.\d+)?/);

    if (match) {
        const numericValue = parseFloat(match[0]);

    // Determine color based on value
    if (numericValue > 0) {
        return 'green';
      } else if (numericValue < 0) {
        return 'red';
      } else {
        return 'black'; // or any default color
      }
    } else {
      // If no numeric value is found, return default color
      return 'black';
    }
}

export const colorCodeRed = (formattedValue) => {
    // Regex removed currency and percentage symbols
    const cleanedValue = formattedValue.replace(/[$%(),]/g, '');

    // Extract Numberical Value
    const match = cleanedValue.match(/-?\d+(\.\d+)?/);

    if (match) {
        const numericValue = parseFloat(match[0]);

    // Determine color based on value
   if (numericValue < 0) {
        return 'red';
      } else {
        return 'black';
      }
    } else {
      return 'black';
    }
}
// Numerical Checks
export const extractNumericValue = (formattedValue) => {
    if (!formattedValue || typeof formattedValue !== 'string') {
      return null;
    }
    // Strip to Number
    const cleanedValue = formattedValue.replace(/[$%(),]/g, '').trim();
  
    const match = cleanedValue.match(/-?\d+(\.\d+)?/);
  
    if (match) {
      const numericValue = parseFloat(match[0]);
  
      // Check for NaN
      if (!isNaN(numericValue)) {
        return numericValue;
      }
    }
  
    // If no numeric value is found or conversion fails, return null
    return null;
  };

  export const formatPercentage = (value) => {
    if (value === null || value === undefined) {
        return '--';
    }
    return `${value.toFixed(2)}%`;
};



