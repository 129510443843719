import React, { Component } from 'react';
import Axios from 'axios';
import { API_URL } from '../../utils/config/config';

import Header from '../../components/post-security/common/header';
import Sidebar from '../../components/post-security/common/sidebar';
import Projects from '../../components/post-security/project_view/projects';
import ProjectDetailedViewContainer from './ProjectDetailedViewContainer';
import { DEFAULT_SHOW_COLUMNS } from '../../components/post-security/project_view/constants';
import ProjectSidebar from '../../components/post-security/project_view/projectSidebar';
import Footer from '../../components/post-security/common/footer';
import { SFDC_STAGE_OPTIONS, SFDC_DIVISIONS, SFDC_COMPANIES } from '../../components/post-security/common/constants';


import {Typography, Button, Snackbar} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { Replay } from '@material-ui/icons';

import { withStyles } from '@material-ui/core';

const Styles = theme => ({
    root_grid: {
        display: 'grid',
        maxHeight: '100vh',
        'grid-template-rows': '4rem calc(100vh - 6rem) 2rem',
    },
    main: {
        'display': 'inline-flex',
        'padding-left': '70px',
        'padding-top': '20px',
        'padding-right': '0px',
        'overflow': 'hidden',
        'padding-bottom': '20px',
        // usually maxheight shouldnt dictate
        maxHeight: 'calc(100vh - 4rem)',
    },
    header: {
        zIndex: 999999,
    },    
    footer: {
        maxHeight: '2rem',
    }
});

class ProjectsViewContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pmResourceNames: [{id: '0', name: 'Loading....'}],
            project_view_data: {},
            filtered_project_view_data: {},
            loading: true,
            error: false,
            offline: false,
            filters: {'ops_leaders': [], 'divisions': [], 'companies': [], 'stages':[]},
            profile_data: [],
            snackbar_show: false,
            snackbar_message: '',
            snackbar_type: 'info',
            divisions: [],
            ops_leaders: [],
            stages: [],
            companies: [],
            showColumns: DEFAULT_SHOW_COLUMNS,
            showDetailedView: false,
            detail_view_project_info: null,
        }    
        this.toggleDetailView = this.toggleDetailView.bind(this)
        this.updateDetailView = this.updateDetailView.bind(this);
        this.updateFilters = this.updateFilters.bind(this);
        this.applyFilters = this.applyFilters.bind(this);
        this.updateShowColumns = this.updateShowColumns.bind(this);
        this.updateJobData = this.updateJobData.bind(this);
        this.fetchData = this.fetchData.bind(this);
    }

    toggleDetailView = (boo) => {
        this.setState({ showDetailedView: boo, detail_view_project_info: null});
    }

    updateDetailView = (values) => {
        this.setState({ detail_view_project_info: values});
    }

    // Changes the filter object in the state when filters are changed
    updateFilters = async (filter_type, new_values) => {
        var obj = {};
        obj[filter_type] = new_values;
        let new_filters = Object.assign({}, this.state.filters, obj);
        this.setState({ filters: new_filters});

        const { getAccessTokenSilently } = this.props.auth;
        const token = await getAccessTokenSilently();

        let data = {
            "user_id": localStorage.getItem('id'),
            "filters": new_filters
        };

        Axios.patch(
            `${API_URL}project_view_filters/${localStorage.getItem('id')}/`,
            data,
            {
                withCredentials: true,
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }            
        )

        this.applyFilters(new_filters);
    }
    // Applies the filters
    applyFilters = (filters) => {
        let filtered_data = this.state.project_view_data;
        for (const [key, value] of Object.entries(filters)) {
            if (value.length > 0) {
                let key_db = "";
                if (key === 'ops_leaders') key_db="sfdc_ops_leader";
                if (key === 'divisions') key_db=["sfdc_division", 'sfdc_second_fa_division', 'sfdc_third_fa_division', 'sfdc_fourth_fa_division'];
                if (key === 'companies') key_db=["sfdc_company", 'sfdc_second_fa_company', 'sfdc_third_fa_company', 'sfdc_fourth_fa_company'];
                if (key === 'stages') key_db="sfdc_stage";

                if ((key === 'divisions')||(key === 'companies')) {
                    filtered_data = filtered_data.filter(function (el) {
                        return ((value.includes(el[key_db[0]]))||(value.includes(el[key_db[1]]))||(value.includes(el[key_db[2]]))||(value.includes(el[key_db[3]])));
                    })    
                } else {
                    filtered_data = filtered_data.filter(function (el) {
                        return value.includes(el[key_db]);
                    })    
                } 
            };
        }
        this.setState({ filtered_project_view_data: filtered_data});
    }

    updateShowColumns = (new_values) => {this.setState({ showColumns: new_values});}

    fetchData = async () => {
        try {
            const { getAccessTokenSilently } = this.props.auth;
            const token = await getAccessTokenSilently();

            const responseFetchProjectView = await Axios.get(`${API_URL}project_view/`,
            {
                withCredentials: true,
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }    );

            // This part refreshed the detailed view project info to update the data for Project Detail View
            if (this.state.detail_view_project_info) {
                this.setState({
                    ...this.state,
                    detail_view_project_info: responseFetchProjectView.data.filter(e => e.id === this.state.detail_view_project_info.id)[0],
                    snackbar_show: true,
                    snackbar_message: 'Save Successful!',
                    snackbar_type: 'success',
                })
            }
            
            this.setState({
                ...this.state,
                loading: false,
                project_view_data: responseFetchProjectView.data,
                filtered_project_view_data: responseFetchProjectView.data,
                ops_leaders: ([...new Set(responseFetchProjectView.data.map(a => a.sfdc_ops_leader))]).sort().filter(Boolean).map(function(x) {return{'title': x}}),
                divisions: SFDC_DIVISIONS,
                companies: SFDC_COMPANIES,
                stages: SFDC_STAGE_OPTIONS,
            })
            this.applyFilters(this.state.filters);
        }
        catch (error) {
            this.setState({ ...this.state, error: error, loading: false });
        }
    }

    fetchPMResourceNames = async () => {
        try {
            const { getAccessTokenSilently } = this.props.auth;
            const token = await getAccessTokenSilently();

            const responseFetchPMResourceNames = await Axios.get(`${API_URL}pm_resources_names/`,            
            {
                withCredentials: true,
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            responseFetchPMResourceNames.data.sort((a, b) => (a.name > b.name) ? 1 : -1)
            
            this.setState({
                ...this.state,
                pmResourceNames: responseFetchPMResourceNames.data,
            })
        }
        catch (error) {
            console.log(error);
        }
    }

    fetchFilterData = async () => {
        if (localStorage.getItem('id')) {
            try {
                const { getAccessTokenSilently } = this.props.auth;
                const token = await getAccessTokenSilently();
    
                // This will cause an issue if a row doesnt exist in DB for that user. Should fix that someday.
                const responseFetchFilterData = await Axios.get(`${API_URL}project_view_filters/${localStorage.getItem('id')}/`,
                {
                    withCredentials: true,
                    headers: {
                        'content-type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }).then((response) => {
                    if (response.status === 200) {
                        let filters = response.data.filters;
                        delete(filters.column_options);
                        this.setState({
                            ...this.state,
                            filters: filters,
                        })
                        if (!this.state.loading) {
                            this.applyFilters(this.state.filters);
                        }    
                    }
                });

                // Fetching Profile Data
                const responseFetchProfileData = await Axios.get(`${API_URL}profile/`,
                {
                    withCredentials: true,
                    headers: {
                        'content-type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }).then((response) => {
                    if (response.status === 200) {
                        this.setState({
                            ...this.state,
                            profile_data: response.data,
                        })
                    }
                });

            }
            catch (error) {
                this.setState({ ...this.state, error: error });
            }    
        }
    }

    // Updating non assignment data for just Jobs model
    updateJobData = async (job_id, data) => {
        const { getAccessTokenSilently } = this.props.auth;
        const token = await getAccessTokenSilently();
        //Snackbar
        this.setState({snackbar_show: true});
        this.setState({snackbar_message: 'Saving...'});
        this.setState({snackbar_type: 'info'});

        Axios.patch(
            `${API_URL}projects/update/${job_id}/`,
            data,
            {
                withCredentials: true,
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }            
        )
        .then((response) => {
            // Following code updates the state - data that feeds into the table to show new date.
            if (response.status === 200) {
                //Snackbar
                this.setState({snackbar_show: true});
                this.setState({snackbar_message: 'Change Saved Successfully'});
                this.setState({snackbar_type: 'success'});
                
                const obj_index = this.state.project_view_data.findIndex(({ id }) => id === response.data.id);
                let obj = this.state.project_view_data[obj_index];
                Object.assign(obj, response.data);

                let current_data = this.state.project_view_data;
                current_data[obj_index] = obj;

                this.setState({project_view_data: current_data});
            }
        }
        )
        .catch(error => {
            console.log(error);
            //Snackbar
            this.setState({snackbar_show: true});
            this.setState({snackbar_message: 'Error Saving Change'});
            this.setState({snackbar_type: 'error'});
        })
    }

    componentDidMount() {
        document.title = "Project View";
        this.fetchData();
        this.fetchPMResourceNames();
        this.fetchFilterData();
    }

    componentDidUpdate() {
        if (!navigator.onLine) {
            console.log('offline');
            this.setState({ ...this.state, offline: true, loading: false });
        }
    }

    render() {        
        const { classes } = this.props;

        return (
            <>
                <div className={classes.root_grid}>
                    <div className={classes.header}>
                        {/* ***** Header Area ***** */}
                        <Header />
                    </div>
                    <div>
                        <Sidebar />
                        {this.state.offline ? (
                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                You are offline!
                            </div>
                        ) : this.state.error ? (
                                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                    <Typography variant="h5" style={{ fontFamily: 'inherit' }} gutterBottom>
                                        <strong>An error occured!</strong>
                                    </Typography>
                                    <center>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            onClick={() => window.location.reload()}
                                        >
                                            <Replay /> Try again
                                        </Button>
                                    </center>
                                </div>
                        ) : (        
                        <>
                        <Snackbar 
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            open={this.state.snackbar_show}
                            autoHideDuration={15000}
                            onClose={() => this.setState({snackbar_show: false})}
                        >
                            <MuiAlert elevation={6} variant="filled" severity={this.state.snackbar_type}>
                                {this.state.snackbar_message}
                            </MuiAlert>
                        </Snackbar>                            
                        <div className={classes.main}>
                            {/* ***** Main Banner Area ***** */}
                            <Projects                                  
                                project_view_data={this.state.filtered_project_view_data}
                                pmResourceNames={this.state.pmResourceNames}
                                loading={this.state.loading}
                                updateJobData={this.updateJobData}
                                filters={this.state.filters} 
                                showColumns={this.state.showColumns}
                                showDetailedView={this.state.showDetailedView}
                                detail_view_project_info={this.state.detail_view_project_info}
                                updateDetailView={this.updateDetailView}
                                toggleDetailView={this.toggleDetailView}
                            />
                            { this.state.showDetailedView ? (
                                    <>
                                        <ProjectDetailedViewContainer
                                            loading={this.state.loading}
                                            detail_view_project_info={this.state.detail_view_project_info}
                                            toggleDetailView={this.toggleDetailView}   
                                            pmResourceNames={this.state.pmResourceNames}  
                                            fetchData={this.fetchData}
                                            multiply_by_sfdc_probability={this.state.filters.multiply_by_sfdc_probability}
                                            profile_data={this.state.profile_data.find(e => e.id === this.state.detail_view_project_info.profile_type)}
                                            auth = {this.props.auth}
                                            />
                                    </>
                                ) : (
                                    <></>
                                )}
                            <ProjectSidebar
                                loading={this.state.loading}
                                filters={this.state.filters} 
                                divisions={this.state.divisions} 
                                ops_leaders={this.state.ops_leaders} 
                                stages={this.state.stages} 
                                companies={this.state.companies}
                                updateFilters={this.updateFilters} 
                                showColumns={this.state.showColumns}
                                updateShowColumns={this.updateShowColumns}
                            />
                        </div>
                        </>
                        )}
                    </div>
                    <div>
                        {/* ***** Footer ***** */}
                        <Footer className={classes.footer}/>
                    </div>                
                </div>

            </>
        );
    }
}

export default withStyles(Styles)(ProjectsViewContainer);
