import { getOverlappingDaysInIntervals } from 'date-fns'; 
import {
  START_YEAR,
  NUM_OF_YEARS,
  MONTH_NAMES,
  MONTHS_PER_YEAR,
  QUARTERS_PER_YEAR,
  MONTHS_PER_QUARTER,
  MONTH_START_END_DATES,
  PHASES
} from './constants'
import moment from 'moment';
import { hexToRgb, colourIsLight, addMonthsToYear, addMonthsToYearAsDate, randomColorTrack1 } from './utils'

export const buildQuarterCells = () => {
  const v = []
  for (let i = 0; i < QUARTERS_PER_YEAR * NUM_OF_YEARS; i += 1) {
    const quarter = (i % 4) + 1
    const startMonth = i * MONTHS_PER_QUARTER
    const s = addMonthsToYear(START_YEAR, startMonth)
    const e = addMonthsToYear(START_YEAR, startMonth + MONTHS_PER_QUARTER)
    v.push({
      id: `${s.year}-q${quarter}`,
      title: `Q${quarter} ${s.year}`,
      start: new Date(`${s.year}-${s.month}-01`),
      end: new Date(`${e.year}-${e.month}-01`),
    })
  }
  return v
}

export const buildMonthCells = () => {
  const v = []
  for (let i = 0; i < MONTHS_PER_YEAR * NUM_OF_YEARS; i += 1) {
    const startMonth = i
    const start = addMonthsToYearAsDate(START_YEAR, startMonth)
    const end = addMonthsToYearAsDate(START_YEAR, startMonth + 1)
    v.push({
      id: `m${startMonth}`,
      title: MONTH_NAMES[i % 12],
      start,
      end,
    })
  }
  return v
}

export const buildWeekCells = () => {
  const v = []
  for (let i = 0; i < 52*MONTHS_PER_YEAR * NUM_OF_YEARS; i += 1) {
    const week = moment().startOf('year').weekday(1).add(i, 'week')
    let week_number = week.week() - moment(week).startOf('month').week() + 1
    week_number = week_number===6 ? 1 : week_number

    v.push({
      id: `w${i}`,
      title: `W${week_number}`,
      start: week.toDate(),
      end: week.add(1, 'week').subtract(0, 'day').toDate(),
    })
  }

  return v
}

export const buildTimebar = () => [
  {
    id: 'quarters',
    title: 'Quarters',
    cells: buildQuarterCells(),
    style: {},
  },
  {
    id: 'months',
    title: 'Months',
    cells: buildMonthCells(),
    useAsGrid: true,
    style: {},
  },
  {
    id: 'weeks',
    title: 'Weeks',
    cells: buildWeekCells(),
    useAsGrid: true,
    style: {},
  },
]

export const buildTrack1Element = ({ trackId, j, elementData }) => {
  const start = new Date(elementData.start);
  const end = new Date(elementData.end);
  const bgColor = elementData.title;
  const color = colourIsLight(...hexToRgb(bgColor)) ? '#000000' : '#ffffff'
  return {
    id: `t-${trackId}-el-${j}`,
    title: '',
    start,
    end,
    tooltip: '',
    style: {
      backgroundColor: bgColor,
      color,
      borderRadius: '2px',
      // boxShadow: '1px 1px 0px rgba(0, 0, 0, 0.25)',
      textTransform: 'capitalize',
    },
  }
}

export const buildElement = ({ trackId, trackData, elementLabel, color }) => {

    let tooltip = JSON.stringify(trackData).replace('{','').replace('}','').replace(/['"]+/g,'').split(",").filter(e => e.split(':')[0] !== 'max_date' && e.split(':')[0] !== 'min_date').map(e => {
      let a = e.split(':')
      return (a[0].split('<<>>')[0]+': '+a[1])
    }).join("\n")

    return {
        id: `t-${trackId}-el-${0}`,
        title: elementLabel,
        start: new Date(trackData.min_date),
        end: new Date(trackData.max_date),
        // start: moment(trackData.max_date,'YYYY-MM-DD').toDate(),
        // end: moment(trackData.max_date,'YYYY-MM-DD').toDate(),
        tooltip: tooltip,
        // tooltip: (`${trackData.project_name}\nPhase: ${trackData.type}\nStage: ${trackData.stage}\nStart: ${new Date(elementData.start).toISOString().slice(0, 10)}\nEnd: ${new Date(elementData.end).toISOString().slice(0, 10)}\nCalculated %FTE: ${elementData.height}`),
        style: {
            backgroundColor: color,
            color: colourIsLight(...hexToRgb(color)) ? '#000000' : '#ffffff',
            borderRadius: '4px',
            textTransform: 'capitalize',
            position: 'relative',
            top: '100%',
            transform: 'translateY(-100%)'
        },
    }
}

export const buildElements = (type, id, trackId, trackData,subTrackKey='', color) => {
  const v = []
  if (type === 'subtrack') {
    v.push(buildElement({trackId, trackData, elementLabel:subTrackKey, color}))  
  } else if (type === 'track1') {
    if (trackData.length > 0) {
      for (let i = 0; i< trackData.length; i++) {
        let j = i+1;
        const elementData = trackData[i];
          v.push(
            buildTrack1Element({
              trackId,
              j,
              elementData,
            })
          )  
      }
    }
  }    
  return v
}

export const buildSubtrack = (trackId, subtrackId, subTrackKey, subTrackData, color) => {
  return ({
    id: `track-${trackId}-${subtrackId}`,
    title: subTrackKey,
    elements: buildElements('subtrack',`track-${trackId}-${subtrackId}`, subtrackId, subTrackData,subTrackKey, color),
  })
}

export const buildTrack = (trackId, trackData) => {
    let data = trackData;
    let multiTrackData = [];
    let track1_data = MONTH_START_END_DATES();

    // Massaging the data
    let organisedTrackData = {}
    Object.keys(data).map(key => {
      let headers = key.split('<<>>')
      if (!Object.keys(organisedTrackData).includes(headers[0])) {
        organisedTrackData[headers[0]] = {}
      }      
      organisedTrackData[headers[0]][headers[1]+'<<>>'+headers[2]] = data[key]
    })

    let tracks = []
    let index = 0

    let phases_in_this_project = Object.keys(organisedTrackData)
    let phase_dates = []

    PHASES.map(e => e.name).forEach(phase => {
      if (phases_in_this_project.includes(phase)) {
        index += 1
        let subtrack_data = organisedTrackData[phase];
        let filtered_subtrack = Object.keys(subtrack_data).filter(key => key.split('<<>>')[1] !== "F")
              .reduce((acc, key) => {
                 acc[key] = subtrack_data[key];
                 return acc;
              }, {});
        let elementDates = Object.values(filtered_subtrack).map(e => moment(e, "YYYY-MM-DD").toDate()).filter(e => e instanceof Date && !isNaN(e))
        let min_date = new Date(Math.min.apply(null, elementDates))
        let max_date = new Date(Math.max.apply(null, elementDates))

        let color = PHASES.find(e => e.name === phase).color

        subtrack_data['min_date'] = min_date
        subtrack_data['max_date'] = max_date

        phase_dates.push({start:min_date, end:max_date, title:phase})

        if (min_date && max_date) {
          let temp = buildSubtrack(trackId, index, phase, subtrack_data,color)
          tracks.push(temp)
        }
      }
    })

    // Track 1 calcs
    track1_data.forEach(month_element => {
      let start_date = moment(month_element.start, "YYYY-MM-DD").toDate()
      let end_date = moment(month_element.end, "YYYY-MM-DD").toDate()
      month_element.title = '#EBECF0'

      phase_dates.forEach(phase => {
        try {
          let overlap = getOverlappingDaysInIntervals(
            { start: start_date, end: end_date },
            { start: phase.start, end: phase.end },
            true
          );
          if (overlap>0) {
            month_element.title = PHASES.find(e => e.name === phase.title).color
          }
        } catch (error) {
        }        
      })
    })

    // console.log(tracks)

    return {
      id: `track-${trackId}`,
      title: trackData.nan,
      elements: buildElements('track1',`track-${trackId}`, trackId,track1_data),
      // arconnect_dp: trackData.arconnect_dp,
      tracks,
      // hasButton: true,
      // link: 'www.google.com',
      isOpen: false,
      style: {
          backgroundColor: '#FFC0CB',
      }
    }
}
