import React, { Component } from 'react';
import Axios from 'axios';
import _ from 'lodash';

import { API_URL } from '../../../../utils/config/config';

import Activities from '../../../components/post-security/new-lesson/activities';

import {Typography, Button} from '@material-ui/core';
import { Replay } from '@material-ui/icons';
import { withStyles } from '@material-ui/core';

const Styles = theme => ({
    main: {
        'display': 'inline-flex',
        'overflow': 'hidden',
        'padding-bottom': document.title === 'Lessons Learned' ? '20px' : '5px',
    },
});

class LLViewContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: false,
            offline: false,
            ll_view_data_og: [],
            ll_view_data: [],
            search_activities: null,
            collapsed_activities: [],
            safety: false,
            reviewed: false,
            divisions: ['general'],
        };
        this.fetchLLViewData = this.fetchLLViewData.bind(this);
        this.updateLLViewData = this.updateLLViewData.bind(this);
        this.updateLLViewSettings = this.updateLLViewSettings.bind(this);
    }

    // Fetches the LLView Data
    fetchLLViewData = async () => {
        try {
            const { getAccessTokenSilently } = this.props.auth;
            const token = await getAccessTokenSilently();

            const responseFetchLLView = await Axios.get(`${API_URL}activities/`, {
                withCredentials: true,
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            this.setState({
                ...this.state,
                loading: false,
                ll_view_data: [...responseFetchLLView.data].reverse(),
                ll_view_data_og: [...responseFetchLLView.data].reverse()
            })
        }
        catch (error) {
            this.setState({ ...this.state, error: error, loading: false });
        }
    }

    // Updates the data on screen based on wbs show/hide, filters
    updateLLViewSettings = (type, new_value) => {
        if (type === 'activity_collapse') {
            let new_collapsed_activities = this.state.collapsed_activities;
            const index = new_collapsed_activities.indexOf(new_value);
            if (index > -1) {
                // Activity already existed. Now removing from collapsed list
                new_collapsed_activities.splice(index, 1);
            } else {
                // Activity not existed in array. Now adding to collapsed list
                new_collapsed_activities.push(new_value);                
            }
            this.setState({collapsed_activities: new_collapsed_activities}, () => {
                this.updateLLViewData()
            });
        } else if (type === 'from_activity_search') {
            this.setState({collapsed_activities: [], search_activities: new_value}, () => {
                this.updateLLViewData()
            })
        }
    }

    updateLLViewData = () => {

        let temp = _.cloneDeep(this.state.ll_view_data_og);

        // filtering based on divisions
        temp = temp.filter(e => {
            if (e.general) {
                return true
            } else {
                this.state.divisions.forEach(division => {
                    if (e[division]) {
                        return true
                    }
                })
                return false
            }
        })

        // filtering based on collapsed activities
        if (!this.state.search_activities) {
            this.state.collapsed_activities.forEach(collapsed => {
                temp = temp.filter(e => {
                    if (typeof(e.ancestry) === "string") {
                        return !e.ancestry.split(',').includes(collapsed.toString())
                    }
                    return true
                })
            })
        }

        // filtering based on activities search
        if (this.state.search_activities) {
            // Finding the actual activities that pass the filter -> getting thier ids and ancestry (which is comma separated ids of parents+ancestors)
            let temp1 = temp.filter(e => e.title.toLowerCase().includes(this.state.search_activities.toLowerCase())).map(e => ({id: e.id, parents: e.ancestry}))

            // Converting the above found values into a flat array.
            // visible items = activities that pass filters + their ancestors
            let visible = temp1.map(e => e.id) + [...new Set(temp1.map(e => e.parents).filter(m => m).map(e => e.split(',').map(k => parseInt(k))).flat(1))]
            
            temp = temp.filter(e => visible.includes(e.id))
        }

        // Filtering LESSONS for reviewed, safety, divisions
        temp.forEach(activity => {
            let temp_lessons = _.cloneDeep(activity.link_activity);

            // Divisions filtering
            if (JSON.stringify(this.state.divisions) !== JSON.stringify(['general'])) {
                temp_lessons = temp_lessons.filter(e => this.state.divisions.includes(e.lesson_division))
            }
            // Reviewed filtering
            if (this.state.reviewed) {
                temp_lessons = temp_lessons.filter(e => e.lesson_reviewed === true)
            }
            // Safety filtering
            if (this.state.safety) {
                temp_lessons = temp_lessons.filter(e => e.lesson_safety === true)
            }

            activity.link_activity = temp_lessons;
        })
        
        this.setState({ ...this.state, ll_view_data: temp});
    }

    componentDidMount() {
        this.fetchLLViewData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.ll_settings) {
            if ((this.props.ll_settings.safety !== prevProps.ll_settings.safety)||(this.props.ll_settings.reviewed !== prevProps.ll_settings.reviewed)||(JSON.stringify(this.props.ll_settings.divisions) !== JSON.stringify(prevProps.ll_settings.divisions))) {
                    this.setState( {
                        ...this.state,
                        safety: this.props.ll_settings.safety,
                        reviewed: this.props.ll_settings.reviewed,
                        divisions: this.props.ll_settings.divisions,
                    }, function() {
                        this.updateLLViewData();
                    })  
                }
        }
    }
    render() {

        const { classes } = this.props;        
        return (
            <>
                {this.state.offline ? (
                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                        You are offline!
                    </div>
                ) : this.state.error ? (
                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                        <Typography variant="h5" style={{ fontFamily: 'inherit' }} gutterBottom>
                            <strong>An error occured!</strong>
                        </Typography>
                        <center>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() => window.location.reload()}
                            >
                                <Replay /> Try again
                            </Button>
                        </center>
                    </div>
                ) : (      
                    <>
                        <div className={classes.main}>
                            {/* ***** Main Banner Area ***** */}
                            {/* <Paper className={classes.pm_view}> */}
                                <Activities 
                                    loading={this.state.loading}
                                    activity_view_data={this.state.ll_view_data}

                                    updateLLViewSettings = {this.updateLLViewSettings}
                                    collapsed_activities = {this.state.collapsed_activities}

                                    selectedActivities={this.props.selectedActivities}
                                    setSelectedActivities={this.props.setSelectedActivities}
                                />
                        </div>
                    </>
                )}
            </>
        );
    }
}

export default withStyles(Styles)(LLViewContent)