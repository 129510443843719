import React from "react";

const AuthenticationFallbackUI = () => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100vw',
            color: 'red',
            fontSize: '2em',
        }}>
            <h4>We cannot log you in right now!</h4>
        </div>
    )
}

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        // Example "componentStack":
        //   in ComponentThatThrows (created by App)
        //   in ErrorBoundary (created by App)
        //   in div (created by App)
        //   in App
        console.error("componentDidCatch: error: ", error);
        console.error("componentDidCatch: info: ", info.componentStack);
    }

    render() {
        if (this.state.hasError) {
        // You can render any custom fallback UI
            return this.props.fallback;
        }

        return this.props.children;
    }
}

export default function AuthenticationErrorBoundary({ children })  {
    return (
        <ErrorBoundary fallback={AuthenticationFallbackUI}>
            {children}
        </ErrorBoundary>
    )
}