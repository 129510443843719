import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import BouncingPreloader from '../common/bouncyLoader';

import PMGantt from './pm_gantt/pm_gantt';
import { red } from '@material-ui/core/colors';

// Styles for overall page
const useStyles = makeStyles(theme => ({
    paper: {
        maxWidth: '95vw',
        minHeight: '85vh',
    },
    pm_gantt: {
      backgroundColor: red,
    }
}));
 
//Function that pulls the data and sends it to the Table Renderer
const PMs = props => {
    let {pm_view_data, loading, showDetailedView, toggleDetailView, updateDetailView, filters, sort_by, handleSortByChange} = props;
    const classes = useStyles();

    return (
        <>
          {loading ? (
            <>
              <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <BouncingPreloader />
              </div>
            </>
          ) : (
            <Paper elevation={7} className={classes.paper} key={pm_view_data}>
                <PMGantt 
                  data={pm_view_data} 
                  sort_by={sort_by}
                  handleSortByChange={handleSortByChange}
                  className={classes.pm_gantt}
                  showDetailedView={showDetailedView}
                  toggleDetailView={toggleDetailView}
                  updateDetailView={updateDetailView}
                  filters={filters}
                />
            </Paper>
          )}
        </>
    );
};


export default PMs;