import React from 'react';
import { Grid, Card, CardContent, Typography } from '@material-ui/core';

import StartDateCard from './StartDateComponent';
import TCODateCard from './TCODateComponent';
import ProjectedEndDateCard from './ProjectedEndDate';
import ProjectTypeCard from './ProjectTypeComponent';
import SquareFootageCard from './SquareFootageComponent';
import PriceSFCard from './PricePerSFComponent';
import ProjectStageCardSmall from './ProjectStatusSmallComponent';
import ProjectDurationCard from './ProjectDurationComponent';

const MasterProjectInfoCard = ({ projectData, onStageColorChange }) => {
  return (
      <CardContent>
        <Grid container spacing={2} style={{ width: '100%', justifyContent: 'center' }}>
          <Grid item xs={4}>
              <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center' }}>Project Type</Typography>
              <ProjectTypeCard projectData={projectData} />
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center' }}>Project Status</Typography>
              <ProjectStageCardSmall projectData={projectData} onStageColorChange={onStageColorChange} />
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center', marginBottom: '0px' }}>Square Footage</Typography>
              <span className='ppsf-container'><SquareFootageCard projectData={projectData} /> <PriceSFCard projectData={projectData} /></span>
            </Grid>
          
            <Grid item xs={4}>
              <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center' }}>Start Date</Typography>
              <StartDateCard projectData={projectData} />
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center' }}>Projected Finish</Typography>
              <ProjectedEndDateCard projectData={projectData} />
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center' }}>Duration</Typography>
              <ProjectDurationCard projectData={projectData} />
            </Grid>
            
           
          
        </Grid>
      </CardContent>
  );
}

export default MasterProjectInfoCard;
