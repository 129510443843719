import React, { Component } from 'react';
import Header from '../../../components/post-security/common/header';
import LessonsViewContent from './LessonsViewContent';

import { withStyles } from '@material-ui/core';

const Styles = theme => ({
    root_grid: {
        display: 'grid',
        height: '100vh',
        'grid-template-rows': '48px auto',
        // overflowY: 'auto',
    },
    header: {
        zIndex: 999999,
    }
});

class LessonsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
    }

    // Locally changing settings

    componentDidMount() {
        document.title = "Lessons View";
    }

    render() {  

        const { classes } = this.props;        

        return (
            <>
                <div className={classes.root_grid}>
                    <div className={classes.header}>
                        {/* ***** Header Area ***** */}
                        <Header />
                    </div>
                    <div>
                        <LessonsViewContent 
                            getAccessTokenSilently = {this.props.auth}
                        />
                    </div>
                </div>
            </>
        );
    }
}

export default withStyles(Styles)(LessonsView)