import React, { useState, useEffect} from 'react';

import {makeStyles, Paper, Typography, Button, TextField, Tooltip, Avatar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import salesforce_logo from "../salesforce_logo.png";

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns'; 
import currency from 'currency.js';
import moment from 'moment';

import ProjectDetailGantt from './project_detail_gantt/project_detail_gantt';
import { track } from 'logrocket';
import { filter } from 'lodash';


// Styles for overall page
const useStyles = makeStyles(theme => ({
    project_detail_view: {
        paddingLeft: document.title === 'Project View' ? '1rem' : '',
        maxHeight: '90%',
    },
    paper: {
        padding: '10px',
    },
    top_grid: {
        display: 'grid',
        gridGap: '5px',
        gridTemplateColumns: 'repeat(3, 1fr)',
    },
    project_grid: {
        display: 'grid',
        gridGap: '5px',
        gridTemplateColumns: 'repeat(4, 1fr)',
    },
    bottom_grid: {
        padding: '10px',
    }
}));

// Provides Initial Tracks Data, called reset because resets to original data upon EDIT -> CANCEL
const resetTracksData = (detail_view_project_info, start_up, groundbreaking, end, close_out) => {
    // Data being fed to Timeline Component
    let initialTracksData = [];
    // Project Assignments data:
    detail_view_project_info.requirements_jobs.forEach(e => {
        initialTracksData.push({...{
            // job_id: detail_view_project_info.id,
            start_up: start_up,
            groundbreaking: groundbreaking,
            end: end,
            close_out: close_out,
            sfdc_probability: detail_view_project_info.sfdc_probability,
        }, ...e});        
    });

    return(initialTracksData);
}

const ProjectDetailView = props => {
    let { loading, detail_view_project_info, toggleDetailView, pmResourceNames, projectEdits, updateProjectEdits, assignmentEdits, updateAssignmentEdits, saveDetailData, multiply_by_sfdc_probability, profile_data } = props;
    const classes = useStyles();

    const [editMode, setEditMode] = useState(false);
    const [tracksData, setTracksData] = useState([]);

    let temp_date = new Date(detail_view_project_info.sfdc_groundbreaking);
    temp_date.setHours(temp_date.getHours()+21);
    const [groundbreaking, setGroundbreaking] = useState(detail_view_project_info.sfdc_groundbreaking ? temp_date : null);
    let start_up = groundbreaking ? new Date(groundbreaking.getTime() - detail_view_project_info.sfdc_startup_duration*24*60*60*1000) : null;

    temp_date = new Date(detail_view_project_info.sfdc_project_completion_date);
    temp_date.setHours(temp_date.getHours()+21);
    const [end, setEnd] = useState(detail_view_project_info.sfdc_project_completion_date ? temp_date : null);
    let close_out = end ? new Date(end.getTime() + detail_view_project_info.sfdc_close_out_duration*24*60*60*1000) : null;

    const rem_value = Math.round(window.innerWidth)/120;
    const [add_row_pm_id, setAdd_row_pm_id] = useState(353);
    const [add_row_pm_name, setAdd_row_pm_name] = useState("PM TBD");

    // Anytime User chooses a new project, it empties the project edit data
    useEffect(() => {
        updateProjectEdits({});
        updateAssignmentEdits([]);
        setEditMode(false);
        // This force updates dates when a new project is chosen
        let new_groundbreaking = null;
        if (props.detail_view_project_info.sfdc_groundbreaking) {
            new_groundbreaking = new Date(props.detail_view_project_info.sfdc_groundbreaking);
            new_groundbreaking.setHours(temp_date.getHours()+22);
        }
        setGroundbreaking(new_groundbreaking);
        start_up = new_groundbreaking ? new Date(new_groundbreaking.getTime() - props.detail_view_project_info.sfdc_startup_duration*24*60*60*1000) : null;
    
        let new_end = null;
        if (props.detail_view_project_info.sfdc_project_completion_date) {
            new_end = new Date(props.detail_view_project_info.sfdc_project_completion_date);
            new_end.setHours(temp_date.getHours()+21);
        }
        setEnd(new_end);
        close_out = new_end ? new Date(new_end.getTime() + props.detail_view_project_info.sfdc_close_out_duration*24*60*60*1000) : null;

        // Data being fed to Timeline Component
        let initialTracksData = resetTracksData(detail_view_project_info, start_up, new_groundbreaking, new_end, close_out);
        let item_order = ['pm_precon', 'pm_proposal', 'pm_design', 'pm_buyout', 'pm_leader', 'additional_pm', 'superintendent','project_assistant'];
        initialTracksData.sort((a,b) => item_order.indexOf(a.requirement_type) - item_order.indexOf(b.requirement_type));
        setTracksData(initialTracksData);

        if (initialTracksData.length > 0) {
            if (initialTracksData.findIndex(a => a.requirement_type === 'pm_leader') > 0) {
                setAdd_row_pm_id(initialTracksData[initialTracksData.findIndex(a => a.requirement_type === 'pm_leader')].assignment_resource)
                setAdd_row_pm_name(initialTracksData[initialTracksData.findIndex(a => a.requirement_type === 'pm_leader')].full_name)
            } else {
                setAdd_row_pm_id(initialTracksData[0].assignment_resource)
                setAdd_row_pm_name(initialTracksData[0].full_name)
            }
        } else {
            if (pmResourceNames.find(e => e.full_name === 'PM TBD')) {
                setAdd_row_pm_id(pmResourceNames.find(e => e.full_name === 'PM TBD').assignment_resource);
            }
        }
    },[props.detail_view_project_info]);
   
    return(
        <div className={classes.project_detail_view}>
            {loading ? (<></>) : (
                <Paper 
                    className={classes.paper}
                    elevation={7}
                    style={{
                        height: document.title === 'Project View' ? document.getElementById('project_view') ? document.getElementById('project_view').clientHeight : 400 : '',
                        width: document.title === 'Project View' ? '68vw' : '',
                        overflowY: document.title === 'Project View' ? 'scroll' : 'auto',
                    }}
                >
                    <div style={{float: 'right'}}>
                        {!editMode && (
                            <>
                            <Button id='Project_Detail_View_Edit' variant='outlined' size='small' onClick={() => setEditMode(true)}>
                                edit
                            </Button>
                            &nbsp; 
                            &nbsp;
                            <CloseIcon style={{position: 'relative', top:'7px'}} onClick={() => toggleDetailView(false)}/>
                            </>
                        )}
                    </div>
                    <div style={{display: 'flex'}}>
                        <Typography variant={'h4'}>{detail_view_project_info.sfdc_opportunity_name}</Typography>
                        &nbsp;
                        <Tooltip title='Open Opportunity in Salesforce'>
                        <Button 
                            style={{padding: '0px', minWidth: '50px'}}
                            aria-label="salesforce"
                            startIcon={
                                <Avatar
                                    style={{height:'35px', width: '35px'}} 
                                    variant="square"
                                    src={salesforce_logo} 
                                />}
                            onClick={() => {window.open(`https://arcomurray.lightning.force.com/lightning/r/Opportunity/${detail_view_project_info.sfdc_id}/view`, "_blank")}}
                        />
                    </Tooltip>

                        <br />
                    </div>
                    <div className={classes.top_grid}>
                        <Typography variant={'body1'}><b>Account:</b> {detail_view_project_info.sfdc_account_name}</Typography>
                        <Typography variant={'body1'}><b>Location:</b> {detail_view_project_info.sfdc_city}, {detail_view_project_info.sfdc_state}</Typography>
                        <Typography variant={'body1'}><b>Stage:</b> {detail_view_project_info.sfdc_stage}</Typography>
                        <Typography variant={'body1'}><b>Ops Leader:</b> {detail_view_project_info.sfdc_ops_leader}</Typography>
                        <Typography variant={'body1'}><b>Division:</b> {detail_view_project_info.sfdc_division}</Typography>
                        <Typography variant={'body1'}><b>Company:</b> {detail_view_project_info.sfdc_company}</Typography>
                        <Typography variant={'body1'}><b>Amount:</b> {currency(detail_view_project_info.sfdc_amount, {precision: 0}).format()}</Typography>
                        <Typography variant={'body1'}><b>Est. Margin:</b> {detail_view_project_info.sfdc_estimated_margin}%</Typography>
                        <Typography variant={'body1'}><b>Cost Status Profit:</b> {currency(detail_view_project_info.sfdc_cost_status_profit, {precision: 0}).format()}</Typography>
                    </div>
                    <br />

                    {/* Key Dates */}
                    <Typography variant={'h6'}><b>Key Dates:</b></Typography>
                    <div className={classes.project_grid}>
                        <Typography variant={'body1'}><b>Startup:</b> {moment(start_up).format('MMM-DD-YYYY')}</Typography>
                        <div style={{display: 'inline-flex'}}>
                            { !editMode && (
                                <Typography variant={'body1'}><b>Groundbreaking:</b> {moment(groundbreaking).format('MMM-DD-YYYY')}</Typography>
                            )}
                            { editMode && (
                                <>
                                    <Typography variant={'body1'}><b>Groundbreaking:&nbsp;</b></Typography>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        id={'Groundbreak_Date_Updated'}
                                        autoOk={true}
                                        disableToolbar
                                        variant="inline"
                                        emptyLabel=""
                                        value={groundbreaking}
                                        onChange={(e) => {
                                            // setValue(new Date(e).toISOString().slice(0, 10));
                                            let a = new Date(e).toLocaleDateString();
                                            setGroundbreaking(new Date(a));
                                            updateProjectEdits({...projectEdits, ...{sfdc_groundbreaking: new Date(a).toISOString().slice(0, 10)}})
                                        }}
                                        format="MMM-dd-yyyy"
                                        inputProps={{
                                            style: {
                                            maxWidth: '7rem',
                                            padding: 1
                                            }
                                        }}

                                    />
                                    </MuiPickersUtilsProvider>
                                </>
                            )}


                        </div>
                        <div style={{display: 'inline-flex'}}>
                            { !editMode && (
                                <Typography variant={'body1'}><b>Completion:</b> {moment(end).format('MMM-DD-YYYY')}</Typography>
                        )}
                            { editMode && (
                                <>
                                    <Typography variant={'body1'}><b>Completion:&nbsp;</b></Typography>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        id={'Project_Completion_Date_Updated'}
                                        autoOk={true}
                                        disableToolbar
                                        variant="inline"
                                        emptyLabel=""
                                        value={end}
                                        onChange={(e) => {
                                            // setValue(new Date(e).toISOString().slice(0, 10));
                                            let a = new Date(e).toLocaleDateString();
                                            setEnd(new Date(a));
                                            updateProjectEdits({...projectEdits, ...{sfdc_project_completion_date: new Date(a).toISOString().slice(0, 10)}})
                                        }}
                                        format="MMM-dd-yyyy"
                                        inputProps={{
                                            style: {
                                            maxWidth: '7rem',
                                            padding: 1
                                            }
                                        }}
                                    />
                                    </MuiPickersUtilsProvider>
                                </>
                            )}
                        </div>

                        
                        <Typography variant={'body1'}><b>Closeout:</b> {moment(close_out).format('MMM-DD-YYYY')}</Typography>
                        <div style={{display: 'inline-flex'}}>
                            { !editMode && (
                                <Typography variant={'body1'}><b>Startup Duration:</b> {detail_view_project_info.sfdc_startup_duration} Days</Typography>
                            )}
                            { editMode && (
                                <>
                                    <Typography variant={'body1'}><b>Startup Duration:&nbsp;</b></Typography>
                                    <TextField
                                        inputProps={{
                                            style: {
                                            maxWidth: '2.5rem',
                                            padding: 1
                                            }
                                        }}
                                        defaultValue={detail_view_project_info.sfdc_startup_duration}
                                        onBlur={e => updateProjectEdits({...projectEdits, ...{sfdc_startup_duration: parseInt(e.target.value)}})
                                    }
                                    />
                                    <Typography variant={'body1'}>Days</Typography>
                                </>
                            )}
                        </div>
                        <Typography variant={'body1'}></Typography>
                        <Typography variant={'body1'}></Typography>
                        <div style={{display: 'inline-flex'}}>
                        { !editMode && (
                            <Typography variant={'body1'}><b>Closeout Duration:</b> {detail_view_project_info.sfdc_close_out_duration} Days</Typography>
                        )}
                        { editMode && (
                                <>
                                    <Typography variant={'body1'}><b>Closeout Duration:&nbsp;</b></Typography>
                                    <TextField
                                        inputProps={{
                                            style: {
                                            maxWidth: '2.5rem',
                                            padding: 1
                                            }
                                        }}
                                        defaultValue={detail_view_project_info.sfdc_close_out_duration}
                                        onBlur={e => updateProjectEdits({...projectEdits, ...{sfdc_close_out_duration: parseInt(e.target.value)}})
                                    }
                                    />
                                    <Typography variant={'body1'}>Days</Typography>
                                </>
                            )}
                        </div>

                    </div>
                    <br />
                    <div className={classes.bottom_grid}>
                        {(groundbreaking && end) && (
                            <ProjectDetailGantt  
                                data={tracksData} 
                                editMode={editMode} 
                                assignmentEdits={assignmentEdits} 
                                setAssignmentEdits={updateAssignmentEdits} 
                                pmResourceNames={pmResourceNames}
                                multiply_by_sfdc_probability={multiply_by_sfdc_probability}
                                profile_data={profile_data}
                            />
                        )}
                        <br />
                        <br />
                        {((tracksData.length > 0) && multiply_by_sfdc_probability) && (
                            <>Calculated %FTE: % Probability of the Project (based on SFDC Stage) x %FTE Assignment &nbsp;(% Probability not applicable to Precon, Permitting, Proposal and Design assignments)<br />
                            </>
                        )}
                        <>Please add only one PM Leader (Salesforce only accepts one PM Leader assignment).</>
                    {editMode && (
                        <div>
                        <br />
                        <Button  variant='outlined' size='small' onClick={() => {
                            let temp = [];
                            temp.push({
                                changeType: 'add',
                                start_up: start_up,
                                groundbreaking: groundbreaking,
                                end: end,
                                close_out: close_out,
                                requirement_start_date: null,
                                requirement_end_date: null,
                                name: add_row_pm_name,
                                assignment_resource: add_row_pm_id,
                                requirement_qty: 1,
                                requirement_type: 'additional_pm',
                            }); 
                            setTracksData([...tracksData, ...temp]);
                        }}>
                            Add Row
                        </Button>
                        <div style={{float: 'right'}}>
                            <Button variant='contained' style={{background: '#093d61', color:'white'}} size='small' onClick={() => {
                                setEditMode(false);
                                let current_pa_requirement_id = null;

                                // -----------------------------------------------------------------------------------------------------------------
                                // Getting pm_leader and additional_pms for Job Object
                                let pm_leader = null;
                                let additional_pms = null;

                                // Indexes of all tracks that were edited. Index match index of tracksData
                                let editedTrackIds = assignmentEdits.map(e => parseInt(e.id.split('-')[1])-1);

                                // Getting PM_Leader and Additional_PMs
                                tracksData.forEach((original, index) => {
                                    console.log('----->')
                                    console.log(original)

                                    // For every track, check if there was an edit on that track. If there was use that value. Otherwise older.
                                    if (editedTrackIds.includes(index)) {
                                        let editedTrack = (assignmentEdits.filter(tr => parseInt(tr.id.split('-')[1])-1 === index))[0];
                                        if ((editedTrack.delete !== true) && (editedTrack.type === 'pm_leader')) {
                                            pm_leader = pmResourceNames.find(obj => obj['full_name'] === editedTrack.title).sfdc_resource_name;
                                        }
                                        if ((editedTrack.delete !== true) && (editedTrack.type === 'additional_pm')) {
                                            let additional_pm_resource_name = pmResourceNames.find(obj => obj['full_name'] === editedTrack.title).sfdc_resource_name;
                                            additional_pms = additional_pms ? additional_pms + ';' + additional_pm_resource_name: additional_pm_resource_name;
                                        }
                                    } else {
                                        if (original.requirement_type === 'pm_leader') {
                                            pm_leader = original.sfdc_resource_name;
                                        }
                                        if (original.requirement_type === 'additional_pm') {
                                            additional_pms = additional_pms ? additional_pms + ';' + original.sfdc_resource_name : original.sfdc_resource_name;
                                        }
                                    }

                                    // Saving the PA Requirement id. Assuming there's only one PA.
                                    if (original.requirement_type === 'project_assistant') {
                                        current_pa_requirement_id = original.requirement_id;
                                    }
                                });
                                // Running the loop again for new added rows
                                assignmentEdits.forEach((edit)=> {
                                    if (parseInt(edit.id.split('-')[1] > tracksData.length)) {
                                        if ((edit.delete !== true) && (edit.type === 'pm_leader')) {
                                            pm_leader = pmResourceNames.find(obj => obj['full_name'] === edit.title).sfdc_resource_name
                                        }
                                        if ((edit.delete !== true) && (edit.type === 'additional_pm')) {
                                            let additional_pm_resource_name = pmResourceNames.find(obj => obj['full_name'] === edit.title).sfdc_resource_name;
                                            additional_pms = additional_pms ? additional_pms + ';' + additional_pm_resource_name : additional_pm_resource_name;
                                        }
                                    }
                                })

                                // -----------------------------------------------------------------------------------------------------------------
                                // Save all data
                                saveDetailData(detail_view_project_info.id, projectEdits, pm_leader, additional_pms, current_pa_requirement_id);
                            }}
                            >
                                save
                            </Button>
                            &nbsp; 
                            &nbsp;
                            <Button variant='outlined' size='small' 
                                onClick={() => {
                                    setEditMode(false);
                                    toggleDetailView(false);
                                    updateProjectEdits({});
                                    updateAssignmentEdits([]);
                                    // Resets data to original data:
                                    let initialTracksData = resetTracksData(detail_view_project_info, start_up, groundbreaking, end, close_out);
                                    setTracksData(initialTracksData);                            
                                }}
                            >
                                cancel
                            </Button>
                        </div>
                        </div>     
                    )}
                    </div>
                </Paper>
            )}
        </div>
    )
}

export default ProjectDetailView;