import React from "react";
import procoreLogo from '../static/procore_logo.png';
import sfdcLogo from '../static/sfdc-logo.png';
import hubDash from '../static/hub_dash_icon.svg';

import { Link } from "@mui/material";

// Dev Note: Passing links with noreferrer to help with security. May need to get rid of this if we want ever want analytics on these.

// TODO: Verify Arco_id and hub

const ProjectExternalLinks = ({projectData, selectedProjectId}) => {

    if (!projectData || !projectData.project_data || (!projectData.project_data.procore_id && !projectData.project_data.sfdc_id && !projectData.project_data.arco_id && !selectedProjectId)) {
        return null;
    }

    const procoreURL = `https://app.procore.com/projects/${projectData.project_data.procore_id}/budgeting`
    const salesforceURL = `https://arcomurray.lightning.force.com/lightning/r/Opportunity/${projectData.project_data.sfdc_id}/view`
    const hubURL = `https://hub.arcomurray.com/project/${selectedProjectId}/`

    return (
        <div className='project-icons-container' style={{verticalAlign: 'middle'}}>
            <Link id="link-1" href={procoreURL} target="_blank" rel="noopener noreferrer" title="Open Budget in Procore">
                    <img src={procoreLogo} className="project-link-icon" alt="Procore" />
                </Link>
            <Link id="link-2" href={salesforceURL} target="_blank" rel="noopener noreferrer" variant="body2">
                <img src={sfdcLogo} className="project-link-icon" alt="Salesforce" title="Open in Salesforce"/>
            </Link>
            <Link id="link-3" href={hubURL} target="_blank" rel="noopener noreferrer" variant="body2">
                <img src={hubDash} className="project-link-icon" alt="Hub Dash" title="Open Hub Dashboard"/>
            </Link>
        </div>
    )
}

export default ProjectExternalLinks;